// @flow

import { buildAgeFromSeconds } from 'com.batch.common/utils'

import { updatePushMessageRecord } from './update-push-content'

import { getPushSettingsSelector } from '../store/push.selector'
import { AgeFactory, AppFactory, type DispatchBoundFn } from 'com.batch.redux/_records'
import { currentProjectAppsSelector } from 'com.batch.redux/app'

export const initDefaultAdvancedSettings = (messageId: string): DispatchBoundFn<void> => {
  return (dispatch, getState) => {
    const state = getState()
    const apps = currentProjectAppsSelector(state)
    const getSettings = getPushSettingsSelector(state)
    const settings = getSettings(messageId)
    const hasIos = apps.some(app => app.platform === 'ios')
    const hasAndroid = apps.some(app => app.platform === 'ios')
    const iosApp = apps.find(app => app.platform === 'ios', null, AppFactory())
    const androidApp = apps.find(app => app.platform === 'android', null, AppFactory())
    const defaultTtlInHours: number =
      iosApp.pushConfig.defaultTtl ?? androidApp.pushConfig.defaultTtl ?? 0

    const priority =
      (hasIos ? iosApp : androidApp).getIn(['pushConfig', 'defaultPriority']) === 'NORMAL'
        ? 'PUSH_PRIORITY_NORMAL'
        : 'PUSH_PRIORITY_HIGH'
    let updatedSettings = settings
      .set('ttlEnabled', Boolean(defaultTtlInHours))
      .set(
        'ttl',
        defaultTtlInHours
          ? buildAgeFromSeconds(defaultTtlInHours * 60 * 60, ['m', 'h', 'd'], 60, 28 * 24 * 3600)
          : AgeFactory({ unit: 'h', valid: true, seconds: 3600, inputValue: '1' })
      )
      .set('priority', priority)
    if (hasAndroid) {
      const collapseKey = androidApp.getIn(['pushConfig', 'defaultCollapseKey'])
      if (collapseKey) {
        updatedSettings = updatedSettings.setIn(['androidOverride', 'collapseKey'], collapseKey)
      }
    }
    dispatch(
      updatePushMessageRecord({
        messageId,
        settings: updatedSettings,
      })
    )
  }
}

export const initDefaultAdvancedSettingsForCampaign = (): DispatchBoundFn<void> => {
  return (dispatch, getState) => {
    const state = getState()
    if (
      !state.orchestration.id &&
      state.orchestration.campaign.sendType !== 'trigger' &&
      state.orchestration.campaign.channel === 'push' &&
      state.orchestration.campaign.messageTypedId &&
      ['NEW', 'DRAFT'].includes(state.orchestration.state)
    ) {
      dispatch(initDefaultAdvancedSettings(state.orchestration.campaign.messageTypedId))
    }
  }
}
