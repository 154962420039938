// @flow

import * as React from 'react'
import { useSelector } from 'react-redux'

import { Loader } from 'components/common/loader/loader'
import { Icon } from 'components/common/svg-icon'
import { colors } from 'components/styled/tokens'

import { pluralizeAndKformat, kformat } from 'com.batch.common/utils'

import { EstimateProfileChannelToolip } from './estimate-profile-channel-tooltip'
import { EstimateContainer, EstimateTitle, EstimateCount } from './estimate-profile.styles'

import { type EstimateProfileRecord } from '../../models/profile-estimate.records'
import { currentProjectSelector } from 'com.batch.redux/project.selector'
import { subscriptionStatusSelector } from 'com.batch.redux/target/target.selector'

export const EstimateProfile = ({
  estimate,
  forceNonOptin,
  showYesNoWarningPrevious,
  style,
}: {
  estimate: EstimateProfileRecord,
  forceNonOptin?: boolean,
  showYesNoWarningPrevious?: boolean,
  style?: { [string]: string | number },
}): React.Node => {
  const subscriptionStatus = useSelector(subscriptionStatusSelector)
  const project = useSelector(currentProjectSelector)
  const channels = React.useMemo(() => {
    let temp: Array<'sms' | 'email' | 'push'> = []
    if (project.emailConfigured) temp.push('email')
    if (project.smsConfigured) temp.push('sms')
    if (project.pushConfigured) temp.push('push')
    return temp
  }, [project.emailConfigured, project.smsConfigured, project.pushConfigured])

  const isNonOptIn = React.useMemo(() => {
    return Boolean((channels.length === 1 && channels.includes('push')) || forceNonOptin)
  }, [channels, forceNonOptin])

  const matchingField = React.useMemo(() => {
    // push channel does not have a native optin feature
    if (subscriptionStatus === 'marketing' && !isNonOptIn) {
      return 'matchingOptin'
    } else {
      return 'matching'
    }
  }, [subscriptionStatus, isNonOptIn])
  return (
    <EstimateContainer $withSeparator style={style}>
      <EstimateTitle>
        Estimated reach{' '}
        {showYesNoWarningPrevious && (
          <React.Fragment>
            &mdash;{' '}
            <span style={{ color: colors.textLight }}> Not taking into account previous steps</span>
          </React.Fragment>
        )}
      </EstimateTitle>
      {estimate.loading === 'LOADING' ? (
        <Loader size="small" loading style={{ width: 36, height: 30, minHeight: 53 }} />
      ) : (
        <EstimateCount $warn={subscriptionStatus === 'fullbase' && !isNonOptIn}>
          <EstimateProfileChannelToolip
            estimate={estimate}
            channel={undefined}
            forceNonOptin={isNonOptIn}
          >
            <div className="styled-main">
              <div>
                <span className="styled-matching">
                  {pluralizeAndKformat('profile', estimate[matchingField].reachableProfileCount)}
                </span>
                <span className="styled-total">
                  {subscriptionStatus === 'marketing' || isNonOptIn
                    ? ''
                    : ' counting opted out users'}
                </span>
              </div>
            </div>
          </EstimateProfileChannelToolip>

          <div className="styled-sub">
            {channels.map(chan => (
              <EstimateProfileChannelToolip
                estimate={estimate}
                channel={chan}
                key={chan}
                forceNonOptin={isNonOptIn}
              >
                <span className="styled-channel">
                  <Icon icon={chan === 'email' ? 'mail' : chan} />
                  {kformat(
                    estimate[chan === 'push' ? 'matching' : matchingField][chan][
                      chan === 'push' ? 'nonUniqueCount' : 'uniqueCount'
                    ]
                  )}
                </span>
              </EstimateProfileChannelToolip>
            ))}
          </div>
        </EstimateCount>
      )}
    </EstimateContainer>
  )
}
