// @flow
/* eslint-disable react/jsx-no-bind */
import { type List } from 'immutable'
import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'

import { Avatar } from 'components/common/avatar'
import { Grid } from 'components/common/grid'
import Loader from 'components/common/loader-legacy'
import { Pager } from 'components/common/pager'
import {
  Table,
  TableHeader,
  TableCellHeader,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
} from 'components/common/table/index'
import { Tag } from 'components/common/tag'
import { FilterSearch } from 'components/filter'
import { LightText } from 'components/styled/text'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { numberFormat } from 'com.batch.common/utils'

import * as companyActions from './../redux/company'

import { Title, Subtitle } from '../console.style'
import { type CompanyRecord } from 'com.batch.redux/_records'

// ====================== COMPONENT
const CompanyListRaw = (): React.Node => {
  // ====================== REDUX STATES
  const companies: List<CompanyRecord> = useSelector(companyActions.companiesSelector)
  const count: number = useSelector(state => state.company.count)
  const page: number = useSelector(state => state.company.page)
  const nbPerPage: number = useSelector(state => state.company.nbPerPage)
  const loading: boolean = useSelector(state => state.company.loading)
  const filter: string = useSelector(state => state.company.filter)

  // ====================== COMPONENT CONSTANTS
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const params = React.useMemo(() => new URLSearchParams(location.search), [location.search])

  // ====================== COMPONENT USE EFFECT
  React.useEffect(() => {
    const targetPage = params.has('page') ? parseInt(params.get('page')) : 1
    const targetFilter = params.get('query') ?? ''

    if (targetPage !== page) {
      dispatch(companyActions.setPage(targetPage))
    }

    if (targetFilter !== filter) {
      dispatch(companyActions.setFilter(targetFilter))
    }
  }, [location, dispatch, params, page, filter])

  // ====================== RENDER
  return (
    <React.Fragment>
      <Grid template="1fr auto">
        <Grid template="185px 1fr" alignItems="end">
          <Title>All companies</Title>
          <Subtitle>
            {numberFormat(count)} compan{count > 1 ? 'ies' : 'y'}
          </Subtitle>
        </Grid>

        <FilterSearch
          identifier="name or ID"
          value={filter}
          onChange={f =>
            navigate({
              search: `?page=1&query=${encodeURIComponent(f)}`,
            })
          }
        />
      </Grid>

      <br />

      <Loader loading={loading} overlay>
        <Table template="70px 1fr 88px 80px 240px">
          <TableHeader>
            <TableCellHeader>Id</TableCellHeader>
            <TableCellHeader colSpan={2}>Name</TableCellHeader>
            <TableCellHeader>Used seats</TableCellHeader>
            <TableCellHeader>Plan</TableCellHeader>
            <TableCellHeader>Trial plan</TableCellHeader>
          </TableHeader>

          <TableBody>
            {companies.map(company => (
              <TableRow key={company.id}>
                <TableCell>
                  <NavLink to={`/console/company/${company.id}`}>{company.id}</NavLink>
                </TableCell>
                <TableCell>
                  <Avatar
                    style={{ marginRight: 12 }}
                    url={company.avatarUrl}
                    placeholder={company.name}
                    color={'#1c2e43'}
                  />
                  <NavLink to={`/console/company/${company.id}`}>{company.name}</NavLink>
                </TableCell>
                <TableCell>
                  <Tag>
                    {company.usedSeats} / {company.seats}
                  </Tag>
                </TableCell>
                <TableCell>{company.plan && <Tag type="label">{company.plan} </Tag>}</TableCell>
                <TableCell>
                  {company.billing.trial ? (
                    <React.Fragment>
                      <Tag type="label">{company.billing.trial.code}</Tag>
                      {company.billing.trialUntil && (
                        <LightText style={{ marginLeft: 10 }}>
                          (until {dayjs(company.billing.trialUntil).format('DD/MM/YYYY')})
                        </LightText>
                      )}
                    </React.Fragment>
                  ) : (
                    'N/A'
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            {companies.size > 0 && (
              <Pager
                total={count}
                page={page}
                nbPerPage={nbPerPage}
                // selectPage={p => dispatch(companyActions.setPage(p))}
                selectPage={p =>
                  navigate({
                    search: `?page=${p}&query=${filter}`,
                  })
                }
                style={{ margin: '20px 0 10px 0' }}
              />
            )}
          </TableFooter>
        </Table>

        <div style={{ textAlign: 'center', color: '#8f959e' }}>
          <small>
            listing {count} compan{count > 0 ? 'ies' : 'y'}
          </small>
        </div>
      </Loader>
    </React.Fragment>
  )
}

export const CompanyList: React.AbstractComponent<{ ... }> = React.memo<{ ... }>(CompanyListRaw)
