import Immutable from 'immutable'
import { ofType } from 'redux-observable'
import { merge, of, timer } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { catchError, debounce, filter, map, switchMap, takeUntil } from 'rxjs/operators' // eslint-disable-line no-unused-vars

import { generateUrl } from 'com.batch.common/router'

import { loadApps, loadAppsFailure, loadAppsSuccess } from './app'
import {
  loadCompanies,
  loadCompaniesFailure,
  loadCompaniesSuccess,
  loadCompaniesListSuccess,
} from './company'
import { loadProjects, loadProjectsFailure, loadProjectsSuccess } from './project'
import { loadUsers, loadUsersFailure, loadUsersSuccess } from './user'

// eslint-disable-next-line prettier/prettier
import { type AppRecord } from 'com.batch.redux/_records'
import { normalizeApp } from 'com.batch.redux/app.api'
import { normalizeCompany } from 'com.batch.redux/company.api'
import { normalizeProject } from 'com.batch.redux/project.api'
import { normalizeUser } from 'com.batch.redux/user.api'
import { type UserRecord } from 'com.batch.redux/user.records'

// ────────────────────────────────────────────────────────────────────────────────
export const loadUsersEpic = (action$, state$) => {
  return action$.pipe(
    filter(action => {
      if (action.type === 'UPDATE_USERS_FILTER') {
        return true
      } else if (action.type === 'UPDATE_USERS_PAGE') {
        return !state$.value.user.idsPerPage.get(action.payload)
      }
      return false
    }),
    debounce(action => (action.type === 'UPDATE_USERS_FILTER' ? timer(200) : timer(0))),
    switchMap(action => {
      const st = state$.value
      const filters = action.type === 'UPDATE_USERS_FILTER' ? action.payload : st.user.filters
      const page = action.type === 'UPDATE_USERS_PAGE' ? action.payload : st.user.page
      return merge(
        of(loadUsers()),
        ajax({
          url: generateUrl('console_api_users', {
            page,
            companyId: filters.companyId ? filters.companyId : '',
            search: filters.search,
          }),
          method: 'GET',
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          },
        }).pipe(
          map(({ response }) => {
            return loadUsersSuccess({
              entities:  new Immutable.List().push(...response.entities.map(normalizeUser)),
              count: response.count,
              nbPerPage: response.nbPerPage,
              page: response.page,
            })
          }),
          catchError(error => {
            return of(loadUsersFailure(error))
          }),
          takeUntil(action$.pipe(ofType('LOAD_USERS'))), // aborts the XHR on new request
          catchError(error => loadUsersFailure(error))
        )
      )
    })
  )
}

// ────────────────────────────────────────────────────────────────────────────────
export const loadCompanyEpic = (action$, state$) => {
  return action$.pipe(
    filter(action => {
      if (action.type === 'UPDATE_COMPANIES_PAGE') {
        return !state$.value.company.idsPerPage.has(action.payload)
      }
      if (action.type === 'LOAD_USERS_SUCCESS') {
        const st = state$.value
        let ids = []
        action.payload.entities.forEach((u: UserRecord) => {
          u.companiesPermissions.forEach((v, k) => {
            if (!st.company.entities.has(k)) ids.push(k)
          })
        })
        return ids.length > 0
      }
      if (action.type === 'LOAD_APPS_SUCCESS') {
        let ids = []
        action.payload.entities.forEach((a: AppRecord) => {
          ids.push(a.companyId)
        })
        return ids.length > 0
      }
      if (action.type === 'FETCH_USER_SUCCESS' || action.type === 'UPDATE_COMPANIES_FILTER') {
        return true
      }
      return false
    }),
    debounce(action => (action.type === 'UPDATE_COMPANIES_FILTER' ? timer(200) : timer(0))),
    switchMap(action => {
      const st = state$.value
      let ids = []
      let isCompaniesList = false
      const query = {}

      if (action.type === 'FETCH_USER_SUCCESS') {
        action.payload.companiesPermissions.forEach((v, k) => {
          if (!st.company.entities.has(k)) ids.push(k)
        })
      } else if (action.type === 'LOAD_APPS_SUCCESS' || action.type === 'FETCH_APP_SUCCESS') {
        let apps = action.type === 'LOAD_APPS_SUCCESS' ? action.payload.entities : st.app.entities
        apps.forEach((a: AppRecord) => {
          ids.push(a.companyId)
        })
      } else if (action.type === 'LOAD_USERS_SUCCESS' || action.type === 'FETCH_USERS_SUCCESS') {
        action.payload.entities.forEach((u: UserRecord) => {
          u.companiesPermissions.forEach((v, k) => {
            if (!st.company.entities.has(k)) ids.push(k)
          })
        })
      } else if (action.type === 'UPDATE_COMPANIES_PAGE') {
        isCompaniesList = true
        query.page = action.payload
        if (st.company.filter) query.search = st.company.filter
      } else if (action.type === 'UPDATE_COMPANIES_FILTER') {
        isCompaniesList = true
        query.page = 1
        query.search = action.payload
      }

      if (ids.length > 0) {
        query.companyIds = ids.join(',')
      }

      return merge(
        of(loadCompanies()),
        ajax({
          url: generateUrl('console_api_companies', query),
          method: 'GET',
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          },
        }).pipe(
          map(({ response }) => {
            if (isCompaniesList) {
              return loadCompaniesListSuccess({
                entities:  new Immutable.List().push(...response.entities.map(normalizeCompany)),
                count: response.count,
                nbPerPage: response.nbPerPage,
                page: response.page,
              })
            } else {
              return loadCompaniesSuccess({
                entities:  new Immutable.List().push(...response.entities.map(normalizeCompany)),
              })
            }
          }),
          catchError(error => {
            console.log(error)
            return of(loadCompaniesFailure(error))
          }),
          // we need more epic to distinguis between the diffrent cases
          // takeUntil(action$.pipe(ofType('LOAD_COMPANIES'))),
          catchError(error => loadCompaniesFailure(error))
        )
      )
    })
  )
}

// ────────────────────────────────────────────────────────────────────────────────
export const loadAppsEpic = (action$, state$) => {
  return action$.pipe(
    filter(action => {
      if (action.type === 'UPDATE_APPS_PAGE') {
        return !state$.value.app.idsPerPage.get(action.payload)
      } else if (action.type === 'UPDATE_APPS_FILTER') {
        return true
      }
      return false
    }),
    debounce(action => (action.type === 'UPDATE_APPS_FILTER' ? timer(200) : timer(0))),
    switchMap(action => {
      const st = state$.value
      const page = action.type === 'UPDATE_APPS_PAGE' ? action.payload : st.app.page
      const filters = action.type === 'UPDATE_APPS_FILTER' ? action.payload : st.app.filters

      return merge(
        of(loadApps()),
        ajax({
          url: generateUrl('console_api_apps', {
            page,
            companyId: !!filters.companyId && filters.companyId,
            query: filters.query ? filters.query : '',
          }),
          method: 'GET',
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          },
        }).pipe(
          map(({ response }) => {
            return loadAppsSuccess({
              entities:  new Immutable.List().push(...response.entities.map(normalizeApp)),
              count: response.count,
              nbPerPage: response.nbPerPage,
              page: response.page,
            })
          }),
          catchError(error => {
            return of(loadAppsFailure(error))
          }),
          takeUntil(action$.pipe(ofType('UPDATE_APPS_PAGE'))), // aborts the XHR on new request
          catchError(error => loadAppsFailure(error))
        )
      )
    })
  )
}

// ────────────────────────────────────────────────────────────────────────────────
export const loadProjectsEpic = (action$, state$) => {
  return action$.pipe(
    filter(action => {
      if (action.type === 'SET_PROJECTS_PAGE') {
        return !state$.value.project.idsPerPage.get(action.payload)
      } else if (action.type === 'SET_PROJECTS_FILTER') {
        return true
      }
      return false
    }),
    debounce(action => (action.type === 'SET_PROJECTS_FILTER' ? timer(200) : timer(0))),
    switchMap(action => {
      const st = state$.value
      const page = action.type === 'SET_PROJECTS_PAGE' ? action.payload.page : st.project.page
      const nbPerPage = action.type === 'SET_PROJECTS_PAGE' ? action.payload.nbPerPage : st.project.nbPerPage ?? 10
      const filters = action.type === 'SET_PROJECTS_FILTER' ? action.payload : st.project.filters

      return merge(
        of(loadProjects()),
        ajax({
          url: generateUrl('console_api_projects', {
            page,
            count: nbPerPage,
            companyId: !!filters.companyId && filters.companyId,
            query: filters.query ? filters.query : '',
          }),
          method: 'GET',
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          },
        }).pipe(
          map(({ response }) => {
            return loadProjectsSuccess({
              entities:  new Immutable.List().push(...response.entities.map(normalizeProject)),
              apps: response.entities.reduce((acc, projectRaw) => {
                projectRaw.apps.map(normalizeApp).forEach(app => {
                  acc = acc.set(app.id, app)
                })
                return acc
              }, Immutable.Map()),
              count: response.count,
              nbPerPage: response.nbPerPage,
              page: response.page,
            })
          }),
          catchError(error => {
            console.log(error)
            return of(loadProjectsFailure(error))
          }),
          takeUntil(action$.pipe(ofType('SET_PROJECTS_PAGE'))), // aborts the XHR on new request
          catchError(error => loadProjectsFailure(error))
        )
      )
    })
  )
}
