// @flow

import * as React from 'react'
import { Route, Routes } from 'react-router-dom'

import { OmniCampaignForm } from 'components/project/campaign/fake-omni-form'

import { SettingsEmail } from '../app/push/settings-email'

import { CappingRules } from 'com.batch/capping/ui/pages/capping-rules'
import { LabelsList } from 'com.batch/labels/ui/pages/labels-list'
import { OrchestrationLoader } from 'com.batch/orchestration/ui/pages/orchestration-loader'
import { OrchestrationAnalytics } from 'com.batch/orchestration-analytics/ui/components/orchestration-analytics/orchestration-analytics'
import { CampaignForm } from 'com.batch/orchestration-campaign/ui/page/campaign-form'
import { JourneyCanvasForm } from 'com.batch/orchestration-journey/ui/pages/journey-canvas-form'
import { OrchestrationList } from 'com.batch/orchestration-list/ui/pages/orchestration-list'

const baseRoute = '/:companyId/projects/:projectId/'

export const ProjectRouter = (): React.Node => {
  return (
    <div>
      <Routes>
        <Route
          path={baseRoute + 'campaigns/push-multiplatform/new'}
          element={<OmniCampaignForm />}
        />
        <Route
          path={baseRoute + 'campaigns/:channel/:token/analytics/*'}
          element={
            <OrchestrationLoader isAnalytics defaultSendType="scheduled">
              <OrchestrationAnalytics />
            </OrchestrationLoader>
          }
        />
        <Route
          path={baseRoute + 'campaigns/:channel/new'}
          element={
            <OrchestrationLoader defaultSendType="now">
              <CampaignForm />
            </OrchestrationLoader>
          }
        />
        <Route
          path={baseRoute + 'automations/:channel/recurring/new'}
          element={
            <OrchestrationLoader defaultSendType="recurring">
              <CampaignForm />
            </OrchestrationLoader>
          }
        />
        <Route
          path={baseRoute + 'campaigns/:channel/:token'}
          element={
            <OrchestrationLoader defaultSendType="now">
              <CampaignForm />
            </OrchestrationLoader>
          }
        />

        <Route
          path={baseRoute + 'automations/:channel/recurring/:token'}
          element={
            <OrchestrationLoader defaultSendType="recurring">
              <CampaignForm />
            </OrchestrationLoader>
          }
        />
        <Route
          path={baseRoute + 'automations/:channel/recurring/:token/analytics/*'}
          element={
            <OrchestrationLoader isAnalytics defaultSendType="recurring">
              <OrchestrationAnalytics />
            </OrchestrationLoader>
          }
        />
        <Route
          path={baseRoute + 'automations/trigger/:token/analytics/*'}
          element={
            <OrchestrationLoader isAnalytics defaultSendType="trigger">
              <OrchestrationAnalytics />
            </OrchestrationLoader>
          }
        />
        <Route
          path={baseRoute + 'automations/trigger/new'}
          element={
            <OrchestrationLoader defaultSendType="trigger">
              <JourneyCanvasForm />
            </OrchestrationLoader>
          }
        />
        <Route
          path={baseRoute + 'automations/trigger/:token'}
          element={
            <OrchestrationLoader defaultSendType="trigger">
              <JourneyCanvasForm />
            </OrchestrationLoader>
          }
        />
        <Route path={baseRoute + 'automations'} element={<OrchestrationList />} />
        <Route path={baseRoute + 'campaigns'} element={<OrchestrationList />} />
        <Route path={baseRoute + 'settings/email'} element={<SettingsEmail />} />
        <Route path={baseRoute + 'settings/labels'} element={<LabelsList />} />
        <Route path={baseRoute + 'settings/cappings'} element={<CappingRules />} />
        <Route path="*" element={null} />
      </Routes>
    </div>
  )
}
