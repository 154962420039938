// @flow

import * as React from 'react'

import { Button } from 'components/common/button/button.styles'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import * as colors from 'components/styled/tokens/colors'

import { type settingsTabKind } from '../../pages/journey-canvas-form'

type JourneyEntryBlockTabButtonProps = {
  hasCustomTargeting?: boolean,
  hasCustomTiming?: boolean,
  hasQuietTimes?: boolean,
  onClick: (tab: settingsTabKind) => void,
  ...
}

export const JourneyEntryBlockTabButton = ({
  onClick,
  hasCustomTargeting,
  hasCustomTiming,
  hasQuietTimes,
}: JourneyEntryBlockTabButtonProps): React.Node => {
  const createOnClickFor = React.useCallback(
    tab => () => {
      onClick(tab)
    },
    [onClick]
  )
  return (
    <div
      style={{
        width: 121,
        height: 36,
        marginTop: 8,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {' '}
      {/* targetingbouton */}
      <Tooltip tooltip="Targeting" placement="bottom">
        {/* if targeting enabled */}
        <Button onClick={createOnClickFor('targeting')} data-testid="button_icon_targeting">
          <Icon
            color={hasCustomTargeting ? colors.textNeutral : colors.textDisabled}
            icon="targeting"
            size={14}
          />
        </Button>
      </Tooltip>
      {/* timing */}
      <Tooltip tooltip="Timing" placement="bottom">
        <Button
          onClick={createOnClickFor('timings')}
          style={{ marginRight: 4, marginLeft: 4 }}
          data-testid="button_icon_timings"
        >
          <Icon
            color={hasCustomTiming ? colors.textNeutral : colors.textDisabled}
            icon="timer"
            size={14}
          />
        </Button>
      </Tooltip>
      {/* Quiet Times */}
      <Tooltip tooltip="Quiet times" placement="bottom">
        <Button onClick={createOnClickFor('quiet-hours')} data-testid="button_icon_quiethours">
          <Icon
            size={14}
            color={hasQuietTimes ? colors.textNeutral : colors.textDisabled}
            icon="quiet-hours"
          />
        </Button>
      </Tooltip>
    </div>
  )
}
