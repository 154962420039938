// @flow

import Immutable, { type List } from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { PushWhenTriggerEvent } from 'components/campaign/push-when-trigger-event'
import { Button } from 'components/common/button/button.styles'
import { Switch } from 'components/common/button/switch'
import { Grid } from 'components/common/grid'
import { Icon } from 'components/common/svg-icon'
import { TimeIntervalInput } from 'components/common/time-interval-input'
import { Select } from 'components/form'
import { Feedback } from 'components/form/feedback'
import { TimePicker } from 'components/form/fields/time-picker'
import { colors } from 'components/styled/tokens'
import { HorizontalDivider } from 'components/styled/utils'

import { useTimerSettingsExitEventHooks } from './timer-settings-exit-event-hooks'
import { QuietTimesContainer, TimerSettingsContainer } from './timer-settings.styles'

import { type AgeRecord, type EventDataAttributeRecord } from 'com.batch.redux/_records'
import { visibleEventsSelector } from 'com.batch.redux/attribute.selector'

import {
  TIMER_MAX_SECONDS,
  TIMER_MIN_SECONDS,
} from 'com.batch/orchestration-journey/models/constants'
import { type TimerMode } from 'com.batch/orchestration-journey/models/journey.records'
import {
  journeySettingsSelector,
  journeyTreeSelector,
} from 'com.batch/orchestration-journey/models/journey.selectors'
import { QuietTimesSummary } from 'com.batch/orchestration-journey/ui/components/quiet-time-summary'
import { updateNode } from 'com.batch/orchestration-journey/usecases/update-node'

type TimerOption = {
  value: TimerMode,
  label: string,
}

type TimerPeriodOptions = {
  value: TimerMode,
  label: string,
}

type DaysOfWeekOption = {
  value: number,
  label: string,
}

const WHOLE_WEEK = Immutable.Set([0, 1, 2, 3, 4, 5, 6])

const timerOptions: List<TimerOption> = new Immutable.List().push(
  { value: 'for', label: 'Wait for' },
  { value: 'until', label: 'Wait until' },
  { value: 'before', label: 'Wait until date attribute' }
)

const daysOfWeekOptions: List<DaysOfWeekOption> = new Immutable.List().push(
  { value: 7, label: 'on any day' },
  { value: 1, label: 'on Monday' },
  { value: 2, label: 'on Tuesday' },
  { value: 3, label: 'on Wednesday' },
  { value: 4, label: 'on Thursday' },
  { value: 5, label: 'on Friday' },
  { value: 6, label: 'on Saturday' },
  { value: 0, label: 'on Sunday' }
)

const timerPeriodOptions: List<TimerPeriodOptions> = new Immutable.List().push(
  { value: 'before', label: 'before' },
  { value: 'after', label: 'after' }
)

type TimerSettingsProps = {
  nodeId: string,
}

const optionToString = (option: ?TimerOption | TimerPeriodOptions | DaysOfWeekOption) =>
  option?.label ?? ''
const eventDateAttributeToString = (event: ?EventDataAttributeRecord) => event?.name ?? ''

export const TimerSettings = ({ nodeId }: TimerSettingsProps): React.Node => {
  const dispatch = useDispatch()
  const events = useSelector(visibleEventsSelector)
  const journeySettings = useSelector(journeySettingsSelector)
  const {
    createOnDeleteExitEvent,
    createOnEventChange,
    createOnQueryChange,
    onExitEventChange,
    exitEvents,
    onAddExitEvent,
  } = useTimerSettingsExitEventHooks(nodeId)
  const hasExitEvent = React.useMemo(() => exitEvents.size > 0, [exitEvents])

  const enterEvent = React.useMemo(
    () => journeySettings.entryEvents?.first()?.name ?? '',
    [journeySettings]
  )

  const { nodesMap } = useSelector(journeyTreeSelector)

  const node = React.useMemo(() => {
    const fetchedNode = nodesMap.get(nodeId)
    return fetchedNode?.type === 'TIMER' ? fetchedNode : null
  }, [nodesMap, nodeId])

  const onTimerModeChange = React.useCallback(
    (option: ?TimerOption | ?TimerPeriodOptions) => {
      if (option && node) {
        dispatch(updateNode(node.set('mode', option.value)))
      }
    },
    [dispatch, node]
  )

  const onTimerChange = React.useCallback(
    (value: AgeRecord) => {
      if (value && node) dispatch(updateNode(node.set('timer', value)))
    },
    [dispatch, node]
  )

  const onDayOfWeekChange = React.useCallback(
    (option: ?DaysOfWeekOption) => {
      if (option && node) {
        // if every day is selected, set all days of the week
        if (option.value === 7) {
          dispatch(updateNode(node.setIn(['waitUntilTime', 'daysOfWeek'], WHOLE_WEEK)))
        } else {
          dispatch(
            updateNode(node.setIn(['waitUntilTime', 'daysOfWeek'], Immutable.Set([option.value])))
          )
        }
      }
    },
    [dispatch, node]
  )

  const createOnTimeChange = React.useCallback(
    (timeQuantity: 'minute' | 'hour') => (timeValue: number) => {
      if (timeValue !== undefined && node)
        dispatch(updateNode(node.setIn(['waitUntilTime', timeQuantity], timeValue)))
    },
    [dispatch, node]
  )

  const triggerEvents = React.useMemo(
    () => events.find(evt => evt.id === enterEvent),
    [enterEvent, events]
  )

  const dateEventOptions = React.useMemo(
    () =>
      triggerEvents
        ? triggerEvents.allowedKeys.toList().filter(data => data.type === 'DATE')
        : new Immutable.List(),
    [triggerEvents]
  )

  const onDateAttributeChange = React.useCallback(
    value => {
      if (value && node) dispatch(updateNode(node.set('timerReference', value.name)))
    },
    [dispatch, node]
  )

  const gridTemplate = React.useMemo(
    () => (node?.mode !== 'until' ? '1fr 212px' : '1fr 90px 138px'),
    [node?.mode]
  )

  if (!node) {
    return null
  }

  return (
    <TimerSettingsContainer>
      <label style={{ marginBottom: 12 }}>Delay behaviour</label>
      <Grid template={gridTemplate} gap={12} alignItems="center">
        <Select
          options={
            dateEventOptions.size === 0
              ? timerOptions.filter(opt => opt.value !== 'before')
              : timerOptions
          }
          menuOffset={50}
          onChange={onTimerModeChange}
          optionToString={optionToString}
          value={timerOptions.find(
            option => option.value === (node.mode === 'after' ? 'before' : node.mode)
          )}
        />
        {node?.mode !== 'until' && (
          <TimeIntervalInput
            age={node.timer}
            min={node?.mode === 'event' ? TIMER_MIN_SECONDS : 0}
            max={TIMER_MAX_SECONDS}
            onChange={onTimerChange}
            style={{ flexDirection: 'row' }}
          />
        )}
        {node?.mode === 'until' && (
          <React.Fragment>
            <TimePicker
              onHoursChange={createOnTimeChange('hour')}
              onMinutesChange={createOnTimeChange('minute')}
              value={[node.waitUntilTime.hour, node.waitUntilTime.minute]}
            />

            <Select
              options={daysOfWeekOptions}
              onChange={onDayOfWeekChange}
              optionToString={optionToString}
              value={
                node.waitUntilTime.daysOfWeek.size === 1 || !node.waitUntilTime.daysOfWeek
                  ? daysOfWeekOptions.find(
                      option => option.value === node.waitUntilTime.daysOfWeek.first()
                    )
                  : daysOfWeekOptions.first()
              }
            />
          </React.Fragment>
        )}
      </Grid>
      {(node?.mode === 'after' || node?.mode === 'before') && (
        <Grid template="112px 21fr" gap={12} alignItems="center" margin={[12, 0, 0, 0]}>
          <Select
            options={timerPeriodOptions}
            onChange={onTimerModeChange}
            optionToString={optionToString}
            value={
              timerPeriodOptions.find(option => option.value === node.mode) ??
              timerPeriodOptions.first()
            }
          />
          <Select
            options={dateEventOptions}
            placeholder={
              dateEventOptions.size === 0
                ? 'no date attribute available'
                : 'select a date attribute'
            }
            isDisabled={dateEventOptions.size === 0}
            optionToString={eventDateAttributeToString}
            optionFormatter={eventDateAttributeToString}
            value={dateEventOptions.find(data => data.name === node.timerReference)}
            onChange={onDateAttributeChange}
          />
        </Grid>
      )}
      {node.mode !== 'until' && !node.timer.valid && (
        <Feedback
          style={{ marginTop: 10 }}
          message={`Delay must be between ${node.timerReference ? 0 : 1} minute and 30 days.`}
          type="error"
        />
      )}
      <HorizontalDivider style={{ margin: '28px 0' }} />
      <div>
        <Switch isActive={hasExitEvent} onChange={onExitEventChange} disabled={events.size === 0}>
          Exit event(s)
          <Icon icon="exit" style={{ marginLeft: 8 }} />
        </Switch>
        <div style={{ marginBottom: 12 }} />
        {hasExitEvent ? (
          <React.Fragment>
            <Grid template={exitEvents.size <= 1 ? '1fr' : '1fr 23px'} style={{ marginBottom: -2 }}>
              {exitEvents.map(({ eventId, eventName }, key) => (
                <React.Fragment key={key}>
                  <PushWhenTriggerEvent
                    isEmailAutomation
                    queryId={eventId}
                    eventId={eventName}
                    events={events.filter(ev => !ev.id.startsWith('be.'))} // filter native events
                    onQueryChange={createOnQueryChange({ eventId, eventName })}
                    onEventChange={createOnEventChange(eventId)}
                  />
                  {exitEvents.size > 1 && (
                    <Button
                      onClick={createOnDeleteExitEvent(eventId)}
                      style={{ marginTop: -12, height: 28, width: 28 }}
                    >
                      <Icon icon="close" />
                    </Button>
                  )}
                </React.Fragment>
              ))}
            </Grid>
            <Button intent="neutral" addOn="prefix" onClick={onAddExitEvent}>
              <Icon icon="add" />
              Add another event
            </Button>
          </React.Fragment>
        ) : (
          <div style={{ color: colors.textLight }}>
            The user will exit the automation if the specified events are triggered.
          </div>
        )}
      </div>
      {journeySettings.hasQuietTimes && (
        <QuietTimesContainer>
          <HorizontalDivider style={{ margin: '28px 0' }} />
          <label style={{ marginBottom: 12, marginRight: 8 }}>Quiet times enabled</label>
          <Icon icon="quiet-hours" />
          <br />
          <QuietTimesSummary quietTimes={journeySettings.quietTimes} />
        </QuietTimesContainer>
      )}
    </TimerSettingsContainer>
  )
}
