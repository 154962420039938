// @flow
import { type Map, type List } from 'immutable'

import { generateUrl } from 'com.batch.common/router'

import { type AppRecord } from 'com.batch.redux/_records'

import {
  type ImportedTokenRecord,
  type InstallRecord,
  type ProfilePlatformRecord,
  ProfilePlatformFactory,
} from 'com.batch/profile/models/profile.records'

type convertPlatformDataType = ({
  installs: Map<string, InstallRecord>,
  importedTokens: Map<string, ImportedTokenRecord>,
  apps: List<AppRecord>,
  companyId: number,
}) => Array<ProfilePlatformRecord>

export const convertPlatformData: convertPlatformDataType = ({
  installs,
  importedTokens,
  apps,
  companyId,
}) => {
  const result = []

  installs.toArray().forEach(([, install]) =>
    result.push(
      ProfilePlatformFactory({
        id: `install_${install.installID}`,
        deviceName: install.marketingName ? install.marketingName : install.type,
        deviceType: install.type,
        platform: install.platform,
        installId: install.installID,
        pushToken: install.pushToken,
        lastActivity: install.lastActivity,
        subscriptionStatus: install.subscriptionStatus,
        notifType: install.notifType,
        debugUrl: generateUrl('app_settings', {
          appId: apps.find(app => app.platform === install.platform.toLowerCase()).id,
          companyId,
          activeTab: 'debug',
          installation_id: install.installID,
        }),
      })
    )
  )

  importedTokens.toArray().forEach(([, importedToken]) =>
    result.push(
      ProfilePlatformFactory({
        kind: 'imported',
        id: `pushToken_${importedToken.pushToken}`,
        deviceName: 'Unknown device',
        platform: importedToken.platform,
        pushToken: importedToken.pushToken,
        subscriptionStatus: 'SUBSCRIBED',
      })
    )
  )

  return result
}
