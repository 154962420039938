// @flow
import * as React from 'react'

import { Skeleton } from 'components/common/empty-states'
import { Icon } from 'components/common/svg-icon'
import { Ellipsis } from 'components/styled/text'

import { getPlatformList } from 'com.batch/profile/infra/formats/get-platform-list'
import {
  type ProfilePlatformRecord,
  type SubscriptionRecord,
} from 'com.batch/profile/models/profile.records'
import {
  ChannelBlockSubscriptions,
  ProfileBodyTitle,
  ChannelBlock,
} from 'com.batch/profile/ui/components/profile-hero/profile-hero.styles'
import { SubscriptionBlock } from 'com.batch/profile/ui/components/profile-hero/subscription-block/subscription-block'

type Props = {
  isLoading?: boolean,
  subscription: SubscriptionRecord,
  platforms: Array<ProfilePlatformRecord>,
}

export const PushChannelBlock = ({
  isLoading = false,
  platforms,
  subscription,
}: Props): React.Node => {
  const isOnlyImportedPlatforms = React.useMemo(
    () => platforms.some(f => f.kind === 'imported') && subscription.push.status === 'UNSUBSCRIBED',
    [platforms, subscription.push.status]
  )

  const mobilePlatform = React.useMemo(
    () =>
      platforms.filter(p =>
        isOnlyImportedPlatforms
          ? p.kind === 'imported'
          : p.kind === 'native' &&
            (p.platform === 'ANDROID' || p.platform === 'IOS') &&
            p.subscriptionStatus === 'SUBSCRIBED'
      ),
    [isOnlyImportedPlatforms, platforms]
  )

  const optinPlatformList = React.useMemo(() => getPlatformList(mobilePlatform), [mobilePlatform])

  return (
    <ChannelBlock>
      <ProfileBodyTitle>
        <h2>
          <Skeleton w={22} h={22}>
            <Icon icon="push" />
          </Skeleton>
          <Skeleton w={80} h={22} style={{ marginLeft: 8 }}>
            <span>Push</span>
          </Skeleton>
        </h2>
      </ProfileBodyTitle>

      {mobilePlatform.length === 0 && !isLoading ? (
        <div className="no-contact">No mobile device is opted-in to push notifications</div>
      ) : (
        <React.Fragment>
          <Skeleton w={150} h={13}>
            <Ellipsis>{optinPlatformList}</Ellipsis>
          </Skeleton>
          <ChannelBlockSubscriptions>
            <SubscriptionBlock
              channel="push"
              subscriptionName="MARKETING"
              subscriptionStatus={subscription.push.status}
              isOnlyImportedPlatforms={isOnlyImportedPlatforms}
            />
            <SubscriptionBlock
              channel="push"
              subscriptionName="TRANSACTIONAL"
              subscriptionStatus={subscription.push.status}
              isOnlyImportedPlatforms={isOnlyImportedPlatforms}
            />
          </ChannelBlockSubscriptions>
        </React.Fragment>
      )}
    </ChannelBlock>
  )
}
