// @flow
import * as React from 'react'

import { Skeleton } from 'components/common/empty-states'
import { Icon } from 'components/common/svg-icon'

import { PlatformModal } from './platform-modal'
import { ProfilePlatformsList } from './profile-platform-list'

import { type ProfilePlatformRecord } from 'com.batch/profile/models/profile.records'
import {
  ProfileBodyTitle,
  ProfilePlatformContainer,
  PlatformsContainer,
} from 'com.batch/profile/ui/components/profile-hero/profile-hero.styles'

type Props = {
  isLoading: boolean,
  platforms: Array<ProfilePlatformRecord>,
}

export const ProfilePlatforms = ({ isLoading, platforms }: Props): React.Node => {
  const [selectedPlatform, setSelectedPlatform] = React.useState<string | null>(null)

  const platformOnView = React.useMemo(
    () => platforms.find(f => f.id === selectedPlatform) ?? null,
    [platforms, selectedPlatform]
  )

  const onModalClosed = React.useCallback(() => setSelectedPlatform(null), [])

  return (
    <React.Fragment>
      <ProfilePlatformContainer>
        <ProfileBodyTitle>
          <Skeleton w={22} h={22} style={{ marginRight: 8 }}>
            <Icon icon="mobile" />
          </Skeleton>
          <Skeleton w={84} h={22} style={{ marginRight: 8 }}>
            <h2>Platforms</h2>
          </Skeleton>
          <Skeleton w={16} h={22}>
            {platforms.length > 0 && <span>{platforms.length}</span>}
          </Skeleton>
        </ProfileBodyTitle>
        <PlatformsContainer>
          <ProfilePlatformsList
            isLoading={isLoading}
            platforms={platforms}
            setSelectedPlatform={setSelectedPlatform}
          />
        </PlatformsContainer>
      </ProfilePlatformContainer>
      {platformOnView && (
        <PlatformModal opened={!!platformOnView} close={onModalClosed} platform={platformOnView} />
      )}
    </React.Fragment>
  )
}
