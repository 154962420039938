// @flow
import * as React from 'react'

import { EmptyField } from 'components/common/empty-states'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { type ProfilePlatformRecord } from 'com.batch/profile/models/profile.records'
import { PlatformButton } from 'com.batch/profile/ui/components/profile-hero/profile-platforms/platform-button'
import { PlatformButtonSkeleton } from 'com.batch/profile/ui/components/profile-hero/profile-platforms/platform-button.styles'

type Props = {
  isLoading: boolean,
  platforms: Array<ProfilePlatformRecord>,
  setSelectedPlatform: (id: string | null) => void,
}
export const ProfilePlatformsList = ({
  isLoading,
  platforms,
  setSelectedPlatform,
}: Props): React.Node => {
  const onButtonClicked = React.useCallback(
    id => () => setSelectedPlatform(id),
    [setSelectedPlatform]
  )

  const nativePlatforms = React.useMemo(
    () =>
      platforms
        .filter(f => f.kind === 'native')
        .sort((a, b) => {
          const dateA = dayjs(a.lastActivity)
          const dateB = dayjs(b.lastActivity)
          if (dateA.isValid() && dateB.isValid() && dateA.isBefore(dateB)) return 1
          return -1
        }),
    [platforms]
  )

  const importedPlatforms = React.useMemo(
    () => platforms.filter(f => f.kind === 'imported'),
    [platforms]
  )

  if (isLoading)
    return (
      <React.Fragment>
        {Array.from({ length: platforms.length > 0 ? platforms.length : 1 }, (_, i) => (
          <PlatformButtonSkeleton key={`empty_platform-${i}`}>
            <EmptyField _height={16} _width={16} style={{ marginRight: 8 }} />
            <EmptyField _height={16} _width={96} />
            <EmptyField _height={16} _width={200} _display="block" style={{ marginTop: 10 }} />
          </PlatformButtonSkeleton>
        ))}
      </React.Fragment>
    )
  if (platforms.length > 0)
    return (
      <React.Fragment>
        {nativePlatforms.map((install, index) => {
          const props =
            install.platform === 'WEBPUSH'
              ? { href: install.debugUrl }
              : { onClick: onButtonClicked(install.id) }
          return <PlatformButton {...props} key={`install_${index}`} platform={install} />
        })}
        {importedPlatforms.map((importedToken, index) => (
          <PlatformButton
            key={index}
            platform={importedToken}
            onClick={onButtonClicked(importedToken.id)}
          />
        ))}
      </React.Fragment>
    )
  return <p>No platforms attached to this profile.</p>
}
