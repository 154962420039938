// @flow

import Immutable, { type List } from 'immutable'
import * as React from 'react'

import {
  Box,
  BoxHeader,
  BoxBody,
  BoxSection,
  BoxFooter,
  FooterBoxActions,
  HeaderBoxActions,
  HeaderBoxTitle,
} from 'components/common/box'
import { Button } from 'components/common/button'
import { Overlay } from 'components/common/empty-states'
import { Grid } from 'components/common/grid'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'
import { FilterSearch } from 'components/filter'
import { Checkbox } from 'components/form'
import { QueryBuilderContext } from 'components/query/query-builder.context'

import { AttributePicker } from './query.styles'

import { type QueryAttributeRecord } from 'com.batch.redux/query/query.records'

type AddConditionModalProps = {
  closeModal: () => void,
  attributes: List<QueryAttributeRecord>,
  addCondition: QueryAttributeRecord => void,
}

type AttFilter = { id: string, label: string, filter: QueryAttributeRecord => boolean, ... }

const FILTER_ALL: AttFilter = {
  filter: () => true,
  id: 'all',
  label: 'All',
}
const FILTER_NATIVE: AttFilter = {
  filter: attr =>
    attr.api.substring(0, 2) === 'b.' ||
    attr.api.substring(0, 3) === 'bt.' ||
    attr.api.substring(0, 3) === 'be.',
  id: 'native',
  label: 'Native attributes',
}
const FILTER_PROFILE: AttFilter = {
  filter: attr =>
    attr.api.substring(0, 2) !== 'b.' &&
    attr.api.substring(0, 3) !== 'be.' &&
    attr.api.substring(0, 2) !== 'bt.',
  id: 'profile',
  label: 'Profile attributes',
}
const FILTER_CUSTOM: AttFilter = {
  filter: attr =>
    attr.api.substring(0, 1) === 'c' ||
    attr.api.substring(0, 1) === 't' ||
    attr.api.substring(0, 1) === 'e',
  id: 'custom',
  label: 'Custom install data',
}
const FILTER_USER: AttFilter = {
  filter: attr => attr.api.substring(0, 1) === 'u',
  id: 'user',
  label: 'Custom user data',
}

export const AddConditionModal = ({
  closeModal,
  attributes,
  addCondition,
}: AddConditionModalProps): React.Node => {
  const { isProfileMode } = React.useContext(QueryBuilderContext)
  const [selected, setSelected] = React.useState(Immutable.Set())
  const [search, setSearch] = React.useState('')
  const filters = React.useMemo(
    () =>
      isProfileMode
        ? [FILTER_ALL, FILTER_NATIVE, FILTER_PROFILE]
        : [FILTER_ALL, FILTER_NATIVE, FILTER_CUSTOM, FILTER_USER],
    [isProfileMode]
  )
  const [filter, setFilter] = React.useState(filters[0])
  const save = React.useCallback(() => {
    selected.forEach(attribute => addCondition(attribute))
    closeModal()
  }, [addCondition, closeModal, selected])

  const attributesFiltered = React.useMemo(() => {
    /*
      Filter attributes : 
        - matching search (if present)
        - matching selected filter (category)
        - excluding attribute that are not marketing only (selectable in condition.js)
    */
    return attributes
      .sort((a, b) => (a.label.toLocaleLowerCase() < b.label.toLocaleLowerCase() ? -1 : 1))
      .filter(
        attr =>
          (!search || attr.label.toLowerCase().indexOf(search.toLowerCase()) !== -1) &&
          filter.filter(attr) &&
          !['b.last_email_open', 'b.last_email_click'].includes(attr.api)
      )
  }, [attributes, filter, search])
  const onSearch = React.useCallback(
    search => {
      setSearch(search)
      setFilter(filters[0])
    },
    [filters]
  )

  const getOnFilterCallback = React.useCallback(filter => () => setFilter(filter), [])
  const getAttributeCallback = React.useCallback(
    attribute => () =>
      setSelected(selected.has(attribute) ? selected.remove(attribute) : selected.add(attribute)),
    [selected]
  )
  return (
    <Popin opened={true} close={closeModal} style={{ width: 930 }}>
      <Box>
        <BoxHeader>
          <HeaderBoxTitle title="Add condition(s)" />
          <HeaderBoxActions>
            <Button kind="inline" intent="neutral" onClick={closeModal}>
              <Icon icon="close" />
            </Button>
          </HeaderBoxActions>
        </BoxHeader>
        <BoxBody>
          <Grid template={search !== '' ? '1fr' : '1fr auto'} style={{ height: 52 }}>
            <FilterSearch
              onChange={onSearch}
              value={search}
              expandable={false}
              identifier="attributes"
              autoFocus={true}
              containerStyle={{
                height: 52,
                paddingRight: 12,
                paddingLeft: 20,
                paddingTop: 1,
                boxShadow: 'none',
                borderRadius: 0,
              }}
            />
            {search === '' && (
              <div style={{ textAlign: 'right', paddingRight: 10 }}>
                {filters.map(f => (
                  <Button
                    key={f.id}
                    size={24}
                    kind="inline"
                    intent="neutral"
                    onClick={getOnFilterCallback(f)}
                    isActive={f === filter}
                    style={{ marginRight: 8 }}
                  >
                    {f.label}
                  </Button>
                ))}
              </div>
            )}
          </Grid>
          <BoxSection
            style={{ margin: 0, borderBottom: 'none', borderRight: 'none', borderLeft: 'none' }}
          >
            <div
              style={{
                height: 285,
                padding: '16px 14px',
                overflowY: 'auto',
              }}
            >
              {attributesFiltered.map(attribute => (
                <AttributePicker isSelected={selected.has(attribute)} key={attribute.api}>
                  <Checkbox
                    checked={selected.has(attribute)}
                    onChange={getAttributeCallback(attribute)}
                  />
                  {attribute.label}
                  <Icon icon={attribute.icon} thickness={1.3} />
                </AttributePicker>
              ))}
              {attributesFiltered.size === 0 && (
                <div
                  style={{
                    maxWidth: '100%',
                    marginTop: 105,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Overlay
                    status="empty"
                    title="No results matching your search"
                    description="You may want to try again but with different keywords"
                  />
                </div>
              )}
            </div>
          </BoxSection>
        </BoxBody>
        <BoxFooter isEditable>
          <Button kind="inline" intent="neutral" onClick={closeModal}>
            Cancel
          </Button>
          <FooterBoxActions>
            <Button kind="primary" intent="action" onClick={save} disabled={selected.size === 0}>
              Add {selected.size > 0 ? selected.size : ''} condition{selected.size > 1 && 's'}
            </Button>
          </FooterBoxActions>
        </BoxFooter>
      </Box>
    </Popin>
  )
}
