// @flow

import { type availableIcons } from 'components/common/svg-icon'

import { type SubscriptionStatus } from 'com.batch/profile/models/profile.records'
import { type TransmissionType } from 'com.batch/shared/models/transmission-type'

type SubuscriptionMessageProps = {
  status: SubscriptionStatus,
  icon: availableIcons,
  title: string,
  message: string,
}

const ICONS = {
  SUBSCRIBED: 'check',
  SUBSCRIBED_IMPORTED: 'check-dashed',
  UNSUBSCRIBED: 'close',
  UNKNOWN: 'exist',
}

// EMAIL Subscription messesages
export const getEmailSubscriptionMessage = (
  subscriptionName: TransmissionType,
  status: SubscriptionStatus,
  email: ?string
): SubuscriptionMessageProps => {
  const isMarketingEmail = subscriptionName === 'MARKETING'
  const title = `${isMarketingEmail ? 'Marketing' : 'Transactional'} emails`
  const icon = ICONS[status]

  // No email
  if (!email)
    return {
      title,
      icon,
      status: 'UNSUBSCRIBED',
      message: isMarketingEmail
        ? 'This profile can’t receive marketing emails as it has no address attached'
        : 'This profile can’t receive service emails as it doesn’t provide an email address',
    }

  // Marketing email
  if (isMarketingEmail) {
    switch (status) {
      case 'SUBSCRIBED':
        return {
          title,
          icon,
          status,
          message: 'This profile has subscribed to marketing emails',
        }

      case 'UNSUBSCRIBED':
        return {
          title,
          icon,
          status,
          message: 'This profile has unsubscribed from marketing emails',
        }

      case 'UNKNOWN':
        return {
          title,
          icon,
          status,
          message: 'This profile has not yet opted-in to marketing emails',
        }
    }
  }
  // Transactionnal email
  else
    return {
      title,
      icon,
      status: 'SUBSCRIBED',
      message: 'This profile can receive service emails as it provides an email address',
    }
}

// SMS Subscription messesages
export const getSmsSubscriptionMessage = (
  subscriptionName: 'MARKETING' | 'TRANSACTIONAL',
  status: SubscriptionStatus,
  phone: ?string
): SubuscriptionMessageProps => {
  const isMarketingSms = subscriptionName === 'MARKETING'
  const title = `${isMarketingSms ? 'Marketing' : 'Transactional'} SMS`
  const icon = ICONS[status]

  // No phone number
  if (!phone)
    return {
      title,
      icon,
      status: 'UNSUBSCRIBED',
      message: `This profile can’t receive ${
        isMarketingSms ? 'marketing' : 'service'
      } sms as it doesn't provide a phone number`,
    }

  // Marketing sms
  if (isMarketingSms) {
    switch (status) {
      case 'SUBSCRIBED':
        return {
          title,
          icon,
          status,
          message: 'This profile has subscribed to marketing sms messages',
        }

      case 'UNSUBSCRIBED':
        return {
          title,
          icon,
          status,
          message: 'This profile has unsubscribed to marketing sms messages',
        }

      case 'UNKNOWN':
        return {
          title,
          icon,
          status,
          message: 'This profile has not yet subscribed to sms marketing messages',
        }
    }
  }
  // Transactionnal sms
  else
    return {
      title,
      icon,
      status: 'SUBSCRIBED',
      message: 'This profile can receive service sms as it provides a phone number',
    }
}

export const getPushSubscriptionMessage = (
  subscriptionName: 'MARKETING' | 'TRANSACTIONAL',
  status: SubscriptionStatus,
  contact: ?string,
  isOnlyImportedPlatforms: boolean
): SubuscriptionMessageProps => {
  const isMarketingPush = subscriptionName === 'MARKETING'
  const title = '' // will become obsolete
  const icon = ICONS[status]

  if (isOnlyImportedPlatforms)
    return {
      title: 'Considered opted-in',
      icon: ICONS.SUBSCRIBED_IMPORTED,
      status: 'SUBSCRIBED',
      message: 'The push token attached has been imported but not yet detected by our systems',
    }

  if (isMarketingPush) {
    switch (status) {
      case 'SUBSCRIBED':
        return {
          title,
          icon,
          status,
          message: 'At least one mobile device is opted in to push notifications',
        }

      case 'UNSUBSCRIBED':
        return {
          title,
          icon,
          status,
          message: 'All mobile devices are opted out of push notifications',
        }

      case 'UNKNOWN':
        return {
          title,
          icon,
          status,
          message: 'This profile has not yet subscribed to push marketing messages',
        }
    }
  }
  // Transactionnal push
  if (status === 'UNSUBSCRIBED') {
    return {
      title,
      icon,
      status,
      message: 'All mobile devices are opted out of push notifications',
    }
  }
  return {
    title,
    icon,
    status: 'SUBSCRIBED',
    message: 'At least one mobile device is opted in to push notifications',
  }
}

export const getSubscriptionMessageByChannel = (
  channel: 'sms' | 'email' | 'push'
): ((
  subscriptionName: 'MARKETING' | 'TRANSACTIONAL',
  status: SubscriptionStatus,
  identifier: ?string,
  isOnlyImportedPlatforms: boolean
) => SubuscriptionMessageProps) => {
  return {
    sms: getSmsSubscriptionMessage,
    email: getEmailSubscriptionMessage,
    push: getPushSubscriptionMessage,
  }[channel]
}
