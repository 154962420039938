// @flow
import Immutable, {
  type RecordFactory,
  type RecordOf,
  type List,
  type Map,
  type Set,
} from 'immutable'

import { type DateRange } from 'components/form/fields/date-picker/date-range-picker'

import { type fetchingState } from 'com.batch.redux/_records'

import { type OrchestrationListStatsStateRecord } from 'com.batch/orchestration-list/models/orchestration-list-stats-state.records'
import { type PartialOrchestrationRecord } from 'com.batch/orchestration-list/models/partial-orchestration.records'

export type OrchestrationListSortableBy = 'token' | 'name' | 'when' | 'channel'
type OrchestrationListStateProps = {
  loadingState: fetchingState,
  mode: schedulingType,
  entities: Map<string, PartialOrchestrationRecord>,
  tokensPerPage: Map<number, List<string>>,
  data: Map<string, OrchestrationListStatsStateRecord>,
  count: number | null,
  countTotal: number | null,
  page: number,
  nbPerPage: number,
  search: string,
  sortDirection: 'asc' | 'dsc',
  statuses: Set<campaignStateType>,
  channels: Set<ChannelUntilCleanup>,
  labels: Set<string>,
  segments: Set<string>,
  sortBy: OrchestrationListSortableBy,
  dateRange: ?DateRange,
}

export const OrchestrationListStateFactory: RecordFactory<OrchestrationListStateProps> =
  Immutable.Record(
    ({
      loadingState: 'INIT',
      mode: 'campaigns',
      entities: Immutable.Map(),
      search: '',
      tokensPerPage: Immutable.Map(),
      data: Immutable.Map(),
      count: null,
      countTotal: null,
      page: 1,
      nbPerPage: 10,
      sortDirection: 'dsc',
      statuses: Immutable.Set(),
      channels: Immutable.Set(),
      labels: Immutable.Set(),
      segments: Immutable.Set(),
      sortBy: 'token',
      dateRange: null,
    }: OrchestrationListStateProps)
  )

export type OrchestrationListStateRecord = RecordOf<OrchestrationListStateProps>
