// @flow

import { type Set, type List } from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Filter } from 'components/campaign/filters/filter'
import { Icon } from 'components/common/svg-icon'
import { FilterSelectMulti } from 'components/filter'
import * as schemes from 'components/styled/tokens/schemes'

import { pluralize } from 'com.batch.common/utils'

import {
  currentPageSegmentsSelector,
  segmentLoadingStateSelector,
} from 'com.batch/segments/store/segments.selector'

import { MAXIMUM_SEGMENT_LIMIT } from 'com.batch/segments/models/segment.constant'
import { type SegmentRecord } from 'com.batch/segments/models/segment.records'
import { fetchSegmentList } from 'com.batch/segments/usecases/fetch-segment-list'

type SegmentsFilterProps = {
  value: Set<string>,
  onChange: (Set<string>) => void,
}

/*
  currently used in "local" autocomplete mode : fetches all segment on first time and stores them in redux
  if we up MAXIMUM_SEGMENT_LIMIT, we should consider using loadOptions instead
*/
export const SegmentsFilter = ({ value, onChange }: SegmentsFilterProps): React.Node => {
  const segments = useSelector(currentPageSegmentsSelector)
  const { fetchingLoadingState } = useSelector(segmentLoadingStateSelector)
  const dispatch = useDispatch()
  React.useEffect(() => {
    if (fetchingLoadingState === 'LOADING' || fetchingLoadingState === 'LOADED') return
    dispatch(
      fetchSegmentList({
        page: 1,
        search: '',
        nbPerPage: MAXIMUM_SEGMENT_LIMIT,
        sortDirection: 'asc',
        sortField: 'displayName',
      })
    )
  }, [dispatch, fetchingLoadingState])

  const onSelect = React.useCallback(
    (values: List<SegmentRecord>) => {
      onChange(values.map(segment => segment.name).toSet())
    },
    [onChange]
  )

  const valueAsSegmentRecord = React.useMemo(
    () => value.map(name => segments.find(segment => segment.name === name)),
    [value, segments]
  )

  const formatTerm = React.useCallback((count: number) => pluralize('segment', count), [])

  const optToString = React.useCallback((opt: ?SegmentRecord) => opt?.displayName ?? '', [])

  const isLoading = React.useMemo(
    () => fetchingLoadingState === 'LOADING' || fetchingLoadingState === 'INIT',
    [fetchingLoadingState]
  )
  const searchMatch = React.useCallback(
    (term: string, segment: SegmentRecord) => {
      const words = term.toLowerCase().split(' ')
      return (
        words.reduce(
          (nbMatch, word) => nbMatch + (segment.displayName.toLowerCase().includes(word) ? 1 : 0),
          0
        ) === words.length
      )
    },

    []
  )
  return (
    <Filter title="Segments">
      <FilterSelectMulti
        autoFocus={false}
        isDisabled={isLoading}
        options={segments}
        value={valueAsSegmentRecord.toList()}
        onChange={onSelect}
        optionToString={optToString}
        placeholder="All segments"
        noResultsNode={<NoResultsView />}
        isSearchable
        optionFormatter={optionFormatter}
        searchMatchFunction={searchMatch}
        term={formatTerm}
        menuOffset={-250}
        style={{
          columnGap: '0',
          minWidth: '140px',
          maxWidth: '170px',
        }}
      />
    </Filter>
  )
}
const optionFormatter = (segment: SegmentRecord): React.Node => (
  <span style={{ display: 'block', overflow: 'hidden', textOverflow: 'ellipsis' }}>
    {segment.displayName}
  </span>
)
const NoResultsView = (): React.Node => (
  <div>
    <Icon icon="no-result" style={{ marginRight: '6px' }} />
    No segments to display
  </div>
)
