// @flow
import styled, { type PropsWithTheme, type StyledComponent, css } from 'styled-components'

import { Label } from 'components/common/label/label.styles'
import { TableContainer } from 'components/common/table/table.styles'
import { colors, schemes } from 'components/styled/tokens'

import { AttributeRowContainer } from 'com.batch/profile/ui/components/attributes-block/attributes-block.styles'

export const EventRowContent: StyledComponent<*, *, *> = styled.div`
  padding: 0 20px;
  display: flex;
`

export const EventRowDetails: StyledComponent<*, *, *> = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  color: ${colors.text};
`

export const EventTooltipContainer: StyledComponent<*, *, *> = styled.div`
  text-align: left;
  font-size: 14px;

  .tz {
    margin-left: 6px;
    font-weight: 600;
  }
`

export const EventRowDetailsDay: StyledComponent<*, *, *> = styled.div`
  color: ${colors.textLight};
  position: relative;
  margin-left: 8px;

  &::before {
    content: '.';
    position: absolute;
    left: -6px;
    bottom: 3px;
  }
`

export const EventRowContentInformation: StyledComponent<{ expanded?: boolean, ... }, *, *> =
  styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px 12px;
    width: 100%;

    ${(props: PropsWithTheme<{ expanded?: boolean, ... }, any>) =>
      props.expanded &&
      css`
        gap: 8px;
      `}
    ${Label} {
      margin-right: 4px;
    }

    ${TableContainer} {
      margin-top: 8px;
      margin-bottom: 8px;
      background: ${schemes.grayscale['02']};
      border: 1px solid ${schemes.darklucent['20']};
      border-radius: 8px;

      ${AttributeRowContainer} {
        min-height: 40px;
      }
    }
  `

export const EventRowTooltipDay: StyledComponent<*, *, *> = styled.span`
  font-weight: 500;
`

export const EventRowTooltipDayAt: StyledComponent<*, *, *> = styled.span`
  color: ${colors.textContrast};
  opacity: 0.5;
`

export const EventRowTooltipDescription: StyledComponent<*, *, *> = styled.div`
  color: ${colors.textContrast};
  margin-top: 2px;
`

export const EventRowDetailsSource: StyledComponent<*, *, *> = styled.span`
  display: flex;
  cursor: pointer;

  &:hover svg,
  &:hover ${EventRowDetailsDay}, &:hover rect {
    fill: ${colors.textNeutral};
    color: ${colors.textNeutral};
  }
`

export const AttributesContainer: StyledComponent<*, *, *> = styled.span`
  & button {
    height: 24px;
    padding: 6px;
    font-weight: 400;
  }
`
