// @flow

import Immutable, { type OrderedMap, type Set } from 'immutable'
import { get as _get } from 'lodash-es'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { legacyPromiseActionCreator } from './actionCreator'
import api, {
  IsEmailOptin,
  IsSmsOptin,
  HasCustomId,
  AudienceAttribute,
  EmailDomainAttribute,
  SegmentsCollectionAttribute,
  LabelEventParam,
  listNativeAttributesForPlatforms,
  LastEmailClickMarketing,
  LastEmailClick,
  LastEmailOpen,
  LastEmailMarketingOpen,
  LastVisitDate,
} from './attribute.api'

import {
  AttributeStateFactory,
  AttributeFactory,
  type AppRecord,
  type AttributeRecord,
  type AttributeStateRecord,
  type DispatchOnlyBoundFn,
} from 'com.batch.redux/_records'
import { type fetchAppSuccessAction } from 'com.batch.redux/app.action'
import { normalizeApp } from 'com.batch.redux/app.api'
import { type toggleChannelAction } from 'com.batch.redux/campaign.action'
import { type FetchUnifiedCustomerDataSummary } from 'com.batch.redux/corelogic/usecases/unified-customer-data/fetch-unified-customer-data-summary'
import { fetchAnalyticsByDay } from 'com.batch.redux/stat'

export * from './attribute.selector'
// ========================================================
// INITIAL STATE
// ========================================================

let props: { entities?: OrderedMap<string, AttributeRecord>, ... } = {}
if (_get(window, 'initialData.app', false)) {
  const app = normalizeApp(window.initialData.app)
  if (!app.platform) {
    props.entities = Immutable.OrderedMap()
  } else {
    props.entities = Immutable.OrderedMap(
      api.listNativeAttributesForApp(app).map(attr => [attr.id, attr])
    )
  }
}
const initialState = AttributeStateFactory(props)

// ========================================================
// ACTIONS TYPE
// ========================================================
type saveAttributeAction = {
  payload: { app: AppRecord, attribute: AttributeRecord, ... },
  type: 'SAVE_ATTRIBUTE',
  ...
}
export type saveAttributeSuccessAction = {
  payload: { attribute: AttributeRecord, ... },
  type: 'SAVE_ATTRIBUTE_SUCCESS',
  ...
}
export type saveAttributeFailureAction = {
  payload: { attribute: AttributeRecord, error: any, ... },
  type: 'SAVE_ATTRIBUTE_FAILURE',
  ...
}

type fetchAttributesAction = {
  type: 'FETCH_ATTRIBUTES',
  payload: { devMode: boolean, ... },
  ...
}
export type fetchAttributesActionSuccessAction = {
  type: 'FETCH_ATTRIBUTES_SUCCESS',
  payload: { data: OrderedMap<string, AttributeRecord>, profileData: boolean, ... },
  ...
}
export type fetchAttributesActionFailureAction = {
  type: 'FETCH_ATTRIBUTES_FAILURE',
  payload: any,
  ...
}

type updateFilterTermAction = {
  type: 'UPDATE_ATTRIBUTE_FILTER_TERM',
  payload: string,
  ...
}

type updateFilterCatAction = {
  type: 'UPDATE_ATTRIBUTE_FILTER_CAT',
  payload: string,
  ...
}

// temp - @todo : update with an import form attrvalue when it is flowed
type fetchValuesSuccess = {
  type: 'FETCH_ATTRIBUTES_VALUES_SUCCESS',
  payload: any,
  ...
}

// temp - @todo : update with an import form targeting when targeting is flowed
type targetingParseAction = {
  type: 'TARGETING_PARSE',
  payload: { missingAttributes?: Array<AttributeRecord>, ... },
  ...
}

type AttributeActions =
  | saveAttributeAction
  | saveAttributeSuccessAction
  | saveAttributeFailureAction
  | fetchAttributesAction
  | fetchAttributesActionSuccessAction
  | fetchAttributesActionFailureAction
  | FetchUnifiedCustomerDataSummary
  | updateFilterTermAction
  | toggleChannelAction
  | updateFilterCatAction
  | fetchValuesSuccess
  | targetingParseAction
  | fetchAppSuccessAction
// ========================================================
// ACTIONS
// ========================================================

export const fetchAttributes: ({
  app: AppRecord,
  devMode: boolean,
  refresh: boolean,
  ...
}) => DispatchOnlyBoundFn<
  Promise<
    fetchAttributesAction | fetchAttributesActionSuccessAction | fetchAttributesActionFailureAction,
  >,
> = ({ app, devMode = false, refresh = true }) => {
  return dispatch =>
    legacyPromiseActionCreator({
      dispatch,
      payload: { app, devMode },
      promise: api.fetchCustomAttributes({ app, devMode, refresh }),
      actionName: 'FETCH_ATTRIBUTES',
    })
}

export const fetchAttributesAndValues = ({
  app,
  devMode = false,
  withEventDays = false,
}: {
  app: AppRecord,
  devMode?: boolean,
  withEventDays?: boolean,
  ...
}): any => {
  return (dispatch, getState) =>
    legacyPromiseActionCreator({
      dispatch,
      payload: { app, devMode },
      promise: api.fetchCustomAttributes({ app, devMode, refresh: false }),
      actionName: 'FETCH_ATTRIBUTES',
      successCallback: function () {
        // once the custom attr are loaded, we asks for their values
        const attributes = getState().attribute
        const ids = attributes.entities
          .filter(a => !a.hidden)
          .map(a => a.id)
          .toIndexedSeq()
          .toJS()
        dispatch({
          type: 'FETCH_ATTRIBUTES_VALUES',
          payload: {
            ids,
          },
        })

        if (withEventDays) {
          // load daily analytics for event by day count
          dispatch(
            fetchAnalyticsByDay({
              to: dayjs.utc(),
              from: dayjs.utc().subtract(4, 'day'),
              dimension: 'events',
              devMode,
              doubleRange: false,
            })
          )
        }
        // load
        api.fetchAttributesValues({ app, attributesIds: ids, devMode }).then(
          data => {
            dispatch({
              type: 'FETCH_ATTRIBUTES_VALUES_SUCCESS',
              payload: data,
            })
          },
          err => {
            dispatch({
              type: 'FETCH_ATTRIBUTES_VALUES_FAILURE',
              payload: {
                ids,
                error: err,
              },
            })

            console.log(err)
          }
        )
      },
    })
}

export const saveAttribute: ({
  app: AppRecord,
  attribute: AttributeRecord,
  ...
}) => DispatchOnlyBoundFn<
  Promise<saveAttributeAction | saveAttributeSuccessAction | saveAttributeFailureAction>,
> = ({ app, attribute }) => {
  return dispatch =>
    legacyPromiseActionCreator({
      dispatch,
      payload: { app, attribute },
      promise: api.saveCustomAttribute({ app, attribute }),
      actionName: 'SAVE_ATTRIBUTE',
    })
}

export const updateAttributeFilterTerm: (
  filterTerm: string
) => updateFilterTermAction = filterTerm => {
  return {
    type: 'UPDATE_ATTRIBUTE_FILTER_TERM',
    payload: filterTerm,
  }
}

export const updateAttributeFilterCat: (filterCat: string) => updateFilterCatAction = filterCat => {
  return {
    type: 'UPDATE_ATTRIBUTE_FILTER_CAT',
    payload: filterCat,
  }
}

// ========================================================
// REDUCERS
// ========================================================

const itemReducer: (
  state: AttributeRecord,
  action: saveAttributeAction | saveAttributeSuccessAction | saveAttributeFailureAction
) => AttributeRecord = (state = AttributeFactory(), action) => {
  // si c'est pas le bon attribut on fait rien
  if (state.id !== action.payload.attribute.id) {
    return state
  }
  switch (action.type) {
    case 'SAVE_ATTRIBUTE':
      return state.set('loading', true)
    case 'SAVE_ATTRIBUTE_FAILURE':
      return state.set('loading', false)
    case 'SAVE_ATTRIBUTE_SUCCESS':
      return action.payload.attribute
    default:
      return state
  }
}

export default function attributeReducer(
  state: AttributeStateRecord = initialState,
  action: AttributeActions
): AttributeStateRecord {
  switch (action.type) {
    case 'CAMPAIGN_TOGGLE_CHANNELS': {
      const platforms: Set<Platforms> = action.payload.reduce(
        (acc, channel) => (channel !== 'email' && channel !== 'sms' ? acc.add(channel) : acc),
        Immutable.Set()
      )
      return state.set(
        'entities',
        platforms.size === 0
          ? Immutable.OrderedMap()
          : Immutable.OrderedMap(
              listNativeAttributesForPlatforms(platforms).map(attr => [attr.id, attr])
            )
      )
    }
    case 'FETCH_ATTRIBUTES_VALUES_SUCCESS':
      return state.set('config', state.config.set('valuesLoaded', true)).set(
        'entities',
        state.entities.map((attribute, attrId) => {
          if (attribute.category === 'event' && _get(action.payload, attrId, []).length > 0) {
            return attribute.set('allowedKeys', attribute.allowedKeys.add(LabelEventParam))
          } else {
            return attribute
          }
        })
      )
    case 'TARGETING_PARSE':
      if (
        typeof action.payload.missingAttributes === 'undefined' ||
        !Array.isArray(action.payload.missingAttributes) ||
        action.payload.missingAttributes.length === 0
      ) {
        return state
      } else {
        action.payload.missingAttributes.map(attr => {
          state = state.set('entities', state.entities.set(attr.id, attr))
        })
        return state
      }
    case 'UPDATE_ATTRIBUTE_FILTER_TERM':
      return state.set('config', state.config.set('filterTerm', action.payload))
    case 'UPDATE_ATTRIBUTE_FILTER_CAT':
      return state.set('config', state.config.set('filterCategory', action.payload))
    case 'SAVE_ATTRIBUTE':
    case 'SAVE_ATTRIBUTE_FAILURE':
    case 'SAVE_ATTRIBUTE_SUCCESS':
      return state.setIn(
        ['entities', action.payload.attribute.id],
        itemReducer(state.entities.get(action.payload.attribute.id, AttributeFactory()), action)
      )
    case 'FETCH_APP_BY_ID_SUCCESS':
      api.listNativeAttributesForApp(action.payload.app).forEach(attr => {
        state = state.setIn(['entities', attr.id], attr)
      })
      return state
    case 'FETCH_UNIFIED_CUSTOMER_DATA_SUMMARY':
      return state
        .set(
          'config',
          state.config.set('attributeLoadingState', 'LOADING').set('profileDataMode', true)
        )
        .set(
          'entities',
          Immutable.OrderedMap()
            .set('b.email_domain', EmailDomainAttribute)
            .set('bt.custom_audiences', AudienceAttribute)
            .set('bt.segments', SegmentsCollectionAttribute)
            .set('b.last_email_click', LastEmailClick)
            .set('b.last_email_click_marketing', LastEmailClickMarketing)
            .set('b.last_email_marketing_open', LastEmailMarketingOpen)
            .set('b.last_email_open', LastEmailOpen)
            .set('b.is_email_optin', IsEmailOptin)
            .set('b.is_sms_optin', IsSmsOptin)
        )
    case 'FETCH_ATTRIBUTES':
      return state
        .set(
          'config',
          state.config
            .set('attributeLoadingState', 'LOADING')
            .set('devMode', action.payload.devMode)
        )
        .set(
          'entities',
          state.entities.filter(attr => attr.native)
        )
    case 'FETCH_UNIFIED_CUSTOMER_DATA_SUMMARY_SUCCESS':
      return state
        .set(
          'config',
          state.config.set('attributeLoadingState', 'LOADED').set('profileDataMode', true)
        )
        .set(
          'entities',
          action.payload
            .set('b.email_domain', EmailDomainAttribute)
            .set('bt.custom_audiences', AudienceAttribute)
            .set('b.has_custom_od', HasCustomId)
            .set('bt.segments', SegmentsCollectionAttribute)
            .set('b.last_email_click', LastEmailClick)
            .set('b.last_email_click_marketing', LastEmailClickMarketing)
            .set('b.last_email_marketing_open', LastEmailMarketingOpen)
            .set('b.last_email_open', LastEmailOpen)
            .set('b.is_email_optin', IsEmailOptin)
            .set('b.last_visit_date', LastVisitDate)
            .set('b.is_sms_optin', IsSmsOptin)
        )
    case 'FETCH_ATTRIBUTES_SUCCESS':
      return (
        state
          // on update la config
          .set(
            'config',
            state.config
              .set('attributeLoadingState', 'LOADED')
              .set('profileDataMode', action.payload.profileData)
          )
          // on garde que les natives, & on merge dedans les custom retournés
          .set('entities', state.entities.merge(action.payload.data))
      )
    case 'FETCH_ATTRIBUTES_FAILURE':
      return state
    default:
      return state
  }
}
