// @flow

import { type SendTestPushResponseType } from '../infra/types/send-test-push.types'
import {
  getPushContentForActiveLanguageSelector,
  getPushSettingsSelector,
} from '../store/push.selector'
import { type DispatchExtraBoundFn } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

import { sendTestPushRequest } from 'com.batch/push/infra/send-test-push.api'

export type SendTestRecipientIdKind = 'customID' | 'installID'
export type SendTestRecipientId = {
  id: string,
  kind: SendTestRecipientIdKind,
}

export type SendTestPushSuccessAction = {
  type: 'SEND_TEST_PUSH_SUCCESS',
  payload: SendTestPushResponseType,
}

export type SendTestPushFailureAction = {
  type: 'SEND_TEST_PUSH_FAILURE',
  payload: {
    error: { errors: Array<{ message: string }> },
  },
}

export const sendTestPush = ({
  messageId,
  recipientId,
}: {
  messageId: string,
  recipientId: SendTestRecipientId,
}): DispatchExtraBoundFn<Promise<SendTestPushResponseType>> => {
  return (dispatch, getState) => {
    const state = getState()
    const getContent = getPushContentForActiveLanguageSelector(state)
    const getSettings = getPushSettingsSelector(state)
    const content = getContent(messageId)
    const settings = getSettings(messageId)
    const project = currentProjectSelector(state)

    return promiseActionCreator({
      dispatch,
      promise: sendTestPushRequest({
        projectKey: project.projectKey,
        push: content,
        settings,
        recipientId,
      }),
      actionName: 'SEND_TEST_PUSH',
    })
  }
}
