// @flow
import { type List } from 'immutable'

import { parseBounceCategories } from '../infra/parses/parse-bounce-categories'
import { type BounceCategoryRecord } from '../models/orchestration-stats.record'
import { orchestrationAnalyticsMapSelector } from '../store/orchestration-analytics.selector'
import { type DispatchExtraBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

export type FetchOrchestrationStatsByClassificationAction = ReduxAction<
  'FETCH_ORCHESTRATION_STATS_BY_CLASSIFICATION',
  string,
>

export type FetchOrchestrationStatsByClassificationSuccessAction = ReduxAction<
  'FETCH_ORCHESTRATION_STATS_BY_CLASSIFICATION_SUCCESS',
  { data: List<BounceCategoryRecord>, token: string },
>

export type FetchOrchestrationStatsByClassificationFailureAction = ReduxAction<
  'FETCH_ORCHESTRATION_STATS_BY_CLASSIFICATION_FAILURE',
  { token: string, error: Error },
>

export type FetchOrchestrationStatsByClassificationActions =
  | FetchOrchestrationStatsByClassificationAction
  | FetchOrchestrationStatsByClassificationSuccessAction
  | FetchOrchestrationStatsByClassificationFailureAction

export const fetchStatsByClassification =
  ({ token }: { token: string }): DispatchExtraBoundFn<Promise<void>> =>
  async (dispatch, getState, { dataService }) => {
    const state = getState()

    const analyticsMap = orchestrationAnalyticsMapSelector(state)
    const loadingState = analyticsMap.get(token)?.classificationsLoadingState ?? 'INIT'
    const expire = analyticsMap.get(token)?.expire ?? -Infinity
    if (
      loadingState !== 'LOADING' &&
      (!['ERROR', 'LOADED'].includes(loadingState) || expire < Date.now())
    ) {
      const project = currentProjectSelector(state)
      const fetchIntentAction: FetchOrchestrationStatsByClassificationAction = {
        type: 'FETCH_ORCHESTRATION_STATS_BY_CLASSIFICATION',
        payload: token,
      }
      dispatch(fetchIntentAction)
      try {
        const data = await dataService.fetchDynamicStats({
          project,
          token,
          groupDimensionNames: ['bounce_classification'],
          parser: parseBounceCategories,
        })
        const fetchSuccessAction: FetchOrchestrationStatsByClassificationSuccessAction = {
          type: 'FETCH_ORCHESTRATION_STATS_BY_CLASSIFICATION_SUCCESS',
          payload: { data, token },
        }
        dispatch(fetchSuccessAction)
      } catch (error) {
        const fetchFailureAction: FetchOrchestrationStatsByClassificationFailureAction = {
          type: 'FETCH_ORCHESTRATION_STATS_BY_CLASSIFICATION_FAILURE',
          payload: { token, error },
        }
        dispatch(fetchFailureAction)
      }
    }
  }
