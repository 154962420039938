// @flow

import Immutable, { type RecordFactory, type RecordOf, type Map, type List } from 'immutable'

import { type ClickLinkRecord } from '../models/click-links-detail.record'
import { type OrchestrationStatsByStepRecord } from '../models/orchestration-stats-by-step.record'
import { type StatsRecord, type BounceCategoryRecord } from '../models/orchestration-stats.record'

import { type BounceDetailRecord } from 'com.batch/orchestration-analytics/models/bounce-detail-record'
import { type EmailProviderRecord } from 'com.batch/orchestration-analytics/models/orchestration-stats-by-provider.record'
import { STATUS } from 'constants/common'

type BounceAnalytics = {
  periods: Immutable.Map<string, BounceDetailRecord>,
  categories: ?List<BounceCategoryRecord>,
}
export const BounceAnalyticsFactory: RecordFactory<BounceAnalytics> = Immutable.Record({
  periods: Immutable.Map(),
  categories: null,
})
export type BounceAnalyticsRecord = RecordOf<BounceAnalytics>

export type EmailAnalyticsProps = {
  stats: ?StatsRecord,
  bounces: BounceAnalyticsRecord,
  providers: Immutable.List<EmailProviderRecord>,
  links: List<ClickLinkRecord>,
}
export const EmailAnalyticsFactory: RecordFactory<EmailAnalyticsProps> = Immutable.Record({
  stats: null,
  bounces: BounceAnalyticsFactory(),
  providers: Immutable.List(),
  links: Immutable.List(),
})
export type EmailAnalyticsRecord = RecordOf<EmailAnalyticsProps>

export type SmsAnalyticsProps = {
  stats: ?StatsRecord,
}
export type SmsAnalyticsRecord = RecordOf<SmsAnalyticsProps>

export type PushAnalyticsProps = {
  stats: ?StatsRecord,
}
export type PushAnalyticsRecord = RecordOf<PushAnalyticsProps>

export type OrchestrationChannelsStatsProps = {
  email: EmailAnalyticsRecord,
  sms: SmsAnalyticsRecord,
  push: PushAnalyticsRecord,
}
export const OrchestrationChannelsStatsFactory: RecordFactory<OrchestrationChannelsStatsProps> =
  Immutable.Record({
    email: Immutable.Record({
      stats: null,
      bounces: Immutable.Map(),
      providers: Immutable.List(),
      links: Immutable.List(),
    })(),
    sms: Immutable.Record({
      stats: null,
    })(),
    push: Immutable.Record({
      stats: null,
    })(),
  })
export type OrchestrationChannelsStatsRecord = RecordOf<OrchestrationChannelsStatsProps>

export type OrchestrationAnalyticsProps = {
  expire: number,
  steps: ?OrchestrationStatsByStepRecord,
  channels: OrchestrationChannelsStatsRecord,
  channelsLoadingState: LoadingState,
  daysLoadingState: LoadingState,
  stepsLoadingState: LoadingState,
  providersLoadingState: LoadingState,
  classificationsLoadingState: LoadingState,
  linksLoadingState: LoadingState,
}

export const OrchestrationAnalyticsFactory: RecordFactory<OrchestrationAnalyticsProps> =
  Immutable.Record({
    expire: Date.now() + 1000 * 60 * 2, // TTL 2 min
    steps: null,
    channels: OrchestrationChannelsStatsFactory(),
    channelsLoadingState: STATUS.INIT,
    daysLoadingState: STATUS.INIT,
    stepsLoadingState: STATUS.INIT,
    providersLoadingState: STATUS.INIT,
    classificationsLoadingState: STATUS.INIT,
    linksLoadingState: STATUS.INIT,
  })

export type OrchestrationAnalyticsRecord = RecordOf<OrchestrationAnalyticsProps>

export type OrchestrationAnalyticsState = Map<string, OrchestrationAnalyticsRecord>
