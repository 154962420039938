// @flow

import { useEffect } from 'react'

const preloadImg = (url: string) => {
  var link = document.createElement('link')
  link.rel = 'preload'
  link.as = 'image'
  link.href = url
  document.head?.appendChild(link)
}

export const usePreloadImages = (images: string[]) => {
  useEffect(() => {
    images.forEach(url => {
      preloadImg(url)
    })
  }, [images])
}
