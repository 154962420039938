// @flow

import { useTransition } from '@react-spring/web'
import * as React from 'react'
import { type Node } from 'react'

import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'

import { CollapsePanelContainer, CollapsePanelContent } from './collapse.styles'

export type CollapseProps = {
  children: Node,
  btnLabel: string,
}

export const Collapse = ({ children, btnLabel }: CollapseProps): React.Node => {
  const [isOpened, setIsOpened] = React.useState<boolean>(false)
  const handleOnClick = React.useCallback(() => setIsOpened(prev => !prev), [setIsOpened])
  return (
    <React.Fragment>
      <Button kind="discreet" addOn="prefix" onClick={handleOnClick}>
        {btnLabel}
        <Icon icon={isOpened ? 'chevron-up' : 'chevron-down'} />
      </Button>
      <CollapsePanel isOpened={isOpened} isStandalone={false}>
        {children}
      </CollapsePanel>
    </React.Fragment>
  )
}

export type CollapsePanelProps = {
  isOpened: boolean,
  children: React.Node,
  isStandalone?: boolean,
  scrollIntoView?: boolean,
}
export const CollapsePanel = ({
  isOpened,
  children,
  isStandalone = true,
  scrollIntoView = false,
}: CollapsePanelProps): React.Node => {
  const collapseRef = React.useRef<?HTMLDivElement>(null)

  const transformProps = React.useMemo(() => {
    const initial = {
      opacity: 0,
      gridTemplateRows: '0fr',
    }

    return {
      initial,
      from: initial,
      enter: {
        opacity: 1,
        gridTemplateRows: '1fr',
      },
      leave: initial,
      onChange: () => {
        if (scrollIntoView) collapseRef.current?.scrollIntoView({ behavior: 'instant' })
      },
    }
  }, [scrollIntoView])
  const transitions = useTransition(isOpened, transformProps)

  return transitions((style, item) =>
    item ? (
      <CollapsePanelContainer ref={collapseRef} style={style} $isStandalone={isStandalone}>
        <CollapsePanelContent>{children}</CollapsePanelContent>
      </CollapsePanelContainer>
    ) : null
  )
}
