// @flow

import { type List } from 'immutable'
import * as React from 'react'
import { useTheme } from 'styled-components'

import { SelectNoSearch } from './select-no-search'
import { SelectSearch } from './select-search'
import { type CommonSelectProps } from './select.helper'

export type BaseSelectProps<T> = {
  ...CommonSelectProps<T>,
  value: ?T,
  options?: List<T>,
  loadOptions?: string => Promise<List<T>>,
  optionToString: (?T) => string,
  onChange: (?T) => void,
  invalid?: boolean,
  isLoading?: boolean,
  optionCreator?: string => T,
  localSearchEveryWhere?: boolean,
  tooltip?: React$Node,
  header?: React.Node,
}
export type SelectProps<T> = {
  ...BaseSelectProps<T>,
  isSearchable?: boolean,
}

export function Select<T>({
  isSearchable = false,
  isDisabled = false,
  ...rest
}: SelectProps<T>): React.Node {
  const theme = useTheme()
  const computedDisabled = React.useMemo(() => {
    return Boolean(isDisabled || theme?.disabledMode)
  }, [isDisabled, theme?.disabledMode])

  return isSearchable || rest.optionCreator ? (
    <SelectSearch {...rest} isDisabled={computedDisabled} />
  ) : (
    <SelectNoSearch {...rest} isDisabled={computedDisabled} />
  )
}
