// @flow
import { type Dayjs } from 'dayjs'
import { type Map } from 'immutable'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { parseBounceByDays } from '../infra/parses/parse-bounce-by-days'
import { type BounceDetailRecord } from '../models/bounce-detail-record'
import { orchestrationAnalyticsMapSelector } from '../store/orchestration-analytics.selector'
import { type DispatchExtraBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

import { type OrchestrationCampaignRecord } from 'com.batch/orchestration-campaign/models/campaign.records'

export type FetchOrchestrationStatsByDayAction = ReduxAction<
  'FETCH_ORCHESTRATION_STATS_BY_DAY',
  string,
>

export type FetchOrchestrationStatsByDaySuccessAction = ReduxAction<
  'FETCH_ORCHESTRATION_STATS_BY_DAY_SUCCESS',
  { data: Map<string, BounceDetailRecord>, token: string },
>

export type FetchOrchestrationStatsByDayFailureAction = ReduxAction<
  'FETCH_ORCHESTRATION_STATS_BY_DAY_FAILURE',
  { token: string, error: Error },
>

export type FetchOrchestrationStatsByDayActions =
  | FetchOrchestrationStatsByDayAction
  | FetchOrchestrationStatsByDaySuccessAction
  | FetchOrchestrationStatsByDayFailureAction

export const getBounceDateRange = (
  campaign: OrchestrationCampaignRecord
): { dateFrom: Dayjs, dateTo: Dayjs } => {
  const dateTo = dayjs.utc()
  let dateFrom = dayjs.utc()

  if (campaign.sendType === 'trigger' || campaign.sendType === 'scheduled') {
    dateFrom = dateFrom.subtract(13, 'day')
  } else if (campaign.recurrent.recurrence === 'MONTHLY') {
    dateFrom = dateFrom.subtract(1, 'year')
  } else if (campaign.recurrent.recurrence === 'WEEKLY') {
    dateFrom = dateFrom.subtract(3, 'month')
  } else if (campaign.recurrent.recurrence === 'DAILY') {
    dateFrom = dateFrom.subtract(13, 'day')
  }

  return { dateFrom, dateTo }
}

export const fetchStatsByDay =
  ({ token }: { token: string }): DispatchExtraBoundFn<Promise<void>> =>
  async (dispatch, getState, { dataService }) => {
    const state = getState()
    const orchestration = state.orchestration.campaign

    const analyticsMap = orchestrationAnalyticsMapSelector(state)
    const loadingState = analyticsMap.get(token)?.daysLoadingState ?? 'INIT'
    const expire = analyticsMap.get(token)?.expire ?? -Infinity
    if (
      loadingState !== 'LOADING' &&
      (!['ERROR', 'LOADED'].includes(loadingState) || expire < Date.now())
    ) {
      const project = currentProjectSelector(state)
      const fetchIntentAction: FetchOrchestrationStatsByDayAction = {
        type: 'FETCH_ORCHESTRATION_STATS_BY_DAY',
        payload: token,
      }
      dispatch(fetchIntentAction)
      try {
        const data = await dataService.fetchDynamicStats({
          project,
          token,
          groupDimensionNames: ['day'],
          parser: parseBounceByDays,
          ...getBounceDateRange(orchestration),
        })
        const fetchSuccessAction: FetchOrchestrationStatsByDaySuccessAction = {
          type: 'FETCH_ORCHESTRATION_STATS_BY_DAY_SUCCESS',
          payload: { data, token },
        }
        dispatch(fetchSuccessAction)
      } catch (error) {
        const fetchFailureAction: FetchOrchestrationStatsByDayFailureAction = {
          type: 'FETCH_ORCHESTRATION_STATS_BY_DAY_FAILURE',
          payload: { token, error },
        }
        dispatch(fetchFailureAction)
      }
    }
  }
