// @flow

import { type List } from 'immutable'
import * as React from 'react'

import { useWidthObserver } from 'components/_hooks'
import { BatchLogo } from 'components/common/batch-logo'
import { ButtonLink, Button } from 'components/common/button'
import { getConsent } from 'components/common/page-tracker'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'

import { type ChannelInUrl, useBuildProjectNav, useProjectSwitcher } from 'com.batch.common/router'

import {
  NavbarContainer,
  NavbarAccountContainer,
  NavbarMenu,
  Separator,
  RedBadge,
} from './navbar.styles'
import { ProfileDropDown } from './profile-dropdown'
import { ProjectsDropdown } from './projects-dropdown'

import { type AppRecord, type CompanyRecord } from 'com.batch.redux/_records'
import { type ProjectRecord } from 'com.batch.redux/project.records'
import { type UserRecord } from 'com.batch.redux/user.records'

type NavbarProps = {
  user: UserRecord,
  company: CompanyRecord,
  projects: List<ProjectRecord>,
  channel: ChannelInUrl,
  allApps: List<AppRecord>,
  project: ProjectRecord,
  showProjectNav?: boolean,
}

const Navbar = ({
  user,
  projects,
  allApps,
  channel,
  company,
  project,
  showProjectNav = true,
}: NavbarProps): React.Node => {
  const ref = React.useRef(null)
  const [ready, setReady] = React.useState(false)
  const [hasMarketingCookies, setHasMarketingCookies] = React.useState(null)
  const width = useWidthObserver(ref, 1200)
  const [hasNews, setHasNews] = React.useState(false)
  const breakpoint = React.useMemo(
    () => (width < 580 ? 'small' : width < 1150 ? 'medium' : 'large'),
    [width]
  )
  const callback = React.useCallback((nbr: number) => {
    setHasNews(nbr > 0)
    setReady(true)
  }, [])

  // init beamer and trigger js load
  React.useEffect(() => {
    if (!window.disable_third_party_scripts) {
      const config: { [string]: mixed } = {
        product_id: 'RAAGRAVw20494',
        display: 'left',
        counter: false,
        alert: false,
        callback,
      }
      if (window.user?.externalId) {
        config['user_id'] = window.user?.externalId
      }
      window.beamer_config = config
      const script = document.createElement('script')
      script.src = 'https://app.getbeamer.com/js/beamer-embed.js'
      document.body && document.body.appendChild(script)
    }
  }, [callback])
  const navData = useBuildProjectNav({
    project,
    channel,
  })
  const openBeamer = React.useCallback(
    () => ready && window.Beamer && window.Beamer.show() && setHasNews(false),
    [ready]
  )
  const generateUrlForProject = useProjectSwitcher({
    project,
    channel,
  })

  // Get cookie consent
  React.useEffect(() => {
    if (hasMarketingCookies === null) {
      getConsent()
        .then(r => setHasMarketingCookies(r.includes('MARKETING')))
        .catch(() => setHasMarketingCookies(false))
    }
  }, [hasMarketingCookies])

  if (!showProjectNav)
    return (
      <NavbarAccountContainer ref={ref} $breakpoint={breakpoint}>
        <a href="#" className="navbar-logo">
          <BatchLogo />
        </a>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ButtonLink href="/" kind="inline" intent="neutral" prefix="return">
            Back to dashboard
          </ButtonLink>
          <Separator style={{ margin: '0 8px 0 0' }} />
          <ProfileDropDown user={user} company={company} light style={{ margin: '3px' }} />
        </div>
      </NavbarAccountContainer>
    )
  return (
    <NavbarContainer $project ref={ref} $breakpoint={breakpoint} data-testid="cep-navbar">
      <ProjectsDropdown
        projects={projects}
        apps={allApps}
        project={project}
        generateUrlForProject={generateUrlForProject}
      />
      {breakpoint !== 'small' && <Separator style={{ margin: '0 12px 0 0' }} />}

      <NavbarMenu className="navbar-navigation styled-react-to-breakpoint">
        {navData.map((item, index) => (
          <Tooltip key={index} isTooltipEmpty={breakpoint === 'large'} tooltip={item.label}>
            <ButtonLink
              href={item.url}
              isActive={item.isActive}
              kind="inlineDark"
              intent="neutral"
              addOn={item.icon ? 'prefix' : undefined}
              addOnGap={10}
              data-testid={`nav-${item.id}`}
            >
              {item.icon && <Icon icon={item.icon} />}
              <span className="styled-hide-on-medium">{item.label}</span>
            </ButtonLink>
          </Tooltip>
        ))}
      </NavbarMenu>

      <NavbarMenu className="navbar-navigation" style={{ justifyContent: 'flex-end' }}>
        {breakpoint !== 'small' && <Separator style={{ marginRight: 10 }} />}
        <Tooltip tooltip="Documentation">
          <span className="styled-react-to-breakpoint">
            <ButtonLink
              href="https://doc.batch.com"
              target="_blank"
              kind="inlineDark"
              intent="neutral"
              className="navbar-optional"
            >
              <Icon icon="documentation" />
            </ButtonLink>
          </span>
        </Tooltip>
        {breakpoint !== 'small' && (
          <React.Fragment>
            <style type="text/css">{'body > #beamerLastPostTitle{ display:none; }'}</style>
            <Tooltip
              tooltip={
                hasMarketingCookies ? (
                  "See what's new"
                ) : (
                  <div style={{ width: 150 }}>
                    To see What’s new, please enable marketing cookies
                  </div>
                )
              }
            >
              <span className="styled-react-to-breakpoint">
                <Button
                  onClick={openBeamer}
                  kind="inlineDark"
                  intent="neutral"
                  className="navbar-optional"
                  style={{
                    marginRight: 12,
                    cursor: hasMarketingCookies ? 'default' : 'not-allowed',
                  }}
                  disabled={!hasMarketingCookies}
                >
                  {hasNews ? (
                    <React.Fragment>
                      <RedBadge />
                      <Icon style={{ position: 'relative' }} color="currentColor" icon="news" />
                    </React.Fragment>
                  ) : (
                    <Icon icon="news" />
                  )}
                </Button>
              </span>
            </Tooltip>
          </React.Fragment>
        )}
        <ProfileDropDown user={user} company={company} />
      </NavbarMenu>
    </NavbarContainer>
  )
}

export { Navbar }
