// @flow
import styled, { type StyledComponent } from 'styled-components'

import { Button } from 'components/common/button'
import { text, textLight, textNeutral } from 'components/styled/tokens/colors'

export const LangPickerContainer: StyledComponent<{}, {}, HTMLDivElement> = styled.div`
  --margin-inline: 20px;
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - var(--margin-inline) * 2);
  line-height: 36px;
  background: white;
  gap: 8px;
  margin-block: 8px;
  margin-inline: var(--margin-inline);

  ${Button} {
    color: ${textNeutral};
    padding-block: 0;
  }

  /* Les boutons avec icon de suppression pas de padding right, le last button = add lang button */
  > ${Button}:has(i):not(:last-child) {
    padding-right: 0;
  }
`

export const DeleteLangButton: StyledComponent<{}, {}, typeof Button> = styled(Button)`
  width: 23px;
  height: 36px;
  padding: 0;
  color: ${textLight};
  justify-content: flex-start;

  &:hover {
    i {
      color: ${text};
    }
  }
`
