// @flow

import * as React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { Tooltip } from 'components/common/tooltip'
import { colors, schemes } from 'components/styled/tokens'

import { kformat } from 'com.batch.common/utils'

import { type EstimateProfileRecord } from '../../models/profile-estimate.records'
import { subscriptionStatusSelector } from 'com.batch.redux/target/target.selector'

type Props = {
  estimate: EstimateProfileRecord,
  channel: ?ChannelUntilCleanup,
  children: React.Node,
  forceNonOptin?: boolean,
}

type Step = {
  label: string,
  count: number,
  ratio: number,
}

const wordingForChannel = (channel: ChannelUntilCleanup): string => {
  switch (channel) {
    case 'email':
      return 'email address'
    case 'sms':
      return 'phone number'
    case 'push':
      return 'push token'
  }
}

const buildStep = (wording: string, nb: number, total: number): Step => {
  return {
    label: wording,
    count: nb,
    ratio: total ? nb / total : 0,
  }
}

export const EstimateProfileChannelToolip = ({
  estimate,
  channel,
  children,
  forceNonOptin,
}: Props): React.Node => {
  const subscriptionStatus = useSelector(subscriptionStatusSelector)
  const steps = React.useMemo<Array<Step>>(() => {
    let steps: Array<Step> = []
    const total = estimate.total.profileCount
    steps.push(buildStep('Total profiles', total, total))
    const matching = estimate.matching.profileCount
    if (matching !== total) steps.push(buildStep('Matching targeting', matching, total))
    const reachable = channel
      ? estimate.matching[channel].nonUniqueCount
      : estimate.matching.reachableProfileCount
    steps.push(
      buildStep(
        channel ? 'With ' + wordingForChannel(channel) : 'Has email, push or sms',
        reachable,
        total
      )
    )
    if (channel) {
      if (channel !== 'push') {
        if (subscriptionStatus === 'marketing') {
          const optedIn = estimate.matchingOptin[channel].nonUniqueCount
          steps.push(buildStep('Opted-in', optedIn, total))
          const unique = estimate.matchingOptin[channel].uniqueCount
          steps.push(buildStep('Unique', unique, total))
        } else {
          const unique = estimate.matching[channel].uniqueCount
          steps.push(buildStep('Unique', unique, total))
        }
      }
    } else {
      if (subscriptionStatus === 'marketing' && !forceNonOptin) {
        const optedIn = estimate.matchingOptin.reachableProfileCount
        steps.push(buildStep('Opted-in', optedIn, total))
      }
    }
    return steps
  }, [
    channel,
    estimate.matching,
    estimate.matchingOptin,
    estimate.total.profileCount,
    forceNonOptin,
    subscriptionStatus,
  ])

  return (
    <Tooltip
      placement={!channel ? 'top' : 'right'}
      minWidth={300}
      offset={[0, 10]}
      tooltip={
        <StyledSteps>
          {steps.map((step, index) => (
            <React.Fragment key={index}>
              <span className="legend">{step.label}</span>
              <span className="count">{kformat(step.count)}</span>
              <span className="bar" style={{ width: `${step.ratio * 100}%` }} />
            </React.Fragment>
          ))}
        </StyledSteps>
      }
    >
      {children}
    </Tooltip>
  )
}

const StyledSteps = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: auto auto 1fr;
  grid-auto-rows: 18px;
  align-items: center;
  grid-gap: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  .legend {
    color: ${colors.textContrast};
    font-weight: 400;
    text-align: left;
  }
  .count {
    color: ${colors.textContrast};
    font-weight: 500;
    text-align: left;
  }
  .bar {
    text-align: left;
    display: block;
    border-radius: 9px;
    height: 12px;
    background: ${schemes.lightlucent['30']};
  }
`
