// @flow

import * as React from 'react'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import request from 'superagent-interface-promise'

import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { LightText, Ellipsis } from 'components/styled/text'

import { generateUrl } from 'com.batch.common/router'

import { setAppDemoCode } from './../redux/codes'
import { normalizeCode } from './../redux/codes.api'
import { type DemoCodeRecord } from './../redux/console.records'

type DemoCodeAppProps = {
  kind: 'ios' | 'android',
  code: DemoCodeRecord,
  ...
}

const DemoCodeAppRaw = ({ kind, code }: DemoCodeAppProps) => {
  // ====================== Dispatch
  const dispatch = useDispatch()

  // ====================== Component states
  const [loading, setLoading] = React.useState<boolean>(false)

  // ====================== Component constants
  const apiKey = code[kind].apiKey
  const appName = code[kind].name
  const appId = code[kind].id

  // ====================== useEffect
  React.useEffect(() => {
    setLoading(true)

    if (!!apiKey && appName.length === 0) {
      request.get(generateUrl('console_api_apps', { query: apiKey })).then(({ body }) => {
        let updatedDatas = body.entities[0] ? body.entities[0] : { apiKey: code[kind].apiKey }
        let updatedCode = code.set(kind, normalizeCode(updatedDatas))
        dispatch(setAppDemoCode(updatedCode))
        setLoading(false)
      })
    } else {
      setLoading(false)
    }
  }, [apiKey, appName, dispatch, kind, code])

  // ====================== Render
  if (loading) {
    return <Icon icon="spinner" />
  } else if (apiKey) {
    return (
      <React.Fragment>
        {appId ? (
          <Tooltip
            noPadding
            tooltip={
              <div style={{ textAlign: 'left', padding: '5px 10px' }}>
                <b>ApiKey</b>
                <Ellipsis>{apiKey}</Ellipsis>
              </div>
            }
          >
            <NavLink to={`/console/apps/${appId}`}>{appName}</NavLink>
          </Tooltip>
        ) : (
          <LightText>{apiKey}</LightText>
        )}
      </React.Fragment>
    )
  } else {
    return <LightText>—</LightText>
  }
}

export const DemoCodeApp: React.AbstractComponent<DemoCodeAppProps> =
  React.memo<DemoCodeAppProps>(DemoCodeAppRaw)
