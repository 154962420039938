// @flow
import { type Map } from 'immutable'

import { type OrchestrationPush } from '../types/push.message.types'
import { type langId } from 'com.batch/message/store/message.state'

import {
  type PushSettingsRecord,
  type PushContentRecord,
} from 'com.batch/message/models/message.records'

export const formatPushMessage = ({
  settings,
  messages,
  multilanguageEnabled,
}: {
  settings: PushSettingsRecord,
  messages: Map<langId, PushContentRecord>,
  multilanguageEnabled: boolean,
}): OrchestrationPush => {
  const ttl = settings.ttlEnabled && settings.ttl.valid ? `${settings.ttl.seconds}s` : undefined
  const priority = settings.priority ?? 'PUSH_PRIORITY_UNSPECIFIED'

  return {
    priority,
    ttl,
    localizedPush: (multilanguageEnabled
      ? messages
      : messages.filter((_, lang) => lang === 'default')
    )
      .map((push, language) => {
        return {
          ...(language && language !== 'default' ? { language } : {}),
          pushMessageContentTemplate: {
            content: {
              title: push.content.pushTitle,
              body: push.content.pushBody,
              picture: push.content.pushPicture ? { url: push.content.pushPicture } : undefined,
              customPayload: settings.customPayload,
              deepLink: push.content.deeplink,
            },
            androidOverride: {
              deepLink: push.androidContent.deeplink,
              icon: push.androidContent.androidIcon
                ? { url: push.androidContent.androidIcon }
                : undefined,
              collapseKey: settings.androidOverride.collapseKey,
              customPayload: settings.androidOverride.customPayload,
            },
            iosOverride: {
              deepLink: push.iosContent.deeplink,
              iosVideo: push.iosContent.video ? { url: push.iosContent.video } : undefined,
              iosAudio: push.iosContent.audio ? { url: push.iosContent.audio } : undefined,
              customPayload: settings.iosOverride.customPayload,
            },
            webPushOverride: {
              deepLink: push.webContent.deeplink,
              icon: push.webContent.icon ? { url: push.webContent.icon } : undefined,
            },
          },
        }
      })
      .toArray()
      .map(kv => kv[1]),
  }
}
