// @flow

import { scaleBand } from 'd3-scale'
import * as React from 'react'

import { textDisabled, textNeutral } from 'components/styled/tokens/colors'

import { TimelineItemHoverWrapper, TimeItem, TimelineItemSkeleton } from './timeline.styles'

type Props = {
  isLoading?: boolean,
  isEmpty?: boolean,
  label: string,
  isHoveredLabel?: boolean,
  isFirst: boolean,
  isLast: boolean,
  xScale: typeof scaleBand,
  rename?: ?string,
  timelineHeight: number,
  fetchFirstLabelWidth?: null | ((width: number) => void),
  fetchLastLabelWidth?: null | ((width: number) => void),
}

const PADDING = 8 + 36

export const TimelineItem = ({
  fetchFirstLabelWidth,
  fetchLastLabelWidth,
  isLoading,
  isEmpty,
  label,
  isHoveredLabel = false,
  isFirst,
  isLast,
  xScale,
  rename,
  timelineHeight,
}: Props): React.Node => {
  const ref = React.useRef<any>(null)
  const [width, setWidth] = React.useState(0)
  const [height, setHeight] = React.useState(0)

  const y = React.useMemo(() => timelineHeight / 2 + height / 3, [timelineHeight, height])

  const x = React.useMemo(() => {
    const middleBar = xScale.bandwidth() / 2
    const middleLabel = width / 2

    if (isFirst) return xScale(label)
    if (isLast) return xScale(label) + xScale.bandwidth() - width
    return xScale(label) + middleBar - middleLabel
  }, [xScale, label, isFirst, isLast, width])

  React.useEffect(() => {
    if (ref?.current) {
      setWidth(ref.current.getBBox().width)
      setHeight(ref.current.getBBox().height)

      if (fetchFirstLabelWidth) fetchFirstLabelWidth(ref.current.getBBox().width)
      if (fetchLastLabelWidth) fetchLastLabelWidth(ref.current.getBBox().width)
    }
  }, [isLast, label, isFirst, fetchFirstLabelWidth, fetchLastLabelWidth])

  if (isLoading || isEmpty)
    return (
      <TimelineItemSkeleton
        ref={ref}
        width="44"
        height="16"
        rx="4"
        x={isLast ? xScale(label) : xScale(label)}
        y={y - 10}
        isLoading={isLoading}
        isEmpty={isEmpty}
      />
    )

  if (isHoveredLabel)
    return (
      <TimelineItemHoverWrapper x={x} y={0}>
        <rect
          x={-PADDING}
          y={0}
          width={width + PADDING * 2}
          height={timelineHeight}
          fill="url(#timeline-item-gradient-start)"
        />
        <TimeItem ref={ref} y={y} fill={textNeutral}>
          {rename ?? label}
        </TimeItem>
      </TimelineItemHoverWrapper>
    )

  return (
    <TimeItem ref={ref} x={x} y={y} fill={textDisabled}>
      {rename ?? label}
    </TimeItem>
  )
}
