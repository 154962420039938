// @flow
import Immutable, { type Map } from 'immutable'
import { createSelector } from 'reselect'

import { type OrchestrationAnalyticsRecord } from './orchestration-analytics.state'

import { orchestrationStateSelector } from 'com.batch/orchestration/store/orchestration.selectors'
import { type State } from 'com.batch.redux/_records'

import {
  getDailyRange,
  getTemporalityRange,
} from 'com.batch/orchestration-analytics/infra/formats/get-range'
import {
  getDailyRange as getDailyRangeLegacy,
  getTemporalityRange as getTemporalityRangeLegacy,
} from 'com.batch/orchestration-analytics/infra/formats/get-range-legacy'
import { type BarChartData, type PlotData } from 'com.batch/shared/infra/types/chart-data'

type Extract<T> = State => T

export const orchestrationAnalyticsMapSelector: Extract<
  Map<string, OrchestrationAnalyticsRecord>,
> = state => state.orchestrationAnalytics

// LEGACY - TO REMOVE WHEN WE have checked everything is OK on prod env.
export const bounceByDaysRangeLegacySelector: State => string => PlotData[] = createSelector(
  orchestrationAnalyticsMapSelector,
  orchestrationStateSelector,
  (orchestrationAnalytics, orchestrationState) => token => {
    const bounces = orchestrationAnalytics.getIn(
      [token, 'channels', 'email', 'bounces', 'periods'],
      Immutable.Map()
    )
    const orchestration = orchestrationState.campaign
    if (
      orchestration.sendType === 'trigger' ||
      (orchestration.sendType === 'recurring' && orchestration.recurrent.recurrence === 'DAILY')
    ) {
      return getDailyRangeLegacy(bounces, 14)
    }
    if (orchestration.sendType === 'recurring' && orchestration.recurrent.recurrence === 'WEEKLY') {
      return getTemporalityRangeLegacy(bounces, 11, 'week')
    }
    if (
      orchestration.sendType === 'recurring' &&
      orchestration.recurrent.recurrence === 'MONTHLY'
    ) {
      return getTemporalityRangeLegacy(bounces, 11, 'month')
    }
    return []
  }
)

// NEW ONE
export const bounceByDaysRangeSelector: State => string => BarChartData[] = createSelector(
  orchestrationAnalyticsMapSelector,
  orchestrationStateSelector,
  (orchestrationAnalytics, orchestrationState) => token => {
    const bounces = orchestrationAnalytics.getIn(
      [token, 'channels', 'email', 'bounces', 'periods'],
      Immutable.Map()
    )
    const orchestration = orchestrationState.campaign
    if (
      orchestration.sendType === 'trigger' ||
      (orchestration.sendType === 'recurring' && orchestration.recurrent.recurrence === 'DAILY')
    ) {
      return getDailyRange(bounces, 14)
    }
    if (orchestration.sendType === 'recurring' && orchestration.recurrent.recurrence === 'WEEKLY') {
      return getTemporalityRange(bounces, 11, 'week')
    }
    if (
      orchestration.sendType === 'recurring' &&
      orchestration.recurrent.recurrence === 'MONTHLY'
    ) {
      return getTemporalityRange(bounces, 11, 'month')
    }
    return []
  }
)
