// @flow
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import { GlobalErrorOverlayProps, UserbaseEmptyIcon, Wrapper } from 'components/common/empty-states'
import { LinkDoc } from 'components/styled/text'

import { ProfilebasMetricsGrid } from './profilebase.syles'

import {
  emailReachabilityLoadingStateSelector,
  emailSubsGrowthSelector,
  emailSubCounterLastMonthSelector,
} from 'com.batch/profilebase/store/email-reachability.selector'
import { emailSubDistribDataSelector } from 'com.batch/profilebase/store/email-sub-distribution.selector'
import {
  profilebaseIsLoadedSelector,
  profilebaseIsLoadingSelector,
  profilebaseHasErrorSelector,
} from 'com.batch/profilebase/store/profilebase.selector'
import {
  distributionSelector,
  loadingStateDistributionSelector,
} from 'com.batch/profilebase/store/profiles-distribution.selector'
import {
  pushReachabilityLoadingStateSelector,
  pushSubsGrowthSelector,
  pushSubCounterLastMonthSelector,
} from 'com.batch/profilebase/store/push-reachability.selector'
import { pushOptinDistribDataSelector } from 'com.batch/profilebase/store/push-sub-distribution.selector'
import {
  smsReachabilityLoadingStateSelector,
  smsSubsGrowthSelector,
  smsSubCounterLastMonthSelector,
} from 'com.batch/profilebase/store/sms-reachability.selector'
import { smsSubDistribDataSelector } from 'com.batch/profilebase/store/sms-sub-distribution.selector'
import { importedTokensFeatureSelector } from 'com.batch.redux/company.selector'

import { PUSH_PLATFORM_FILTER } from 'com.batch/profilebase/constants/push-platform-filter'
import { ChannelOverview } from 'com.batch/profilebase/ui/components/channel-overview/channel-overview'
import { PushFilter } from 'com.batch/profilebase/ui/components/channel-overview/push-filter'
import { ChannelsMetrics } from 'com.batch/profilebase/ui/components/channels-metrics/channels-metrics'
import { ProfilesMetrics } from 'com.batch/profilebase/ui/components/profiles-metrics/profiles-metrics'
import { fetchProfilesDistribution } from 'com.batch/profilebase/usecases/fetch-profiles-distribution'
import { fetchReachabilityTimeserie } from 'com.batch/profilebase/usecases/fetch-reachability-timeserie'
import {
  fetchEmailSubDistribution,
  fetchSmsSubDistribution,
  fetchPushSubDistribution,
} from 'com.batch/profilebase/usecases/fetch-sub-distribution'
import { STATUS } from 'constants/common'

export const Profilebase = (): React.Node => {
  const dispatch = useDispatch()
  const [search] = useSearchParams()

  const [isLoaded, setIsLoaded] = React.useState(false)

  const importedTokensFeature = useSelector(importedTokensFeatureSelector)

  const profilebaseIsLoaded = useSelector(profilebaseIsLoadedSelector)
  const profilebaseIsLoading = useSelector(profilebaseIsLoadingSelector)
  const hasErrors = useSelector(profilebaseHasErrorSelector)

  const profileDistribLoadingState = useSelector(loadingStateDistributionSelector)
  const profileDistribData = useSelector(distributionSelector)

  const emailSubsDistribData = useSelector(emailSubDistribDataSelector)

  const emailSubByDayLoadingState = useSelector(emailReachabilityLoadingStateSelector)
  const emailSubByDayData = useSelector(emailSubsGrowthSelector)
  const emailSubLastMonthCounter = useSelector(emailSubCounterLastMonthSelector)

  const smsSubsDistribData = useSelector(smsSubDistribDataSelector)

  const smsSubByDayLoadingState = useSelector(smsReachabilityLoadingStateSelector)
  const smsSubByDayData = useSelector(smsSubsGrowthSelector)
  const smsSubLastMonthCounter = useSelector(smsSubCounterLastMonthSelector)

  const pushOptinsDistribData = useSelector(pushOptinDistribDataSelector)

  const pushSubByDayLoadingState = useSelector(pushReachabilityLoadingStateSelector)
  const pushSubByDayData = useSelector(pushSubsGrowthSelector)
  const pushSubLastMonthCounter = useSelector(pushSubCounterLastMonthSelector)

  const isLoading = React.useMemo(
    () => !isLoaded && profilebaseIsLoading,
    [profilebaseIsLoading, isLoaded]
  )

  const noProfiles = React.useMemo(
    () => profileDistribLoadingState === STATUS.LOADED && profileDistribData.total === 0,
    [profileDistribLoadingState, profileDistribData.total]
  )

  const platformFilter = React.useMemo(
    () =>
      PUSH_PLATFORM_FILTER.find(f => f.filter === search.get('pushPlatform')) ??
      PUSH_PLATFORM_FILTER[0],
    [search]
  )

  React.useEffect(() => {
    dispatch(fetchEmailSubDistribution('marketing')).catch(error => {
      console.error('Error while fetching subscribed email distribution:', error)
    })
    dispatch(fetchSmsSubDistribution('marketing')).catch(error => {
      console.error('Error while fetching subscribed email distribution:', error)
    })
    dispatch(fetchReachabilityTimeserie('EMAIL')).catch(error => {
      console.error('Error while fetching email reachability timeserie:', error)
    })
    dispatch(fetchReachabilityTimeserie('SMS')).catch(error => {
      console.error('Error while fetching sms reachability timeserie:', error)
    })
    dispatch(fetchProfilesDistribution()).catch(error => {
      console.error('Error while fetching profiles distribution:', error)
    })
  }, [dispatch])

  React.useEffect(() => {
    dispatch(fetchPushSubDistribution(platformFilter.value)).catch(error => {
      console.error('Error while fetching optin push distribution:', error)
    })
    dispatch(fetchReachabilityTimeserie('PUSH', platformFilter.value)).catch(error => {
      console.error('Error while fetching push reachability timeserie:', error)
    })
  }, [dispatch, platformFilter])

  React.useEffect(() => {
    if (profilebaseIsLoaded && !isLoaded) setIsLoaded(true)
  }, [isLoaded, profilebaseIsLoaded])

  return (
    <Wrapper
      isEmpty={noProfiles || hasErrors}
      isLoading={isLoading}
      isOverlayShown={!isLoading && (noProfiles || hasErrors)}
      overlayProps={
        hasErrors
          ? GlobalErrorOverlayProps
          : {
              status: 'empty-page',
              title: 'No data available',
              description:
                'We haven’t been able to find any data yet. Please check if your SDK is up and running to fill your userbase.',
              content: <UserbaseEmptyIcon />,
              links: [
                {
                  name: 'Troubleshooting',
                  href: 'https://doc.batch.com/dashboard/analytics/troubleshooting',
                },
                {
                  name: 'Install the SDK',
                  href: 'https://doc.batch.com/download/',
                },
              ],
            }
      }
    >
      <ProfilebasMetricsGrid gap={16} template="330px minmax(457px, 1fr)" alignItems="start">
        <ProfilesMetrics
          total={profileDistribData.total}
          identified={profileDistribData.customId}
        />
        <ChannelsMetrics
          email={profileDistribData.email}
          sms={profileDistribData.phoneNumber}
          push={profileDistribData.push}
          importedTokens={profileDistribData.importedTokens}
          importedTokensFeature={importedTokensFeature}
        />
      </ProfilebasMetricsGrid>
      <ChannelOverview
        channel="push"
        subsDistribution={pushOptinsDistribData}
        subsByDayLoadingState={pushSubByDayLoadingState}
        subsByDay={pushSubByDayData}
        cumulSubsLastMonth={pushSubLastMonthCounter}
        platformFilter={platformFilter}
        filter={!isLoading && <PushFilter />}
      />
      <ChannelOverview
        channel="email"
        subsDistribution={emailSubsDistribData}
        subsByDayLoadingState={emailSubByDayLoadingState}
        subsByDay={emailSubByDayData}
        cumulSubsLastMonth={emailSubLastMonthCounter}
        link={
          <LinkDoc
            intent="action"
            href="https://help.batch.com/en/articles/8390553-understanding-your-email-reach"
            target="_blank"
          >
            Understand Email on profile
          </LinkDoc>
        }
      />
      <ChannelOverview
        channel="sms"
        subsDistribution={smsSubsDistribData}
        subsByDayLoadingState={smsSubByDayLoadingState}
        subsByDay={smsSubByDayData}
        cumulSubsLastMonth={smsSubLastMonthCounter}
      />
    </Wrapper>
  )
}
