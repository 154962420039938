// @flow
import { type Set, type Map } from 'immutable'

import { type MessageStateRecord } from 'com.batch/message/store/message.state'
import { type ProjectRecord } from 'com.batch.redux/project.records'
import { type TargetStateRecord } from 'com.batch.redux/target/target.records'

import { type OrchestrationCampaignOnly } from 'com.batch/orchestration/infra/types/orchestration.types'
import { type EmailContentForCampaign } from 'com.batch/orchestration-campaign/infra/types/orchestration-campaign.types'
import { type OrchestrationCampaignRecord } from 'com.batch/orchestration-campaign/models/campaign.records'
import { formatPushMessage } from 'com.batch/push/infra/parses/push.format'
import { type OrchestrationPush } from 'com.batch/push/infra/types/push.message.types'
import { type SmsContent } from 'com.batch/sms/infra/types/sms.message.types'

type OrchestrationCampaignFormatterProps = {
  target: TargetStateRecord,
  campaign: OrchestrationCampaignRecord,
  project: ProjectRecord,
  state: campaignStateType,
  messageIdsReplacement: Map<string, string>,
  name: string,
  id: ?string,
  query: ?string,
  message: MessageStateRecord,
  labelCodes: Set<string>,
}

type OrchestrationCampaignFormatter =
  OrchestrationCampaignFormatterProps => OrchestrationCampaignOnly
export const formatOrchestrationCampaign: OrchestrationCampaignFormatter = ({
  target,
  campaign,
  messageIdsReplacement,
  project,
  query,
  id,
  state,
  message,
  name,
  labelCodes,
}) => {
  let messagesMap: {
    [string]:
      | { emailChannel: EmailContentForCampaign }
      | { smsMessage: SmsContent }
      | { pushMessage: OrchestrationPush },
  } = {}
  const replacedMessageId = messageIdsReplacement.get(
    campaign.messageTypedId,
    campaign.messageTypedId
  )
  const emailMsg = message.email.get(campaign.messageTypedId)
  if (emailMsg && campaign.channel === 'email') {
    const multilanguageEnabled = emailMsg.get('multilanguageEnabled')
    const localizedContent = emailMsg.get('localizedContent')
    messagesMap[replacedMessageId] = {
      emailChannel: {
        email: {
          emailTarget: target.subscriptionStatus === 'marketing' ? 'Marketing' : 'Fullbase',
          localizedEmails: (multilanguageEnabled
            ? localizedContent
            : localizedContent.filter((_, lang) => lang === 'default')
          )
            .map((email, language) => {
              return {
                ...(language && language !== 'default' ? { language } : {}),
                subject: email.subject ?? '',
                html: email.html ?? '',
                replyTo: email.replyTo ?? undefined,
                from: {
                  senderIdentityId: email.senderIdentityId?.toString() ?? '',
                  address: {
                    email: email.fromEmail ?? '',
                    name: email.name ?? '',
                  },
                },
              }
            })
            .toArray()
            .map(kv => kv[1]),
        },
      },
    }
  }
  const pushMsg = message.push.get(campaign.messageTypedId)
  if (pushMsg && campaign.channel === 'push') {
    const multilanguageEnabled = pushMsg.get('multilanguageEnabled')
    const messages = pushMsg.get('localizedContent')
    const settings = pushMsg.get('settings')
    messagesMap[replacedMessageId] = {
      pushMessage: formatPushMessage({
        settings,
        messages,
        multilanguageEnabled,
      }),
    }
  }
  const smsMsg = message.sms.get(campaign.messageTypedId)
  if (smsMsg && campaign.channel === 'sms') {
    const multilanguageEnabled = smsMsg.get('multilanguageEnabled')
    const localizedContent = smsMsg.get('localizedContent')
    messagesMap[replacedMessageId] = {
      smsMessage: {
        target: target.subscriptionStatus === 'marketing' ? 'MARKETING' : 'TRANSACTIONAL',
        localizedSms: (multilanguageEnabled
          ? localizedContent
          : localizedContent.filter((_, lang) => lang === 'default')
        )
          .map((sms, language) => {
            return {
              ...(language && language !== 'default' ? { language } : {}),
              message: {
                content: { text: sms.smsMessage },
              },
            }
          })
          .toArray()
          .map(kv => kv[1]),
      },
    }
  }
  return {
    campaign: {
      scheduling: {
        ...{
          token: id ?? '',
          localTimezoneDependant: campaign.sendType === 'now' ? false : campaign.tzAware,
          state: state === 'RUNNING' || state === 'STOPPED' ? state : 'STOPPED',
          projectKey: {
            textual: { text: project.projectKey },
          },
        },
        ...(campaign.sendType === 'scheduled' && campaign.oneTime.sendDate
          ? { oneTime: { pushDate: campaign.oneTime.sendDate.format('YYYYMMDDHHmmss') } }
          : campaign.sendType === 'now'
            ? { oneTime: {} }
            : campaign.sendType === 'recurring'
              ? {
                  recurrent: {
                    recurrence: campaign.recurrent.recurrence,
                    frequency: campaign.recurrent.frequency,
                    startDate: campaign.recurrent.startDate
                      ? campaign.recurrent.startDate.format('YYYYMMDDHHmmss')
                      : undefined,
                    endDate: campaign.recurrent.endDate
                      ? campaign.recurrent.endDate.format('YYYYMMDDHHmmss')
                      : undefined,
                  },
                }
              : {}),
      },
      content: {
        name,
        query,
        capping:
          campaign.sendType === 'recurring' && campaign.recurrent.hasCapping
            ? campaign.recurrent.capping
            : undefined,
        targeting: {
          languages: target.languages.toArray(),
          regions: target.regions.toArray(),
        },
        channels:
          campaign.channel === 'email'
            ? [{ emailChannel: { messageReference: replacedMessageId } }]
            : campaign.channel === 'sms'
              ? [{ messageReference: replacedMessageId, channelType: 'CHANNEL_TYPE_SMS' }]
              : [{ messageReference: replacedMessageId, channelType: 'CHANNEL_TYPE_PUSH' }],
        labels: labelCodes.toArray(),
      },
    },
    messages: messagesMap,
  }
}
