// @flow
import promiseDebounce from 'es6-promise-debounce'
import Immutable, { type Map } from 'immutable'
import * as React from 'react'
import { connect } from 'react-redux'
import request from 'superagent-interface-promise'

import { Avatar } from 'components/common/avatar'
import { Grid } from 'components/common/grid'
import { Tag } from 'components/common/tag'
import { FilterSelect } from 'components/filter'
import { Select } from 'components/form'

import { generateUrl } from 'com.batch.common/router'

import { CompanyName, CompanyPlan, CompanyId } from '../console.style'
import { loadCompaniesSuccess, createCompany } from '../redux/company'
import { type State } from '../redux/console.records'
import { CompanyFactory, type CompanyRecord } from 'com.batch.redux/_records'
import { normalizeCompany } from 'com.batch.redux/company.api'

type OwnProps = {
  creatable?: boolean,
  isClearable?: boolean,
  placeholder?: string,
  companyId: ?number,
  setCompany: (c: ?CompanyRecord) => void,
}
type StateProps = {
  companies: Map<number, CompanyRecord>,
}
type DispatchProps = {
  loadCompaniesSuccess: typeof loadCompaniesSuccess,
  createCompany: (name: string) => Promise<CompanyRecord>,
}

type CompanyPickerProps = { ...OwnProps, ...StateProps, ...DispatchProps }

const optionCreator = (name: string) => CompanyFactory({ name })
const CompanyPickerRaw = ({
  companyId,
  companies,
  setCompany,
  creatable = false,
  placeholder = 'Select a company',
  isClearable,
  loadCompaniesSuccess,
  createCompany,
}: CompanyPickerProps) => {
  // const onNewOptionCreated = (name: string): Promise<CompanyRecord> => {
  //   return createCompany(name).then(
  //     (c: CompanyRecord) => {
  //       setCompany(c)
  //       return c
  //     },
  //     err => {
  //       console.warn(err)
  //       return CompanyFactory()
  //     }
  //   )
  // }
  const fetchCompanies = (inputValue: string) => {
    const inputIsNumber = `${parseInt(inputValue)}` === inputValue
    const queryParam: string = inputIsNumber ? 'companyIds' : 'search'
    const query = {
      [queryParam]: inputValue,
    }
    return request.get(generateUrl('console_api_companies', query)).then(({ body }) => {
      const companies = new Immutable.List().push(...body.entities.map(normalizeCompany))
      loadCompaniesSuccess({
        count: parseInt(body.count),
        entities: new Immutable.List().push(...companies),
      })
      return companies
    })
  }

  const onCompanyChanged = React.useCallback(
    comp => {
      if (comp && !comp.id) {
        createCompany(comp.name).then(c => {
          setCompany(c)
        })
      } else {
        setCompany(comp)
      }
    },
    [createCompany, setCompany]
  )

  const company = companyId && companies.has(companyId) ? companies.get(companyId) : null

  const props = {
    loadOptions: promiseDebounce(fetchCompanies, 400),
    value: company,
    onChange: onCompanyChanged,
    style: { width: 400 },
    optionMenuHeight: 46,
    optionMenuShownCount: 7,
    optionMenuStyle: { height: 46 },
    menuOffset: 40,
    optionToString: (company: ?CompanyRecord) => company?.name ?? '',
    isClearable,
    placeholder,
    optionFormatter: CompanyOption,
  }

  return creatable ? (
    <Select {...props} optionCreator={optionCreator} />
  ) : (
    <FilterSelect {...props} />
  )
}

const CompanyOption = (company: CompanyRecord, { context }: { context: 'value' | 'menu', ... }) => {
  return (
    <Grid
      template={context === 'menu' ? ' 32px 1fr 70px 50px 45px' : '1fr 70px 50px 45px'}
      style={{ flexGrow: 1, flexShrink: 1, minWidth: 0 }}
    >
      {context === 'menu' && (
        <Avatar
          size={28}
          url={company.avatarUrl}
          placeholder={company.name || ''}
          color={'#1c2e43'}
        />
      )}
      <CompanyName style={{ overflowX: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
        {company.name}
      </CompanyName>
      <CompanyPlan>{company.billing.plan.name}</CompanyPlan>
      <Tag>
        {company.usedSeats} / {company.seats}
      </Tag>
      <CompanyId>{company.id}&nbsp;</CompanyId>
    </Grid>
  )
}

const mapStateToProps = (state: State) => {
  return {
    companies: state.company.entities,
  }
}

export const CompanyPicker: React.AbstractComponent<OwnProps> = connect<
  CompanyPickerProps,
  OwnProps,
  _,
  _,
  _,
  _,
>(mapStateToProps, {
  loadCompaniesSuccess,
  createCompany,
})(CompanyPickerRaw)
