// @flow
import * as React from 'react'

import { Grid } from 'components/common/grid'
import { Separator } from 'components/styled/utils'

import { kformat } from 'com.batch.common/utils'

import { ChannelMetricsBox, ImportedTooltipContainer } from './channels-metrics.styles'

import { ChannelMetric } from 'com.batch/profilebase/ui/components/channels-metrics/channel-metric'

type Props = {
  email: number,
  sms: number,
  push: number,
  importedTokens: number,
  importedTokensFeature: boolean,
}
export const ChannelsMetrics = ({
  email,
  sms,
  push,
  importedTokens,
  importedTokensFeature,
}: Props): React.Node => {
  const pushMetrics = React.useMemo(() => {
    const metrics = [{ value: kformat(push), label: 'profiles with the app' }]

    if (importedTokensFeature)
      metrics.push({
        value: kformat(importedTokens),
        label: 'imported tokens',
        size: 'M',
        hint: (
          <ImportedTooltipContainer>
            <div>Push tokens imported from your previous push provider.</div>
            <div>They will be considered as regular tokens as soon as detected by our SDKs.</div>
          </ImportedTooltipContainer>
        ),
      })

    return metrics
  }, [push, importedTokens, importedTokensFeature])

  return (
    <ChannelMetricsBox>
      <Grid
        gap={0}
        template={`1fr 1px ${importedTokensFeature ? '2' : '1'}fr 1px 1fr`}
        alignItems="stretch"
      >
        <ChannelMetric
          channel="email"
          metrics={[{ value: kformat(email), label: 'profiles with email address' }]}
        />
        <Separator fullHeight />
        <ChannelMetric channel="push" metrics={pushMetrics} />
        <Separator fullHeight />
        <ChannelMetric
          channel="sms"
          metrics={[{ value: kformat(sms), label: 'profiles with phone number' }]}
        />
      </Grid>
    </ChannelMetricsBox>
  )
}
