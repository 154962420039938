// @flow
import styled, { type StyledComponent } from 'styled-components'

import { colors } from 'components/styled/tokens'

export const TimerSettingsContainer: StyledComponent<*, *, HTMLDivElement> = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const QuietTimesContainer: StyledComponent<*, *, HTMLDivElement> = styled.div`
  margin-top: auto;
  margin-bottom: 8px;
  color: ${colors.textLight};
`
