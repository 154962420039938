// @flow
import { getPlatformName } from './get-platform-name'

import { type ProfilePlatformRecord } from 'com.batch/profile/models/profile.records'

export const getPlatformList = (platforms: Array<ProfilePlatformRecord>): string =>
  platforms
    .reduce((acc, current) => {
      if (current.platform && !acc.includes(getPlatformName(current.platform)))
        acc.push(getPlatformName(current.platform))
      return acc
    }, [])
    .join(' & ')
