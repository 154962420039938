// @flow
/* eslint-disable react/jsx-no-bind */
import { type List, type Map } from 'immutable'
import * as React from 'react'
import { connect } from 'react-redux'
import { useNavigate, useLocation, Link } from 'react-router-dom'

import { Avatar } from 'components/common/avatar'
import { Button, ButtonNavLink } from 'components/common/button'
import { Grid } from 'components/common/grid'
import Loader from 'components/common/loader-legacy'
import { Pager } from 'components/common/pager'
import { Icon } from 'components/common/svg-icon'
import {
  Table,
  TableHeader,
  TableCellHeader,
  TableBody,
  TableCell,
  TableFooter,
  TableCellActions,
  TableRow,
} from 'components/common/table/index'
import { Tooltip } from 'components/common/tooltip'
import { FilterSearch } from 'components/filter'

import { numberFormat } from 'com.batch.common/utils'

import { deleteConfirm } from './delete-confirm'
import { OnboardingCircle } from './onboarding-circle'

import { CompanyPicker } from '../company/company-picker'
import { Title, Subtitle } from '../console.style'
import { type State, type UserFilterRecord } from '../redux/console.records'
import { updateFilters, setPage, usersPageSelectors, deleteUser } from '../redux/user'
import { type CompanyRecord, CompanyFactory } from 'com.batch.redux/_records'
import { type UserRecord } from 'com.batch.redux/user.records'

type OwnProps = {}

type StateProps = {
  filters: UserFilterRecord,
  page: number,
  count: number,
  nbPerPage: number,
  loading: boolean,
  users: List<UserRecord>,
  companies: Map<number, CompanyRecord>,
}

type DispatchProps = {
  updateFilters: typeof updateFilters,
  setPage: typeof setPage,
  deleteUser: typeof deleteUser,
}

type UserListProps = { ...OwnProps, ...StateProps, ...DispatchProps }

const UserListRaw = ({
  filters,
  updateFilters,
  users,
  companies,
  deleteUser,
  loading,
  page,
  setPage,
  count,
  nbPerPage,
}: UserListProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  /*
Comme on utilise pas react-redux-router, le comportement est un peu chelou ;
- on veut que l'url soit la source of truth pour le state pagination, filtering (& sorting à terme)
- on a besoin des infos dans le state redux pour que l'epic puisse trigger le load (& le cancel)
Du coup targetPage, c'est ce que l'URL réclame ; & page c'est ce qu'on a actuellement dans redux.
Si ça ne matche pas (= on a changé d'URL) => on trigger une action pour update redux & trigger l'epic

*/

  React.useEffect(() => {
    const targetPage = params.has('page') ? parseInt(params.get('page')) : 1
    const targetSearch = params.has('search') ? params.get('search') || '' : ''
    const targetCompanyId = params.has('companyId') ? parseInt(params.get('companyId')) : null
    if (targetPage !== page) {
      setPage(targetPage)
    }
    if (targetCompanyId !== filters.companyId || targetSearch !== filters.search) {
      updateFilters(filters.set('search', targetSearch).set('companyId', parseInt(targetCompanyId)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const onDelete = (user: UserRecord) => {
    deleteConfirm(user).then(
      () => deleteUser(user),
      () => {}
    )
  }

  return (
    <React.Fragment>
      <Grid template="1fr 400px 200px 70px">
        <Grid alignItems="end" template="120px 1fr">
          <Title>All users</Title>
          <Subtitle>
            {numberFormat(count)} user{count > 0 && 's'}
          </Subtitle>
        </Grid>
        <CompanyPicker
          isClearable
          placeholder="Filter by company"
          companyId={filters.companyId}
          setCompany={c => {
            navigate({
              search: `?page=1&search=${encodeURIComponent(filters.search)}&companyId=${
                c ? c.id : ''
              }`,
            })
          }}
        />
        <div>
          <FilterSearch
            expandable={false}
            identifier="email or ID"
            value={filters.search}
            onChange={q => {
              navigate({
                search: `?page=1&search=${encodeURIComponent(q)}&companyId=${
                  filters.companyId ? filters.companyId : ''
                }`,
              })
            }}
          />
        </div>
        <ButtonNavLink kind="primary" intent="action" to="/console/users/new">
          Create
        </ButtonNavLink>
      </Grid>

      <br />

      <Loader loading={loading} overlay>
        <Table template="100px 1fr 1.5fr 1fr 130px 99px">
          <TableHeader>
            <TableCellHeader>ID</TableCellHeader>
            <TableCellHeader colSpan={2}>Name</TableCellHeader>
            <TableCellHeader>Email</TableCellHeader>
            <TableCellHeader>Company</TableCellHeader>
            <TableCellHeader>Last access</TableCellHeader>
            <div />
          </TableHeader>
          <TableBody>
            {users.map(user => (
              <React.Fragment key={user.hashCode()}>
                <TableRow>
                  <TableCell>
                    <OnboardingCircle step={user.onboardingStep} />
                    &nbsp;&nbsp;{user.id}
                  </TableCell>
                  <TableCell>
                    <Avatar
                      url={user.avatarUrl || undefined}
                      style={{ marginRight: 12 }}
                      placeholder={`${user.firstName || ''} ${user.lastName || ''}`}
                    />
                    {user.firstName} {user.lastName}
                  </TableCell>
                  <TableCell noOverflow>
                    <span style={{ minWidth: 0, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {user.email}
                    </span>
                  </TableCell>
                  <TableCell>
                    {user.companiesPermissions.keySeq().map(cid => {
                      return (
                        <Link to={`/console/company/${cid}`} key={cid}>
                          {companies.get(cid, CompanyFactory({ name: 'loading...' })).name ||
                            'No name'}{' '}
                          <span style={{ color: '#707782' }}>({cid})</span>
                        </Link>
                      )
                    })}
                  </TableCell>
                  <TableCell>
                    <Tooltip
                      placement="left"
                      tooltip={
                        <React.Fragment>
                          Last&nbsp;access:&nbsp;
                          {user.lastAccess
                            ? user.lastAccess.format('DD/MM/YY hh:mm:ss [UTC]Z')
                            : 'Never'}
                          <br />
                          Last&nbsp;login:&nbsp;
                          {user.lastLogin
                            ? user.lastLogin.format('DD/MM/YY hh:mm:ss [UTC]Z')
                            : 'Never'}
                          <br />
                          Registered&nbsp;on:&nbsp;
                          {user.createdAt
                            ? user.createdAt.format('DD/MM/YY hh:mm:ss [UTC]Z')
                            : 'N/A'}
                        </React.Fragment>
                      }
                    >
                      <span>{user.lastAccess ? user.lastAccess.format('DD/MM/YY') : 'Never'}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCellActions>
                    <ButtonNavLink
                      kind="discreet"
                      intent="action"
                      style={{ marginRight: 8 }}
                      to={`/console/users/${parseInt(user.id)}`}
                    >
                      Edit
                    </ButtonNavLink>
                    <Button kind="inline" onClick={() => onDelete(user)}>
                      <Icon icon="delete" />
                    </Button>
                  </TableCellActions>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
          <TableFooter>
            {count > 0 && (
              <Pager
                total={count}
                page={page}
                nbPerPage={nbPerPage}
                selectPage={(page: number) => {
                  navigate({
                    search: `?page=${page}&search=${encodeURIComponent(filters.search)}&companyId=${
                      filters.companyId ? filters.companyId : ''
                    }`,
                  })
                }}
              />
            )}
          </TableFooter>
        </Table>
      </Loader>
    </React.Fragment>
  )
}

const mapStateToProps = (state: State): StateProps => {
  return {
    filters: state.user.filters,
    users: usersPageSelectors(state),
    companies: state.company.entities,
    page: state.user.page,
    count: state.user.count,
    nbPerPage: state.user.nbPerPage,
    loading: state.user.loading,
  }
}

export const UserList: React.AbstractComponent<OwnProps> = connect<
  UserListProps,
  OwnProps,
  _,
  _,
  _,
  _,
>(mapStateToProps, {
  updateFilters,
  setPage,
  deleteUser,
})(
  // $FlowFixMe
  React.memo(UserListRaw)
)
