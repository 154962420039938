// @flow
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Content } from 'components/styled/blocs'

import { EmailAnalytics } from './email-analytics'
import { PushAnalytics } from './push-analytics'
import { SmsAnalytics } from './sms-analytics'

import { StepsAnalytics } from '../steps-analytics/steps-analytics'
import { orchestrationStateSelector } from 'com.batch/orchestration/store/orchestration.selectors'
import { orchestrationAnalyticsMapSelector } from 'com.batch/orchestration-analytics/store/orchestration-analytics.selector'

import {
  OrchestrationStepFactory,
  StepStatsFactory,
  type OrchestrationStatsByStepRecord,
  type OrchestrationStepRecord,
} from 'com.batch/orchestration-analytics/models/orchestration-stats-by-step.record'
import { fetchStatsByChannel } from 'com.batch/orchestration-analytics/usecases/fetch-stats-by-channel'
import { journeyTreeSelector } from 'com.batch/orchestration-journey/models/journey.selectors'
import { useGetSchedulingTypeFromUrl } from 'com.batch/orchestration-list/ui/hooks/use-scheduling-type-from-url'

export const OrchestrationAnalytics = (): React.Node => {
  const { token }: { token: string, ... } = useParams()
  const dispatch = useDispatch()
  const schedulingType = useGetSchedulingTypeFromUrl()
  const { channels } = useSelector(orchestrationStateSelector)
  const { nodesMap } = useSelector(journeyTreeSelector)

  const analyticsMap = useSelector(orchestrationAnalyticsMapSelector)
  const smsStats = React.useMemo(
    () => analyticsMap.getIn([token, 'channels', 'sms', 'stats']),
    [analyticsMap, token]
  )
  const pushStats = React.useMemo(
    () => analyticsMap.getIn([token, 'channels', 'push', 'stats']),
    [analyticsMap, token]
  )
  const statsByStep: ?OrchestrationStatsByStepRecord = React.useMemo(
    () => analyticsMap.getIn([token, 'steps']),
    [analyticsMap, token]
  )
  const loadingState: LoadingState = React.useMemo(
    () => analyticsMap.get(token)?.channelsLoadingState ?? 'INIT',
    [analyticsMap, token]
  )

  const steps: Array<OrchestrationStepRecord> = React.useMemo(
    () =>
      nodesMap.toArray().reduce((acc, [, node]) => {
        if (node.type !== 'MESSAGE') return acc
        const step =
          statsByStep?.find(step => step.step === node.id) ??
          OrchestrationStepFactory({
            step: node.id,
            channel: node.channel,
            stats: StepStatsFactory(),
          })
        return [...acc, step]
      }, []),
    [nodesMap, statsByStep]
  )

  const stepsShown = React.useMemo(
    () => schedulingType === 'automations' && steps.length > 1,
    [schedulingType, steps]
  )

  React.useEffect(() => {
    if (token) {
      dispatch(fetchStatsByChannel({ token })).catch(() => {})
    }
  }, [dispatch, token])

  return (
    <Content style={{ overflow: 'visible', padding: '40px 26px 0' }}>
      {stepsShown && <StepsAnalytics steps={steps} />}
      {channels.has('email') && <EmailAnalytics loadingState={loadingState} />}
      {channels.has('push') && <PushAnalytics loadingState={loadingState} stats={pushStats} />}
      {channels.has('sms') && <SmsAnalytics loadingState={loadingState} stats={smsStats} />}
    </Content>
  )
}
