// @flow

import Immutable from 'immutable'

import { request } from 'com.batch.common/request'
import { generateUrl } from 'com.batch.common/router'

import { type DataService, type FetchDynamicStatsArgs } from './data-service.interface'
import {
  type GetOrchestrationEmailClicksByUrlBulkResponse,
  type GetOrchestrationStatsBulkResponse,
  type GetOrchestrationStatsDynamicDimensionBulkResponse,
  type FetchMonthlySentResponse,
} from './types/grpc-stats-service'

import { parseMonthlySentResponse } from 'com.batch/account/infra/parses/parse-monthly-sent'
import { parseClickLinks } from 'com.batch/orchestration-analytics/infra/parses/parse-click-links'
import { parseOrchestrationStatsToListStatsState } from 'com.batch/orchestration-list/infra/parses/parse-orchestration-stats-to-list-stats-state'
import { OrchestrationListStatsStateFactory } from 'com.batch/orchestration-list/models/orchestration-list-stats-state.records'

export const dataService: DataService = {
  fetchCampaignsData: async ({ project, tokens }) => {
    try {
      const response = await request.post<GetOrchestrationStatsBulkResponse>(
        generateUrl('api_grpc_messaging_data_service', {
          methodName: 'GetOrchestrationStats',
        }),
        {
          projectKey: {
            textual: {
              text: project.projectKey,
            },
          },
          tokens: tokens,
        }
      )

      return Immutable.Map(
        tokens.map(token => {
          const match = response.stats?.find(raw => raw.base.token === token)

          return [
            token,
            match
              ? parseOrchestrationStatsToListStatsState(match.base)
              : OrchestrationListStatsStateFactory({ loadingState: 'LOADED' }),
          ]
        })
      )
    } catch (err) {
      throw Immutable.Map(
        tokens.map(token => {
          return [token, OrchestrationListStatsStateFactory({ loadingState: 'ERROR' })]
        })
      )
    }
  },
  fetchDynamicStats: async <T>({
    project,
    token,
    groupDimensionNames,
    includeFilterDimensions,
    dateFrom,
    dateTo,
    parser,
  }: FetchDynamicStatsArgs<T>): Promise<T> => {
    try {
      const response = await request.post<GetOrchestrationStatsDynamicDimensionBulkResponse>(
        generateUrl('api_grpc_messaging_data_service', {
          methodName: 'GetOrchestrationStatsDynamicDimension',
        }),
        {
          projectKey: {
            textual: {
              text: project.projectKey,
            },
          },
          tokens: [token],
          groupDimensionNames,
          includeFilterDimensions,
          dateFrom: dateFrom?.format('YYYY-MM-DD'),
          dateTo: dateTo?.format('YYYY-MM-DD'),
        }
      )
      return parser(response.stats)
    } catch (error) {
      throw new Error(
        `Error while loading orchestration ${token} dynamic dimension: ${error.message}`
      )
    }
  },
  fetchClicksByUrl: async ({ project, token }) => {
    try {
      const response = await request.post<GetOrchestrationEmailClicksByUrlBulkResponse>(
        generateUrl('api_grpc_messaging_data_service', {
          methodName: 'GetOrchestrationEmailClicksByUrl',
        }),
        {
          projectKey: {
            textual: {
              text: project.projectKey,
            },
          },
          tokens: [token],
          urlGroupingMode: 'SMART_WITH_LINK_NAME',
          groupLimit: 50,
        }
      )
      const match = response.orchestrationEmailClickDetails?.find(raw => raw.token === token)
      return parseClickLinks({ clickDetails: match?.clickDetail })
    } catch (error) {
      throw new Error(`Error while loading orchestration ${token} click details: ${error.message}`)
    }
  },
  getMonthlySentForBilling: async ({ projectKeys, month }) => {
    try {
      const response = await request.post<FetchMonthlySentResponse>(
        generateUrl('api_grpc_messaging_data_service', {
          methodName: 'GetMonthlySentForBilling',
        }),
        {
          projectKeys: projectKeys.map(p => ({
            textual: {
              text: p,
            },
          })),
          month,
        }
      )
      return parseMonthlySentResponse(response)
    } catch (error) {
      throw new Error(`Error : ${error.message}`)
    }
  },
}
