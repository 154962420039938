// @flow
import styled, { css, type StyledComponent } from 'styled-components'

import { Box, BoxBody, BoxFooter, BoxHeader, BoxTitle } from 'components/common/box'
import { Button } from 'components/common/button'
import { EmptyField } from 'components/common/empty-states'
import { IconContainer } from 'components/common/svg-icon'
import { TextLabel } from 'components/styled/text'
import { colors } from 'components/styled/tokens'
import { darklucent, grayscale } from 'components/styled/tokens/schemes'
import { Separator } from 'components/styled/utils'

import { CopyableText } from 'com.batch/shared/ui/component/copy/copyable.styles'

export const ProfileHeroContainer: StyledComponent<*, *, *> = styled(Box)`
  ${BoxHeader} {
    height: 80px;

    & > div:first-child {
      display: flex;
      gap: 14px;
      max-width: 60%;
    }
  }
`

export const ProfileHeroUtcContainer: StyledComponent<*, *, HTMLElement> = styled.div`
  cursor: pointer;

  span {
    margin-left: 6px;
    color: ${colors.textNeutral};
  }

  ${IconContainer} {
    svg {
      color: ${colors.textNeutral};
    }
  }
`
type ProfileTooltipProps = { align?: string, $minWidth?: number, ... }
export const ProfileTooltip: StyledComponent<ProfileTooltipProps, {}, HTMLElement> = styled.div`
  text-align: ${(p: ProfileTooltipProps) => p.align ?? 'left'};
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  color: ${colors.textContrastLight};

  ${(props: ProfileTooltipProps) =>
    props.$minWidth &&
    css`
      min-width: ${props.$minWidth}px;
    `}

  .profile-tooltip-title {
    margin-bottom: 4px;
    color: ${colors.textContrast};
  }
  .profile-tooltip-message {
    color: ${colors.textContrastLight};
  }
`

export const ProfileHeroInformation: StyledComponent<{ isLoading: boolean, ... }, *, HTMLElement> =
  styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100% - 52px);

    ${BoxTitle} {
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .details {
      margin-top: ${(props: { isLoading: boolean, ... }) => (props.isLoading ? 6 : 4)}px;

      & > *:not(:last-child)::after {
        content: '•';
        color: ${colors.textDisabled};
        margin-right: 6px;
      }
    }

    & > * {
      display: flex;
      align-items: baseline;
    }
  `
type ProfileHeroFooterItemContainerProps = { noContact: boolean, ... }
export const ProfileHeroFooterItemContainer: StyledComponent<
  ProfileHeroFooterItemContainerProps,
  { ... },
  HTMLElement,
> = styled.div`
  display: flex;
  align-items: baseline;
  font-weight: 500;
  color: ${colors.textLight};

  &:hover,
  &:hover > span {
    color: ${colors.textNeutral};
  }

  ${(p: ProfileHeroFooterItemContainerProps) =>
    p.noContact &&
    css`
      &,
      & > span,
      &:hover,
      &:hover > span {
        color: ${colors.textDisabled};
      }
    `}
`

export const ProfileLangContainer: StyledComponent<*, *, HTMLElement> = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  color: ${colors.textNeutral};

  /* ${EmptyField} {
    margin-top: 6px;
  } */
`

export const EmptyLang: StyledComponent<*, *, HTMLElement> = styled.div`
  color: ${colors.textDisabled};
`

export const ProfileIdentifierContainer: StyledComponent<*, *, HTMLElement> = styled.div`
  display: block;
  margin-right: 25px;
  max-width: 35%;

  ${CopyableText} {
    font-family:
      SF Mono,
      monospace,
      serif;
    margin-top: 4px;
    color: ${colors.textNeutral};
  }

  ${TextLabel} {
    color: ${colors.textNeutral};
  }

  ${EmptyField}:first-child {
    margin-top: 4px;
  }

  ${EmptyField}:nth-child(even) {
    margin-top: 14px;
  }
`

export const ProfileUsernameContainer: StyledComponent<*, *, HTMLElement> = styled.div`
  display: flex;
  gap: 6px;
`
type ProfileFooterContainerProps = { isBodyShown: boolean, ... }
export const ProfileFooterContainer: StyledComponent<ProfileFooterContainerProps, { ... }, *> =
  styled(BoxFooter)`
    padding: 0 8px 0 22px;
    height: auto;
    border-top: 0;
    background-color: ${grayscale['01']};

    ${(props: ProfileFooterContainerProps) =>
      !props.isBodyShown &&
      css`
        justify-content: space-between;
      `}
    ${Button} ${IconContainer} {
      margin-left: 9px;
    }
  `

export const Reachability: StyledComponent<*, *, HTMLElement> = styled.div`
  display: flex;

  > *:not(:last-child) {
    position: relative;
    margin-right: 20px;

    &::after {
      display: inline-block;
      content: '·';
      color: ${colors.textDisabled};
      position: absolute;
      right: -12px;
    }
  }

  .no-contact {
    color: ${colors.textDisabled};
  }
`

export const ProfileBodyTitle: StyledComponent<*, *, HTMLElement> = styled.div`
  color: ${colors.text};

  h2 {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 0;

    & > span {
      margin-left: 8px;
    }
  }
`

export const ProfileBodyContainer: StyledComponent<*, *, *> = styled(BoxBody)`
  background-color: ${grayscale['01']};
  border-radius: 0;

  padding: 0 12px;

  ${ProfileBodyTitle} {
    margin-bottom: 8px;
  }

  > div {
    border-bottom: 1px solid ${darklucent['20']};

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${Reachability} span:first-child {
    color: ${colors.text};
  }

  ${Reachability} span:nth-child(even) {
    color: ${colors.textLight};
  }
`

export const ChannelBlock: StyledComponent<*, *, *> = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1 0 0;

  ${CopyableText} {
    margin-top: 0;
  }

  .no-contact {
    color: ${colors.textDisabled};
  }
`

export const ChannelBlockSubscriptions: StyledComponent<*, *, *> = styled.div`
  display: flex;
  gap: 16px;
`

export const ProfileBodyChannels: StyledComponent<*, *, *> = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  padding: 12px 8px 16px 8px;

  > ${ChannelBlock} {
    padding: 8px 0;
  }

  ${Separator} {
    align-self: stretch;
    margin: 0;
  }
`

export const ProfilePlatformContainer: StyledComponent<*, *, HTMLElement> = styled.div`
  padding: 20px 8px;

  ${ProfileBodyTitle} {
    display: flex;
    align-items: baseline;

    & > span {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.16px;
      color: ${colors.textLight};
    }

    & > * {
      padding-right: 8px;
    }
  }
`

export const PlatformsContainer: StyledComponent<*, *, HTMLElement> = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 12px;

  > p {
    color: ${colors.textDisabled};
  }
`

export const ProfilePlatformsCounterContainer: StyledComponent<*, *, HTMLElement> = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 500;

  & > span {
    color: ${colors.textLight};
    margin-right: 4px;

    &:hover {
      color: ${colors.textNeutral};
    }
  }
`

export const ProfileLastVisitContainer: StyledComponent<*, *, HTMLElement> = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: ${colors.textNeutral};

  & > * {
    margin-right: 6px;
  }
`
