// @flow
/**
 * Goal : take a conditon with an attribute defined, then allow to define function / operator
 *        and offer an adequate InputType
 */

import Immutable, { type List } from 'immutable'
import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { useToggle } from 'components/_hooks'
import { Button } from 'components/common/button'
import { Grid } from 'components/common/grid'
import { Icon } from 'components/common/svg-icon'
import { Select } from 'components/form'
import { colors } from 'components/styled/tokens'

import { ConditionEventFilter } from './condition-event-filter'
import * as Inputs from './input'
import { LeftBase } from './left/left-base'
import { LeftTag } from './left/left-tag'
import { QueryBuilderContext } from './query-builder.context'
import {
  ConditionContainer,
  ConditionPart,
  ConditionPartAction,
  ConditionPartAttribute,
} from './query.styles'

import { AttributeFactory } from 'com.batch.redux/_records'
import { allAttrSelector } from 'com.batch.redux/attribute.selector'
import { actions, api } from 'com.batch.redux/query/query'
import {
  FunctionParamsFactory,
  type ConditionRecord,
  type QueryAttributeRecord,
} from 'com.batch.redux/query/query.records'

const attributeToString = (attr: any) => attr?.label ?? ''

const attributeFormatter = (attribute: ?QueryAttributeRecord) =>
  !attribute ? null : (
    <Grid template="auto 1fr">
      <Icon
        icon={attribute.icon}
        thickness={1.2}
        style={{ color: colors.textLight, marginTop: -1, marginLeft: 3 }}
      />
      <span style={{ minWidth: 0, overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {attribute.label}
      </span>
    </Grid>
  )

type ConditionProps = {
  condition: ConditionRecord,
  setTouched: () => void,
  withSeparator?: boolean,
  attributes: List<QueryAttributeRecord>,
  id: string,
  queryId: string,
  parentIsLogicalNot: boolean,
  disabledMode?: boolean,
}

const REFINE_ATTRIBUTE_MARKETING_ONLY = 'Marketing'
const REFINE_ATTRIBUTE_ALL = 'Marketing & transactional'

const refineAttributeSelectOptions = new Immutable.List().push(
  ...[REFINE_ATTRIBUTE_MARKETING_ONLY, REFINE_ATTRIBUTE_ALL]
)
const optionToString = (opt: ?string) => opt ?? ''

export const Condition = ({
  condition,
  setTouched,
  withSeparator,
  attributes,
  disabledMode,
  id,
  queryId,
  parentIsLogicalNot,
}: ConditionProps): React.Node => {
  const dispatch = useDispatch()
  const { errors } = React.useContext(QueryBuilderContext)
  const eventFilterState = useToggle(!!condition.functionParams?.eventFilterOn)
  const allAttributes = useSelector(allAttrSelector)
  const availableEventFilteringOptions = React.useMemo(() => {
    const event = allAttributes.get(condition?.attribute?.api ?? '', AttributeFactory())
    return event.allowedKeys
      .filter(
        data => data.type === 'STRING' || data.type === '__LABEL__' || data.type === '__TAG__'
      )
      .map(data => {
        return {
          label: data.type === '__LABEL__' ? 'Label' : data.type === '__TAG__' ? 'Tag' : data.name,
          value: data.type === '__LABEL__' || data.type === '__TAG__' ? data.type : data.name,
        }
      })
      .toList()
  }, [allAttributes, condition?.attribute?.api])
  const removeCondition = React.useCallback(() => {
    setTouched()
    dispatch(actions.removeCondition({ id, queryId }))
  }, [dispatch, id, queryId, setTouched])

  const updateCondition = React.useCallback(
    (condition: ConditionRecord) => {
      setTouched()
      dispatch(actions.updateCondition({ id, queryId, condition }))
    },
    [dispatch, id, queryId, setTouched]
  )
  const negate = React.useCallback(() => {
    setTouched()
    dispatch(actions.negate({ id, queryId }))
  }, [dispatch, id, queryId, setTouched])

  const unNegate = React.useCallback(() => {
    setTouched()
    dispatch(actions.unNegate({ id, queryId }))
  }, [dispatch, id, queryId, setTouched])
  const onAttributeChange = React.useCallback(
    attr => {
      attr && updateCondition(api.buildDefaultCondition(attr))
    },
    [updateCondition]
  )

  const onRemoveFilter = React.useCallback(() => {
    updateCondition(condition.set('functionParams', null))
    eventFilterState.close()
  }, [condition, eventFilterState, updateCondition])

  const onEventFilterUpdate = React.useCallback(
    fp => updateCondition(condition.set('functionParams', fp)),
    [condition, updateCondition]
  )

  const isInvalid = React.useMemo(() => errors.has(id), [errors, id])

  const baseProps = React.useMemo(
    () => ({
      condition,
      updateCondition: updateCondition,
      isInvalid,
      negated: parentIsLogicalNot,
      removeSelf: removeCondition,
      negate,
      unNegate,
      isDisabled: disabledMode,
    }),
    [
      condition,
      updateCondition,
      isInvalid,
      parentIsLogicalNot,
      removeCondition,
      negate,
      unNegate,
      disabledMode,
    ]
  )
  const Component = Inputs[condition.value.mode]

  const refineMode = React.useMemo(() => {
    return condition.attribute?.api.includes('marketing')
      ? REFINE_ATTRIBUTE_MARKETING_ONLY
      : REFINE_ATTRIBUTE_ALL
  }, [condition.attribute?.api])

  const handleAttributeRefinementChange = React.useCallback(
    refineMode => {
      const currentAttributeId = condition.attribute?.api
      const refinedAttributeId =
        refineMode === REFINE_ATTRIBUTE_ALL
          ? currentAttributeId?.replace('_marketing', '')
          : currentAttributeId === 'b.last_email_click'
            ? 'b.last_email_click_marketing'
            : 'b.last_email_marketing_open'
      updateCondition(api.buildDefaultCondition(attributes.find(a => a.api === refinedAttributeId)))
    },
    [condition.attribute?.api, updateCondition, attributes]
  )

  const showRefineAttributeSelect = React.useMemo(() => {
    return [
      'b.last_email_open',
      'b.last_email_marketing_open',
      'b.last_email_click',
      'b.last_email_click_marketing',
    ].includes(condition.attribute?.api)
  }, [condition.attribute?.api])

  const attributesExcluded = React.useMemo(() => {
    return attributes
      .sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1))
      .filter(
        attr =>
          !['b.last_email_open', 'b.last_email_click'].includes(attr.api) &&
          (!['b.is_email_optin', 'b.is_sms_optin'].includes(attr.api) ||
            queryId.startsWith('YESNO__'))
      )
  }, [attributes, queryId])

  return (
    <React.Fragment>
      <ConditionContainer withSeparator={withSeparator && !eventFilterState.value}>
        <ConditionPartAttribute style={{ flexGrow: !condition.attribute ? 1 : 0 }}>
          <Select
            isSearchable
            invalid={(!condition.attribute || condition.attribute.api === '__UNSET__') && isInvalid}
            options={attributesExcluded}
            value={condition.attribute}
            placeholder="Pick an attribute"
            optionFormatter={attributeFormatter}
            menuOffset={40}
            optionToString={attributeToString}
            onChange={onAttributeChange}
          />
        </ConditionPartAttribute>
        {showRefineAttributeSelect && (
          <Select
            style={{ width: 190, minWidth: 190, marginRight: 10 }}
            value={refineMode}
            optionToString={optionToString}
            options={refineAttributeSelectOptions}
            onChange={handleAttributeRefinementChange}
          />
        )}
        {condition.attribute?.type === 'EVENT' && availableEventFilteringOptions.size > 0 && (
          <ConditionPartAction style={{ margin: '0 17px 0 7px' }}>
            <Button kind="secondary" intent="neutral" onClick={eventFilterState.toggle}>
              <Icon icon="filter" />
            </Button>
          </ConditionPartAction>
        )}
        {!!condition.attribute && (
          <React.Fragment>
            <ConditionPart>
              {condition.attribute.type === 'TAG' &&
              !['bt.custom_audiences', 'bt.segments'].includes(condition.attribute?.api) ? (
                <LeftTag {...baseProps} />
              ) : (
                <LeftBase {...baseProps} />
              )}
            </ConditionPart>
            <ConditionPart
              style={{
                flexGrow: 1,
                marginRight: !['bt.custom_audiences', 'bt.segments'].includes(
                  condition.attribute?.api
                )
                  ? 17
                  : -10,
              }}
            >
              <Component {...baseProps} />
            </ConditionPart>
          </React.Fragment>
        )}
        <ConditionPartAction>
          {!disabledMode &&
            !['bt.custom_audiences', 'bt.segments'].includes(condition.attribute?.api) && (
              <Button
                kind="discreet"
                intent="danger"
                onClick={removeCondition}
                disabled={disabledMode}
              >
                <Icon icon="remove" />
              </Button>
            )}
        </ConditionPartAction>
      </ConditionContainer>
      {eventFilterState.value && (
        <ConditionEventFilter
          withSeparator={withSeparator}
          eventId={condition.attribute?.api ?? ''}
          params={condition.functionParams ?? FunctionParamsFactory()}
          availableEventFilteringOptions={availableEventFilteringOptions}
          update={onEventFilterUpdate}
          removeFilter={onRemoveFilter}
        />
      )}
    </React.Fragment>
  )
}
