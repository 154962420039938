// @flow

import Immutable, { type Map } from 'immutable'
import { createSelector } from 'reselect'

import {
  getLangFromContent,
  getMessageKey,
  type MessageIdGetterSelector,
  previewLanguageSelector,
} from 'com.batch/message/store/message.selector'
import { type State } from 'com.batch.redux/_records'
import { currentProjectSelector } from 'com.batch.redux/project.selector'
import { subscriptionStatusSelector } from 'com.batch.redux/target/target.selector'

import {
  SmsMessageFactory,
  SmsContentFactory,
  type SmsMessageRecord,
  type SmsContentRecord,
} from 'com.batch/message/models/message.records'
import { countSmsMessage } from 'com.batch/sms/usecases/count-sms-message'
import { validateSmsFields } from 'com.batch/sms/usecases/validate-sms-fields'

export const getSmsMessageSelector =
  (state: State): (string => SmsMessageRecord) =>
  messageId =>
    state.message.sms.get(messageId, SmsMessageFactory())

export const getSmsContentSelector: MessageIdGetterSelector<Map<string, SmsContentRecord>> =
  createSelector(getSmsMessageSelector, getPushMessage => messageId => {
    return getPushMessage(messageId).get('localizedContent', Immutable.Map())
  })

export const getSmsContentForActiveLanguageSelector: MessageIdGetterSelector<SmsContentRecord> =
  createSelector(getSmsContentSelector, previewLanguageSelector, (getData, langId) => messageId => {
    const data = getData(messageId)
    return data.get(langId ?? 'default', SmsContentFactory())
  })

type getErrorsForMessage = (messageId: string) => Map<string, Array<string>>
export const getSmsErrorsSelector: State => getErrorsForMessage = createSelector(
  getSmsMessageSelector,
  subscriptionStatusSelector,
  (getMessage, targetedUserbase) => messageId => {
    const message = getMessage(messageId)
    const localizedContent = message.get('localizedContent', Immutable.Map())
    const multilanguageEnabled = message.get('multilanguageEnabled')
    const languages = getLangFromContent(localizedContent, multilanguageEnabled)
    const errors: Array<[string, Array<string>]> = languages
      .map(lang => {
        const contentForLang = localizedContent.get(lang.value, SmsContentFactory())
        let localErrors = []

        const smsMessage = contentForLang.smsMessage

        if (!smsMessage) localErrors.push('SMS message is missing')

        const { parts } = countSmsMessage({
          message: smsMessage,
          countStop: targetedUserbase === 'marketing',
        })

        const smsErrors = validateSmsFields({
          message: smsMessage,
          parts,
        })

        if (smsErrors.has('MSG_TOO_LONG')) localErrors.push('SMS message is too long')

        return [getMessageKey(lang), localErrors]
      })
      .filter(err => err[1].length > 0)
      .toArray()
    return Immutable.Map(errors)
  }
)

export const smsSenderSelector: State => ?string = createSelector(
  currentProjectSelector,
  project => {
    return project.sspConfiguration?.senders.first()
  }
)
