// @flow

import { type List } from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useToggle } from 'components/_hooks'
import { PermissionButton } from 'components/common/button/permission-button'
import { confirm } from 'components/common/confirm'
import { ConfirmWarning } from 'components/common/confirm.styles'
import { GlobalErrorOverlayProps } from 'components/common/empty-states/empty-states'
import { Wrapper } from 'components/common/empty-states/wrapper'
import { Grid } from 'components/common/grid'
import { Loader } from 'components/common/loader/loader'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { Content } from 'components/styled/blocs'
import { Title } from 'components/styled/text'

import { pluralize } from 'com.batch.common/utils'

import { labelsSelector } from 'com.batch/labels/store/labels.selector'

import { type CappingRuleRecord } from 'com.batch/capping/model/capping-rule'
import { CappingRuleBlock } from 'com.batch/capping/ui/components/capping-rule-block'
import { NewCappingPopin } from 'com.batch/capping/ui/components/new-capping-rule-popin'
import { cancelNewCappingRules } from 'com.batch/capping/usecases/cancel-new-capping-rules'
import { deleteCappingRules } from 'com.batch/capping/usecases/delete-capping-rules'
import { newCappingRules } from 'com.batch/capping/usecases/new-capping-rules'
import { saveCappingRules } from 'com.batch/capping/usecases/save-capping-rules'
import { fetchLabelsAndCappingRules } from 'com.batch/labels/usecases/fetch-labels-and-capping-rules'

const MAXIMUM_CAPPING_RULES_LIMIT = 20

export const CappingRules = (): React.Node => {
  const { labels, loadingState } = useSelector(labelsSelector)
  const dispatch = useDispatch()
  const [editingLabelCode, setEditingLabelCode] = React.useState<string | null>(null)

  const toggle = useToggle()

  const onDelete = React.useCallback(
    (cappingRules: List<CappingRuleRecord>) => {
      const labelOrchestrationCount = labels.find(
        label => label.code === cappingRules.first().labelCode
      ).orchestrationCount
      confirm({
        sensitive: true,
        message: (
          <article>
            {labelOrchestrationCount > 0 && (
              <ConfirmWarning>
                This capping is currently used in&nbsp;
                {pluralize('orchestration', labelOrchestrationCount)}.
              </ConfirmWarning>
            )}
            <p>
              You’re about to delete the capping for this project. This action is final and can’t be
              undone.
            </p>
          </article>
        ),
        title: 'Delete this capping?',
        smallFooter: true,
      })
        .then(() => dispatch(deleteCappingRules(cappingRules.first().labelCode)))
        .catch(() => {})
    },
    [dispatch, labels]
  )

  const onCancelNewCappingRules = React.useCallback(() => {
    dispatch(cancelNewCappingRules())
  }, [dispatch])

  const onSave = React.useCallback(
    (cappingRules: List<CappingRuleRecord>, labelCode: string) => {
      dispatch(saveCappingRules(labelCode, cappingRules))
        .then(() => {
          onCancelNewCappingRules()
          setEditingLabelCode(null)
        })
        .catch(() => {})
    },
    [dispatch, onCancelNewCappingRules]
  )

  React.useEffect(() => {
    dispatch(fetchLabelsAndCappingRules())
  }, [dispatch])

  const isLoading = React.useMemo(() => loadingState === 'LOADING', [loadingState])

  const hasNoContent = React.useMemo(
    () =>
      loadingState === 'ERROR' ||
      (!labels.some(label => label.cappingRules.size > 0) && loadingState === 'LOADED'),
    [labels, loadingState]
  )

  const isCappingRulesLimitReached = React.useMemo(
    () => labels.filter(label => label.cappingRules.size > 0).size >= MAXIMUM_CAPPING_RULES_LIMIT,
    [labels]
  )

  const allLabelsHaveCappingRules = React.useMemo(
    () => labels.every(label => label.cappingRules.size > 0),
    [labels]
  )

  const onEdit = React.useCallback(
    (editingCode: string | null) => {
      onCancelNewCappingRules()
      setEditingLabelCode(editingCode)
    },
    [onCancelNewCappingRules]
  )

  const onNewCappingRule = React.useCallback(
    (labelCode: string) => {
      onCancelNewCappingRules()
      dispatch(newCappingRules(labelCode))
      setEditingLabelCode(labelCode)
    },
    [dispatch, onCancelNewCappingRules, setEditingLabelCode]
  )

  return (
    <Content top>
      <Wrapper
        isEmpty={hasNoContent}
        isLoading={isLoading}
        isOverlayShown={loadingState === 'ERROR' || hasNoContent}
        overlayProps={
          loadingState === 'ERROR'
            ? GlobalErrorOverlayProps
            : emptyPageOverlayProps(() => toggle.open(), labels.size === 0)
        }
      >
        <Grid template="1fr auto" gap={8} margin={[0, 0, 32, 0]}>
          <Title mb={0} overEmptyState>
            Label frequency cappings
          </Title>
          <Tooltip
            isTooltipEmpty={!isCappingRulesLimitReached && !allLabelsHaveCappingRules}
            placement="bottom"
            tooltip={
              allLabelsHaveCappingRules
                ? 'All existing labels are already associated with capping rules.'
                : 'Impossible to create more than 20 cappings.'
            }
          >
            <div>
              <PermissionButton
                intent="action"
                kind="primary"
                addOn="prefix"
                onClick={toggle.open}
                disabled={isCappingRulesLimitReached || allLabelsHaveCappingRules || hasNoContent}
                isAllowed={true}
              >
                <Icon icon="add" />
                Add capping
              </PermissionButton>
            </div>
          </Tooltip>
        </Grid>
        <Loader loading={isLoading}>
          {labels.map(label => {
            if (label.cappingRules.size === 0) {
              return null
            }

            return (
              <CappingRuleBlock
                label={label}
                onSave={onSave}
                onDelete={onDelete}
                key={label.code}
                onCancelNewCappingRules={onCancelNewCappingRules}
                isEditing={editingLabelCode === label.code}
                onEdit={onEdit}
              />
            )
          })}
        </Loader>
        <Popin opened={toggle.value} close={toggle.close}>
          <NewCappingPopin
            close={toggle.close}
            labels={labels.filter(label => label.cappingRules.size === 0)}
            onClickLabel={onNewCappingRule}
          />
        </Popin>
      </Wrapper>
    </Content>
  )
}

const emptyPageOverlayProps = (onClickAddNewCappingRule: () => void, isButtonDisabled: boolean) => {
  return {
    status: 'empty-page',
    title: 'No capping rules to display',
    description: (
      <div>
        <p style={{ marginBottom: 10 }}>
          You haven’t created any capping rules for this project for now. You can create your first
          one by clicking on the button below.
        </p>
        <Tooltip
          tooltip="Create a label on the labels page first."
          isTooltipEmpty={!isButtonDisabled}
          placement="bottom"
        >
          <div>
            <PermissionButton
              intent="action"
              kind="primary"
              onClick={onClickAddNewCappingRule}
              isAllowed={true}
              disabled={isButtonDisabled}
            >
              <Icon icon="add" style={{ paddingRight: '5px' }} />
              Add capping
            </PermissionButton>
          </div>
        </Tooltip>
      </div>
    ),
  }
}
