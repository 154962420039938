// @flow
import Immutable, { type Map, type Set } from 'immutable'

import { generateTypedId } from 'com.batch.common/typed-id'

import {
  type Orchestration,
  type DashboardEmailConfigByLanguage,
  type DashboardConfigByMessageId,
} from '../types/orchestration.types'
import { type MessageStateRecord } from 'com.batch/message/store/message.state'
import { type ProjectRecord } from 'com.batch.redux/project.records'
import { TargetStateFactory, type TargetStateRecord } from 'com.batch.redux/target/target.records'

import { formatOrchestrationCampaign } from 'com.batch/orchestration-campaign/infra/formats/orchestration-campaign.format'
import { type OrchestrationCampaignRecord } from 'com.batch/orchestration-campaign/models/campaign.records'
import { formatOrchestrationJourney } from 'com.batch/orchestration-journey/infra/formats/orchestration-journey.format'
import {
  type JourneySettingsRecord,
  type JourneyNodeRecord,
} from 'com.batch/orchestration-journey/models/journey.records'
import { generateProjectKeyBlockRequest } from 'com.batch/shared/infra/generate-block-request'

export type OrchestrationFormatterParams = {
  targets: Map<string, TargetStateRecord>,
  project: ProjectRecord,
  campaign: OrchestrationCampaignRecord,
  settings: JourneySettingsRecord,
  queries: Map<string, string>,
  rootNodeId: string,
  nodes: Map<string, JourneyNodeRecord>,
  messageIdsToReplace: Set<string>,
  message: MessageStateRecord,
  name: string,
  id: ?string,
  incomplete: boolean,
  state: campaignStateType,
  labelCodes: Set<string>,
}
type OrchestrationFormatter = OrchestrationFormatterParams => Orchestration
export const formatOrchestration: OrchestrationFormatter = ({
  targets,
  nodes,
  messageIdsToReplace,
  message,
  campaign,
  rootNodeId,
  incomplete = false,
  queries,
  project,
  settings,
  state,
  id,
  name,
  labelCodes,
}) => {
  const messageIdsReplacementTuples: Array<[string, string]> = messageIdsToReplace
    .toArray()
    .map(id => {
      return [id, generateTypedId('message')]
    })
  const messageIdsReplacement = Immutable.Map(messageIdsReplacementTuples)

  /*
    dashboard config holds data for dashboard only — stripo unoptimized html
    intermediate variables are here to please Flow
  */
  const usedMessageId = nodes
    .filter(node => node.type === 'MESSAGE')
    .map(node => (node.type === 'MESSAGE' ? node.messageReference : ''))
    .valueSeq()

  let configByMessageId: { [string]: DashboardConfigByMessageId, ... } = {}
  message.email
    .filter((_, id) => {
      const isLegacyDefaultMessage = id === 'default'
      return campaign.sendType === 'trigger' ? usedMessageId.includes(id) : !isLegacyDefaultMessage
    })
    .forEach((message, messageId) => {
      let configByLanguage: { [lang: string]: DashboardEmailConfigByLanguage, ... } = {}
      message.get('localizedContent').forEach((content, lang) => {
        if (content.htmlEditorConfig)
          configByLanguage[lang] = {
            htmlEditorConfig: {
              type: content.htmlEditorConfig.type,
              templateId: content.htmlEditorConfig.templateId,
              html: content.htmlEditorConfig.html,
              css: content.htmlEditorConfig.css,
            },
          }
      })
      configByMessageId[messageIdsReplacement.get(messageId, messageId)] = {
        langConfigs: configByLanguage,
      }
    })
  let journeyOrCampaign =
    campaign.sendType === 'trigger'
      ? {
          journey: formatOrchestrationJourney({
            targets,
            nodes,
            rootNodeId,
            message,
            messageIdsReplacement,
            queries,
            settings,
            name,
            state,
            labelCodes,
          }),
        }
      : formatOrchestrationCampaign({
          target: targets.get('default', TargetStateFactory()),
          campaign,
          project,
          messageIdsReplacement,
          query: queries.get('targeting'),
          id,
          state,
          message,
          name,
          labelCodes,
        })
  return {
    orchestrationState: state,
    orchestrationSource: 'DASHBOARD',
    orchestrationType:
      campaign.sendType === 'trigger'
        ? 'TRIGGER'
        : campaign.sendType === 'recurring'
          ? 'RECURRENT'
          : 'ONETIME',
    orchestrationChannels: undefined, // filled by backend
    dashboardConfig: { incomplete, emailAdditionnalConfig: configByMessageId },
    ...generateProjectKeyBlockRequest(project.projectKey),
    id: id,
    name: name,
    ...journeyOrCampaign,
  }
}
