// @flow
import Immutable from 'immutable'

import { buildAgeFromDuration } from 'com.batch.common/utils'

import { type OrchestrationPush } from '../types/push.message.types'
import { AgeFactory } from 'com.batch.redux/_records'

import {
  PushSettingsAndroidOverrideFactory,
  AndroidPushMessageRawFactory,
  PushContentFactory,
  PushMessageRawFactory,
  PushContentTemplatesFactory,
  PushAndroidContentTemplatesFactory,
  type PushContentRecord,
  type PushMessageRecord,
  PushSettingsFactory,
  PushMessageFactory,
  IosPushMessageRawFactory,
  WebPushMessageRawFactory,
  PushSettingsIosOverrideFactory,
} from 'com.batch/message/models/message.records'

export const parsePush = (
  raw: OrchestrationPush
): {
  message: PushMessageRecord,
  contentFullyEmpty: boolean,
  contentInvalid: boolean,
} => {
  let contentInvalid = false
  let contentFullyEmpty = false
  const tuplesLangValueArray: Array<[string, PushContentRecord]> = raw.localizedPush.map(
    ({ language, region, pushMessageContentTemplate }) => {
      const lang = language && region ? `${language}-${region}` : language ? language : 'default'
      // proto duration format is like "1s" for 1 second
      const p = pushMessageContentTemplate
      const pushContentRecord = PushContentFactory({
        content: PushMessageRawFactory({
          pushTitle: p.content.title,
          pushBody: p.content.body,
          pushPicture: p.content.picture ? p.content.picture.url : '',
          templates: PushContentTemplatesFactory({
            pushTitle: p.content.title,
            pushBody: p.content.body,
            pushPicture: p.content.picture ? p.content.picture.url : '',
          }),
          deeplink: p.content.deepLink ?? '',
        }),
        androidContent: AndroidPushMessageRawFactory({
          deeplink: p?.androidOverride?.deepLink ?? '',
          androidIcon: p?.androidOverride?.icon?.url ?? '',
          templates: PushAndroidContentTemplatesFactory({
            androidIcon: p?.androidOverride?.icon?.url ?? '',
          }),
        }),
        iosContent: IosPushMessageRawFactory({
          deeplink: p?.iosOverride?.deepLink ?? '',
          video: p?.iosOverride?.iosVideo?.url ?? '',
          audio: p?.iosOverride?.iosAudio?.url ?? '',
        }),
        webContent: WebPushMessageRawFactory({
          deeplink: p?.webPushOverride?.deepLink ?? '',
          icon: p?.webPushOverride?.icon?.url ?? '',
        }),
      })
      return [lang, pushContentRecord]
    }
  )
  const { pushMessageContentTemplate: first } = raw.localizedPush[0]
  return {
    message: PushMessageFactory({
      multilanguageEnabled: raw.localizedPush.length > 1,
      localizedContent: Immutable.OrderedMap(tuplesLangValueArray),
      settings: PushSettingsFactory({
        ttl: raw.ttl ? buildAgeFromDuration(raw.ttl) : AgeFactory(),
        ttlEnabled: Boolean(raw.ttl),
        priority: raw.priority,
        customPayload: first.content.customPayload,
        androidOverride: PushSettingsAndroidOverrideFactory({
          collapseKey: first.androidOverride?.collapseKey ?? '',
          customPayload: first.androidOverride?.customPayload ?? '',
        }),
        iosOverride: PushSettingsIosOverrideFactory({
          customPayload: first.iosOverride?.customPayload ?? '',
        }),
      }),
    }),
    contentFullyEmpty,
    contentInvalid,
  }
}
