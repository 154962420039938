// @flow

import { type Dayjs } from 'dayjs'
import Immutable, { type List, type Map, type Set } from 'immutable'
import { createSelector } from 'reselect'

import { dayjs } from 'com.batch.common/dayjs.custom'
import validators from 'com.batch.common/form.validators'
import { textUsesTemplating } from 'com.batch.common/utils'

import {
  type AppRecord,
  type CampaignRecord,
  type ClusterRecord,
  type CustomAudienceRecord,
  type LanguageRecord,
  type RegionRecord,
  type State,
  type TemplateRecord,
  type Variant,
} from 'com.batch.redux/_records'
import CampaignModel from 'com.batch.redux/api.model.campaign'
import {
  currentAppSelector,
  restrictedLanguagesSelector,
  restrictedRegionsSelector,
  safariIconSelector,
} from 'com.batch.redux/app'
import {
  activeLanguageIdSelector,
  campaignConfigSelector,
  contentSelector,
  currentCampaign,
  enrichedLanguagesSelector,
  enrichedRegionsSelector,
  pickedLanguagesSelector as translationLanguagesSelector,
} from 'com.batch.redux/campaign.selector'
import { clustersAvailableForTargeting } from 'com.batch.redux/cluster'
import {
  AbTestedInAppFactory,
  type AbTestedInAppRecord,
  AbTestedPushFactory,
  type AbTestedPushRecord,
  InAppContentFactory,
  type InAppContentRecord,
  PushContentLegacyFactory,
  type PushSettingsRecord,
  type SdkActionRecord,
} from 'com.batch.redux/content.records'
import {
  campaignNameSelector,
  inappSelector,
  pushSelector,
  pushSettingsSelector,
} from 'com.batch.redux/content.selector'
import { getAPIQueryForIdSelector } from 'com.batch.redux/query/query.selector'
import {
  activeClustersIds,
  activeLanguagesIds,
  activeRegionsIds,
} from 'com.batch.redux/targeting.selector'
import { PreferredTemplateCache } from 'com.batch.redux/template'
import { type AbTestedThemeRecord } from 'com.batch.redux/theme.records'
import { InAppVariantsThemeSelector } from 'com.batch.redux/theme.selector'

import { DELAY_MODE, STATUS } from 'constants/common'

export const pushContentABForActiveLanguageSelector: State => AbTestedPushRecord = createSelector(
  pushSelector,
  activeLanguageIdSelector,
  (data: Map<string, AbTestedPushRecord>, langId: ?string) => {
    return data.get(langId ?? 'default', AbTestedPushFactory())
  }
)

export const inAppContentForActiveLanguageSelector: State => AbTestedInAppRecord = createSelector(
  inappSelector,
  activeLanguageIdSelector,
  (data: Map<string, AbTestedInAppRecord>, langId: ?string) => {
    return data.get(langId ?? '', AbTestedInAppFactory())
  }
)

export const pickedLanguagesSelector: State => List<LanguageRecord> = createSelector(
  activeLanguagesIds,
  enrichedLanguagesSelector,
  (ids, languages) => {
    let res = []
    ids.forEach(id => {
      res.push(languages.find(l => l.value === id))
    })
    return new Immutable.List().push(...res)
  }
)

export const reviewLanguagesSelector: State => {
  invert: boolean,
  data: List<LanguageRecord>,
  ...
} = createSelector(
  pickedLanguagesSelector,
  enrichedLanguagesSelector,
  (picked: List<LanguageRecord>, all: List<LanguageRecord>) => {
    const allLanguages = all.filter(l => l.value !== 'default')
    const invert = allLanguages.size > 4 && allLanguages.size / 2 < picked.size
    return {
      invert,
      data: invert
        ? allLanguages.filter(
            lang => typeof picked.findKey(l => l.value === lang.value) === 'undefined'
          )
        : picked,
    }
  }
)
export const customAudiencesSelector: State => Set<CustomAudienceRecord> = createSelector(
  currentCampaign,
  c => {
    return c.customAudiences
  }
)
export const pickedRegionsSelector: State => List<RegionRecord> = createSelector(
  activeRegionsIds,
  enrichedRegionsSelector,
  (ids, regions) => {
    let res = []
    ids.forEach(id => {
      res.push(regions.find(r => r.value === id))
    })

    return new Immutable.List().push(...res)
  }
)
export const reviewRegionsSelector: State => {
  invert: boolean,
  data: List<RegionRecord>,
  ...
} = createSelector(
  pickedRegionsSelector,
  enrichedRegionsSelector,
  (picked: List<RegionRecord>, allRegionsWithFake: List<RegionRecord>) => {
    // Exclude regions wiv key ZZ, XX ou UNDEFINED
    const allRegions = allRegionsWithFake.filter(
      r => r.value !== 'XX' && r.value !== 'ZZ' && r.value !== 'UNDEFINED'
    )
    // If invert is true, it filters regions of  "all" to exclude region that havent value in picked list.
    // Otherwise it returns picked.
    const isTotalSizeEqualToPicked = picked.size === allRegions.size
    // Invert the condition if more than an half of countries has been selected.
    const invert =
      allRegions.size > 4 && allRegions.size / 2 < picked.size && !isTotalSizeEqualToPicked
    return {
      invert,
      data: invert
        ? allRegions.filter(
            region => typeof picked.findKey(l => l.value === region.value) === 'undefined'
          )
        : picked,
    }
  }
)
export const clustersTargeting: State => List<ClusterRecord> = createSelector(
  clustersAvailableForTargeting,
  activeClustersIds,
  (clusters, ids) => {
    return clusters.map(c => c.set('active', ids.has(c.get('code'))))
  }
)
const replaceFromCache = (text: string, cache: Map<string, TemplateRecord>) => {
  const res: string | TemplateRecord = cache.get(text, text)
  if (res && typeof res === 'object' && res.results && res.results.size > 0) {
    const first = res.results.get(0)
    if (first) {
      return first.result
    }
  }
  return text
}

const targetingSelector = (state: State) => state.targeting
const targetingAsJsSelector = createSelector(targetingSelector, target => target.toJS())

const SelectedFieldSector = (state: State) => state.theme.selectedField

const LoadingThemesSelector = (state: State) =>
  state.theme.loadingState === STATUS.LOADING || state.theme.loadingState === STATUS.INIT

export const StandalonePreviewSelector: State => {
  getVariantData: (variantId: Variant) => {
    title: string,
    message: string,
    image: string,
    audio: string,
    video: string,
    ...
  },
  safariIcon: string,
  variantsThemes: AbTestedThemeRecord,
  hasLanding: boolean,
  loadingThemes: boolean,
  selectedField: string,
  getLandingVariantData: (variant: Variant) => InAppContentRecord,
  templatedFields: Set<string>,
  app: AppRecord,
  mode: Map<Variant, 'light' | 'dark'>,
  date: Dayjs,
  icon: string,
  hasPush: boolean,
  image: string,
  audio: string,
  video: string,
} = createSelector(
  currentCampaign,
  LoadingThemesSelector,
  safariIconSelector,
  currentAppSelector,
  campaignConfigSelector,
  activeLanguageIdSelector,
  contentSelector,
  PreferredTemplateCache,
  InAppVariantsThemeSelector,
  SelectedFieldSector,
  (
    campaign,
    loadingThemes,
    safariIcon,
    app,
    config,
    lang,
    content,
    cache: Map<string, TemplateRecord>,
    variantsThemes,
    selectedField
  ) => {
    const attachmentKind = content.pushSettings.attachmentKind
    const landingData = content.inapp.get(lang ?? '', AbTestedInAppFactory())
    const pushData = content.push.get(lang ?? '', AbTestedPushFactory())

    let fieldsWithTemplateData = []
    variants.forEach(variant => {
      if (textUsesTemplating(landingData.get(variant)?.imageUrl))
        fieldsWithTemplateData.push('landingImage')
    })

    if (textUsesTemplating(content.pushSettings.attachmentUrl))
      fieldsWithTemplateData.push('attachment')
    if (textUsesTemplating(content.pushSettings.iconUrl)) fieldsWithTemplateData.push('icon')

    return {
      loadingThemes,
      getVariantData: function (variantId) {
        const variantData = pushData.get(variantId, PushContentLegacyFactory())
        const title = replaceFromCache(
          variantData.title.value === '' && app.platform === 'android'
            ? app.name
            : variantData.title.value,
          cache
        ).trim()
        const mediaKind = variantData.mediaKind

        const message = replaceFromCache(variantData.message.value, cache).trim()
        const image = mediaKind === 'image' ? replaceFromCache(variantData.mediaUrl, cache) : ''
        const audio = mediaKind === 'audio' ? replaceFromCache(variantData.mediaUrl, cache) : ''
        const video = mediaKind === 'video' ? replaceFromCache(variantData.mediaUrl, cache) : ''

        return {
          title,
          message,
          image,
          audio,
          video,
        }
      },
      selectedField,
      variantsThemes,
      app,
      safariIcon,
      templatedFields: Immutable.Set(fieldsWithTemplateData),
      getLandingVariantData: (variant: Variant) => {
        const landingVariant = landingData.get(variant)
        return InAppContentFactory({
          header: replaceFromCache(landingVariant.header, cache),
          title: replaceFromCache(landingVariant.title, cache),
          text: replaceFromCache(landingVariant.text, cache),
          mainButtonAction: landingVariant.mainButtonAction,
          mainButtonLabel: replaceFromCache(landingVariant.mainButtonLabel, cache),
          secondaryButtonAction: landingVariant.secondaryButtonAction,
          secondaryButtonLabel: replaceFromCache(landingVariant.secondaryButtonLabel, cache),
          globalTapAction: landingVariant.globalTapAction,
          imageUrl: replaceFromCache(landingVariant.imageUrl, cache),
          imageAlt: landingVariant.imageAlt,
          imageIsGif: landingVariant.imageIsGif,
          webviewUrl: landingVariant.webviewUrl,
          linkOpenTarget: landingVariant.linkOpenTarget,
          trackingId: replaceFromCache(landingVariant.trackingId, cache),
        })
      },
      mode: Immutable.Map({
        a:
          // $FlowIgnore j'ai bien check que a n'était pas null
          !variantsThemes.get('a') || variantsThemes.get('a').statusBarMode === 'auto'
            ? 'light'
            : // $FlowIgnore on a bien check si === auto donc le résultat peut pas être égal à auto
              variantsThemes.get('a').statusBarMode,
        b:
          // $FlowIgnore j'ai bien check que b n'était pas null
          !variantsThemes.get('b') || variantsThemes.get('b').statusBarMode === 'auto'
            ? 'light'
            : // $FlowIgnore on a bien check si === auto donc le résultat peut pas être égal à auto
              variantsThemes.get('b').statusBarMode,
      }),
      hasPush: campaign.type.toLowerCase() === 'push',
      hasLanding: campaign.hasLanding || campaign.type.toLowerCase() !== 'push',
      date:
        campaign.sendType === 'now'
          ? dayjs()
          : campaign.start === null || typeof campaign.start === 'undefined'
            ? dayjs()
            : campaign.start,
      icon: content.pushSettings.iconUrl,
      image:
        attachmentKind === 'image'
          ? replaceFromCache(content.pushSettings.attachmentUrl, cache)
          : '',
      audio: attachmentKind === 'audio' ? content.pushSettings.attachmentUrl : '',
      video: attachmentKind === 'video' ? content.pushSettings.attachmentUrl : '',
    }
  }
)

export const buildActionFromCtaData = (
  inappContent: InAppContentRecord,
  ctaName: 'mainButton' | 'secondaryButton' | 'globalTap'
): { action: null | string, args: any, label?: string, ... } => {
  const k =
    ctaName === 'mainButton'
      ? 'mainButtonAction'
      : ctaName === 'secondaryButton'
        ? 'secondaryButtonAction'
        : 'globalTapAction'
  const act: SdkActionRecord = inappContent.get(k)
  let actions: Array<[string, any]> = []
  switch (act.action.value) {
    case 'batch.dismiss':
    case 'batch.ios_request_notifications':
    case 'batch.ios_smart_reoptin':
    case 'batch.android_smart_reoptin':
    case 'batch.rating':
    case 'batch.ios_tracking_consent':
    case 'batch.ios_redirect_settings':
    case 'batch.android_redirect_settings':
      actions.push([act.action.value, {}])
      break
    case 'batch.android_request_notifications':
      actions.push([act.action.value, { _useChannel: true }])
      break
    case 'custom':
      try {
        actions.push([act.customActionName, JSON.parse(act.customActionArgs)])
      } catch (_) {
        actions.push([act.customActionName, {}])
      }
      break
    case 'batch.deeplink':
      actions.push(['batch.deeplink', { l: act.deeplinkUrl, li: act.deeplinkInternal }])
      break
    case 'batch.clipboard':
      actions.push(['batch.clipboard', { t: act.copyText }])
      if (act.deeplinkUrl !== '')
        actions.push(['batch.deeplink', { l: act.deeplinkUrl, li: act.deeplinkInternal }])
      break
  }
  act.additional.forEach(add => {
    let params: {
      a?: string,
      c?: string,
      e?: string,
      l?: string,
      t?: string,
      ...
    } = {
      [((add.mode === 'EVENT' ? 'e' : 'c'): string)]: add.name.replace(
        add.mode === 'EVENT' ? 'e.' : 't.',
        ''
      ),
    }
    if (add.mode === 'EVENT') {
      if (add.label) {
        params.l = add.label
      }
      actions.unshift(['batch.user.event', params])
    } else {
      params.a = add.mode === 'ADD_TAG' ? 'add' : 'remove'
      add.tags.forEach(tag => {
        actions.unshift(['batch.user.tag', { ...params, t: tag }])
      })
    }
  })
  let cta: { label?: string, action: null | string, args: any, ... } = {
    action: actions.length === 1 ? actions[0][0] : 'batch.group',
    args: actions.length === 1 ? actions[0][1] : { actions },
  }

  if (ctaName !== 'globalTap') {
    cta.label = inappContent.get(
      ctaName === 'mainButton' ? 'mainButtonLabel' : 'secondaryButtonLabel'
    )
  }
  return cta
}

export type customError = $ReadOnly<{ topic: string, message: string, lang: ?LanguageRecord, ... }>

const buildError = (topic: string, message: string, lang: ?LanguageRecord) => {
  return { topic, message, lang }
}

const variants = ['a', 'b']
const inAppMessageFields = Immutable.Set([
  'header',
  'title',
  'global',
  'text',
  'cta1',
  'cta2',
  'image',
])
export const customErrorsSelector: (state: State) => List<customError> = createSelector(
  campaignNameSelector,
  pushSettingsSelector,
  pushSelector,
  inappSelector,
  currentCampaign,
  InAppVariantsThemeSelector,
  currentAppSelector,
  restrictedLanguagesSelector,
  restrictedRegionsSelector,
  pickedLanguagesSelector,
  pickedRegionsSelector,
  translationLanguagesSelector,
  (
    campaignName: string,
    pushSettings: PushSettingsRecord,
    push: Map<string, AbTestedPushRecord>,
    inapp: Map<string, AbTestedInAppRecord>,
    campaign: CampaignRecord,
    variantsThemes: AbTestedThemeRecord,
    app: AppRecord,
    restrictedLanguages: Set<string>,
    restrictedRegions: Set<string>,
    targetedLanguages: List<LanguageRecord>,
    targetedRegions: List<RegionRecord>,
    translations: List<LanguageRecord>
  ) => {
    // INIT
    let errors = []
    const state = campaign.state
    const allowPastStart = state !== 'NEW' && state !== 'DRAFT'
    const sendType = campaign.sendType

    // CHECK NAME
    if (campaignName.length < 3) {
      errors.push(buildError('Name', 'Please enter a name for your campaign'))
    }

    // CHECK RESTRICTED LANG/REGION (LOREAL)
    if (restrictedLanguages.size > 0 && targetedLanguages.size === 0) {
      errors.push(
        buildError(
          'Who',
          'Your account is restricted, you need to pick at least one of the languages you are allowed to target'
        )
      )
    }
    if (restrictedRegions.size > 0 && targetedRegions.size === 0) {
      errors.push(
        buildError(
          'Who',
          'Your account is restricted, you need to pick at least one of the regions you are allowed to target'
        )
      )
    }

    // CHECK TRIGGER CONFIG
    const tc = campaign.triggerConfig
    if (sendType === 'trigger') {
      if (!tc.enterEvent) errors.push(buildError('When', 'Please provide a trigger event'))
      if (!tc.pushTimer.valid)
        errors.push(
          buildError(
            'When',
            'Please pick a valid value for “Push timing” between 1 minute & 30 days'
          )
        )
      if (tc.pushTimerMode !== 'event' && !tc.pushTimerReference)
        errors.push(buildError('When', 'Please complete your push timing configuration'))
      if (
        tc.delayMode === DELAY_MODE.TIMER &&
        tc.hasExitEvent &&
        tc.exitEvents.reduce((acc, ee) => !ee.eventId || acc, false)
      )
        errors.push(buildError('When', 'Please provide a cancellation event'))
      if (tc.hasCapping && !tc.capping) errors.push(buildError('When', 'Please provide a capping'))
      if (tc.hasGrace && !tc.grace.valid)
        errors.push(buildError('When', 'Please pick a grace period between 1 hour & 7 days'))
      if (!tc.pushTimer)
        errors.push(buildError('When', 'Please provide a valid value for Send after'))
      if (tc.hasInstanceId && !tc.instanceId)
        errors.push(buildError('When', 'Please provide a valid instance ID.'))
    }

    // CHECK DATES
    if (campaign.type === 'push') {
      const now = dayjs()
      if (sendType === 'trigger') {
        // ================================= CHECK END FOR DRIP
        if (tc.hasEnd && !tc.end) {
          errors.push(buildError('When', 'Please provide an end date'))
        }

        // ================================= CHECK DATE FOR DRIP

        if (tc.hasStart) {
          if (!tc.start) {
            errors.push(buildError('When', 'Please provide a start date'))
          } else {
            const start = tc.start
            if (tc.hasEnd) {
              if (!tc.end) {
                errors.push(buildError('When', 'Please provide an end date'))
              } else {
                const end = tc.end
                if (!allowPastStart && (!start || now.isAfter(start))) {
                  errors.push(buildError('When', "Send date can't be in the past"))
                }
                if (end && (!start || end.isBefore(start))) {
                  errors.push(buildError('When', 'End date must be after start date'))
                }
              }
            }
          }
        }
      } else {
        // ====================================================== CHECK DATE FOR STANDARD PUSH
        const start = campaign.start
        if (!start && sendType !== 'now') {
          errors.push(buildError('When', 'Please provide a start date'))
        } else {
          if (sendType !== 'now') {
            if (!allowPastStart && (!start || now.isAfter(start))) {
              errors.push(buildError('When', "Send date can't be in the past"))
            }
            const end = campaign.end
            if (end && (!start || end.isBefore(start))) {
              errors.push(buildError('When', 'End date must be after start date'))
            }
          }
          if (sendType === 'recurring') {
            const freq = parseInt(campaign.repeatFrequency)
            if (isNaN(freq) || freq < 1) {
              errors.push(buildError('When', 'Repeat frequency must be greater than 0'))
            }
          }
        }
      }
    } else {
      // ====================================================== CHECK DATE FOR IN-APP CAMPAIGN
      if (campaign.start && campaign.end && campaign.end.isBefore(campaign.start)) {
        errors.push(buildError('When', 'End date must be after start date'))
      }
    }

    // CHECK TRADS
    translations.map(lang => {
      // push
      if (campaign.type === 'push') {
        const pushContentAb = push.get(lang.value, AbTestedPushFactory())
        variants
          .filter(variant => variant === 'a' || campaign.abtesting.enabled)
          .map(variant => {
            const data = pushContentAb.get(variant, PushContentLegacyFactory())
            let missings = []
            if (
              data.title.value === '' &&
              (app.platform === 'windows' || app.platform === 'webpush')
            )
              missings.push('title')
            if (data.message.value === '') missings.push('message')
            if (missings.length) {
              errors.push(
                buildError('Notification', `Please enter a value for ${missings.join(', ')}`, lang)
              )
            }
          })
      }
      // inapp
      if (campaign.type === 'in-app' || campaign.hasLanding) {
        const inAppContentAb = inapp.get(lang.value, AbTestedInAppFactory())

        variants
          .filter(
            variant => variant === 'a' || (campaign.abtesting.enabled && campaign.type !== 'push')
          )
          .map(variant => {
            const neededLandingFields =
              variantsThemes
                .get(variant)
                ?.fields.filter(f => inAppMessageFields.has(f.id) && !f.hidden)
                .map(f => f.id) ?? Immutable.Set()

            const data = inAppContentAb.get(variant, InAppContentFactory())
            let missings = []
            if (neededLandingFields.has('header') && data.header === '') {
              missings.push('header')
            }
            if (neededLandingFields.has('title') && data.title === '') {
              missings.push('title')
            }
            if (neededLandingFields.has('text') && data.text === '') {
              missings.push('text')
            }
            if (neededLandingFields.has('cta1') && data.mainButtonLabel === '') {
              missings.push('main button')
            }
            if (neededLandingFields.has('cta2') && data.secondaryButtonLabel === '') {
              missings.push('secondary button')
            }
            if (neededLandingFields.has('image') && data.imageUrl === '') {
              missings.push('image')
            }
            if (
              variantsThemes.get(variant)?.payloadVars.kind === 'webview' &&
              (!data.webviewUrl || !!validators.url(data.webviewUrl))
            ) {
              missings.push('webview url')
            }
            if (missings.length) {
              errors.push(
                buildError('In-App', `Please enter a value for ${missings.join(', ')}`, lang)
              )
            }
          })
      }
    })

    // CHECK PAYLOAD
    if (pushSettings.payload !== '') {
      try {
        JSON.parse(pushSettings.payload)
      } catch (e) {
        errors.push(buildError('Custom payload', 'Please enter a valid JSON paylaod'))
      }
    }
    return new Immutable.List().push(...errors)
  }
)

export const invalidLangIdsSelector: (state: State) => Set<string> = createSelector(
  customErrorsSelector,
  (list: List<customError>) =>
    Immutable.Set(
      list
        .filter(error => !!error.lang)
        .toArray()
        .map(err => (err.lang ? err.lang.value : ''))
    )
)

// formats a campaign in API format, for persistence, based on state
export const apiCampaignSelector: State => any = createSelector(
  InAppVariantsThemeSelector,
  currentAppSelector,
  currentCampaign,
  targetingAsJsSelector,
  pushSelector,
  inappSelector,
  pushSettingsSelector,
  campaignNameSelector,
  getAPIQueryForIdSelector,
  (variantsThemes, app, campaign, targeting, push, inapp, pushSettings, name, getQueryForId) => {
    const type = campaign.type
    const withLanding = type === 'in-app' || campaign.hasLanding

    let clean = new CampaignModel()
    clean
      .setName(name)
      .setLabels(campaign.pickedLabels)
      .setLive(campaign.state === 'RUNNING')
      .setRegions(targeting.regions)
      .setAudiences(campaign.customAudiences.map(c => c.name).toArray())
      .setLanguages(targeting.languages)
      .setQuery(targeting.query)
    if (type === 'in-app') {
      const triggerLabel = campaign.triggerLabel || null
      // on a activé le custom payload sur les in-apps donc :
      clean.setCustomPayloadForInApp(pushSettings.payload)
      clean
        .setInAppTriggerConfig({
          trigger: campaign.trigger ? campaign.trigger : null,
          triggerLabel,
          priority: app.features.has('inapp-priority') ? campaign.inAppPriority : null,
          grace: campaign.gracePeriod ? campaign.gracePeriod : null,
          capping: campaign.capping,
        })
        .setRange(campaign.start, campaign.end, campaign.tzAware)
      if (app.features.has('inapp-just-in-time')) {
        clean.setJustInTime(campaign.inappJustInTime)
      }
    } else {
      if (campaign.sendType === 'trigger') {
        // à ce stade triggerConfig n'a pas encore les query de build sur enter / exits
        clean.setTrigger(
          campaign.triggerConfig.set('enterEventQuery', getQueryForId('enterEvent')).set(
            'exitEvents',
            campaign.triggerConfig.exitEvents.map((ee, key) => {
              return {
                eventId: ee.eventId,
                query: getQueryForId(`exitEvent-${key}`),
              }
            })
          )
        )
      } else {
        clean.setWhen(
          campaign.sendType,
          campaign.start,
          campaign.end,
          campaign.tzAware,
          campaign.repeatUnit,
          campaign.repeatFrequency,
          campaign.capping
        )
      }
      clean
        .setAdvanced(
          pushSettings.priority,
          pushSettings.hasCollapseKey ? pushSettings.collapseKey : null,
          pushSettings.hasExpiration ? pushSettings.expiration : null,
          pushSettings.payload
        )
        .setMedia(pushSettings.iconUrl, false, false, false)
    }

    // add clusters
    // $FlowFixMe
    targeting.clusters.forEach(c => clean.addCluster(c))

    // landing
    if (withLanding) {
      clean.activateLanding({
        themes: campaign.variantsThemeCodes,
        abTesting: campaign.abtesting,
        campaignType: campaign.type,
      })
      const hasInAppABTesting = campaign.abtesting.enabled && campaign.type !== 'push' // pas de a/b sur push landing

      campaign.translations.forEach((lg: string) => {
        let variants = hasInAppABTesting && campaign.type !== 'push' ? ['a', 'b'] : ['a']

        variants.forEach(variant => {
          let translation: translationType = {
            language: lg === 'default' ? null : lg,
            actions: [],
          }

          const inappForLang = inapp
            .get(lg, AbTestedInAppFactory())
            .get(variant, InAppContentFactory())

          const theme = variantsThemes[variant]
          let usedFields = Immutable.Set()
          if (theme && !!theme.code && theme.fields) {
            theme.fields.map(f => {
              if (!f.hidden) {
                usedFields = usedFields.add(f.id)
              }
            })
          }

          let fields: Array<'header' | 'text' | 'title' | 'imageAlt' | 'imageUrl' | 'trackingId'> =
            []

          fields.push('trackingId')
          if (usedFields.includes('header')) fields.push('header')
          if (usedFields.includes('text')) fields.push('text')
          if (usedFields.includes('title')) fields.push('title')
          if (usedFields.includes('image')) {
            fields.push('imageAlt')
            fields.push('imageUrl')
          }

          if (theme && theme.payloadVars.kind === 'webview') {
            translation['webview_url'] = inappForLang.webviewUrl
            translation['link_open_target'] =
              inappForLang.linkOpenTarget === 'browser' ? 'BROWSER' : 'IN_APP'
          }

          fields.forEach(field => {
            // $FlowFixMe I don't get this one
            const val = inappForLang.get(field)
            if (val) {
              if (field === 'text') {
                // we only provide body_html and the back does the heavy lifting
                // translation['body_html'] = val
                translation['body'] = val
              } else if (field === 'imageAlt') {
                translation['image_description'] = val
              } else if (field === 'imageUrl') {
                translation['image'] = val
              } else if (field === 'trackingId') {
                translation['tracking_id'] = val
              } else {
                translation[field] = val
              }
            }
          })

          if (usedFields.includes('cta1')) {
            translation.actions.push(buildActionFromCtaData(inappForLang, 'mainButton'))
          }
          if (usedFields.includes('cta2')) {
            translation.actions.push(buildActionFromCtaData(inappForLang, 'secondaryButton'))
          }
          if (usedFields.includes('global')) {
            const ga = buildActionFromCtaData(inappForLang, 'globalTap')
            translation.global_action = ga.action === null ? null : ga
          }
          clean.addInAppTranslation(
            translation,
            hasInAppABTesting ? variant : false,
            hasInAppABTesting ? campaign.abtesting.activeVariants.has(variant) : true
          )
        })
      })
    } else {
      // otherwise the landing won't be deleted
      clean.landing = null
    }

    if (type === 'push') {
      const hasABTesting = campaign.abtesting.enabled
      let variants = hasABTesting ? ['a', 'b'] : ['a']

      campaign.translations.add('default').forEach((lg: string) => {
        variants.forEach((variant: 'a' | 'b') => {
          let msgTrad: {
            language: ?string,
            title?: string,
            body: string,
            deeplink?: string,
            media?: { [key: 'picture' | 'audio' | 'video']: string, ... },
            ...
          } = {
            body: push
              .get(lg, AbTestedPushFactory())
              .get(variant, PushContentLegacyFactory())
              .message.value.trim(),
            language: lg === 'default' ? null : lg,
          }
          const title = push
            .get(lg, AbTestedPushFactory())
            .get(variant, PushContentLegacyFactory())
            .title.value.trim()
          if (title) {
            msgTrad.title = title
          }

          const deeplink = push
            .get(lg, AbTestedPushFactory())
            .get(variant, PushContentLegacyFactory()).deeplink

          if (deeplink) {
            msgTrad.deeplink = deeplink
          }

          let mediaKind = push
            .get(lg, AbTestedPushFactory())
            .get(variant, PushContentLegacyFactory()).mediaKind

          let mediaUrl = push
            .get(lg, AbTestedPushFactory())
            .get(variant, PushContentLegacyFactory()).mediaUrl

          // Sur le fake omni form multiplatform form du turfu les images ne sont pas A/B ni par langue, si aucune img n'est trouvée dans le message on cherche alors au dessus aussi
          if (!mediaUrl) {
            mediaKind = pushSettings.attachmentKind
            mediaUrl = pushSettings.attachmentUrl
          }

          if (mediaKind && mediaUrl) {
            const mediaKindFormatted: string = mediaKind === 'image' ? 'picture' : mediaKind

            msgTrad.media = {
              [mediaKindFormatted]: mediaUrl,
            }
          }

          clean.addPushTranslation(
            msgTrad,
            hasABTesting ? variant : false,
            hasABTesting ? campaign.abtesting.activeVariants.has(variant) : true
          )
        })
      })
    }
    return clean
  }
)
