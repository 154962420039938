// @flow
import Immutable, { type Map } from 'immutable'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { buildAgeFromDuration } from 'com.batch.common/utils'

import { type OrchestrationJourneyParserResult } from '../../../orchestration/infra/parses/orchestration.parse'
import { TIMER_MAX_SECONDS, TIMER_MIN_SECONDS } from '../../models/constants'
import { generateUniqueEventId } from '../../models/journey-trigger.helper'
import {
  SplitBranchFactory,
  QuietTimesFactory,
  type QuietTimesRecord,
  type JourneyNodeRecord,
  JourneySettingsFactory,
  EventNextFactory,
  JourneyNodes,
  EventWithOptionalQueryFactory,
  WaitUntilTimeFactory,
} from '../../models/journey.records'
import {
  type TimerBlock,
  type Blocks,
  type OrchestrationJourney,
} from '../types/orchestration-journey.types'
import { AgeFactory, type AgeRecord } from 'com.batch.redux/_records'
import { type TargetStateRecord, TargetStateFactory } from 'com.batch.redux/target/target.records'

import {
  parseMessages,
  type messageParserConfig,
} from 'com.batch/message/infra/parses/messages.parse'
import { consistentLocaleCase } from 'com.batch/orchestration/infra/parses/consistent-locale-case'
import {
  type DashboardConfig,
  type OrchestrationChannels,
} from 'com.batch/orchestration/infra/types/orchestration.types'

type queryToParse = { queryId: string, query: string, eventId: string, retries: number, ... }
type OrchestrationJourneyParser = ({
  journey: OrchestrationJourney,
  config: DashboardConfig,
  state: campaignStateType,
  channels: ?OrchestrationChannels,
  id: string,
  name: string,
  labelCodes: Array<string>,
}) => OrchestrationJourneyParserResult

const ensureEventNameIsPrefixed = (name?: string): string => {
  if (!name) return ''
  if (name.startsWith('e.')) {
    return name
  }
  return `e.${name}`
}

const calculateTimerMode = (raw: TimerBlock) => {
  if (raw.timer.timerReference) {
    return raw.timer?.timer?.slice(0, 1) === '-' ? 'before' : 'after'
  }
  if (raw.timer.timer) {
    return 'for'
  }
  if (raw.timer.waitUntilTime) {
    return 'until'
  }
}

const buildTimer = (timer: string, timerReferenceAttribute: ?string): AgeRecord => {
  const durationString = timer
  const [dropNano] = durationString.split('.')
  const ensureUnitIsPresent =
    dropNano.includes('s') || dropNano.includes('h') || dropNano.includes('d')
      ? dropNano
      : `${dropNano}s`
  const finalDuration = ensureUnitIsPresent.replace('-', '')
  const timerAgeRecord = buildAgeFromDuration(finalDuration, ['m', 'h', 'd'])
  return timerAgeRecord.set(
    'valid',
    timerAgeRecord.seconds >= (timerReferenceAttribute ? 0 : TIMER_MIN_SECONDS) &&
      timerAgeRecord.seconds <= TIMER_MAX_SECONDS
  )
}

export const parseBlock: (Blocks, { [string]: messageParserConfig, ... }) => JourneyNodeRecord = (
  raw,
  parserConfig
) => {
  if (raw.timer) {
    // TIMER
    /* 
      we append .000000001s to timer so it can work with negative duration on the backend
      not needed here so we drop it before parsing
    */
    return JourneyNodes.Timer({
      id: raw.name,
      timer: raw.timer.timer
        ? buildTimer(raw.timer.timer, raw.timer.timerReference?.timerReferenceAttribute)
        : AgeFactory({
            inputValue: '1',
            seconds: 3600,
            valid: true,
            unit: 'h',
            label: 'hour',
            fullText: '1 hour',
          }),
      waitUntilTime: raw.timer.waitUntilTime
        ? WaitUntilTimeFactory({
            minute: raw.timer.waitUntilTime.min,
            hour: raw.timer.waitUntilTime.hour,
            daysOfWeek: Immutable.Set<number>(
              raw.timer.waitUntilTime?.daysOfWeek
                ? raw.timer.waitUntilTime.daysOfWeek
                : [0, 1, 2, 3, 4, 5, 6]
            ),
          })
        : WaitUntilTimeFactory(),
      mode: calculateTimerMode(raw),
      timerReference: raw.timer.timerReference?.timerReferenceAttribute,
      onEvents: new Immutable.List().push(
        ...(!Array.isArray(raw.timer.onEvents)
          ? []
          : raw.timer.onEvents.map(({ triggers, nextNodeId }) => {
              return EventNextFactory({
                triggers: new Immutable.List().push(
                  ...triggers.map(evt => {
                    let query = evt.event?.jsonQuery ?? ''
                    return EventWithOptionalQueryFactory({
                      name: ensureEventNameIsPrefixed(evt.event.event),
                      query,
                      eventId: generateUniqueEventId(raw.name),
                      requireMatchingInstanceId: evt.requireMatchingInstanceId,
                    })
                  })
                ),
                nextNodeId: nextNodeId,
              })
            }))
      ),
      nextNodeId: raw.timer.nextNodeId,
    })
  } else if (raw.message) {
    // MESSAGE
    const config = parserConfig[raw.message.messageReference]

    const isFullyEmpty = (!config || config.contentFullyEmpty) && !raw.label

    return JourneyNodes.Message({
      id: raw.name,
      label: raw.label,
      messageReference: raw.message.messageReference,
      nextNodeId: raw.message.nextNodeId,
      hasQuietTimes: Boolean(raw.decorators?.find(d => d.quiet)),
      channel: config?.channel ?? 'email',
      errors: Immutable.Set(
        !isFullyEmpty && (!config || config.contentInvalid) ? ['INCOMPLETE_MESSAGE'] : []
      ),
    })
  } else if (raw.yesNo) {
    // YESNO
    return JourneyNodes.YesNo({
      id: raw.name,
      label: raw.label,
      yesNodeId: raw.yesNo.yesNodeId,
      noNodeId: raw.yesNo.noNodeId,
    })
  } else if (raw.random) {
    // YESNO
    return JourneyNodes.Random({
      id: raw.name,
      label: raw.label,
      splits: new Immutable.List().push(
        ...raw.random.splits.map(rs =>
          SplitBranchFactory({
            weight: rs.weight,
            nextNodeId: rs.nextNodeId,
          })
        )
      ),
    })
  }
  return JourneyNodes.Final({
    id: raw.name,
  })
}

const extractQueries: (nodes: Map<string, JourneyNodeRecord>) => Array<queryToParse> = nodes => {
  let queries: Array<queryToParse> = []
  nodes.forEach(node => {
    switch (node.type) {
      case 'FINAL':
      case 'MESSAGE':
        break
      case 'TIMER':
        node.onEvents.forEach(({ triggers }) => {
          triggers.forEach(({ name, query, eventId }) => {
            if (query) {
              queries.push({
                queryId: eventId,
                query: query,
                eventId: name,
                retries: 0,
              })
            }
          })
        })
    }
  })
  return queries
}

export const parseOrchestrationJourney: OrchestrationJourneyParser = ({
  id,
  state,
  channels,
  name,
  journey,
  config,
  labelCodes,
}) => {
  let queries = []
  const settings = journey.settings

  if (settings.targeting?.jsonQuery)
    queries.push({
      queryId: 'targeting',
      query: typeof settings.targeting.jsonQuery === 'string' ? settings.targeting.jsonQuery : '',
      eventId: '',
      retries: 0,
    })

  /*
    subscription status is handled on a per message basis in the backend
    and as a global setting on target in the dashboard. we retrieve the value
  */
  const messages = journey.messages
  const { subscriptionStatus, message, messageConfigs } = parseMessages({ messages, config })
  const rootId = journey.rootId
  let nodes = Immutable.Map(
    Object.keys(journey.nodes).map(key => [key, parseBlock(journey.nodes[key], messageConfigs)])
  )

  let quietTimes: ?QuietTimesRecord = null

  // extract queries and targetings from yesno blocks
  let yesNoTargets: Array<[string, TargetStateRecord]> = []
  let yesNoQueries: Array<queryToParse> = []
  Object.keys(journey.nodes).forEach(nodeId => {
    const node = journey.nodes[nodeId]
    if (node.yesNo) {
      let hasQuery = false
      if (node.yesNo.branchingCriteria.jsonQuery) {
        yesNoQueries.push({
          queryId: nodeId,
          query: node.yesNo.branchingCriteria.jsonQuery,
          eventId: '',
          retries: 0,
        })
        hasQuery = true
      }
      const languages = Immutable.Set(node.yesNo.branchingCriteria.languages ?? [])
      const regions = Immutable.Set(node.yesNo.branchingCriteria.regions ?? [])
      yesNoTargets.push([
        nodeId,
        TargetStateFactory({
          languages,
          regions,
        }),
      ])
      if (!hasQuery && !languages.size && !regions.size) {
        const nodeRecord = nodes.get(nodeId)
        if (nodeRecord && nodeRecord.type === 'YESNO') {
          nodes = nodes.set(nodeId, nodeRecord.set('errors', Immutable.Set(['MISSING_TARGETING'])))
        }
      }
    }
  })
  nodes
    .filter(node => node.type === 'MESSAGE')
    .forEach(messageNode => {
      const rawProtoNode = journey.nodes[messageNode.id]
      rawProtoNode.decorators
        ?.filter(deco => {
          return deco.quiet
        })
        .forEach(quietDecoratorProto => {
          // Check valuestype of decorator (i'll do later (gator) mister)
          if (quietDecoratorProto.quiet) {
            quietTimes = QuietTimesFactory({
              behavior:
                quietDecoratorProto.quiet?.behaviour === 'SKIP'
                  ? 'skip'
                  : !quietDecoratorProto.quiet?.behaviour
                    ? 'wait'
                    : 'wait',
              startHour: quietDecoratorProto.quiet?.startHour,
              endHour: quietDecoratorProto.quiet?.endHour,
              startMin: quietDecoratorProto.quiet?.startMin
                ? quietDecoratorProto.quiet?.startMin
                : 0,
              endMin: quietDecoratorProto.quiet?.endMin ? quietDecoratorProto.quiet?.endMin : 0,
              quietDaysOfWeek: Immutable.Set<number>(
                quietDecoratorProto.quiet?.quietDaysOfWeek ?? []
              ),
              quietHoursTimePeriodDisabled:
                quietDecoratorProto.quiet?.quietHoursTimePeriodDisabled ?? false,
            })
          }
        })
    })
  // when quiet times is disabled, activate the toggle for all messages nodes
  if (!quietTimes) {
    nodes = nodes.map(node => (node.type === 'MESSAGE' ? node.set('hasQuietTimes', true) : node))
  }
  return {
    settings: JourneySettingsFactory({
      hasInstanceId: Boolean(settings.instanceId),
      instanceId: !settings.instanceId
        ? ''
        : settings.instanceId?.type === 'LABEL'
          ? 'eventLabel()'
          : `eventAttr(attr: '${settings.instanceId.instanceIdFieldName}')`,
      hasStart: Boolean(settings?.startTime),
      start: settings?.startTime ? dayjs.utc(settings?.startTime ?? '', 'YYYY/MM/DD HH:mm') : null,
      hasEnd: Boolean(settings?.endTime),
      end: settings?.endTime ? dayjs.utc(settings?.endTime ?? '', 'YYYY/MM/DD HH:mm') : null,
      hasCapping: Boolean(settings.globalCapping),
      capping: settings.globalCapping,
      hasGrace: Boolean(settings.globalGracePeriod),
      gracePeriod: settings.globalGracePeriod
        ? buildAgeFromDuration(settings.globalGracePeriod, ['h', 'd'])
        : AgeFactory(),
      entryEvents: new Immutable.List().push(
        ...(settings.entryEvents ?? []).map((evt, key) => {
          // for now we only suppport one enter event
          if (typeof evt.jsonQuery === 'string' && key === 0) {
            queries.push({
              queryId: 'enterEvent',
              query: evt.jsonQuery,
              eventId: ensureEventNameIsPrefixed(evt.event),
              retries: 0,
            })
          }
          return EventWithOptionalQueryFactory({
            name: ensureEventNameIsPrefixed(evt.event),
            query: evt.jsonQuery ?? '',
            requireMatchingInstanceId: true,
          })
        })
      ),
      hasQuietTimes: Boolean(quietTimes),
      quietTimes: quietTimes ? quietTimes : QuietTimesFactory(),
    }),
    message,
    queries: [...queries, ...extractQueries(nodes), ...yesNoQueries],
    targets: Immutable.Map([
      [
        'default',
        TargetStateFactory({
          languages: Immutable.Set(settings.targeting?.languages ?? []).map(consistentLocaleCase),
          regions: Immutable.Set(settings.targeting?.regions ?? []),
          subscriptionStatus,
        }),
      ],
      ...yesNoTargets,
    ]),
    incomplete: config?.incomplete ?? false,
    rootId,
    nodes,
    state,
    channels: Immutable.Set([
      ...(channels?.includes('EMAIL') ? ['email'] : []),
      ...(channels?.includes('PUSH') ? ['push'] : []),
      ...(channels?.includes('SMS') ? ['sms'] : []),
    ]),
    id,
    name,
    labelCodes: Immutable.Set(labelCodes),
  }
}
