// @flow

import { parseStatsByStep } from '../infra/parses/parse-stats-by-step'
import { type OrchestrationStatsByStepRecord } from '../models/orchestration-stats-by-step.record'
import { orchestrationAnalyticsMapSelector } from '../store/orchestration-analytics.selector'
import { type DispatchExtraBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

export type FetchOrchestrationStatsByStepAction = ReduxAction<
  'FETCH_ORCHESTRATION_STATS_BY_STEP',
  string,
>

export type FetchOrchestrationStatsByStepSuccessAction = ReduxAction<
  'FETCH_ORCHESTRATION_STATS_BY_STEP_SUCCESS',
  { token: string, data: OrchestrationStatsByStepRecord },
>

export type FetchOrchestrationStatsByStepFailureAction = ReduxAction<
  'FETCH_ORCHESTRATION_STATS_BY_STEP_FAILURE',
  {
    token: string,
    error: Error,
  },
>

export type FetchOrchestrationStatsByStepActions =
  | FetchOrchestrationStatsByStepAction
  | FetchOrchestrationStatsByStepSuccessAction
  | FetchOrchestrationStatsByStepFailureAction

export const fetchStatsByStep =
  ({ token }: { token: string }): DispatchExtraBoundFn<Promise<void>> =>
  async (dispatch, getState, { dataService }) => {
    const state = getState()

    const analyticsMap = orchestrationAnalyticsMapSelector(state)
    const loadingState = analyticsMap.get(token)?.stepsLoadingState ?? 'INIT'
    const expire = analyticsMap.get(token)?.expire ?? -Infinity

    if (
      loadingState !== 'LOADING' &&
      (!['ERROR', 'LOADED'].includes(loadingState) || expire < Date.now())
    ) {
      const project = currentProjectSelector(state)
      const fetchIntentAction: FetchOrchestrationStatsByStepAction = {
        type: 'FETCH_ORCHESTRATION_STATS_BY_STEP',
        payload: token,
      }
      dispatch(fetchIntentAction)
      try {
        const data = await dataService.fetchDynamicStats({
          project,
          token,
          groupDimensionNames: ['step', 'channel'],
          parser: parseStatsByStep,
        })
        const fetchSuccessAction: FetchOrchestrationStatsByStepSuccessAction = {
          type: 'FETCH_ORCHESTRATION_STATS_BY_STEP_SUCCESS',
          payload: { data, token },
        }
        dispatch(fetchSuccessAction)
      } catch (error) {
        const fetchFailureAction: FetchOrchestrationStatsByStepFailureAction = {
          type: 'FETCH_ORCHESTRATION_STATS_BY_STEP_FAILURE',
          payload: { token, error },
        }
        dispatch(fetchFailureAction)
      }
    }
  }
