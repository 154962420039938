// @flow
// ====================== LIBS

import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'

import { useToggle } from 'components/_hooks'
import { Popin } from 'components/common/popin/popin'
import { colors } from 'components/styled/tokens'
import { label } from 'components/styled/tokens/texts'

import { getEmailContentForActiveLanguageSelector } from 'com.batch/email/store/email-automation.selector'
import { previewLanguageSelector } from 'com.batch/message/store/message.selector'

import { EmailBuilderHeader } from 'com.batch/email/ui/components/email-builder-header'
import { EmailChoice } from 'com.batch/email/ui/components/email-choice/email-choice'
import { EmailPreview } from 'com.batch/email/ui/components/email-preview/email-preview'
import { PopinSendTestEmail } from 'com.batch/email/ui/components/popins/popin-send-test'
import { CodeEditor } from 'com.batch/email/ui/pages/code-editor'
import { DragAndDropEditor } from 'com.batch/email/ui/pages/drag-and-drop-editor'
import { EmailBuilderContainer, EmailClient } from 'com.batch/email/ui/pages/email-builder.styles'
import { updateEmailTemplate } from 'com.batch/email/usecases/update-email-template'
import { PreviewProfilePopin } from 'com.batch/shared/ui/component/popins/preview-profile-popin'

type EmailBuilderProps = {
  messageId: string,
  campaignFormMode?: boolean,
}

export const EmailBuilder: React$AbstractComponent<EmailBuilderProps, *> = React.memo(
  ({ messageId, campaignFormMode = false }: EmailBuilderProps): React.Node => {
    const dispatch = useDispatch()

    const getContent = useSelector(getEmailContentForActiveLanguageSelector)
    const content = React.useMemo(() => getContent(messageId), [getContent, messageId])
    const lang = useSelector(previewLanguageSelector)
    const togglePopinSendTest = useToggle()
    const codeEditorPopinState = useToggle()
    const dragAndDropEditorPopinState = useToggle()
    const dynamicContentPopinState = useToggle()

    const updateTemplate = React.useCallback(() => {
      dispatch(updateEmailTemplate({ messageId, lang }))
    }, [dispatch, lang, messageId])

    /**
     * Ouvre en conséquence la modale code ou drag&drop selon le mode d'édition initiale du HTML.
     */
    const editTemplate = React.useCallback(() => {
      if (content?.htmlEditorConfig?.type === 'STRIPO') {
        dragAndDropEditorPopinState.open()
      } else {
        codeEditorPopinState.open()
      }
    }, [codeEditorPopinState, content?.htmlEditorConfig?.type, dragAndDropEditorPopinState])

    React.useEffect(() => {
      updateTemplate()
    }, [updateTemplate, messageId, lang])

    return (
      <React.Fragment>
        <EmailBuilderContainer>
          <EmailClient $transparentBg={!content?.templates.html}>
            <p
              style={{
                background: colors.fillBackground,
                height: 28,
                paddingInline: 28,
                display: 'flex',
                alignItems: 'center',
                color: colors.textDisabled,
                font: label.font,
                borderBottom: `1px solid ${colors.stroke}`,
                flexShrink: 0,
                fontSize: 12,
              }}
            >
              New message
            </p>
            <EmailBuilderHeader messageId={messageId} />
            {content?.templates.html ? (
              <EmailPreview
                messageId={messageId}
                showToolbar={true}
                editTemplate={editTemplate}
                sendTestEmailPopinState={togglePopinSendTest}
                dynamicContentPopinState={dynamicContentPopinState}
                campaignFormMode={campaignFormMode}
              />
            ) : (
              <EmailChoice
                messageId={messageId}
                dragAndDropEditorToggleState={dragAndDropEditorPopinState}
                codeEditorToggleState={codeEditorPopinState}
                content={content}
              />
            )}
          </EmailClient>
        </EmailBuilderContainer>
        <Popin
          opened={dragAndDropEditorPopinState.value}
          close={dragAndDropEditorPopinState.close}
          fullscreen={true}
          disableEscapeClose={true}
        >
          <DragAndDropEditor
            messageId={messageId}
            popinState={dragAndDropEditorPopinState}
            sendTestEmailPopinState={togglePopinSendTest}
          />
        </Popin>

        <Popin
          opened={codeEditorPopinState.value}
          close={codeEditorPopinState.close}
          fullscreen={true}
          disableEscapeClose={true}
        >
          <CodeEditor
            messageId={messageId}
            popinState={codeEditorPopinState}
            sendTestEmailPopinState={togglePopinSendTest}
          />
        </Popin>

        <ThemeProvider theme={{ disabledMode: false }}>
          <PopinSendTestEmail togglePopin={togglePopinSendTest} content={content} />

          <PreviewProfilePopin
            togglePopin={dynamicContentPopinState}
            updateTemplate={updateTemplate}
          />
        </ThemeProvider>
      </React.Fragment>
    )
  }
)
