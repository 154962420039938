// @flow

import Immutable, { type List } from 'immutable'
import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { formatInstanceId } from 'com.batch.common/utils'

import { QueryBuilderContext } from '../query-builder.context'
import { currentAppSelector } from 'com.batch.redux/app'
import { searchOptions } from 'com.batch.redux/attribute.api'
import { currentCampaignAppsSelector } from 'com.batch.redux/campaign.selector'
import { fetchUnifiedCustomerDataValues } from 'com.batch.redux/corelogic/usecases/unified-customer-data/fetch-unified-customer-data-values'
import { fetchUnifiedCustomerEventValues } from 'com.batch.redux/corelogic/usecases/unified-customer-data/fetch-unified-customer-event-values'
import { type ConditionRecord } from 'com.batch.redux/query/query.records'
import { groupFetchAttributeValues, fetchEventData } from 'com.batch.redux/stat.api'

export type InputProps = {
  condition: ConditionRecord,
  updateCondition: ConditionRecord => void,
  isInvalid: boolean,
  isDisabled?: boolean,
  removeSelf: () => void,
  ...
}

const eventAttributeRegex = /eventAttr\(attr: ?'([a-zA-Z0-9-_]*)'\)/

export function useSearchOptions(
  attributeId: string,
  isTag?: boolean
): string => Promise<List<string>> {
  const app = useSelector(currentAppSelector)
  const apps = useSelector(currentCampaignAppsSelector)
  const dispatch = useDispatch()
  const { context, eventId, isProfileMode } = React.useContext(QueryBuilderContext)

  const loadOptions = React.useCallback(
    search => {
      if (isProfileMode) {
        if (context === 'event_filter') {
          const eventDataType =
            attributeId === 'eventLabel()'
              ? 'LABELS'
              : attributeId === 'eventTags()'
                ? 'TAGS'
                : 'ATTRIBUTES'

          return dispatch(
            fetchUnifiedCustomerEventValues({
              attributeName: formatInstanceId(attributeId),
              eventName: eventId ?? '',
              type: eventDataType,
              searchTerm: search,
            })
          )
        }
        return dispatch(
          fetchUnifiedCustomerDataValues({
            key: attributeId,
            type: isTag ? 'TAGS' : 'ATTRIBUTES',
            searchTerm: search,
          })
        )
      }
      if (context === 'targeting') {
        if (app.id === 0) {
          return groupFetchAttributeValues({
            appIds: apps.map(app => app.id).toArray(),
            attributeId,
            query: search,
          })
        } else {
          return searchOptions({ app, search, attributeId })
        }
      }
      const eventDataType =
        attributeId === 'eventLabel()'
          ? 'label'
          : attributeId === 'eventTags()'
            ? 'tag'
            : 'attribute'
      const tmp = eventAttributeRegex.exec(attributeId)
      const eventDataAttributeKey = tmp !== null ? tmp[1] : undefined
      return fetchEventData({
        appId: app.id,
        query: search,
        eventId: eventId ?? '',
        eventDataType,
        eventDataAttributeKey:
          eventDataType === 'label'
            ? 'Label'
            : eventDataType === 'tag'
              ? 'Tag'
              : eventDataAttributeKey,
      }).then(({ body }) => {
        try {
          return new Immutable.List().push(
            ...body.results[eventId].filter(item => item.value !== '').map(item => item.value)
          )
        } catch (_) {
          return new Immutable.List()
        }
      })
    },
    [app, apps, attributeId, context, eventId, isProfileMode, isTag, dispatch]
  )
  return loadOptions
}

export const optionFormatter = (
  option:
    | {
        label: string,
        value: string,
        __isNew__: true,
        ...
      }
    | string
): React.Node => {
  return typeof option === 'string' ? (
    option
  ) : (
    <React.Fragment>
      <em>Create </em>
      {option.value}...
    </React.Fragment>
  )
}
