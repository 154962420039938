// @flow
import Immutable, { type Set, type List } from 'immutable'
import * as React from 'react'

import { Grid } from 'components/common/grid'
import { InputWrapper, Select, SelectMulti } from 'components/form'
import {
  OperatorAndNegateFormatter,
  type operatorAndNegate,
} from 'components/query/left/left-helper'

import { type RegionRecord, type LanguageRecord } from 'com.batch.redux/_records'
import { InOperator, NotInOperator } from 'com.batch.redux/query/query.records.operators'

const optionOperatorToString = (opt: ?operatorAndNegate) => opt?.operator.value ?? ''
const optToString = (opt: ?(LanguageRecord | RegionRecord | InvertOption)) => opt?.label ?? ''
const operators: List<operatorAndNegate> = new Immutable.List().push(
  ...[
    { operator: InOperator, negate: false },
    { operator: NotInOperator, negate: false },
  ]
)
type InvertOption = {
  value: boolean,
  label: string,
}
type LangRegionProps = {
  verticalForm: boolean,
  kind: 'language' | 'country',
  selected: Set<string>,
  values: List<LanguageRecord | RegionRecord>,
  onChange: (Set<string>) => void,
}
export const LangRegion = ({
  verticalForm,
  kind,
  selected,
  values,
  onChange,
}: LangRegionProps): React.Node => {
  const [isInverted, setIsInverted] = React.useState(
    selected.size > 5 && selected.size / 2 < values.size && values.size !== selected.size
  )
  const onChangeInvertMode = React.useCallback(
    (opt: ?operatorAndNegate) => {
      if (opt) {
        setIsInverted(opt.operator === NotInOperator)
      }
      if (selected.size > 0) {
        onChange(Immutable.Set(values.filter(v => !selected.has(v.value)).map(item => item.value)))
      }
    },
    [onChange, selected, values]
  )
  const onSelectChange = React.useCallback(
    list => {
      onChange(
        isInverted
          ? Immutable.Set(
              values.filter(v => !list.some(w => v.value === w.value)).map(item => item.value)
            )
          : Immutable.Set(list.map(item => item.value))
      )
    },
    [onChange, isInverted, values]
  )

  return (
    <InputWrapper
      label={kind === 'language' ? 'Language' : 'Country'}
      style={
        verticalForm
          ? { marginTop: 20, marginBottom: 20 }
          : { alignItems: 'center', minHeight: 42, padding: '10px 0' }
      }
    >
      <Grid template="150px 1fr">
        <Select
          isSearchable={false}
          isClearable={false}
          style={{ width: 150 }}
          options={operators}
          isDisabled={values.size < 5}
          optionToString={optionOperatorToString}
          optionFormatter={OperatorAndNegateFormatter}
          value={isInverted ? operators.get(1) : operators.get(0)}
          onChange={onChangeInvertMode}
        />
        <SelectMulti
          key={isInverted ? 'invert' : 'normal'}
          optionToString={optToString}
          placeholder={
            kind === 'language'
              ? 'Speak any language (pick to restrict)'
              : 'Live in any country (pick to restrict)'
          }
          options={values}
          isClearable
          value={values.filter(value =>
            isInverted && selected.size > 0 ? !selected.has(value.value) : selected.has(value.value)
          )}
          onChange={onSelectChange}
        />
      </Grid>
    </InputWrapper>
  )
}
