// @flow
import Immutable, { type Map } from 'immutable'
import { createSelector } from 'reselect'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { type State, type fetchingState } from 'com.batch.redux/_records'

import {
  type ImportedTokenRecord,
  type ProfileStateRecord,
  type ProfileDataRecord,
  type InstallRecord,
  type ProfilePlatformRecord,
  ProfileDataFactory,
  ProfilePlatformFactory,
} from 'com.batch/profile/models/profile.records'

type extract<T> = State => T

export const profileSelector = (state: State): ProfileStateRecord => {
  return state.profile
}

export const profileLoadingStateSelector: extract<fetchingState> = createSelector(
  profileSelector,
  profileState => profileState.loadingState
)

export const profileDataStateSelector: extract<ProfileDataRecord> = createSelector(
  profileSelector,
  profileState => profileState.data.get(profileState.profileId) ?? ProfileDataFactory()
)

export const eventsLoadingStateSelector: extract<fetchingState> = createSelector(
  profileDataStateSelector,
  profileDataState => profileDataState.eventsLoadingState
)

export const nbProfilesSelector: extract<number> = createSelector(
  profileSelector,
  profileState => profileState.data.size
)

export const profileIndexSelector: extract<number> = createSelector(profileSelector, profileState =>
  profileState.data.keySeq().indexOf(profileState.profileId)
)

export const lastVisitInstallByPlatformSelector: extract<Map<string, InstallRecord>> =
  createSelector(profileDataStateSelector, profileDataState =>
    profileDataState.installs
      .reduce((acc, current) => {
        const accCurrentPlatform = acc.get(current.platform)
        if (
          !accCurrentPlatform ||
          dayjs(accCurrentPlatform.lastActivity).isBefore(dayjs(current.lastActivity))
        )
          return acc.set(current.platform, current)
        return acc
      }, Immutable.Map())
      .reduce((acc, current) => acc.set(current.installID, current), Immutable.Map())
  )

export const importedTokensSelector: extract<Map<string, ImportedTokenRecord>> = createSelector(
  profileDataStateSelector,
  profileDataState => profileDataState.importedTokens
)

export const profilePlatformsSelectors: extract<Map<string, ProfilePlatformRecord>> =
  createSelector(profileDataStateSelector, profileDataState => {
    const result = []
    profileDataState.installs.forEach(install => {
      result.push([
        `install_${install.installID}`,
        ProfilePlatformFactory({
          deviceName: install.marketingName ? install.marketingName : install.type,
          deviceType: install.type,
          platform: install.platform,
          installId: install.installID,
          pushToken: install.pushToken,
          lastActivity: install.lastActivity,
          subscriptionStatus: install.subscriptionStatus,
          notifType: install.notifType,
        }),
      ])
    })
    profileDataState.importedTokens.forEach(importedToken => {
      result.push([
        `importedToken_${importedToken.pushToken}`,
        ProfilePlatformFactory({
          kind: 'imported',
          deviceName: 'Unknown device',
          platform: importedToken.platform,
          pushToken: importedToken.pushToken,
          subscriptionStatus: 'SUBSCRIBED',
        }),
      ])
    })
    return Immutable.Map(result)
  })
