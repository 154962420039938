// @flow

import * as React from 'react'
import styled, { type StyledComponent } from 'styled-components'

import { Icon } from 'components/common/svg-icon'
import { Tooltip, type placement as TooltipPlacement } from 'components/common/tooltip'
import { colors } from 'components/styled/tokens'

export function Hint({
  children,
  size,
  maxTooltipWidth,
  minTooltipWidth,
  placement = 'top',
  noMargin,
  icon = 'help',
  intent = 'default',
  style,
  arrow = true,
}: {
  children: React.Node,
  size?: number,
  maxTooltipWidth?: number,
  minTooltipWidth?: number,
  placement?: TooltipPlacement,
  icon?: 'help' | 'details',
  intent?: 'default' | 'disabled',
  noMargin?: boolean,
  style?: Object,
  arrow?: boolean,
}): React.Node {
  return noMargin ? (
    <Tooltip
      tooltip={children}
      placement={placement}
      maxWidth={maxTooltipWidth}
      minWidth={minTooltipWidth}
      arrow={arrow}
    >
      <HintIcon size={size} noMargin={noMargin} style={style} icon={icon} intent={intent} />
    </Tooltip>
  ) : (
    <span style={{ marginLeft: 4 }}>
      <Tooltip
        tooltip={children}
        placement={placement}
        maxWidth={maxTooltipWidth}
        minWidth={minTooltipWidth}
        arrow={arrow}
      >
        <HintIcon size={size} noMargin={noMargin} style={style} icon={icon} intent={intent} />
      </Tooltip>
    </span>
  )
}

type HintIconProps = { noMargin?: boolean, intent: 'default' | 'disabled' }

export const HintIcon: StyledComponent<HintIconProps, *, typeof Icon> = styled(Icon)`
  vertical-align: baseline;
  padding: 6px;
  margin: ${(p: HintIconProps) => (p.noMargin ? '0' : '-6px 0 -6px 0')};
  color: ${(p: HintIconProps) =>
    p.intent === 'disabled' ? colors.textDisabled : colors.textLight};
  cursor: pointer;
  &:hover {
    color: ${colors.textNeutral};
  }
`

export default Hint
