// @flow

import * as React from 'react'
import styled, { type StyledComponent } from 'styled-components'

import { schemes, Opacity } from 'components/styled/tokens'

//  In order to update flow hint for avaliableIcons enum, you can run & paste the result
//  ls public/medias/icons/ | sed 's/.svg//g'  | sed "s/^.*/ '&':'',/g" | pbcopy

type hardcodedIcons = {
  split: '',
  notfound: '',
  action: '',
  add: '',
  adjust: '',
  air: '',
  'all-of': '',
  analytics: '',
  android: '',
  'android-smartphone': '',
  'arrow-double-horizontal': '',
  'arrow-long-down': '',
  'arrow-long-left': '',
  'arrow-long-right': '',
  'arrow-long-up': '',
  ascending: '',
  audiences: '',
  audio: '',
  automation: '',
  fit: '',
  exit: '',
  billing: '',
  boolean: '',
  'box-valid': '',
  browser: '',
  calendar: '',
  'campaign-instant': '',
  'campaign-instant-icon': '',
  'campaign-recurring': '',
  'campaign-scheduled': '',
  'campaign-trigger': '',
  change: '',
  check: '',
  'check-dashed': '',
  'chevron-down': '',
  'chevron-left': '',
  'chevron-right': '',
  'chevron-up': '',
  children: '',
  chrome: '',
  'click-false': '',
  click: '',
  close: '',
  code: '',
  'collapse-expand': '',
  'collapse-node': '',
  'collapse-shrink': '',
  collection: '',
  computer: '',
  connect: '',
  cookie: '',
  copy: '',
  cordova: '',
  cross: '',
  crown: '',
  'custom-audience': '',
  danger: '',
  date: '',
  default: '',
  delete: '',
  descending: '',
  details: '',
  device: '',
  different: '',
  documentation: '',
  door: '',
  dots: '',
  download: '',
  draft: '',
  'draft-fill': '',
  edit: '',
  'ends-with': '',
  enter: '',
  equal: '',
  error: '',
  event: '',
  exist: '',
  expand: '',
  'expand-invert': '',
  'expand-node': '',
  external: '',
  favorize: '',
  'file-icon': '',
  file: '',
  filter: '',
  flutter: '',
  forbidden: '',
  fullscreen: '',
  graphic: '',
  'greater-equal': '',
  greater: '',
  help: '',
  hide: '',
  histogram: '',
  identifier: '',
  image: '',
  import: '',
  integer: '',
  'in-app': '',
  infos: '',
  interaction: '',
  ios: '',
  'ios-smartphone': '',
  label: '',
  link: '',
  location: '',
  lock: '',
  logout: '',
  'lower-equal': '',
  lower: '',
  mac: '',
  macros: '',
  mail: '',
  'mail-rounded': '',
  'mail-fill': '',
  'mail-fill-rounded': '',
  message: '',
  metric: '',
  mobile: '',
  mouse: '',
  money: '',
  news: '',
  none: '',
  'no-result': '',
  'not-one-of': '',
  notif: '',
  number: '',
  object: '',
  'one-of': '',
  operator: '',
  optin: '',
  options: '',
  os: '',
  parent: '',
  paused: '',
  'pc-windows': '',
  pending: '',
  place: '',
  planned: '',
  play: '',
  play_circled: '',
  privacy: '',
  'push-expanded': '',
  push: '',
  'push-compact': '',
  'quiet-hours': '',
  'react-native': '',
  'reload-animated': '',
  reload: '',
  remove: '',
  required: '',
  return: '',
  'reveal-false': '',
  reveal: '',
  'running-animated': '',
  running: '',
  'running-fill': '',
  save: '',
  schedule: '',
  search: '',
  segments: '',
  select: '',
  send: '',
  settings: '',
  'smart-segments': '',
  sms: '',
  show: '',
  smart: '',
  spinner: '',
  star: '',
  'starts-with': '',
  stopped: '',
  success: '',
  table: '',
  'target-false': '',
  target: '',
  targeting: '',
  team: '',
  text: '',
  timer: '',
  tiret: '',
  trigger: '',
  unavailable: '',
  undelivered: '',
  unify: '',
  undo: '',
  redo: '',
  unity: '',
  unlock: '',
  upload: '',
  user: '',
  version: '',
  video: '',
  view: '',
  'waiting-fill': '',
  waiting: '',
  wait: '',
  'warning-triangle': '',
  warning: '',
  webpush: '',
  'webpush-fill': '',
  windows: '',
  world: '',
  yesno: '',
  translation: '',
  'trending-up': '',
  'trending-down': '',
  ...
}

let data: hardcodedIcons = {
  split: '',
  notfound: '',
  action: '',
  add: '',
  adjust: '',
  air: '',
  'all-of': '',
  analytics: '',
  android: '',
  'android-smartphone': '',
  'arrow-double-horizontal': '',
  'arrow-long-down': '',
  'arrow-long-left': '',
  'arrow-long-right': '',
  'arrow-long-up': '',
  ascending: '',
  audiences: '',
  audio: '',
  automation: '',
  billing: '',
  boolean: '',
  'box-valid': '',
  browser: '',
  calendar: '',
  'campaign-instant': '',
  'campaign-instant-icon': '',
  'campaign-recurring': '',
  'campaign-scheduled': '',
  'campaign-trigger': '',
  change: '',
  check: '',
  'check-dashed': '',
  'chevron-down': '',
  'chevron-left': '',
  'chevron-right': '',
  'chevron-up': '',
  children: '',
  chrome: '',
  'click-false': '',
  fit: '',
  exit: '',
  click: '',
  close: '',
  code: '',
  'collapse-expand': '',
  'collapse-node': '',
  'collapse-shrink': '',
  collection: '',
  computer: '',
  connect: '',
  cookie: '',
  copy: '',
  cordova: '',
  cross: '',
  crown: '',
  'custom-audience': '',
  danger: '',
  date: '',
  default: '',
  delete: '',
  descending: '',
  details: '',
  device: '',
  different: '',
  documentation: '',
  door: '',
  dots: '',
  download: '',
  draft: '',
  'draft-fill': '',
  edit: '',
  'ends-with': '',
  enter: '',
  equal: '',
  error: '',
  event: '',
  exist: '',
  expand: '',
  'expand-invert': '',
  'expand-node': '',
  external: '',
  favorize: '',
  'file-icon': '',
  file: '',
  filter: '',
  flutter: '',
  forbidden: '',
  fullscreen: '',
  graphic: '',
  'greater-equal': '',
  greater: '',
  help: '',
  hide: '',
  histogram: '',
  identifier: '',
  image: '',
  import: '',
  integer: '',
  'in-app': '',
  infos: '',
  interaction: '',
  ios: '',
  'ios-smartphone': '',
  label: '',
  link: '',
  location: '',
  lock: '',
  logout: '',
  'lower-equal': '',
  lower: '',
  mac: '',
  macros: '',
  mail: '',
  'mail-rounded': '',
  'mail-fill': '',
  'mail-fill-rounded': '',
  message: '',
  metric: '',
  mobile: '',
  mouse: '',
  money: '',
  news: '',
  none: '',
  'no-result': '',
  'not-one-of': '',
  notif: '',
  number: '',
  object: '',
  'one-of': '',
  operator: '',
  optin: '',
  options: '',
  os: '',
  parent: '',
  paused: '',
  'pc-windows': '',
  pending: '',
  place: '',
  planned: '',
  play: '',
  play_circled: '',
  privacy: '',
  'push-expanded': '',
  push: '',
  'push-compact': '',
  'quiet-hours': '',
  'react-native': '',
  'reload-animated': '',
  reload: '',
  remove: '',
  required: '',
  return: '',
  'reveal-false': '',
  reveal: '',
  'running-animated': '',
  running: '',
  'running-fill': '',
  save: '',
  schedule: '',
  search: '',
  segments: '',
  select: '',
  send: '',
  settings: '',
  'smart-segments': '',
  sms: '',
  show: '',
  smart: '',
  spinner: '',
  star: '',
  'starts-with': '',
  stopped: '',
  success: '',
  table: '',
  'target-false': '',
  target: '',
  targeting: '',
  team: '',
  text: '',
  timer: '',
  tiret: '',
  trigger: '',
  unavailable: '',
  undelivered: '',
  unify: '',
  unity: '',
  unlock: '',
  undo: '',
  redo: '',
  upload: '',
  user: '',
  version: '',
  video: '',
  view: '',
  'waiting-fill': '',
  waiting: '',
  wait: '',
  'warning-triangle': '',
  warning: '',
  webpush: '',
  'webpush-fill': '',
  windows: '',
  world: '',
  yesno: '',
  translation: '',
  'trending-up': '',
  'trending-down': '',
}

export type availableIcons = $Keys<typeof data>
export const availableIconsArray: Array<availableIcons> = Object.keys(data)
availableIconsArray.forEach(k => {
  // $FlowFixMe
  const mod = require(`../../../public/medias/icons/${k}.svg`)
  data[k] = mod.default
})

export type IconProps = {
  icon: availableIcons,
  size?: number,
  color?: string,
  thickness?: number,
  wireframe?: boolean,
  className?: string,
  style?: any,
  ...
}

export const Icon: React.AbstractComponent<IconProps, HTMLElement> = React.forwardRef<IconProps, *>(
  (props: IconProps, ref) => {
    const { icon, size, color, thickness, wireframe, className, style } = props
    const IconRoot = data[icon]
    if (!IconRoot) {
      console.error(`Icon "${icon}" doesn't exist.`)
      return null
    }

    let computedColor = 'currentColor'
    if (defaultColors[icon]) computedColor = defaultColors[icon]
    if (color) computedColor = color

    return (
      <IconContainer
        ref={ref}
        role="image"
        {...{ color: computedColor, size, thickness, wireframe, className, style }}
      >
        <IconRoot />
      </IconContainer>
    )
  }
)
type IconContainerProps = {
  color: string,
  size?: number,
  thickness?: number,
  wireframe?: boolean,
  ...
}
export const IconContainer: StyledComponent<IconContainerProps, { ... }, HTMLElement> = styled.i`
  display: inline-block;
  font-size: ${(p: IconContainerProps) => (p.size ? `${p.size}px` : '1em')};
  line-height: ${(p: IconContainerProps) => (p.size ? `${p.size}px` : '1em')};
  color: currentColor;

  svg,
  svg:root,
  svg:not(:root) {
    width: 1em;
    height: 1em;
    overflow: visible;
    stroke: ${(p: IconContainerProps) => p.color};
    color: ${(p: IconContainerProps) => p.color};
    stroke-width: ${(p: IconContainerProps) => p.thickness || 1.5}px;
    fill: ${(p: IconContainerProps) => p.color};
    strokeLinecap: round;
    strokeLinejoin: round;
    border: ${(p: IconContainerProps) =>
      p.wireframe ? `1px solid ${Opacity(schemes.red['70'], 0.58)}` : 'none'};
  }

  path,
  circle,
  rect {
    transform=translate(110,110); 
    vector-effect: non-scaling-stroke;
  }
`

const defaultColors: { [key: availableIcons]: string, ... } = {
  android: '#8cc052',
  windows: '#0078d7',
  ios: '#cccfda',
}

Icon.displayName = 'Icon'

export const loadIconsForTest = async () => {
  for (const k of availableIconsArray) {
    try {
      // $FlowFixMe
      const mod = await import(`../../../public/medias/icons/${k}.svg`)
      data[k] = mod.default
    } catch (error) {
      console.error(`Failed to load icon: ${k}`, error)
    }
  }
}
