// @flow

import { parseStatsByChannel } from '../infra/parses/parse-stats-by-channel'
import { type OrchestrationStatsRecord } from '../models/orchestration-stats.record'
import { orchestrationAnalyticsMapSelector } from '../store/orchestration-analytics.selector'
import { type DispatchExtraBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

export type FetchOrchestrationStatsByChannelAction = ReduxAction<
  'FETCH_ORCHESTRATION_STATS_BY_CHANNEL',
  string,
>

export type FetchOrchestrationStatsByChannelSuccessAction = ReduxAction<
  'FETCH_ORCHESTRATION_STATS_BY_CHANNEL_SUCCESS',
  { data: OrchestrationStatsRecord, token: string },
>

export type FetchOrchestrationStatsByChannelFailureAction = ReduxAction<
  'FETCH_ORCHESTRATION_STATS_BY_CHANNEL_FAILURE',
  { token: string, error: Error },
>

export type FetchOrchestrationStatsByChannelActions =
  | FetchOrchestrationStatsByChannelAction
  | FetchOrchestrationStatsByChannelSuccessAction
  | FetchOrchestrationStatsByChannelFailureAction

export const fetchStatsByChannel =
  ({ token }: { token: string }): DispatchExtraBoundFn<Promise<void>> =>
  async (dispatch, getState, { dataService }) => {
    const state = getState()

    const analyticsMap = orchestrationAnalyticsMapSelector(state)
    const loadingState = analyticsMap.get(token)?.channelsLoadingState ?? 'INIT'
    const expire = analyticsMap.get(token)?.expire ?? -Infinity

    if (
      loadingState !== 'LOADING' &&
      (!['ERROR', 'LOADED'].includes(loadingState) || expire < Date.now())
    ) {
      const project = currentProjectSelector(state)
      const fetchIntentAction: FetchOrchestrationStatsByChannelAction = {
        type: 'FETCH_ORCHESTRATION_STATS_BY_CHANNEL',
        payload: token,
      }
      dispatch(fetchIntentAction)
      try {
        const data = await dataService.fetchDynamicStats({
          project,
          token,
          groupDimensionNames: ['channel'],
          parser: parseStatsByChannel,
        })
        const fetchSuccessAction: FetchOrchestrationStatsByChannelSuccessAction = {
          type: 'FETCH_ORCHESTRATION_STATS_BY_CHANNEL_SUCCESS',
          payload: { data, token },
        }
        dispatch(fetchSuccessAction)
      } catch (error) {
        const fetchFailureAction: FetchOrchestrationStatsByChannelFailureAction = {
          type: 'FETCH_ORCHESTRATION_STATS_BY_CHANNEL_FAILURE',
          payload: { token, error },
        }
        dispatch(fetchFailureAction)
      }
    }
  }
