// @flow
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useIsCurrentUserAllowedTo } from 'components/_hooks/use-allowed'
import { SwitchButton } from 'components/common/button'
import { confirm } from 'components/common/confirm'
import { Icon } from 'components/common/svg-icon'

import {
  multilanguageEnabledSelector,
  pickedLanguagesSelector,
  previewLanguageSelector,
} from 'com.batch/message/store/message.selector'
import { orchestrationMetaSelector } from 'com.batch/orchestration/store/orchestration.selectors'

import { saveDragDropHtml } from 'com.batch/email/usecases/update-content'
import { toggleMultilanguage, clearLanguages } from 'com.batch/message/usecases/multilanguage'

type Props = {
  messageId: string,
  channel: ChannelUntilCleanup,
  style?: Object,
  templateId?: string,
}
export const MultilanguageToggle = ({
  messageId,
  channel,
  style,
  templateId,
}: Props): React.Node => {
  const dispatch = useDispatch()
  const multilanguageEnabled = useSelector(multilanguageEnabledSelector({ messageId, channel }))
  const pickedLanguages = useSelector(
    pickedLanguagesSelector({
      messageId,
      channel,
    })
  )
  const { state } = useSelector(orchestrationMetaSelector)
  const activeLanguage = useSelector(previewLanguageSelector)
  const userHasWritePermission = useIsCurrentUserAllowedTo(['app', 'push:write'])

  const onEnableEmailMultilanguage = React.useCallback(() => {
    confirm({
      title: 'Enable multi-language?',
      message: (
        <p>
          Each template is independent and will be duplicated from the default one when a new
          language is added. Changes made to one template will not affect the others.
        </p>
      ),
      confirm: 'Yes, enable it',
    }).then(
      () => {
        dispatch(toggleMultilanguage({ messageId, channel }))
      },
      () => {}
    )
  }, [messageId, channel, dispatch])

  const onDisableMultilanguage = React.useCallback(() => {
    confirm({
      title: 'Disable multi-language?',
      message: <p>All languages will be deleted except the default one.</p>,
      sensitive: true,
      confirm: 'Yes, disable it',
    }).then(
      () => {
        dispatch(toggleMultilanguage({ messageId, channel }))
        dispatch(clearLanguages({ messageId, channel }))
      },
      () => {}
    )
  }, [messageId, channel, dispatch])

  const handleMultilanguageOnChange = React.useCallback(
    async (enabled: boolean) => {
      if (templateId) {
        try {
          await dispatch(saveDragDropHtml({ lang: activeLanguage, templateId, messageId }))
        } catch (e) {
          console.error(e)
        }
      }

      if (enabled && channel === 'email') {
        onEnableEmailMultilanguage()
      } else if (!enabled && pickedLanguages.size > 1) {
        onDisableMultilanguage()
      } else {
        dispatch(toggleMultilanguage({ messageId, channel }))
      }
    },
    [
      dispatch,
      messageId,
      channel,
      pickedLanguages,
      onEnableEmailMultilanguage,
      onDisableMultilanguage,
      multilanguageEnabled,
    ]
  )

  return (
    <SwitchButton
      addOn="prefix"
      style={{ display: 'flex', justifyContent: 'center', ...style }}
      onChange={handleMultilanguageOnChange}
      isActive={multilanguageEnabled}
      disabled={state === 'COMPLETED' || !userHasWritePermission}
    >
      Multi-language
      <Icon icon="translation" style={{ marginLeft: 8 }} />
    </SwitchButton>
  )
}
