// @flow

import * as React from 'react'
import { useSelector } from 'react-redux'
import { useDropzone } from 'react-dropzone'

import { Button, ButtonLink } from 'components/common/button'
import { Grid } from 'components/common/grid'
import { Dropzone, Section, SectionTitle } from 'components/console/console.style'
import { Checkbox, Copy, Form, Input, InputWrapper } from 'components/form'
import * as colors from 'components/styled/colors'
import { schemes } from 'components/styled/tokens'

import { generateUrl } from 'com.batch.common/router'

import { type CompanyRecord, type SAMLRecord, SAMLRecordFactory } from 'com.batch.redux/_records'

type Props = {
  company: CompanyRecord,
  updateSaml: ({ company: CompanyRecord, saml: SAMLRecord, ... }) => Promise<CompanyRecord>,
  ...
}

export const CompanySSO = ({ company, updateSaml }: Props): React.Node => {
  const loading = useSelector(state => state.company.loading)
  const [form, setForm] = React.useState<{
    isEnabled: boolean,
    entityId: ?string,
    loginUrl: ?string,
    certificate: ?string,
    ...
  }>({
    isEnabled: company.saml?.isEnabled ?? false,
    entityId: company.saml?.entityId,
    loginUrl: company.saml?.loginUrl,
    certificate: company.saml?.certificate,
  })

  const onDropCertificate = React.useCallback(acceptedFiles => {
    acceptedFiles.forEach(file => {
      const reader = new FileReader()
      reader.onload = () => {
        setForm({ ...form, certificate: reader.result?.toString() })
      }
      reader.readAsBinaryString(file)
    })
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropCertificate,
    disabled: !form.isEnabled,
  })

  const handleChange = React.useCallback(
    ({ target }) => {
      setForm({ ...form, [target.name]: target.value })
    },
    [form]
  )

  const handleIsEnableChecked = React.useCallback(() => {
    setForm({ ...form, isEnabled: !form.isEnabled })
  }, [form])

  const handleOnSubmit = React.useCallback(() => {
    updateSaml({
      company,
      saml: SAMLRecordFactory({ ...form }),
    }).then(
      () => {},
      resp => {
        alert(
          'Error while saving / syncing with backend : ”' +
            resp.error +
            '”. Please try saving again / check console log / contact @front'
        )
      }
    )
  }, [form, updateSaml, company])

  const isLoginUrlValid = React.useCallback(() => {
    try {
      return Boolean(new URL(form.loginUrl ?? ''))
    } catch (e) {
      return false
    }
  }, [form.loginUrl])

  const isFormValid = React.useCallback(() => {
    if (form.isEnabled) {
      return (
        (form.certificate ?? '').length > 0 && (form.entityId ?? '').length > 0 && isLoginUrlValid()
      )
    }
    return company.saml?.isEnabled
  }, [form.isEnabled, form.certificate, isLoginUrlValid, form.entityId, company.saml?.isEnabled])

  return (
    <Grid template="1fr 1fr" alignItems="stretch" margin={[12, 0, 0, 0]}>
      <Section style={{ borderTop: '1px solid #e9e9e9' }}>
        <SectionTitle>Single Sign On (SSO)</SectionTitle>

        <Form onSubmit={handleOnSubmit}>
          <InputWrapper label="Enable">
            <Checkbox
              label="Enable/Disable the SSO through SAML for this company"
              checked={form.isEnabled}
              onChange={handleIsEnableChecked}
            />
          </InputWrapper>

          <InputWrapper label="Login URL">
            <Input
              name="loginUrl"
              disabled={!form.isEnabled}
              value={form.loginUrl ?? ''}
              onChange={handleChange}
            />
          </InputWrapper>

          <InputWrapper label="Entity ID (Issuer)">
            <Input
              name="entityId"
              disabled={!form.isEnabled}
              value={form.entityId ?? ''}
              onChange={handleChange}
            />
          </InputWrapper>

          <InputWrapper label="Certificate">
            <Dropzone {...getRootProps()} style={{ marginBottom: '20px' }}>
              <input {...getInputProps()} />
              <p>Drop a certificate here or copy-paste the certificate in the field below</p>
            </Dropzone>
            <Input
              name="certificate"
              disabled={!form.isEnabled}
              value={form.certificate ?? ''}
              rows={10}
              required
              onChange={handleChange}
            />
          </InputWrapper>

          <div style={{ marginTop: 20 }}>
            <Button
              isLoading={loading}
              disabled={!isFormValid()}
              type="submit"
              intent="action"
              kind="primary"
            >
              Save changes
            </Button>
          </div>
        </Form>
      </Section>
      <div>
        <Section style={{ backgroundColor: schemes.grayscale['10'], padding: 40 }}>
          <SectionTitle>
            Status:{' '}
            {company.saml?.isEnabled ? (
              <span style={{ color: 'green' }}>Enabled</span>
            ) : (
              <span style={{ color: 'red' }}>Disabled</span>
            )}
          </SectionTitle>

          <p style={{ marginBottom: '20px' }}>
            {company.saml?.isEnabled &&
              !!company.saml?.certificateExpiration &&
              `Certificate expiration date: ${company.saml?.certificateExpiration.format(
                'DD/MM/YYYY'
              )}`}
          </p>

          <div style={{ fontWeight: 'bold' }}>INFORMATION:</div>
          <p>
            Disabling SSO will <strong>reset all passwords</strong>. If you do not want to do that,
            please ask the tech team.
          </p>
        </Section>
        <Section style={{ backgroundColor: schemes.grayscale['10'], padding: 40, marginTop: 40 }}>
          <SectionTitle>Configuration for the client</SectionTitle>

          <p style={{ marginBottom: '20px' }}>
            This information will be required by the client to configure the SSO through his IDP.
          </p>

          <Copy
            label="Assertion Consumer Service URL"
            value={`${window.location.protocol}//${window.location.host}${generateUrl(
              'sso_saml_acs'
            )}`}
          />

          <Copy
            label="Entity ID (Issuer)"
            value={`${window.location.protocol}//${window.location.host}${generateUrl(
              'sso_saml_metadata'
            )}`}
          />

          <div style={{ marginTop: 20 }}>
            <ButtonLink
              kind="primary"
              intent="action"
              href={generateUrl('sso_saml_metadata')}
              download
            >
              Download Metadata
            </ButtonLink>
          </div>
        </Section>
      </div>
    </Grid>
  )
}
