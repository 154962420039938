// @flow
import Immutable, { type Map } from 'immutable'
import { createSelector } from 'reselect'

import { getPushContentError, getPushSettingsErrors } from './push.selector.helper'

import {
  getLangFromContent,
  type MessageIdGetterSelector,
  getMessageKey,
  previewLanguageSelector,
} from 'com.batch/message/store/message.selector'
import { type State } from 'com.batch.redux/_records'

import {
  PushMessageFactory,
  PushSettingsFactory,
  PushContentFactory,
  type PushMessageRecord,
  type PushContentRecord,
  type PushSettingsRecord,
} from 'com.batch/message/models/message.records'

export const getPushMessageSelector =
  (state: State): (string => PushMessageRecord) =>
  messageId =>
    state.message.push.getIn([messageId], PushMessageFactory())

export const getPushSettingsSelector: MessageIdGetterSelector<PushSettingsRecord> = createSelector(
  getPushMessageSelector,
  getPushMessage => messageId => getPushMessage(messageId).get('settings', PushSettingsFactory())
)

export const getPushContentSelector: MessageIdGetterSelector<Map<string, PushContentRecord>> =
  createSelector(
    getPushMessageSelector,
    getPushMessage => messageId =>
      getPushMessage(messageId).get('localizedContent', Immutable.Map())
  )

export const getPushContentForActiveLanguageSelector: MessageIdGetterSelector<PushContentRecord> =
  createSelector(
    getPushContentSelector,
    previewLanguageSelector,
    (getData, langId) => messageId => {
      const data = getData(messageId)
      return data.get(langId ?? 'default', PushContentFactory())
    }
  )

type getErrorsForMessage = (messageId: string) => Map<string, Array<string>>
export const getPushErrorsSelector: State => getErrorsForMessage = createSelector(
  getPushMessageSelector,
  getMessage => messageId => {
    const message = getMessage(messageId)
    const settings = message.get('settings', PushSettingsFactory())
    const localizedContent = message.get('localizedContent', Immutable.Map())
    const multilanguageEnabled = message.get('multilanguageEnabled')
    const languages = getLangFromContent(localizedContent, multilanguageEnabled)
    const settingsErrors = getPushSettingsErrors(settings)
    const contentErrors: Array<[string, Array<string>]> = languages
      .map(lang => {
        const contentForLang = localizedContent.get(lang.value, PushContentFactory())
        let localErrors = getPushContentError(contentForLang)
        return [getMessageKey(lang), localErrors]
      })
      .filter(err => err[1].length > 0)
      .toArray()
    return Immutable.Map([
      ...(settingsErrors.length > 0 ? [['Settings', settingsErrors]] : []),
      ...contentErrors,
    ])
  }
)
