/* eslint-disable react/jsx-no-bind */
// @flow

import Immutable, { type Map, type List } from 'immutable'
import * as React from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'

import {
  Box,
  BoxHeader,
  BoxBody,
  BoxFooter,
  BoxSectionContainer,
  HeaderBoxTitle,
  HeaderBoxActions,
} from 'components/common/box'
import { Button } from 'components/common/button'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'
import { TableToggle, TableToggleItem } from 'components/common/tabletoggle'
import { SelectMulti, Form, InputWrapper, Input } from 'components/form'

import { dayjs, formatDate, parseDate } from 'com.batch.common/dayjs.custom'

import {
  DataExportJobFactory,
  type ExportJobRecord,
  type DataExportJobRecord,
} from './../redux/console.records'

import { type AppRecord } from 'com.batch.redux/_records'

type ExportFormProps = {
  opened: boolean,
  close: () => void,
  app: AppRecord,
  fetchExportJobs: ({ app: AppRecord, ... }) => Promise<List<ExportJobRecord>>,
  createExportJob: ({ app: AppRecord, exportJob: DataExportJobRecord, ... }) => Promise<{
    request_id: string,
    ...
  }>,
  ...
}
type IdsTypes = 'install_id' | 'custom_id' | 'advertising_id'
type EventsTypes =
  | 'push_sent'
  | 'push_open'
  | 'push_error'
  | 'inapp_shown'
  | 'inapp_clicked'
  | 'inapp_closed'

const exportKind = [
  { value: 'campaign', name: 'Push campaign export' },
  { value: 'in-app-campaign', name: 'In-app campaign export' },
  { value: 'transactional', name: 'Transactional export' },
]
const idsArray: Array<IdsTypes> = ['install_id', 'custom_id', 'advertising_id']
const eventsArray: Array<EventsTypes> = [
  'push_sent',
  'push_open',
  'push_error',
  'inapp_shown',
  'inapp_clicked',
  'inapp_closed',
]

// ====================== COMPONENT
const ExportForm = ({
  opened,
  close,
  app,
  fetchExportJobs,
  createExportJob,
}: ExportFormProps): React.Node => {
  const [exportJob, setExportJob] = React.useState<DataExportJobRecord>(
    DataExportJobFactory({ entity: app.features.has('export-events') ? 'campaign' : 'userbase' })
  )
  const [errors, setErrors] = React.useState<Map<string, string>>(Immutable.Map({}))
  const [loading, setLoading] = React.useState<boolean>(false)

  React.useEffect(() => {
    let currentEventsList = []

    if (exportJob.entity === 'userbase' || exportJob.entity === 'reachability') {
      currentEventsList = []
    } else if (exportJob.entity === 'in-app-campaign') {
      eventsArray.forEach(e => e.includes('inapp') && currentEventsList.push(e))
    } else if (exportJob.entity === 'campaign' || exportJob.entity === 'transactional') {
      eventsArray.forEach(e => e.includes('push') && currentEventsList.push(e))
    } else {
      currentEventsList === exportJob.get('events')
    }

    setExportJob(
      exportJob
        .set('events', currentEventsList)
        .set('kind', exportJob.entity === 'reachability' ? 'events' : exportJob.kind)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportJob.entity])

  const handleSubmit = () => {
    setLoading(false)
    let err = []

    if (exportJob.ids.length === 0) {
      err.push(['ids', "This fields shouldn't be empty."])
    }

    if (exportJob.entity !== 'userbase' && exportJob.entity !== 'reachability') {
      if (exportJob.identifier === '') {
        err.push(['identifier', "This fields shouldn't be empty."])
      }
    }
    if (exportJob.entity !== 'userbase') {
      if (exportJob.from === null) {
        err.push(['from', "This fields shouldn't be empty."])
      } else if (exportJob.to === null) {
        err.push(['to', "This fields shouldn't be empty."])
      } else if (exportJob.from.isAfter(exportJob.to !== 'now' ? exportJob.to : dayjs())) {
        err.push(['from', 'Invalid date.'])
      }
    }

    if (err.length === 0) {
      createExportJob({ app, exportJob }).then(() => {
        setLoading(false)
        close()
        fetchExportJobs({ app })
      })
    } else {
      setErrors(Immutable.Map(err))
      setLoading(false)
    }
  }

  return (
    <Popin opened={opened} close={close} style={{ width: 680 }}>
      <Box>
        <BoxHeader>
          <HeaderBoxTitle title="Request your export job" />
          <HeaderBoxActions>
            <Button kind="inline" onClick={close}>
              <Icon icon="close" />
            </Button>
          </HeaderBoxActions>
        </BoxHeader>

        <Form horizontal onSubmit={handleSubmit}>
          <BoxBody>
            <BoxSectionContainer $padding>
              <TableToggle>
                <TableToggleItem
                  active={exportJob.entity === 'userbase'}
                  disabled={!app.features.has('export-userbase')}
                  onClick={evt => {
                    evt.preventDefault()
                    setExportJob(exportJob.set('entity', 'userbase'))
                  }}
                >
                  Userbase export
                </TableToggleItem>
                <TableToggleItem
                  active={exportJob.entity === 'reachability'}
                  disabled={!app.features.has('export-events')}
                  onClick={evt => {
                    evt.preventDefault()
                    setExportJob(exportJob.set('entity', 'reachability'))
                  }}
                >
                  Reachability export
                </TableToggleItem>
                <TableToggleItem
                  active={exportJob.entity !== 'userbase' && exportJob.entity !== 'reachability'}
                  disabled={!app.features.has('export-events')}
                  onClick={evt => {
                    evt.preventDefault()
                    setExportJob(exportJob.set('entity', 'campaign'))
                  }}
                >
                  Campaign export
                </TableToggleItem>
              </TableToggle>
            </BoxSectionContainer>

            {exportJob.entity !== 'userbase' && exportJob.entity !== 'reachability' && (
              <BoxSectionContainer $padding style={{ paddingTop: 40, paddingBottom: 40 }}>
                <TableToggle style={{ marginBottom: 25 }}>
                  {exportKind.map((elem, index) => (
                    <TableToggleItem
                      key={index}
                      active={exportJob.entity === elem.value}
                      onClick={evt => {
                        evt.preventDefault()
                        setExportJob(
                          exportJob
                            .set('entity', elem.value)
                            .set('identifier', '')
                            .set('events', [])
                        )
                      }}
                    >
                      {elem.name}
                    </TableToggleItem>
                  ))}
                </TableToggle>

                <InputWrapper
                  htmlFor="identifier"
                  label={exportJob.entity !== 'transactional' ? 'Campaign token' : 'Group ID'}
                  feedback={errors.has('identifier') && errors.get('identifier')}
                >
                  <Input
                    id="identifier"
                    name="identifier"
                    placeholder={
                      exportJob.entity !== 'transactional' ? 'campaign_token' : 'group_id'
                    }
                    value={exportJob.get('identifier', '')}
                    onChange={evt => setExportJob(exportJob.set('identifier', evt.target.value))}
                    invalid={errors.has('identifier')}
                  />
                </InputWrapper>
              </BoxSectionContainer>
            )}

            {exportJob.entity !== 'userbase' && (
              <BoxSectionContainer $padding style={{ paddingTop: 40, paddingBottom: 40 }}>
                <InputWrapper
                  htmlFor="from"
                  label="From"
                  feedback={errors.has('from') && errors.get('from')}
                >
                  <DayPickerInput
                    id="from"
                    locale="en"
                    inputProps={{
                      id: 'from',
                      className: 'form-control',
                      placeholder: `E.g. ${dayjs().locale('en').format('DD/MM/YYYY')}`,
                    }}
                    format="DD/MM/YYYY"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    value={exportJob.from ? exportJob.from.format('DD/MM/YYYY') : ''}
                    onDayChange={day => {
                      setExportJob(exportJob.set('from', day ? dayjs.utc(day) : null))
                    }}
                    invalid={errors.has('from')}
                  />
                </InputWrapper>
                <InputWrapper htmlFor="to" label="To">
                  <div>
                    <TableToggle>
                      <TableToggleItem
                        type="button"
                        active={exportJob.to === 'now'}
                        onClick={evt => {
                          evt.preventDefault()
                          setExportJob(exportJob.set('to', 'now'))
                        }}
                      >
                        Now
                      </TableToggleItem>
                      <TableToggleItem
                        active={exportJob.to !== 'now'}
                        onClick={evt => {
                          evt.preventDefault()
                          setExportJob(exportJob.set('to', dayjs()))
                        }}
                      >
                        Custom
                      </TableToggleItem>
                    </TableToggle>
                    {exportJob.to !== 'now' && (
                      <DayPickerInput
                        id="to"
                        style={{ marginTop: 5 }}
                        locale="en"
                        inputProps={{
                          id: 'to',
                          className: 'form-control',
                          placeholder: `E.g. ${dayjs().locale('en').format('DD/MM/YYYY')}`,
                        }}
                        format={'DD/MM/YYYY'}
                        formatDate={formatDate}
                        parseDate={parseDate}
                        value={
                          !!exportJob.to && exportJob.to !== 'now'
                            ? exportJob.to.format('DD/MM/YYYY')
                            : ''
                        }
                        onDayChange={day =>
                          setExportJob(exportJob.set('to', day ? dayjs.utc(day) : null))
                        }
                      />
                    )}
                  </div>
                </InputWrapper>
              </BoxSectionContainer>
            )}
            <BoxSectionContainer $padding style={{ paddingTop: 40, paddingBottom: 40 }}>
              <InputWrapper
                htmlFor="ids"
                label="IDs to export"
                feedback={errors.has('ids') && errors.get('ids')}
              >
                <SelectMulti
                  optionToString={opt => opt ?? ''}
                  isClearable={false}
                  id="ids"
                  placeholder="Select ids ..."
                  options={new Immutable.List().push(...idsArray)}
                  onChange={newEventsList => {
                    setExportJob(exportJob.set('ids', newEventsList.toArray()))
                  }}
                  value={new Immutable.List().push(...exportJob.ids)}
                  invalid={errors.has('ids')}
                />
              </InputWrapper>

              {exportJob.entity !== 'userbase' && exportJob.entity !== 'reachability' && (
                <React.Fragment>
                  <InputWrapper label="Deduplicate">
                    <TableToggle>
                      <TableToggleItem
                        type="button"
                        active={exportJob.kind === 'ids'}
                        onClick={evt => {
                          evt.preventDefault()
                          setExportJob(exportJob.set('kind', 'ids'))
                        }}
                      >
                        Yes (ids only)
                      </TableToggleItem>
                      <TableToggleItem
                        active={exportJob.kind === 'events'}
                        onClick={evt => {
                          evt.preventDefault()
                          setExportJob(exportJob.set('kind', 'events'))
                        }}
                      >
                        No (ids + events)
                      </TableToggleItem>
                    </TableToggle>
                  </InputWrapper>
                  {/* this is ugly, building immutable object on the fly breaks the select, hence the key hack */}
                  <InputWrapper htmlFor="events" label="Events" key={exportJob.entity}>
                    <SelectMulti
                      isClearable={false}
                      id="events"
                      optionToString={opt => opt ?? ''}
                      placeholder="Select events ..."
                      options={new Immutable.List().push(...eventsArray)}
                      onChange={newEventsList => {
                        setExportJob(exportJob.set('events', newEventsList.toArray()))
                      }}
                      value={new Immutable.List().push(...exportJob.events)}
                    />
                  </InputWrapper>
                </React.Fragment>
              )}
            </BoxSectionContainer>
          </BoxBody>

          <BoxFooter>
            <Button type="submit" kind="primary" intent="action" isLoading={loading}>
              Send the request
            </Button>
          </BoxFooter>
        </Form>
      </Box>
    </Popin>
  )
}

export { ExportForm }
