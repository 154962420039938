// @flow

import Immutable, {
  type RecordFactory,
  type RecordOf,
  type List,
  type Map,
  type OrderedMap,
} from 'immutable'

import {
  type fetchingState,
  type RegionRecord,
  type LanguageRecord,
  RegionFactory,
  LanguageFactory,
} from 'com.batch.redux/_records'

import { type Platform, type QueryValue } from 'com.batch/profile/infra/debug/models/shared.model'
import { STATUS } from 'constants/common'

export type PlatformType = 'ANDROID' | 'IOS' | 'WEBPUSH'
export type SourceType = 'SDK' | 'API' | 'PROFILE_API'

type CustomEventProps = {
  name: string,
  label: string,
  platform: PlatformType,
  attributes: Map<string, QueryValue>,
  tags: Map<string, Array<string>>,
  sendDate: string,
  source: SourceType,
}
export const CustomEventFactory: RecordFactory<CustomEventProps> = Immutable.Record(
  ({
    name: '',
    label: '',
    platform: 'ANDROID',
    attributes: Immutable.Map(),
    tags: Immutable.Map(),
    sendDate: '',
    source: 'PROFILE_API',
  }: CustomEventProps)
)

export type CustomEventRecord = RecordOf<CustomEventProps>

export type SubscriptionStatus = 'SUBSCRIBED' | 'UNSUBSCRIBED' | 'UNKNOWN'

export type SubscriptionEmailProps = {
  status: SubscriptionStatus,
  lastEmailOpened: ?string,
  lastEmailClicked: ?string,
}
export const SubscriptionEmailFactory: RecordFactory<SubscriptionEmailProps> = Immutable.Record(
  ({
    status: 'UNKNOWN',
    lastEmailOpened: null,
    lastEmailClicked: null,
  }: SubscriptionEmailProps)
)

export type SubscriptionEmailRecord = RecordOf<SubscriptionEmailProps>

export type SubscriptionSmsProps = {
  status: SubscriptionStatus,
}

export const SubscriptionSmsFactory: RecordFactory<SubscriptionSmsProps> = Immutable.Record(
  ({
    status: 'UNKNOWN',
  }: SubscriptionSmsProps)
)

export type SubscriptionSmsRecord = RecordOf<SubscriptionSmsProps>

export type SubscriptionPushProps = {
  status: SubscriptionStatus,
}

export const SubscriptionPushFactory: RecordFactory<SubscriptionPushProps> = Immutable.Record({
  status: 'UNSUBSCRIBED',
})
export type SubscriptionPushRecord = RecordOf<SubscriptionPushProps>

export type SubscriptionProps = {
  emailMarketing: SubscriptionEmailRecord,
  emailService: SubscriptionEmailRecord,
  smsMarketing: SubscriptionSmsRecord,
  smsService: SubscriptionSmsRecord,
  push: SubscriptionPushRecord,
}
export const SubscriptionFactory: RecordFactory<SubscriptionProps> = Immutable.Record(
  ({
    emailMarketing: SubscriptionEmailFactory(),
    emailService: SubscriptionEmailFactory(),
    smsMarketing: SubscriptionSmsFactory(),
    smsService: SubscriptionSmsFactory(),
    push: SubscriptionPushFactory(),
  }: SubscriptionProps)
)
export type SubscriptionRecord = RecordOf<SubscriptionProps>

type IdentityProps = {
  customUserId: string,
  region: RegionRecord,
  language: LanguageRecord,
}
export const IdentityFactory: RecordFactory<IdentityProps> = Immutable.Record(
  ({
    customUserId: '',
    region: RegionFactory(),
    language: LanguageFactory(),
  }: IdentityProps)
)

export type IdentityRecord = RecordOf<IdentityProps>

export type InstallProps = {
  type: string,
  marketingName: ?string,
  installID: string,
  platform: Platform,
  pushToken: string,
  subscriptionStatus: SubscriptionStatus,
  notifType: string[],
  lastActivity: string,
}
export const InstallFactory: RecordFactory<InstallProps> = Immutable.Record({
  type: '',
  marketingName: '',
  installID: '',
  platform: '',
  pushToken: '',
  subscriptionStatus: 'UNKNOWN',
  notifType: [],
  lastActivity: '',
})
export type InstallRecord = RecordOf<InstallProps>

export type LastVisitProps = {
  platform: Platform,
  date: string,
}
export const LastVisitFactory: RecordFactory<LastVisitProps> = Immutable.Record({
  platform: '',
  date: '',
})
export type LastVisitRecord = RecordOf<LastVisitProps>

export type ImportedTokenProps = {
  platform: Platform,
  pushToken: string,
}
export const ImportedTokenFactory: RecordFactory<ImportedTokenProps> = Immutable.Record(
  ({
    platform: 'ANDROID',
    pushToken: '',
  }: ImportedTokenProps)
)
export type ImportedTokenRecord = RecordOf<ImportedTokenProps>

type ProfileDataProps = {
  identity: IdentityRecord,
  email: string,
  phoneNumber: string,
  lastActivity: string,
  lastVisit: LastVisitRecord,
  lastActivity: string,
  subscription: SubscriptionRecord,
  attributes: Map<string, QueryValue>,
  installs: Map<string, InstallRecord>,
  importedTokens: Map<string, ImportedTokenRecord>,
  id: string,
  customEvents: OrderedMap<string, List<CustomEventRecord>>,
  eventsLoadingState: fetchingState,
  timezone: ?string,
}
export const ProfileDataFactory: RecordFactory<ProfileDataProps> = Immutable.Record(
  ({
    identity: IdentityFactory(),
    email: '',
    phoneNumber: '',
    lastVisit: LastVisitFactory(),
    lastActivity: '',
    subscription: SubscriptionFactory(),
    attributes: Immutable.Map(),
    installs: Immutable.Map(),
    importedTokens: Immutable.Map(),
    id: '',
    customEvents: Immutable.OrderedMap(),
    eventsLoadingState: STATUS.INIT,
    timezone: null,
  }: ProfileDataProps)
)
export type ProfileDataRecord = RecordOf<ProfileDataProps>

// ====================== REDUCER ====================== //
type ProfileStateProps = {
  loadingState: fetchingState,
  data: OrderedMap<string, ProfileDataRecord>,
  profileId: string,
}

export const ProfileStateFactory: RecordFactory<ProfileStateProps> = Immutable.Record(
  ({
    loadingState: STATUS.INIT,
    data: Immutable.OrderedMap().set('', ProfileDataFactory()),
    profileId: '',
  }: ProfileStateProps)
)
export type ProfileStateRecord = RecordOf<ProfileStateProps>

// ====================== OTHER RECORD ====================== //
// import { type Platform } from 'com.batch/profile/infra/debug/models/shared.model'
// import { type SubscriptionStatus } from 'com.batch/profile/models/profile.records'

export type PlatformKindType = 'native' | 'imported'

export type ProfilePlatformProps = {
  kind: PlatformKindType,
  id: string,
  deviceName: string,
  deviceType: string,
  platform: Platform,
  installId: string,
  pushToken: string,
  lastActivity: string,
  subscriptionStatus: SubscriptionStatus,
  notifType: Array<string>,
  debugUrl: string,
}
export const ProfilePlatformFactory: RecordFactory<ProfilePlatformProps> = Immutable.Record(
  ({
    kind: 'native',
    id: '',
    deviceName: '',
    deviceType: '',
    platform: 'ANDROID',
    installId: '',
    pushToken: '',
    lastActivity: '',
    subscriptionStatus: 'UNKNOWN',
    notifType: [],
    debugUrl: '',
  }: ProfilePlatformProps)
)
export type ProfilePlatformRecord = RecordOf<ProfilePlatformProps>
