// @flow

import * as React from 'react'
import { type Node as ReactNode } from 'react'

import Hint from 'components/common/hint'

import { Feedback } from './feedback'
import { InputWrapperContainer } from './input-wrapper.styles'

type InputWrapperProps = {
  label?: ReactNode,
  hint?: ReactNode,
  labelStyle?: { ... },
  hintPlacement?: 'top' | 'bottom' | 'left' | 'right',
  hintMaxSize?: number,
  hintMinSize?: number,
  style?: { ... },
  feedback?: ReactNode,
  feedbackType?: 'error' | 'warning' | 'insight',
  feedbackWeight?: number,
  additional?: ReactNode,
  htmlFor?: string,
  children?: ReactNode,
  optional?: boolean,
  testId?: string,
  ...
}

const InputWrapper = ({
  label,
  style,
  hint,
  labelStyle,
  hintPlacement,
  hintMaxSize,
  hintMinSize,
  feedback,
  feedbackType,
  feedbackWeight,
  additional,
  children,
  htmlFor,
  optional,
  testId,
}: InputWrapperProps): React.Node => {
  return (
    <InputWrapperContainer style={style} data-testid={testId}>
      {(label || hint) && (
        <span>
          {label && (
            <label style={labelStyle} htmlFor={htmlFor}>
              {label}{' '}
              {optional && <span style={{ fontWeight: 400, color: '#818792' }}>optional</span>}
            </label>
          )}
          {hint && (
            <Hint
              minTooltipWidth={hintMinSize ?? 120}
              maxTooltipWidth={hintMaxSize ?? 280}
              placement={hintPlacement ? hintPlacement : 'top'}
            >
              {hint}
            </Hint>
          )}
        </span>
      )}
      {Boolean(children) && (
        <div style={{ width: '100%' }}>
          {children}
          {(!!feedback || !!additional) && (
            <div className="styled-input-wrapper-message">
              {feedback && (
                <Feedback
                  type={feedbackType || 'error'}
                  message={feedback}
                  fontWeight={feedbackWeight}
                />
              )}
              {additional && additional}
            </div>
          )}
        </div>
      )}
    </InputWrapperContainer>
  )
}

export { InputWrapper }
