// @flow

import {
  SubDistributionStateFactory,
  type SubDistributionStateRecord,
} from 'com.batch/profilebase/models/sub-distribution.records'
import { type FetchPushSubDistributionActions } from 'com.batch/profilebase/usecases/fetch-sub-distribution'
import { STATUS } from 'constants/common'

const initialState = SubDistributionStateFactory()

type PushSubDistributionAction = FetchPushSubDistributionActions

export function pushSubDistributionReducer(
  state: SubDistributionStateRecord = initialState,
  action: PushSubDistributionAction
): SubDistributionStateRecord {
  switch (action.type) {
    case 'FETCH_PUSH_SUB_DISTRIBUTION':
      return state.set('loadingState', STATUS.LOADING)
    case 'FETCH_PUSH_SUB_DISTRIBUTION_SUCCESS':
      return state
        .set('loadingState', STATUS.LOADED)
        .set('distribution', action.payload.subDistribution)
    case 'FETCH_PUSH_SUB_DISTRIBUTION_FAILURE':
      return state.set('loadingState', STATUS.ERROR)
    default:
      return state
  }
}
