// @flow

import Immutable, { type List } from 'immutable'

import { type DispatchExtraBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

import { type ListSegmentResponse } from 'com.batch/segments/infra/segments-service'
import { SegmentFactory, type SegmentRecord } from 'com.batch/segments/models/segment.records'

type FetchSegmentListAction = ReduxAction<
  'FETCH_SEGMENT_LIST',
  {
    page: number,
    nbPerPage: number,
  },
>

export type FetchSegmentListFailureAction = ReduxAction<'FETCH_SEGMENT_LIST_FAILURE', string>

export type FetchSegmentListSuccessAction = ReduxAction<
  'FETCH_SEGMENT_LIST_SUCCESS',
  {
    segments: List<SegmentRecord>,
    nbPerPage: number,
    page: number,
    total: number,
    totalMatching: number,
    sortBy: ?('displayName' | 'updatedAt'),
    sortDirection: ?('asc' | 'dsc'),
    search: string,
    trashCache: boolean,
  },
>

export type FetchSegmentActionType =
  | FetchSegmentListAction
  | FetchSegmentListFailureAction
  | FetchSegmentListSuccessAction

export type SetPageSegmentAction = ReduxAction<'SET_PAGE_SEGMENT_LIST', number>

export const setSegmentPage = (page: number): SetPageSegmentAction => ({
  type: 'SET_PAGE_SEGMENT_LIST',
  payload: page,
})

export const fetchSegmentList = ({
  page,
  nbPerPage = 10,
  search,
  sortDirection,
  sortField,
  forceTrashCache = false,
}: {
  page: number,
  nbPerPage: number,
  search: ?string,
  sortDirection: ?('asc' | 'dsc'),
  sortField: ?('displayName' | 'updatedAt'),
  forceTrashCache?: boolean,
}): DispatchExtraBoundFn<Promise<void>> => {
  return async (dispatch, getState, { segmentsService }) => {
    try {
      const state = getState()
      const project = currentProjectSelector(state)
      const segments = state.segments

      // if filters or sort changed, we need to fetch the list again across all pages
      const trashCache =
        search !== segments.search ||
        segments.sortBy !== sortField ||
        segments.sortDirection !== sortDirection ||
        forceTrashCache

      if (!trashCache && segments.segmentsPerPage.has(page)) {
        dispatch(setSegmentPage(page))
        return
      }

      dispatch(
        ({
          type: 'FETCH_SEGMENT_LIST',
          payload: {
            page,
            nbPerPage,
          },
        }: FetchSegmentListAction)
      )

      const segmentListServiceResponse: ListSegmentResponse =
        await segmentsService.fetchSegmentList({
          projectKey: project.projectKey,
          page: Math.max(0, page - 1),
          search: search ?? '',
          nbPerPage,
          sortDirection: !sortField
            ? 'SORT_DIRECTION_DESC'
            : sortDirection === 'asc'
              ? 'SORT_DIRECTION_ASC'
              : sortDirection === 'dsc'
                ? 'SORT_DIRECTION_DESC'
                : 'SORT_DIRECTION_UNSPECIFIED',
          sortField:
            sortField === 'displayName' ? 'SORT_FIELD_DISPLAY_NAME' : 'SORT_FIELD_CREATED_AT',
          total: trashCache ? null : segments.total,
          totalMatching: trashCache ? null : segments.totalMatching,
        })

      const segmentRecordArray =
        segmentListServiceResponse.segments?.map(segment =>
          SegmentFactory({
            displayName: segment.displayName,
            name: segment.name,
            query: segment.query,
            campaignCount: segment.campaignCount,
            automationCount: segment.automationCount,
            campaignRunningCount: segment.campaignRunningCount,
            automationRunningCount: segment.automationRunningCount,
          })
        ) ?? []

      const segmentRecordList = new Immutable.List<SegmentRecord>().push(...segmentRecordArray)
      dispatch(
        ({
          type: 'FETCH_SEGMENT_LIST_SUCCESS',
          payload: {
            segments: segmentRecordList,
            nbPerPage,
            page,
            total: segmentListServiceResponse.countTotal,
            totalMatching: segmentListServiceResponse.countFiltered,
            search: search ?? '',
            sortBy: sortField,
            sortDirection: sortDirection,
            trashCache,
          },
        }: FetchSegmentListSuccessAction)
      )
    } catch (e) {
      dispatch(
        ({
          type: 'FETCH_SEGMENT_LIST_FAILURE',
          payload: e.message ?? '',
        }: FetchSegmentListFailureAction)
      )
    }
  }
}
