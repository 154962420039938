// @flow
import dayjs, { type Dayjs } from 'dayjs'
import * as React from 'react'

import { schemes } from 'components/styled/tokens'

import { randomSize, humanizeDayjs } from 'com.batch.common/utils'

// import { sampleBounceByDayChartData } from './bounce-by-days-chart.sample'

import { type pushCampaignSendType } from 'com.batch.redux/_records'

import { BounceByDaysWrapper } from 'com.batch/orchestration-analytics/ui/components/bounce-by-days-chart/bounce-by-days-chart.styles'
import { type BarChartData } from 'com.batch/shared/infra/types/chart-data'
import { BarChartProvider, BarChart } from 'com.batch/shared/ui/component/charts/bar-chart'
import { STATUS } from 'constants/common'

type Props = {
  bounces?: BarChartData[],
  setDateOnView: (?Dayjs) => void,
  loadingState?: LoadingState,
  recurrence: 'DAILY' | 'WEEKLY' | 'MONTHLY',
  sendType: pushCampaignSendType,
  totalBounces: number,
}

const RECURRENCE = { DAILY: 'day', WEEKLY: 'week', MONTHLY: 'month' }

export const BounceByDaysChart = ({
  bounces = [],
  setDateOnView,
  loadingState,
  sendType,
  recurrence,
  totalBounces,
}: Props): React.Node => {
  const groups = [
    { name: 'block', color: schemes.orange['70'] },
    { name: 'hard', color: schemes.orange['50'] },
    { name: 'soft', color: schemes.orange['20'] },
  ]
  const nbBars = React.useMemo(() => {
    if (sendType === 'recurring') return 12
    return 14
  }, [sendType])

  const dateRange = React.useMemo(
    () =>
      Array.from({ length: nbBars }, (_, index) => index).map(i => {
        if (recurrence === 'WEEKLY')
          return dayjs
            .utc()
            .startOf('week')
            .subtract(nbBars, 'week')
            .add(i + 1, 'week')
            .add(1, 'day')

        return dayjs
          .utc()
          .startOf(RECURRENCE[recurrence])
          .subtract(nbBars, RECURRENCE[recurrence])
          .add(i + 1, RECURRENCE[recurrence])
      }),
    [nbBars, recurrence]
  )

  const overwriteDateName = React.useMemo(
    () =>
      recurrence === 'MONTHLY'
        ? dateRange.map(date => ({ date: date, name: date.format('MMMM') }))
        : [
            { date: dateRange[dateRange.length - 1], name: 'Today' },
            { date: dateRange[0], name: humanizeDayjs({ date: dateRange[0] }) },
          ],
    [dateRange, recurrence]
  )

  const skeletonData = React.useMemo(
    () =>
      dateRange.map(date => {
        const hard = randomSize(100, 600)
        const soft = randomSize(100, 600)
        const block = randomSize(100, 600)
        const total = hard + block + soft
        return {
          date: date,
          hard: {
            value: hard,
            rate: hard / total,
          },
          soft: {
            value: soft,
            rate: soft / total,
          },
          block: {
            value: block,
            rate: block / total,
          },
        }
      }),
    [dateRange]
  )

  const hasNoBounces = React.useMemo(() => {
    return (
      sendType === 'scheduled' ||
      (sendType !== 'scheduled' && loadingState === STATUS.LOADED && bounces.length === 0)
    )
  }, [sendType, loadingState, bounces])

  const data = React.useMemo(() => {
    return loadingState === STATUS.LOADING || hasNoBounces ? skeletonData : bounces
  }, [loadingState, hasNoBounces, skeletonData, bounces])

  const noDataMsg = React.useMemo(() => {
    if (sendType === 'scheduled') return 'No data per day on a campaign.'
    if (totalBounces === 0) return 'No bounces for this automation.'
    if (sendType === 'trigger')
      return 'This graph shows bounces over the last 14 days.\\ANo bounces occurred in the last 14 days.'
    switch (recurrence) {
      case 'DAILY':
        return 'This graph shows bounces over the last 14 days.\\ANo bounces occurred in the last 14 days.'
      case 'WEEKLY':
        return 'This graph shows bounces over the last 11 weeks.\\ANo bounces occurred in the last 11 weeks.'
      case 'MONTHLY':
        return 'This graph shows bounces over the last 11 months.\\ANo bounces occurred in the last 11 months.'
    }
  }, [sendType, recurrence, totalBounces])

  return (
    <BounceByDaysWrapper noDataMsg={noDataMsg} overlay={hasNoBounces}>
      <BarChartProvider
        isLoading={loadingState === STATUS.LOADING}
        isEmpty={hasNoBounces}
        dateRange={dateRange}
        setDateOnView={setDateOnView}
      >
        <BarChart
          data={data}
          groups={groups}
          height={270}
          barPadding={0.1}
          scaleLineNumber={6}
          showTimeline
          scaleLineKind="overTheMaxBar"
          timelineRenameDates={overwriteDateName}
        />
      </BarChartProvider>
    </BounceByDaysWrapper>
  )
}
