// @flow
import styled, { type StyledComponent } from 'styled-components'

import { IconContainer } from 'components/common/svg-icon'
import { colors } from 'components/styled/tokens'

import { type SubscriptionStatus } from 'com.batch/profile/models/profile.records'

const getColorFromStatus = (status: SubscriptionStatus | 'IMPORTED'): string => {
  if (status === 'IMPORTED') return colors.text
  if (status === 'SUBSCRIBED') return colors.textSuccess
  if (status === 'UNSUBSCRIBED') return colors.textDanger
  return colors.textDisabled
}
type props = { subscriptionStatus: SubscriptionStatus | 'IMPORTED', ... }
export const SubscriptionBlockContainer: StyledComponent<props, {}, HTMLDivElement> = styled.div`
  display: flex;
  gap: 4px;
  cursor: pointer;
  ${IconContainer}:first-child {
    color: ${(p: props) => getColorFromStatus(p.subscriptionStatus)};
  }
`
