// @flow

import * as React from 'react'
import { useDispatch } from 'react-redux'

import { EmptyField } from 'components/common/empty-states'
import { previewDeleteCompany } from 'components/console/redux/company'
import { Input, InputWrapper } from 'components/form'

type Props = {
  isLoading: boolean,
  setIsLoading: (isLoading: boolean) => void,
  isOpen: boolean,
  companyId: number,
  companyIdValue: number,
  onCompanyIdValueChange: (evt: SyntheticInputEvent<HTMLInputElement>) => void,
  companyName: string,
  onCompanyNameChange: (evt: SyntheticInputEvent<HTMLInputElement>) => void,
  ...
}

export const ResumeStep = ({
  isLoading,
  setIsLoading,
  isOpen,
  companyId,
  companyIdValue,
  onCompanyIdValueChange,
  companyName,
  onCompanyNameChange,
}: Props): React.Node => {
  const dispatch = useDispatch()
  const [nbCampaignsToStop, setNbCampaignsToStop] = React.useState<number>(0)
  const [nbApiKeys, setNbApiKeys] = React.useState<number>(0)
  const [nbAppsToArchive, setNbAppsToArchive] = React.useState<number>(0)
  const [nbPushCampaignsToArchive, setNbPushCampaignsToArchive] = React.useState<number>(0)
  const [nbUsersToDelete, setNbUsersToDelete] = React.useState<number>(0)
  const handleOnPaste = React.useCallback(e => e.preventDefault(), [])

  React.useEffect(() => {
    if (isOpen) {
      setIsLoading(true)
      dispatch(previewDeleteCompany({ companyId })).then(preview => {
        setNbUsersToDelete(preview.nbUsersToDelete)
        setNbPushCampaignsToArchive(preview.nbPushCampaignsToArchive)
        setNbAppsToArchive(preview.nbAppsToArchive)
        setNbApiKeys(preview.nbApiKeys)
        setNbCampaignsToStop(preview.nbCampaignsToStop)
        setIsLoading(false)
      })
    }
  }, [companyId, dispatch, isOpen, setIsLoading])

  return (
    <React.Fragment>
      <div style={{ marginTop: 10, marginBottom: 10 }}>
        It will stop {isLoading ? <EmptyField /> : <strong>{nbCampaignsToStop} campaigns</strong>},
        archive {isLoading ? <EmptyField /> : <strong>{nbPushCampaignsToArchive} campaigns</strong>}
        , archive {isLoading ? <EmptyField /> : <strong>{nbAppsToArchive} apps</strong>}, blacklist{' '}
        {isLoading ? <EmptyField /> : <strong>{nbApiKeys} api keys</strong>}, delete{' '}
        {isLoading ? <EmptyField /> : <strong>{nbUsersToDelete} users</strong>}.
      </div>
      <p>Please confirm it by typing Company's name and id:</p>
      <InputWrapper label="Name">
        <Input
          placeholder="Name"
          value={companyName}
          onChange={onCompanyNameChange}
          onPaste={handleOnPaste}
          autoComplete={false}
          disabled={isLoading}
        />
      </InputWrapper>
      <InputWrapper label="ID">
        <Input
          placeholder="ID"
          type="number"
          value={companyIdValue}
          onChange={onCompanyIdValueChange}
          onPaste={handleOnPaste}
          autoComplete={false}
          disabled={isLoading}
        />
      </InputWrapper>
    </React.Fragment>
  )
}
