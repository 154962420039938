// @flow
import * as React from 'react'
import { ThemeProvider } from 'styled-components'

import { Icon } from 'components/common/svg-icon'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { humanizeDayjs } from 'com.batch.common/utils'

import { platforms } from 'com.batch/profile/constants/platforms'
import { type ProfilePlatformRecord } from 'com.batch/profile/models/profile.records'
import {
  Debug,
  LastVisitDate,
  PlatformButtonContainer,
  PlatformName,
  PlatformDetails,
  PlatformStatus,
} from 'com.batch/profile/ui/components/profile-hero/profile-platforms/platform-button.styles'
import { ProfileMockupOptin } from 'com.batch/profile/ui/components/profile-hero/profile-platforms/profile-mockup-optin'

type Props = {
  platform: ProfilePlatformRecord,
  onClick?: () => void,
  href?: string,
}

export const PlatformButton = ({ platform, onClick, href }: Props): React.Node => {
  const platformData = React.useMemo(() => platforms[platform.platform], [platform.platform])

  const containerProps = React.useMemo(
    () => (href ? { as: 'a', href, target: '_blank' } : { onClick }),
    [href, onClick]
  )

  const lastActivity = React.useMemo(
    () =>
      platform.lastActivity && dayjs(platform.lastActivity).isValid()
        ? humanizeDayjs({ date: dayjs(platform.lastActivity) })
        : null,
    [platform.lastActivity]
  )

  const subtitle = React.useMemo(() => {
    if (platform.kind === 'imported')
      return (
        <React.Fragment>
          <Icon icon="import" style={{ marginRight: 6 }} />
          Imported token
        </React.Fragment>
      )

    return lastActivity ? `Last visit ${lastActivity}` : 'No last visit'
  }, [platform.kind, lastActivity])

  return (
    <ThemeProvider theme={{ platformButtomKind: platform.kind }}>
      <PlatformButtonContainer {...containerProps}>
        <PlatformDetails>
          <PlatformName platform={platformData.icon}>
            <Icon icon={platformData.icon} />
            <span>{platform.deviceName}</span>
          </PlatformName>
          <LastVisitDate>{subtitle}</LastVisitDate>
        </PlatformDetails>
        <PlatformStatus>
          <ProfileMockupOptin
            kind={platform.kind}
            size="short"
            device={platform.deviceType}
            subscriptionStatus={platform.subscriptionStatus}
          />
          <Debug>
            <div>
              {platform.platform === 'WEBPUSH' ? 'View in debug' : 'Inspect'}
              <Icon icon="arrow-long-right" />
            </div>
          </Debug>
        </PlatformStatus>
      </PlatformButtonContainer>
    </ThemeProvider>
  )
}
