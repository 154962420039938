// @flow

import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useClickOutsideOnExistingRefs } from 'components/_hooks'

import { inlineEditorConfigSelector } from 'com.batch/message-builder/store/inline-editor.selector'
import { getPushContentForActiveLanguageSelector } from 'com.batch/push/store/push.selector'

import { type PushContentRecord } from 'com.batch/message/models/message.records'
import { MediaOverlay, OverlayIcon } from 'com.batch/message-builder/ui/components/common'
import { InfoPanelPortaled } from 'com.batch/message-builder/ui/components/info-panel/info-panel'
import { setInlineEditor } from 'com.batch/message-builder/usecases/manage-inline-editor'

type MediaFieldProps = {|
  messageId: string,
  field: 'media' | 'icon',
  children: ({ url: string, overlay: React.Node, kind: 'image' | 'audio' | 'video' }) => React.Node,
|}

export const MediaField: React$AbstractComponent<MediaFieldProps, *> = React.memo(
  ({ messageId, field, children }: MediaFieldProps): React.Node => {
    const getContent = useSelector(getPushContentForActiveLanguageSelector)
    const content: PushContentRecord = React.useMemo(
      () => getContent(messageId),
      [getContent, messageId]
    )

    const inlineEditorConfig = useSelector(inlineEditorConfigSelector)
    const dispatch = useDispatch()
    const setEditing = React.useCallback(
      (field: null | 'image' | 'icon') => {
        let newConfig = inlineEditorConfig.set('field', field).set('variant', 'a')
        dispatch(setInlineEditor(newConfig))
      },
      [dispatch, inlineEditorConfig]
    )
    const overlay = React.useMemo(() => {
      return (
        <OverlayWithHooks
          messageId={messageId}
          isImage={field === 'media'}
          variant="a"
          setEditing={setEditing}
        />
      )
    }, [field, messageId, setEditing])
    return children(
      field === 'media'
        ? {
            kind: content.get('content').attachmentKind,
            url: content.getIn(['content', 'templates'])?.pushPicture ?? '',
            overlay,
          }
        : {
            kind: 'image',
            url: content.getIn(['androidContent', 'templates', 'androidIcon']) ?? '',
            overlay,
          }
    )
  }
)

type OverlayWithHooksProps = {
  isImage: boolean,
  variant: 'a' | 'b',
  messageId: string,
  setEditing: (null | 'image' | 'icon') => void,
}

const OverlayWithHooks = ({
  isImage,
  setEditing,
  messageId,
}: OverlayWithHooksProps): React.Node => {
  const [panelOpen, setPanelOpen] = React.useState(false)
  const popperRef = React.useRef(null)
  const triggerRef = React.useRef(null)
  const openPanel = React.useCallback(() => {
    setPanelOpen(true)
    setEditing(isImage ? 'image' : 'icon')
  }, [isImage, setEditing])
  const closePanel = React.useCallback(() => {
    setPanelOpen(false)
    setEditing(null)
  }, [setEditing])
  useClickOutsideOnExistingRefs(() => setPanelOpen(false), [popperRef, triggerRef])
  return (
    <MediaOverlay ref={triggerRef} $hover={panelOpen} onClick={openPanel}>
      <OverlayIcon />
      {panelOpen && (
        <InfoPanelPortaled
          ref={popperRef}
          variant="a"
          messageId={messageId}
          canUseTemplating
          field={isImage ? 'pushPicture' : 'androidIcon'}
          clearEditing={closePanel}
          value=""
        />
      )}
    </MediaOverlay>
  )
}
