// @flow

import * as React from 'react'
import { type Node } from 'react'
import styled, { type StyledComponent } from 'styled-components'

import { Icon, type availableIcons } from 'components/common/svg-icon'

export type TagType = 'main' | 'label'
type TagProps = {
  type?: TagType,
  icon?: availableIcons,
  children: Node,
  onClick?: (e: SyntheticMouseEvent<HTMLElement>) => any,
  className?: string,
  style?: Object,
  ...
}

export const Tag: React$AbstractComponent<TagProps, HTMLSpanElement> = React.forwardRef<
  TagProps,
  *,
>(({ type = 'main', icon, children, onClick, className, style }: TagProps, ref) => {
  const color = Colors[type]
  return (
    <TagContainer color={color} onClick={onClick} ref={ref} className={className} style={style}>
      {icon && <IconStyled icon={icon} size={14} />}
      {children}
    </TagContainer>
  )
})

export const Colors: { [key: TagType]: [number, number, number], ... } = {
  main: [197, 177, 50],
  label: [236, 25, 49],
}
const hsla = (h: number, s: number, l: number, a: number): string =>
  `hsla(${h}, ${s}%, ${l}%, ${a})`

type TagContainerProps = { color: [number, number, number], onClick?: any, ... }
export const TagContainer: StyledComponent<TagContainerProps, *, HTMLSpanElement> = styled.span`
  display: inline-flex;
  align-items: center;
  padding: 5px 9px;
  border-radius: 20px;
  font-size: 13px;
  line-height: 13px;
  font-weight: 500;
  letter-spacing: -0.1px;
  color: ${(props: TagContainerProps) =>
    hsla(props.color[0], props.color[1], props.color[2] - 8, 1)};
  background-color: ${(props: TagContainerProps) => hsla(...props.color, 0.08)};
  white-space: nowrap;
  cursor: ${(props: TagContainerProps) => (props.onClick ? 'pointer' : 'default')};

  & + & {
    margin: 0 0 0 3px;
  }
`

const IconStyled: StyledComponent<*, *, typeof Icon> = styled(Icon)`
  margin: 0 9px 2px 2px;
`

export default Tag
