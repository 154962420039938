// @flow
import {
  generateEdge,
  buildSourceAndHandleFromBranchId,
  handleAddOrSourceBranch,
} from '../reactflow.tools'
import { type FormatterProps, NoPosition } from '../reactflow.types'

import { type MessageNodeRecord } from 'com.batch/orchestration-journey/models/journey.records'

export const generatePlaceholder = (channel: ChannelUntilCleanup): string => {
  switch (channel) {
    case 'email':
      return 'Email message'
    case 'sms':
      return 'SMS message'
    case 'push':
      return 'Push message'
  }
}

export const formatMessage = ({
  node,
  ...props
}: {
  node: MessageNodeRecord,
  ...FormatterProps,
}): FormatterProps => {
  props.reactflowNodes.push({
    id: node.id,
    type: 'messageNode',
    sizingType: 'MESSAGE',
    position: { x: 0, y: 0 },
    data: {
      reduxNodeId: node.id,
      messageReference: node.messageReference,
      name: node.label,
      channel: node.channel,
      placeholder: generatePlaceholder(node.channel),
      errors: node.errors,
      hasQuietTimes: node.hasQuietTimes ?? false,
      onClick: () => props.callbacks.openMessageSheet(node),
      onRemove: () => props.callbacks.onRemoveNode(node.id),
    },
  })
  let addButtonId: ?string = null
  if (props.ownedBy.type !== 'REJOIN') {
    addButtonId = node.id + '_add'
    props.reactflowNodes.push({
      ...NoPosition,
      id: addButtonId,
      type: 'buttonNode',
      sizingType: 'add',
      data: {
        reduxNodeId: node.id,
        flowRestrictions: props.flowRestrictions,
        isActive: false,
        pasteNode: () => props.callbacks.onPasteNode([props.ownedBy]),
        insertNode: (nodeType, channel) => {
          props.callbacks.onInsertNode({
            branchIds: [props.ownedBy],
            nodeType,
            channel,
          })
        },
      },
    })
    props.reactflowEdges.push(
      generateEdge({
        ...buildSourceAndHandleFromBranchId(props.ownedBy),
        target: addButtonId,
      })
    )
  }
  props.reactflowEdges.push(
    generateEdge({
      ...handleAddOrSourceBranch(props.ownedBy, addButtonId),
      target: node.id,
    })
  )
  return props
}
