// @flow
import Immutable, { type Map } from 'immutable'

import {
  OrchestrationAnalyticsFactory,
  type OrchestrationAnalyticsRecord,
} from 'com.batch/orchestration-analytics/store/orchestration-analytics.state'

import { type FetchClicksByUrlActions } from 'com.batch/orchestration-analytics/usecases/fetch-clicks-by-url'
import { type FetchOrchestrationStatsByChannelActions } from 'com.batch/orchestration-analytics/usecases/fetch-stats-by-channel'
import { type FetchOrchestrationStatsByClassificationActions } from 'com.batch/orchestration-analytics/usecases/fetch-stats-by-classification'
import { type FetchOrchestrationStatsByDayActions } from 'com.batch/orchestration-analytics/usecases/fetch-stats-by-day'
import { type FetchOrchestrationStatsByProviderActions } from 'com.batch/orchestration-analytics/usecases/fetch-stats-by-provider'
import { type FetchOrchestrationStatsByStepActions } from 'com.batch/orchestration-analytics/usecases/fetch-stats-by-step'
import { STATUS } from 'constants/common'

type OrchestrationAnalyticsActions =
  | FetchOrchestrationStatsByChannelActions
  | FetchOrchestrationStatsByDayActions
  | FetchOrchestrationStatsByStepActions
  | FetchOrchestrationStatsByProviderActions
  | FetchOrchestrationStatsByClassificationActions
  | FetchClicksByUrlActions

export const orchestrationAnalyticsReducer = (
  state: Map<string, OrchestrationAnalyticsRecord> = Immutable.Map(),
  action: OrchestrationAnalyticsActions
): Map<string, OrchestrationAnalyticsRecord> => {
  switch (action.type) {
    case 'FETCH_ORCHESTRATION_STATS_BY_CHANNEL':
      return state.set(
        action.payload,
        state
          .get(action.payload, OrchestrationAnalyticsFactory())
          .set('channelsLoadingState', STATUS.LOADING)
      )
    case 'FETCH_ORCHESTRATION_STATS_BY_CHANNEL_SUCCESS':
      return state.set(
        action.payload.token,
        state
          .get(action.payload.token, OrchestrationAnalyticsFactory())
          .set('channelsLoadingState', STATUS.LOADED)
          .set('expire', Date.now() + 1000 * 60 * 2) // TTL 2 min
          .setIn(['channels', 'email', 'stats'], action.payload.data.email)
          .setIn(['channels', 'sms', 'stats'], action.payload.data.sms)
          .setIn(['channels', 'push', 'stats'], action.payload.data.push)
      )
    case 'FETCH_ORCHESTRATION_STATS_BY_CHANNEL_FAILURE':
      return state.set(
        action.payload.token,
        state
          .get(action.payload.token, OrchestrationAnalyticsFactory())
          .set('channelsLoadingState', STATUS.ERROR)
          .set('expire', Date.now())
      )

    case 'FETCH_ORCHESTRATION_STATS_BY_DAY':
      return state.set(
        action.payload,
        state
          .get(action.payload, OrchestrationAnalyticsFactory())
          .set('daysLoadingState', STATUS.LOADING)
      )

    case 'FETCH_ORCHESTRATION_STATS_BY_DAY_SUCCESS':
      return state.set(
        action.payload.token,
        state
          .get(action.payload.token, OrchestrationAnalyticsFactory())
          .set('daysLoadingState', STATUS.LOADED)
          .setIn(['channels', 'email', 'bounces', 'periods'], action.payload.data)
      )
    case 'FETCH_ORCHESTRATION_STATS_BY_DAY_FAILURE':
      return state.set(
        action.payload.token,
        state
          .get(action.payload.token, OrchestrationAnalyticsFactory())
          .set('daysLoadingState', STATUS.ERROR)
      )
    case 'FETCH_ORCHESTRATION_STATS_BY_STEP':
      return state.set(
        action.payload,
        state
          .get(action.payload, OrchestrationAnalyticsFactory())
          .set('stepsLoadingState', STATUS.LOADING)
      )
    case 'FETCH_ORCHESTRATION_STATS_BY_STEP_SUCCESS':
      return state.set(
        action.payload.token,
        state
          .get(action.payload.token, OrchestrationAnalyticsFactory())
          .set('stepsLoadingState', STATUS.LOADED)
          .set('steps', action.payload.data)
      )
    case 'FETCH_ORCHESTRATION_STATS_BY_STEP_FAILURE':
      return state.set(
        action.payload.token,
        state
          .get(action.payload.token, OrchestrationAnalyticsFactory())
          .set('stepsLoadingState', STATUS.ERROR)
      )
    case 'FETCH_ORCHESTRATION_STATS_BY_PROVIDER':
      return state.set(
        action.payload,
        state
          .get(action.payload, OrchestrationAnalyticsFactory())
          .set('providersLoadingState', STATUS.LOADING)
      )
    case 'FETCH_ORCHESTRATION_STATS_BY_PROVIDER_SUCCESS':
      return state.set(
        action.payload.token,
        state
          .get(action.payload.token, OrchestrationAnalyticsFactory())
          .set('providersLoadingState', STATUS.LOADED)
          .setIn(['channels', 'email', 'providers'], action.payload.data)
      )
    case 'FETCH_ORCHESTRATION_STATS_BY_PROVIDER_FAILURE':
      return state.set(
        action.payload.token,
        state
          .get(action.payload.token, OrchestrationAnalyticsFactory())
          .set('providersLoadingState', STATUS.ERROR)
      )
    case 'FETCH_ORCHESTRATION_STATS_BY_CLASSIFICATION':
      return state.set(
        action.payload,
        state
          .get(action.payload, OrchestrationAnalyticsFactory())
          .set('classificationsLoadingState', STATUS.LOADING)
      )
    case 'FETCH_ORCHESTRATION_STATS_BY_CLASSIFICATION_SUCCESS':
      return state.set(
        action.payload.token,
        state
          .get(action.payload.token, OrchestrationAnalyticsFactory())
          .set('classificationsLoadingState', STATUS.LOADED)
          .setIn(['channels', 'email', 'bounces', 'categories'], action.payload.data)
      )
    case 'FETCH_ORCHESTRATION_STATS_BY_CLASSIFICATION_FAILURE':
      return state.set(
        action.payload.token,
        state
          .get(action.payload.token, OrchestrationAnalyticsFactory())
          .set('classificationsLoadingState', STATUS.ERROR)
      )
    case 'FETCH_CLICKS_BY_URL':
      return state.set(
        action.payload.token,
        state
          .get(action.payload.token, OrchestrationAnalyticsFactory())
          .set('linksLoadingState', STATUS.LOADING)
      )
    case 'FETCH_CLICKS_BY_URL_SUCCESS':
      return state.set(
        action.payload.token,
        state
          .get(action.payload.token, OrchestrationAnalyticsFactory())
          .set('linksLoadingState', STATUS.LOADED)
          .setIn(['channels', 'email', 'links'], action.payload.data)
      )
    case 'FETCH_CLICKS_BY_URL_FAILURE':
      return state.set(
        action.payload.token,
        state
          .get(action.payload.token, OrchestrationAnalyticsFactory())
          .set('linksLoadingState', STATUS.ERROR)
      )

    default:
      return state
  }
}
