// / @flow
import Immutable from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  generateUniqueEventId,
  formatJourneyQuery,
  parseTriggerQuery,
} from '../../models/journey-trigger.helper'
import { visibleEventsSelector } from 'com.batch.redux/attribute.selector'

import { getNodeId } from 'com.batch/orchestration-journey/models/id-generator'
import {
  EventNextFactory,
  EventWithOptionalQueryFactory,
  FinalNodeFactory,
} from 'com.batch/orchestration-journey/models/journey.records'
import { journeyTreeSelector } from 'com.batch/orchestration-journey/models/journey.selectors'
import { updateNode } from 'com.batch/orchestration-journey/usecases/update-node'

export type TimerSettingsExitEventHooks = {
  exitEvents: Immutable.List<{
    eventId: string,
    query: ?{ ... },
    eventName: string,
  }>,
  onExitEventChange: (exitEventEnabled: boolean) => void,
  createOnQueryChange: ({ eventName: string, eventId: string }) => (query: ?{ ... }) => void,
  createOnEventChange: (eventId: string) => (evt: string) => void,
  createOnDeleteExitEvent: (eventId: string) => () => void,
  onAddExitEvent: () => void,
}

export const useTimerSettingsExitEventHooks = (nodeId: string): TimerSettingsExitEventHooks => {
  const { nodesMap } = useSelector(journeyTreeSelector)
  const events = useSelector(visibleEventsSelector)

  const dispatch = useDispatch()
  const node = React.useMemo(() => {
    const fetchedNode = nodesMap.get(nodeId)
    return fetchedNode?.type === 'TIMER' ? fetchedNode : null
  }, [nodesMap, nodeId])

  const exitEvents = React.useMemo(
    () =>
      node?.onEvents.reduce(
        (eventLists, onEvents) =>
          eventLists.merge(
            onEvents.triggers.reduce(
              (acc, event) =>
                acc.push({
                  eventName: event.name,
                  query: formatJourneyQuery(event.query),
                  eventId: event.eventId,
                }),
              new Immutable.List()
            )
          ),
        new Immutable.List()
      ) ?? new Immutable.List(),
    [node]
  )

  const onExitEventChange = React.useCallback(
    exitEventEnabled => {
      if (node) {
        if (exitEventEnabled) {
          const firstEvent = events.filter(ev => !ev.id.startsWith('be.')).first()
          const finalNode = FinalNodeFactory({ id: getNodeId('FINAL') })
          dispatch(updateNode(finalNode, true))
          dispatch(
            updateNode(
              node.set(
                'onEvents',
                new Immutable.List().push(
                  EventNextFactory({
                    nextNodeId: finalNode.id,
                    triggers: new Immutable.List().push(
                      EventWithOptionalQueryFactory({
                        name: firstEvent.id,
                      })
                    ),
                  })
                )
              )
            )
          )
        } else {
          dispatch(updateNode(node.set('onEvents', new Immutable.List())))
        }
      }
    },
    [dispatch, events, node]
  )

  const createOnQueryChange = React.useCallback(
    ({ eventId, eventName }: { eventId: string, eventName: string }) =>
      query => {
        if (node) {
          const updatedOnEvents = node.onEvents.map(onEvent =>
            onEvent.set(
              'triggers',
              onEvent.triggers.map(evWithOptQuery =>
                evWithOptQuery.eventId === eventId
                  ? EventWithOptionalQueryFactory({
                      name: eventName,
                      eventId,
                      query: parseTriggerQuery(query),
                    })
                  : evWithOptQuery
              )
            )
          )
          dispatch(updateNode(node.set('onEvents', updatedOnEvents)))
        }
      },
    [dispatch, node]
  )

  /*
    when the event changes, we need to 
    - reset the event id so we don't look to the same query in the redux query reducer
    - set the "local" copy of the query to null
  */
  const createOnEventChange = React.useCallback(
    (eventId: string) => (eventName: string) => {
      if (node) {
        const updatedOnEvents = node.onEvents.map(onEvent =>
          onEvent.set(
            'triggers',
            onEvent.triggers.map(evWithOptQuery =>
              evWithOptQuery.eventId === eventId
                ? EventWithOptionalQueryFactory({
                    name: eventName,
                    eventId: generateUniqueEventId(node.id),
                    query: undefined,
                  })
                : evWithOptQuery
            )
          )
        )
        dispatch(updateNode(node.set('onEvents', updatedOnEvents)))
      }
    },
    [dispatch, node]
  )

  /*
    reminder : we got an onEvents List, each onEvent able to lead to another branch of the journey
    on each onEvent, we got another List in the triggers object, where we try to remove the matching eventId 
    targeted by this callback
  */
  const createOnDeleteExitEvent = React.useCallback(
    (eventId: string) => () => {
      if (node) {
        const updatedOnEvents = node.onEvents
          .map(onEvent => {
            const updatedTriggers = onEvent.triggers.filter(
              evWithOptQuery => evWithOptQuery.eventId !== eventId
            )

            return onEvent.set('triggers', updatedTriggers)
          })
          .filter(onEvent => onEvent.triggers.size > 0)
        dispatch(updateNode(node.set('onEvents', updatedOnEvents)))
      }
    },
    [dispatch, node]
  )
  /*
    for now we only support one item in onEvents, so we always use index = 0
    shape of this API will depend on frontend UX
  */
  const onAddExitEvent = React.useCallback(() => {
    const TEMP_INDEX = 0
    const firstEvent = events.filter(ev => !ev.id.startsWith('be.')).first()
    const triggers = node?.onEvents.get(TEMP_INDEX)?.triggers
    if (triggers && node)
      dispatch(
        updateNode(
          node.setIn(
            ['onEvents', TEMP_INDEX, 'triggers'],
            triggers.push(
              EventWithOptionalQueryFactory({
                name: firstEvent.id,
                eventId: generateUniqueEventId(node.id),
              })
            )
          )
        )
      )
  }, [dispatch, node, events])

  return {
    exitEvents,
    onExitEventChange,
    createOnQueryChange,
    createOnEventChange,
    createOnDeleteExitEvent,
    onAddExitEvent,
  }
}
