// @flow

import setTimeout from 'core-js/features/set-timeout'
import Immutable, { type List } from 'immutable'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { useClickOutsideOnExistingRefs, useQuery } from 'components/_hooks'
import { Button } from 'components/common/button'
import { FormGrid } from 'components/common/grid'
import { Icon } from 'components/common/svg-icon'
import { FilterSearch, FilterSelect } from 'components/filter'
import { colors } from 'components/styled/tokens'

import { type IdentifierKind } from 'com.batch/profile/constants/identifier-kind'
import { ProfileSearchContainer } from 'com.batch/profile/ui/components/profile-search/profile-search.styles'
import { resetProfile } from 'com.batch/profile/usecases/reset-store-profile'

type IdentifierKindOption = { filter: IdentifierKind, label: string }

const IDENTIFIER_KIND_OPTIONS: List<IdentifierKindOption> = new Immutable.List().push(
  { filter: 'email', label: 'Email address' },
  { filter: 'custom_id', label: 'Custom ID' },
  { filter: 'installation_id', label: 'Installation ID' }
)

const optToString = (opt: ?IdentifierKindOption) => opt?.label ?? ''

type Props = {
  onSearch(terms: string, identifierKind: IdentifierKind, isRefreshAction: boolean): void,
  isLoading: boolean,
  dataLoaded: boolean,
  hasError: boolean,
}

export const ProfileSearch = ({ onSearch, isLoading, dataLoaded, hasError }: Props): React.Node => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const queryString = useQuery()

  const searchRef = React.useRef(null)
  const profileSearchRef = React.useRef(null)

  const qsIdentifierKind: IdentifierKind = React.useMemo(() => {
    if (queryString.has('custom_id')) return 'custom_id'
    if (queryString.has('installation_id')) return 'installation_id'
    return 'email'
  }, [queryString])

  const qsTerms = React.useMemo(
    () => queryString.get(qsIdentifierKind) ?? '',
    [qsIdentifierKind, queryString]
  )

  const [searchTerms, setSearchTerms] = React.useState(qsTerms)
  const [searchTermsHasChanged, setSearchTermsHasChanged] = React.useState(false)
  const [identifierKind, setIdentifierKind] = React.useState(qsIdentifierKind)
  const [focusSearch, setFocusSearch] = React.useState(true)
  const [firstRun, setFirstRun] = React.useState(true)

  const selectedIdentifierKind = React.useMemo(
    () => IDENTIFIER_KIND_OPTIONS.find(option => option.filter === identifierKind),
    [identifierKind]
  )

  const inputSize = React.useMemo(
    () => (firstRun || focusSearch ? 344 : 154),
    [firstRun, focusSearch]
  )

  const isRefresh = React.useMemo(
    () =>
      searchTerms === qsTerms &&
      Boolean(searchTerms) &&
      identifierKind === qsIdentifierKind &&
      !searchTermsHasChanged &&
      !hasError,
    [searchTerms, identifierKind, qsTerms, qsIdentifierKind, searchTermsHasChanged, hasError]
  )

  const onSeachFocus = React.useCallback(() => setTimeout(() => setFocusSearch(true), 0), [])

  const optionFormatter = React.useCallback((opt, { context }) => {
    if (context === 'value') return `by ${opt.label}`
    return opt.label
  }, [])

  const onIdentifierKindChange = React.useCallback(
    (option: ?IdentifierKindOption, isOpen: ?boolean) => {
      setFocusSearch(true)
      const filter = option && option.filter
      if (!filter) return
      if (!isOpen) {
        searchRef.current?.focus()
      }
      setIdentifierKind(filter)
    },
    []
  )

  const onSearchBlur = React.useCallback(() => {
    setTimeout(() => {
      if (!searchTerms && focusSearch) setFocusSearch(false)
    }, 0)
  }, [searchTerms, focusSearch])

  const onSearchChange = React.useCallback((value: string) => {
    setSearchTerms(value)
    setSearchTermsHasChanged(true)
  }, [])

  const onSearchSubmit = React.useCallback(
    e => {
      e.preventDefault()
      setSearchTermsHasChanged(false)
      navigate({
        search: `${identifierKind}=${encodeURIComponent(searchTerms.trim())}`,
      })
      onSearch(searchTerms, identifierKind, isRefresh)
    },
    [navigate, onSearch, searchTerms, identifierKind, isRefresh]
  )

  const resetAllProfile = React.useCallback(() => {
    dispatch(resetProfile())
    setFocusSearch(false)
    setFirstRun(false)
    setSearchTermsHasChanged(false)
  }, [dispatch])

  useClickOutsideOnExistingRefs(onSearchBlur, [profileSearchRef])

  React.useEffect(() => {
    if (qsTerms && !isLoading && !dataLoaded && !hasError)
      onSearch(searchTerms, identifierKind, isRefresh)
  }, [qsTerms, isLoading, dataLoaded, hasError, onSearch, searchTerms, identifierKind, isRefresh])

  React.useEffect(() => {
    if (firstRun) setFirstRun(false)
  }, [queryString, firstRun])

  React.useEffect(() => {
    setSearchTerms(qsTerms)
  }, [qsTerms])

  React.useEffect(() => {
    setIdentifierKind(qsIdentifierKind)
  }, [qsIdentifierKind])

  React.useEffect(() => {
    if (!queryString.keys().next().value && dataLoaded) resetAllProfile()
  }, [queryString, dataLoaded, resetAllProfile])

  return (
    <FormGrid
      template="auto auto 1fr"
      gap={8}
      onSubmit={onSearchSubmit}
      style={{ justifySelf: 'end' }}
    >
      <ProfileSearchContainer ref={profileSearchRef} isEmpty={!searchTerms && !focusSearch}>
        <FilterSearch
          ref={searchRef}
          placeholder="Search profile"
          value={searchTerms}
          isSensitive
          onChange={onSearchChange}
          autoFocus
          expandable
          width={inputSize}
          expandedMaxWidth={inputSize}
          onFocus={onSeachFocus}
          isFocusedFilter={focusSearch}
          isClearable={false}
        />
        {focusSearch && (
          <FilterSelect
            isSearchable={false}
            options={IDENTIFIER_KIND_OPTIONS}
            value={selectedIdentifierKind}
            optionToString={optToString}
            onChange={onIdentifierKindChange}
            isClearable={false}
            onBlur={onSeachFocus}
            onFocus={onSeachFocus}
            optionFormatter={optionFormatter}
            minWidth={200}
            style={
              searchTerms || selectedIdentifierKind
                ? { width: 200, color: colors.text }
                : { width: 200 }
            }
          />
        )}
      </ProfileSearchContainer>
      {isRefresh ? (
        <Button
          type="submit"
          kind="secondary"
          intent="action"
          disabled={isLoading}
          addOn="prefix"
          isLoading={isLoading}
          style={{ width: 92 }}
        >
          {!isLoading && (
            <React.Fragment>
              <Icon icon="reload" /> Refresh
            </React.Fragment>
          )}
        </Button>
      ) : (
        <Button
          type="submit"
          kind="primary"
          intent="action"
          disabled={!searchTerms || isLoading}
          addOn="prefix"
          isLoading={isLoading}
          style={{ width: 92 }}
        >
          {!isLoading && (
            <React.Fragment>
              <Icon icon="search" /> Search
            </React.Fragment>
          )}
        </Button>
      )}
    </FormGrid>
  )
}
