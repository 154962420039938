// @flow

import * as React from 'react'
import { ThemeProvider } from 'styled-components'

import { useIsCurrentUserAllowedTo } from 'components/_hooks'
import { PermissionButton, Button, IconDropdown, ButtonLink } from 'components/common/button'
import { Code } from 'components/common/code'
import { confirm } from 'components/common/confirm'
import { Icon } from 'components/common/svg-icon'
import { TableRow, TableCell, TableCellActions } from 'components/common/table'
import { Tooltip } from 'components/common/tooltip'
import { Input } from 'components/form'
import { Ellipsis } from 'components/styled/text'
import { colors } from 'components/styled/tokens'

import { generateUrl } from 'com.batch.common/router'
import { formatIdentifier } from 'com.batch.common/utils'

import { type AppRecord, type TestDeviceRecord } from 'com.batch.redux/_records'
import { type MessageType } from 'com.batch.redux/toaster'

type TestDeviceProps = {
  device: TestDeviceRecord,
  app: AppRecord,
  companyId: number,
  isEditing: boolean,
  onSend: (d: TestDeviceRecord) => Promise<any>,
  onSave: (d: TestDeviceRecord) => any,
  onDelete?: (d: TestDeviceRecord) => any,
  editLine: () => any,
  closeEditLine: () => any,
  showToast: MessageType => any,
  ...
}

const TestDevice = ({
  device,
  app,
  companyId,
  isEditing,
  onSend,
  onSave,
  onDelete,
  editLine,
  closeEditLine,
  showToast,
}: TestDeviceProps) => {
  const [sending, setSending] = React.useState<boolean>(false)
  const [saving, setSaving] = React.useState<boolean>(false)
  const [deleting, setDeleting] = React.useState<boolean>(false)
  const [localDevice, setLocalDevice] = React.useState<TestDeviceRecord>(device)
  const [errors, setErrors] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (!isEditing && localDevice.name !== device.name) {
      setLocalDevice(localDevice.set('name', device.name))
      if (errors) setErrors(false)
    }
  }, [isEditing, device.name, errors, localDevice])

  const save = React.useCallback(() => {
    setSaving(true)

    if (localDevice.name.length === 0) {
      setErrors(true)
      setSaving(false)
    } else {
      setErrors(false)
      onSave(localDevice).then(
        () => {
          setSaving(false)
          closeEditLine()
          showToast({ kind: 'success', message: 'Test device saved' })
        },
        () => setSaving(false)
      )
    }
  }, [localDevice, onSave, closeEditLine, showToast])

  const test = React.useCallback(() => {
    setSending(true)
    onSend(localDevice).then(
      () => {
        setSending(false)
      },
      () => setSending(false)
    )
  }, [localDevice, onSend])

  const remove = React.useCallback(() => {
    confirm({
      sensitive: true,
      message: <p>This device will be permanently removed from your registered devices list.</p>,
      title: 'Delete this device?',
    }).then(
      () => {
        setDeleting(true)
        if (onDelete) {
          onDelete(localDevice).then(
            () => {
              setDeleting(false)
              showToast({ kind: 'success', message: 'Test device deleted' })
            },
            () => setDeleting(false)
          )
        }
      },
      () => {}
    )
  }, [localDevice, onDelete, showToast])

  const isAllowed = useIsCurrentUserAllowedTo(['app', 'push:config:write'])

  const handleOnDeviceNameChange = React.useCallback(
    (evt: SyntheticInputEvent<HTMLInputElement>) => {
      setLocalDevice(localDevice.set('name', evt.target.value))
    },
    [localDevice]
  )

  const handleOnClickClose = React.useCallback(() => {
    closeEditLine()
  }, [closeEditLine])

  return (
    <TableRow isEditing={isEditing} as={!isEditing ? 'div' : 'form'}>
      {!isEditing ? (
        <TableCell>
          <Ellipsis>{localDevice.name}</Ellipsis>
        </TableCell>
      ) : (
        <ThemeProvider theme={{ size: 'small' }}>
          <TableCell kind="input">
            <Input
              type="text"
              placeholder="Device name"
              onChange={handleOnDeviceNameChange}
              value={localDevice.name}
              invalid={errors}
            />
          </TableCell>
        </ThemeProvider>
      )}

      <TableCell>{formatIdentifier(app.platform, localDevice.kind)}</TableCell>

      <TableCell noOverflow>
        <Ellipsis>
          <Code
            disabledCopy
            style={{ background: colors.fillBackground }}
            styleText={{ color: colors.text }}
          >
            {localDevice.value}
          </Code>
        </Ellipsis>
      </TableCell>

      <TableCellActions>
        {!isEditing ? (
          <React.Fragment>
            <PermissionButton
              kind="discreet"
              intent="neutral"
              onClick={editLine}
              isAllowed={isAllowed}
            >
              <Icon icon="edit" />
            </PermissionButton>

            <Tooltip tooltip="Send a test push" placement="top">
              <PermissionButton
                isLoading={sending}
                onClick={test}
                isAllowed={isAllowed}
                kind="discreet"
              >
                <Icon icon="send" />
              </PermissionButton>
            </Tooltip>

            <PermissionButton
              kind="discreet"
              intent="danger"
              isLoading={deleting}
              onClick={remove}
              isAllowed={isAllowed}
            >
              <Icon icon="delete" />
            </PermissionButton>
          </React.Fragment>
        ) : (
          <Button
            type="button"
            kind="secondary"
            intent="action"
            style={{ width: 50 }}
            isLoading={saving}
            disabled={localDevice.name === device.name}
            onClick={save}
          >
            Save
          </Button>
        )}
        <IconDropdown
          buttonProps={{ kind: 'inline', intent: 'neutral', type: 'button' }}
          placement="left-start"
        >
          {isEditing ? (
            <Button type="button" onClick={handleOnClickClose}>
              Close
            </Button>
          ) : (
            <PermissionButton onClick={editLine} isAllowed={isAllowed}>
              Edit
            </PermissionButton>
          )}

          <ButtonLink
            target="_blank"
            href={generateUrl('app_settings', {
              appId: app.id,
              companyId: companyId,
              activeTab: 'debug',
              [(localDevice.kind: string)]: localDevice.value,
            })}
          >
            Debug
          </ButtonLink>

          <PermissionButton
            intent="danger"
            isLoading={deleting}
            onClick={remove}
            isAllowed={isAllowed}
          >
            Delete
          </PermissionButton>
        </IconDropdown>
      </TableCellActions>
    </TableRow>
  )
}
export default (React.memo<TestDeviceProps>(TestDevice): React.AbstractComponent<TestDeviceProps>)
