// @flow

import { type DispatchExtraBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

import { type ParsePushOptinDistributionResponse } from 'com.batch/profilebase/infra/parses/parse-push-optin-distribution'
import { type PlatformFilterType } from 'com.batch/profilebase/infra/types/push-platform-filter.type'
import { type SubDistributionRecord } from 'com.batch/profilebase/models/sub-distribution.records'

export type FetchEmailSubDistributionAction = ReduxAction<'FETCH_EMAIL_SUB_DISTRIBUTION', null>
export type FetchEmailSubDistributionSuccessAction = ReduxAction<
  'FETCH_EMAIL_SUB_DISTRIBUTION_SUCCESS',
  SubDistributionRecord,
>
export type FetchEmailSubDistributionFailureAction = ReduxAction<
  'FETCH_EMAIL_SUB_DISTRIBUTION_FAILURE',
  Error,
>
export type FetchEmailSubDistributionActions =
  | FetchEmailSubDistributionAction
  | FetchEmailSubDistributionSuccessAction
  | FetchEmailSubDistributionFailureAction

export type FetchSmsSubDistributionAction = ReduxAction<'FETCH_SMS_SUB_DISTRIBUTION', null>
export type FetchSmsSubDistributionSuccessAction = ReduxAction<
  'FETCH_SMS_SUB_DISTRIBUTION_SUCCESS',
  SubDistributionRecord,
>
export type FetchSmsSubDistributionFailureAction = ReduxAction<
  'FETCH_SMS_SUB_DISTRIBUTION_FAILURE',
  Error,
>
export type FetchSmsSubDistributionActions =
  | FetchSmsSubDistributionAction
  | FetchSmsSubDistributionSuccessAction
  | FetchSmsSubDistributionFailureAction

export type FetchPushSubDistributionAction = ReduxAction<'FETCH_PUSH_SUB_DISTRIBUTION', null>
export type FetchPushSubDistributionSuccessAction = ReduxAction<
  'FETCH_PUSH_SUB_DISTRIBUTION_SUCCESS',
  ParsePushOptinDistributionResponse,
>
export type FetchPushSubDistributionFailureAction = ReduxAction<
  'FETCH_PUSH_SUB_DISTRIBUTION_FAILURE',
  Error,
>
export type FetchPushSubDistributionActions =
  | FetchPushSubDistributionAction
  | FetchPushSubDistributionSuccessAction
  | FetchPushSubDistributionFailureAction

export const fetchEmailSubDistribution = (
  subscriptionList: 'marketing' | 'transactional'
): DispatchExtraBoundFn<Promise<SubDistributionRecord>> => {
  return (dispatch, getState, { ucpDataService }) => {
    const { projectKey } = currentProjectSelector(getState())
    return promiseActionCreator({
      dispatch,
      promise: ucpDataService.fetchEmailSubDistribution({ projectKey, subscriptionList }),
      actionName: 'FETCH_EMAIL_SUB_DISTRIBUTION',
    })
  }
}

export const fetchSmsSubDistribution = (
  subscriptionList: 'marketing' | 'transactional'
): DispatchExtraBoundFn<Promise<SubDistributionRecord>> => {
  return (dispatch, getState, { ucpDataService }) => {
    const { projectKey } = currentProjectSelector(getState())
    return promiseActionCreator({
      dispatch,
      promise: ucpDataService.fetchSmsSubDistribution({ projectKey, subscriptionList }),
      actionName: 'FETCH_SMS_SUB_DISTRIBUTION',
    })
  }
}

export const fetchPushSubDistribution = (
  platform: PlatformFilterType
): DispatchExtraBoundFn<Promise<ParsePushOptinDistributionResponse>> => {
  return (dispatch, getState, { ucpDataService }) => {
    const { projectKey } = currentProjectSelector(getState())
    return promiseActionCreator({
      dispatch,
      promise: ucpDataService.fetchPushOptinDistribution({ projectKey, platform }),
      actionName: 'FETCH_PUSH_SUB_DISTRIBUTION',
    })
  }
}
