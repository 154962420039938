// @flow

import Immutable, { type List } from 'immutable'
import request from 'superagent-interface-promise'

import { generateUrl } from 'com.batch.common/router'

import { ExportJobFactory, type DataExportJobRecord, type ExportJobRecord } from './console.records'
import { normalizeExportJob } from './export.api'

import { type AppRecord, type DispatchOnlyBoundFn } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'

type FetchExportJobsAction = {
  type: 'FETCH_EXPORT_JOBS',
  payload: null,
  ...
}
type FetchExportJobsSuccessAction = {
  type: 'FETCH_EXPORT_JOBS_SUCCESS',
  payload: List<DataExportJobRecord>,
  ...
}
type FetchExportJobsFailureAction = {
  type: 'FETCH_EXPORT_JOBS_FAILURE',
  payload: null,
  ...
}

type CreateExportJobAction = {
  type: 'CREATE_EXPORT_JOBS',
  payload: null,
  ...
}
type CreateExportJobSuccessAction = {
  type: 'CREATE_EXPORT_JOBS_SUCCESS',
  payload: { request_id: string, ... },
  ...
}
type CreateExportJobFailureAction = {
  type: 'CREATE_EXPORT_JOBS_FAILURE',
  payload: null,
  ...
}

type allowedExportJobsActions =
  | FetchExportJobsAction
  | FetchExportJobsSuccessAction
  | FetchExportJobsFailureAction
  | CreateExportJobAction
  | CreateExportJobSuccessAction
  | CreateExportJobFailureAction

// ====================== ACTIONS
export const fetchExportJobs =
  ({ app }: { app: AppRecord, ... }): DispatchOnlyBoundFn<Promise<List<DataExportJobRecord>>> =>
  dispatch => {
    return promiseActionCreator({
      actionName: 'FETCH_EXPORT_JOBS',
      dispatch,
      promise: request.get(generateUrl('console_api_exports_jobs', { appId: app.id })).then(
        ({ body }) => {
          return new Immutable.List().push(...body.map(normalizeExportJob))
        },
        err => console.log(err)
      ),
      payload: null,
    })
  }

export const createExportJob =
  ({
    app,
    exportJob,
  }: {
    app: AppRecord,
    exportJob: DataExportJobRecord,
    ...
  }): DispatchOnlyBoundFn<Promise<{ request_id: string, ... }>> =>
  dispatch => {
    let from = exportJob.from ? exportJob.from.format('YYYY-MM-DDTHH:mm:ss') : null
    let to = exportJob.to
      ? exportJob.to === 'now'
        ? exportJob.to
        : exportJob.to.format('YYYY-MM-DDTHH:mm:ss')
      : null

    let newExportJobDatas = { ...exportJob.toJS(), from: from, to: to }

    return promiseActionCreator({
      actionName: 'CREATE_EXPORT_JOBS',
      dispatch,
      promise: request
        .post(generateUrl('console_api_export_job_create', { appId: app.id }), newExportJobDatas)
        .then(
          ({ body }) => body,
          err => {
            throw err
          }
        ),
      payload: null,
    })
  }
// ====================== REDUCER
export const exportReducer = (
  state: ExportJobRecord = ExportJobFactory(),
  action: allowedExportJobsActions
): ExportJobRecord => {
  switch (action.type) {
    case 'CREATE_EXPORT_JOBS':
      return state.set('loading', true)

    case 'CREATE_EXPORT_JOBS_SUCCESS':
      return state.set('loading', false)

    case 'FETCH_EXPORT_JOBS_SUCCESS':
      return state.set('entities', action.payload)
  }
  return state
}
