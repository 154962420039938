// @flow
import Immutable from 'immutable'

import { MessageStateFactory, type MessageStateRecord } from './message.state'

import {
  EmailMessageFactory,
  EmailContentFactory,
  PushContentFactory,
  PushContentTemplatesFactory,
  PushMessageFactory,
  PushMessageRawFactory,
  AndroidPushMessageRawFactory,
  PushAndroidContentTemplatesFactory,
  SmsMessageFactory,
  SmsContentFactory,
} from '../models/message.records'
import { type RemoveEmailContentAction } from '../usecases/remove-email-content'
import { type FetchSenderIdentitiesSuccessAction } from 'com.batch.redux/corelogic/usecases/sender-identity/fetch-sender-identities'
import { removeDeletedSenderIdentitiesFromEmailContent } from 'com.batch.redux/corelogic/usecases/sender-identity/remove-deleted-sender-identities-from-email-content'

import {
  type EmptyAllEmailSendersAction,
  type saveDragDropHtmlActions,
  type saveDragDropOptimizedTemplateActions,
  type UpdateEmailInfoAction,
  type UpdateEmailSenderAction,
} from 'com.batch/email/usecases/update-content'
import { type UpdateEmailContentAction } from 'com.batch/email/usecases/update-email-content'
import { type UpdateEmailTemplateAction } from 'com.batch/email/usecases/update-email-template'
import { type SetIsEmailUploadingAction } from 'com.batch/email/usecases/upload-email'
import {
  type AddLanguageAction,
  type ClearLanguagesAction,
  type RemoveLanguageAction,
  type SetActiveLanguageAction,
  type ToggleMultilanguageAction,
} from 'com.batch/message/usecases/multilanguage'
import { type UpdateAllContentAction } from 'com.batch/message/usecases/update-all-content'
import { type InitFormAction } from 'com.batch/orchestration/usecases/init-form'
import { type InsertNodeAfterAction } from 'com.batch/orchestration-journey/usecases/insert-node-after'
import { type SetEditingNodeIdAction } from 'com.batch/orchestration-journey/usecases/set-editing-node-id'
import {
  type UpdatePushMessageContentAction,
  type UpdatePushMessageRecordAction,
} from 'com.batch/push/usecases/update-push-content'
import { type UpdatePushTemplateAction } from 'com.batch/push/usecases/update-push-template'
import { type UpdateSmsContentAction } from 'com.batch/sms/usecases/update-sms-content'
import { type UpdateSmsTemplateAction } from 'com.batch/sms/usecases/update-sms-template'
import { STATUS } from 'constants/common'

const messageUpdated = (id: string, state: MessageStateRecord): MessageStateRecord =>
  state.set('updatedMessageIds', state.updatedMessageIds.add(id))

type MessageActions =
  | UpdateEmailContentAction
  | UpdateEmailInfoAction
  | UpdateSmsContentAction
  | UpdateSmsTemplateAction
  | UpdateEmailTemplateAction
  | UpdateEmailSenderAction
  | EmptyAllEmailSendersAction
  | UpdateAllContentAction
  | InitFormAction
  | SetIsEmailUploadingAction
  | saveDragDropHtmlActions
  | saveDragDropOptimizedTemplateActions
  | FetchSenderIdentitiesSuccessAction
  | RemoveEmailContentAction
  | UpdatePushMessageRecordAction
  | UpdatePushMessageContentAction
  | UpdatePushTemplateAction
  | AddLanguageAction
  | RemoveLanguageAction
  | ClearLanguagesAction
  | SetActiveLanguageAction
  | ToggleMultilanguageAction
  | InsertNodeAfterAction
  | SetEditingNodeIdAction
export const messageReducer = (
  state: MessageStateRecord = MessageStateFactory(),
  action: MessageActions
): MessageStateRecord => {
  switch (action.type) {
    case 'INIT_FORM': {
      switch (action.payload.channel) {
        case 'email':
          return MessageStateFactory({
            email: Immutable.Map({
              [action.payload.messageTypedId]: EmailMessageFactory({
                localizedContent: Immutable.OrderedMap({ default: EmailContentFactory() }),
              }),
            }),
          })
        case 'sms':
          return MessageStateFactory({
            sms: Immutable.Map({
              [action.payload.messageTypedId]: SmsMessageFactory({
                localizedContent: Immutable.OrderedMap({ default: SmsContentFactory() }),
              }),
            }),
          })
        case 'push':
          return MessageStateFactory({
            push: Immutable.Map({
              [action.payload.messageTypedId]: PushMessageFactory({
                localizedContent: Immutable.OrderedMap({ default: PushContentFactory() }),
              }),
            }),
          })
      }

      return MessageStateFactory()
    }
    case 'ADD_LANGUAGE': {
      const newState = state.set('previewLanguage', action.payload.lang)
      switch (action.payload.channel) {
        case 'push':
          return messageUpdated(
            action.payload.messageId,
            newState.updateIn(
              ['push', action.payload.messageId, 'localizedContent'],
              localizedContent => {
                const language = localizedContent.get(action.payload.lang)
                if (language) return localizedContent

                const attachmentKind = localizedContent.getIn(
                  ['default', 'content', 'attachmentKind'],
                  'image'
                )
                const pushPicture = localizedContent.getIn(
                  ['default', 'content', 'pushPicture'],
                  ''
                )
                const androidIcon = localizedContent.getIn(
                  ['default', 'androidContent', 'androidIcon'],
                  ''
                )

                return localizedContent.set(
                  action.payload.lang,
                  PushContentFactory({
                    content: PushMessageRawFactory({
                      attachmentKind,
                      pushPicture,
                      templates: PushContentTemplatesFactory({
                        pushPicture,
                      }),
                    }),
                    androidContent: AndroidPushMessageRawFactory({
                      androidIcon,
                      templates: PushAndroidContentTemplatesFactory({
                        androidIcon,
                      }),
                    }),
                  })
                )
              }
            )
          )
        case 'email':
          return messageUpdated(
            action.payload.messageId,
            newState.updateIn(
              ['email', action.payload.messageId, 'localizedContent'],
              localizedContent => {
                const language = localizedContent.get(action.payload.lang)
                if (language) return localizedContent

                const defaultEmail = localizedContent.get('default', EmailContentFactory())
                return localizedContent.set(action.payload.lang, defaultEmail)
              }
            )
          )
        case 'sms':
          return messageUpdated(
            action.payload.messageId,
            newState.updateIn(
              ['sms', action.payload.messageId, 'localizedContent'],
              localizedContent => {
                const language = localizedContent.get(action.payload.lang)
                if (language) return localizedContent

                return localizedContent.set(action.payload.lang, SmsContentFactory())
              }
            )
          )
        default:
          return state
      }
    }
    case 'REMOVE_LANGUAGE': {
      const path = [action.payload.messageId, 'localizedContent', action.payload.lang]
      switch (action.payload.channel) {
        case 'push':
          return messageUpdated(
            action.payload.messageId,
            state.set('previewLanguage', 'default').deleteIn(['push', ...path])
          )
        case 'email':
          return messageUpdated(
            action.payload.messageId,
            state.set('previewLanguage', 'default').deleteIn(['email', ...path])
          )
        case 'sms':
          return messageUpdated(
            action.payload.messageId,
            state.set('previewLanguage', 'default').deleteIn(['sms', ...path])
          )
        default:
          return state
      }
    }
    case 'CLEAR_LANGUAGES': {
      switch (action.payload.channel) {
        case 'push':
          return messageUpdated(
            action.payload.messageId,
            state
              .updateIn(['push', action.payload.messageId, 'localizedContent'], localizedContent =>
                localizedContent.filter((_, lang) => lang === 'default')
              )
              .set('previewLanguage', 'default')
          )
        case 'email':
          return messageUpdated(
            action.payload.messageId,
            state.updateIn(
              ['email', action.payload.messageId, 'localizedContent'],
              localizedContent => localizedContent.filter((_, lang) => lang === 'default')
            )
          )
        case 'sms':
          return messageUpdated(
            action.payload.messageId,
            state.updateIn(
              ['sms', action.payload.messageId, 'localizedContent'],
              localizedContent => localizedContent.filter((_, lang) => lang === 'default')
            )
          )
        default:
          return state
      }
    }
    case 'SET_ACTIVE_LANGUAGE':
      return state.set('previewLanguage', action.payload)
    case 'TOGGLE_MULTILANGUAGE': {
      switch (action.payload.channel) {
        case 'push': {
          return messageUpdated(
            action.payload.messageId,
            state
              .set('previewLanguage', 'default')
              .updateIn(['push', action.payload.messageId], pushMessage => {
                const multilanguageEnabled = pushMessage.get('multilanguageEnabled', false)
                return pushMessage.set('multilanguageEnabled', !multilanguageEnabled)
              })
          )
        }
        case 'email': {
          return messageUpdated(
            action.payload.messageId,
            state
              .set('previewLanguage', 'default')
              .updateIn(['email', action.payload.messageId], emailMessage => {
                const multilanguageEnabled = emailMessage.get('multilanguageEnabled', false)
                return emailMessage.set('multilanguageEnabled', !multilanguageEnabled)
              })
          )
        }
        case 'sms': {
          return messageUpdated(
            action.payload.messageId,
            state
              .set('previewLanguage', 'default')
              .updateIn(['sms', action.payload.messageId], smsMessage => {
                const multilanguageEnabled = smsMessage.get('multilanguageEnabled', false)
                return smsMessage.set('multilanguageEnabled', !multilanguageEnabled)
              })
          )
        }
        default:
          return state
      }
    }
    case 'UPDATE_ALL_CONTENT':
      return state
        .set('email', action.payload.email)
        .set('sms', action.payload.sms)
        .set('push', action.payload.push)
    case 'UPDATE_SMS_CONTENT':
      return messageUpdated(
        action.payload.messageId,
        state.updateIn(
          ['sms', action.payload.messageId, 'localizedContent', action.payload.lang],
          smsContent => {
            return (smsContent || SmsContentFactory()).set(
              action.payload.field,
              action.payload.value
            )
          }
        )
      )
    case 'UPDATE_SMS_TEMPLATE':
      return state.updateIn(
        ['sms', action.payload.messageId, 'localizedContent', action.payload.lang],
        smsContent => {
          return (smsContent || SmsContentFactory()).setIn(
            ['templates', 'smsMessage'],
            action.payload.template
          )
        }
      )
    case 'UPDATE_EMAIL_SENDER':
      if (!state.email.has(action.payload.messageId)) {
        return state.setIn(
          ['email', action.payload.messageId],
          EmailMessageFactory({
            localizedContent: Immutable.OrderedMap([
              [
                action.payload.lang,
                EmailContentFactory({
                  senderIdentityId: action.payload.senderIdentityId,
                  fromEmail: action.payload.fromEmail,
                  name: action.payload.name,
                  isPristine: false,
                }),
              ],
            ]),
          })
        )
      }
      return messageUpdated(
        action.payload.messageId,
        state.updateIn(
          ['email', action.payload.messageId, 'localizedContent', action.payload.lang],
          content =>
            content
              .set('senderIdentityId', action.payload.senderIdentityId)
              .set('fromEmail', action.payload.fromEmail)
              .set('name', action.payload.name)
              .set('isPristine', false)
        )
      )

    case 'FETCH_SENDER_IDENTITIES_SUCCESS': {
      /*
        This is only usefull when we fetch orchestration BEFORE sender ids resolve.
        it's not perfect : we won't show toast or update incomplete flags on triggers nodes, but
        deleted sender ids is not really an issue and the XHR call seems to always resolve before
      */

      const [updated, email] = removeDeletedSenderIdentitiesFromEmailContent(
        state.email,
        action.payload
      )
      if (updated.length === 0) return state
      return state.set('email', email)
    }
    case 'UPDATE_EMAIL_INFO':
      return messageUpdated(
        action.payload.messageId,
        state.updateIn(
          ['email', action.payload.messageId, 'localizedContent', action.payload.lang],
          email => {
            return (email || EmailContentFactory())
              .set(action.payload.field, action.payload.value)
              .set('isPristine', false)
          }
        )
      )
    case 'UPDATE_EMAIL_CONTENT':
      return messageUpdated(
        action.payload.messageId,
        state.updateIn(
          ['email', action.payload.messageId, 'localizedContent', action.payload.lang],
          email => {
            let updatedContent = (email || EmailContentFactory())
              .set(action.payload.field, action.payload.value)
              .set('isPristine', false)

            if (action.payload.htmlEditorConfig || action.payload.htmlEditorConfig === null) {
              updatedContent = updatedContent.set(
                'htmlEditorConfig',
                action.payload.htmlEditorConfig
              )
            }

            return updatedContent
          }
        )
      )
    case 'UPDATE_EMAIL_TEMPLATE':
      return state.updateIn(
        ['email', action.payload.messageId, 'localizedContent', action.payload.lang],
        email => {
          let updatedContent = email || EmailContentFactory()
          if (action.payload.html !== undefined) {
            updatedContent = updatedContent.setIn(['templates', 'html'], action.payload.html)
          }
          if (action.payload.subject !== undefined) {
            updatedContent = updatedContent.setIn(['templates', 'subject'], action.payload.subject)
          }
          if (action.payload.replyTo !== undefined) {
            updatedContent = updatedContent.setIn(['templates', 'replyTo'], action.payload.replyTo)
          }
          return updatedContent
        }
      )
    case 'SET_IS_EMAIL_UPLOADING':
      return state.setIn(
        ['email', action.payload.messageId, 'localizedContent', action.payload.lang],
        state.email
          .getIn(
            [action.payload.messageId, 'localizedContent', action.payload.lang],
            EmailContentFactory()
          )
          .set('isEmailUploading', action.payload.isEmailUploading)
      )
    case 'UPDATE_DRAG_DROP_OPTIMIZED_HTML_CONTENT':
    case 'UPDATE_DRAG_DROP_HTML_CONTENT':
      return state.setIn(
        ['email', action.payload.messageId, 'localizedContent', action.payload.lang],
        state.email
          .getIn(
            [action.payload.messageId, 'localizedContent', action.payload.lang],
            EmailContentFactory()
          )
          .set('loadingState', STATUS.LOADING)
      )
    case 'UPDATE_DRAG_DROP_OPTIMIZED_HTML_CONTENT_SUCCESS':
    case 'UPDATE_DRAG_DROP_HTML_CONTENT_SUCCESS':
      return state.setIn(
        ['email', action.payload.messageId, 'localizedContent', action.payload.lang],
        state.email
          .getIn(
            [action.payload.messageId, 'localizedContent', action.payload.lang],
            EmailContentFactory()
          )
          .set('loadingState', STATUS.LOADED)
          .set('isPristine', false)
      )
    case 'UPDATE_DRAG_DROP_OPTIMIZED_HTML_CONTENT_FAILURE':
    case 'UPDATE_DRAG_DROP_HTML_CONTENT_FAILURE':
      return state.setIn(
        ['email', action.payload.messageId, 'localizedContent', action.payload.lang],
        state.email
          .getIn(
            [action.payload.messageId, 'localizedContent', action.payload.lang],
            EmailContentFactory()
          )
          .set('loadingState', STATUS.ERROR)
      )
    case 'REMOVE_EMAIL_CONTENT':
      return state.set('email', state.email.delete(action.payload))
    case 'EMPTY_ALL_EMAIL_SENDERS':
      return state.set(
        'email',
        state.email.map(message => {
          const cleanedContent = message.localizedContent.map(langContent =>
            langContent.merge({ fromEmail: null, senderIdentityId: undefined, name: null })
          )
          return message.set('localizedContent', cleanedContent)
        })
      )
    case 'UPDATE_PUSH_MESSAGE_CONTENT':
      return messageUpdated(
        action.payload.messageId,
        state.updateIn(
          ['push', action.payload.messageId, 'localizedContent', action.payload.lang],
          pushContent => {
            const updatedContent = pushContent || PushContentFactory()
            let { content, androidContent } = updatedContent
            const { field } = action.payload
            switch (field) {
              case 'pushTitle':
              case 'pushBody':
              case 'pushPicture': {
                content = content.set(field, action.payload.value)
                return updatedContent.set('content', content)
              }
              case 'androidIcon': {
                androidContent = androidContent.set(field, action.payload.value)
                return updatedContent.set('androidContent', androidContent)
              }
              default:
                return updatedContent
            }
          }
        )
      )
    case 'UPDATE_PUSH_MESSAGE_RECORD':
      return messageUpdated(
        action.payload.messageId,
        state.updateIn(['push', action.payload.messageId], pushMessage => {
          let updatedMessage = pushMessage || PushMessageFactory()
          if (action.payload.content && action.payload.lang) {
            updatedMessage = updatedMessage.setIn(
              ['localizedContent', action.payload.lang],
              action.payload.content
            )
          }
          if (action.payload.settings) {
            updatedMessage = updatedMessage.set('settings', action.payload.settings)
          }
          return updatedMessage
        })
      )
    case 'UPDATE_PUSH_TEMPLATE':
      return state.updateIn(
        ['push', action.payload.messageId, 'localizedContent', action.payload.lang],
        pushContent => {
          let updatedContent = pushContent || PushContentFactory()
          const { content, androidContent } = action.payload
          if (content?.pushTitle !== undefined) {
            updatedContent = updatedContent.setIn(
              ['content', 'templates', 'pushTitle'],
              content.pushTitle
            )
          }
          if (content?.pushBody !== undefined) {
            updatedContent = updatedContent.setIn(
              ['content', 'templates', 'pushBody'],
              content.pushBody
            )
          }
          if (content?.pushPicture !== undefined) {
            updatedContent = updatedContent.setIn(
              ['content', 'templates', 'pushPicture'],
              content.pushPicture
            )
          }
          if (androidContent?.androidIcon !== undefined) {
            updatedContent = updatedContent.setIn(
              ['androidContent', 'templates', 'androidIcon'],
              androidContent.androidIcon
            )
          }
          return updatedContent
        }
      )
    case 'SET_EDITING_NODE_ID':
    case 'INSERT_NODE_AFTER': {
      if (state.previewLanguage !== 'default') return state.set('previewLanguage', 'default')
      return state
    }
    default:
      return state
  }
}
