// @flow

import Immutable from 'immutable'
import * as React from 'react'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Switch } from 'components/common/button/switch'
import { Grid } from 'components/common/grid'
import { Tooltip } from 'components/common/tooltip'
import { InputWrapper, TimePicker, Checkbox } from 'components/form'
import { Radio } from 'components/form/fields/radio'
import { schemes } from 'components/styled/tokens'
import * as colors from 'components/styled/tokens/colors'
import { HorizontalDivider } from 'components/styled/utils'

import { convertNumberToDayOfWeek } from 'com.batch.common/utils'

import {
  journeySettingsSelector,
  quietTimesSelector,
} from 'com.batch/orchestration-journey/models/journey.selectors'
import { QuietTimesSummary } from 'com.batch/orchestration-journey/ui/components/quiet-time-summary'
import { updateSettings } from 'com.batch/orchestration-journey/usecases/update-settings'

const dayValues = [1, 2, 3, 4, 5, 6, 0]

export const QuietTimesBlock = (): React.Node => {
  const journeySettings = useSelector(journeySettingsSelector)
  const { hasQuietHoursTimePeriodDisabled, quietTimes, quietDaysOfWeek } =
    useSelector(quietTimesSelector)
  const [hasQuietDays, setHasQuietDays] = React.useState(quietDaysOfWeek.size > 0)
  const dispatch = useDispatch()

  const hasQuietHoursTimePeriod = !hasQuietHoursTimePeriodDisabled

  const noMessagesWillBeReceived = useMemo(() => {
    return (
      quietDaysOfWeek.size === 7 ||
      (quietTimes.startHour === 0 &&
        quietTimes.endHour === 0 &&
        quietTimes.startMin === 0 &&
        quietTimes.endMin === 0)
    )
  }, [quietTimes, quietDaysOfWeek])

  const generateFallbackSummary = useCallback(() => {
    return (
      <React.Fragment>
        If you send a message during this period, the user will{' '}
        {quietTimes.behavior === 'wait' ? (
          <b>receive it at the next available time</b>
        ) : (
          <b>not receive it and continue the automation</b>
        )}
        .
      </React.Fragment>
    )
  }, [quietTimes])

  const handleQuietTimesPeriodSwitchChange = React.useCallback(
    (active: boolean) => {
      dispatch(
        updateSettings(
          journeySettings
            .set('hasQuietTimes', active)
            .setIn(['quietTimes', 'quietDaysOfWeek'], Immutable.Set())
            .setIn(['quietTimes', 'quietHoursTimePeriodDisabled'], !active)
        )
      )
      if (!active) {
        setHasQuietDays(false)
      }
    },
    [dispatch, journeySettings]
  )

  const handleQuietDaysSwitchChange = React.useCallback(
    (active: boolean) => {
      setHasQuietDays(active)
      dispatch(
        updateSettings(
          journeySettings
            .set('hasQuietTimes', active || hasQuietHoursTimePeriod)
            .setIn(['quietTimes', 'quietDaysOfWeek'], Immutable.Set())
        )
      )
    },
    [dispatch, journeySettings, hasQuietHoursTimePeriod]
  )

  const handleQuietDaysChange = React.useCallback(
    (day: number) => () => {
      const newQuietDaysOfWeek = quietDaysOfWeek.has(day)
        ? quietDaysOfWeek.delete(day)
        : quietDaysOfWeek.add(day)

      dispatch(
        updateSettings(
          journeySettings
            .set('hasQuietTimes', newQuietDaysOfWeek.size > 0 || hasQuietHoursTimePeriod)
            .setIn(['quietTimes', 'quietDaysOfWeek'], newQuietDaysOfWeek)
        )
      )
    },
    [dispatch, journeySettings, quietDaysOfWeek, hasQuietHoursTimePeriod]
  )

  const handleBehavior = React.useCallback(
    behavior => () => {
      dispatch(updateSettings(journeySettings.setIn(['quietTimes', 'behavior'], behavior)))
    },
    [dispatch, journeySettings]
  )

  const createOnChangeHandler = React.useCallback(
    field => {
      return value => {
        dispatch(updateSettings(journeySettings.setIn(['quietTimes', field], value)))
      }
    },
    [dispatch, journeySettings]
  )

  return (
    <div style={{ padding: '20px 20px', height: 'fit-content' }}>
      <div style={{ marginBottom: '8px' }}>
        <Switch isActive={hasQuietHoursTimePeriod} onChange={handleQuietTimesPeriodSwitchChange}>
          <span>Quiet hours</span>
        </Switch>
        {!hasQuietHoursTimePeriod && (
          <body style={{ marginTop: '8px', color: colors.textLight }}>
            A time during the day when the user won’t receive any messages.
          </body>
        )}
      </div>

      {hasQuietHoursTimePeriod && (
        <Grid
          template="140px 10px 140px"
          style={{ marginTop: '8px', marginBottom: '28px', columnGap: '8px' }}
        >
          <TimePicker
            onHoursChange={createOnChangeHandler('startHour')}
            onMinutesChange={createOnChangeHandler('startMin')}
            value={[quietTimes.startHour, quietTimes.startMin]}
          />
          to
          <TimePicker
            onHoursChange={createOnChangeHandler('endHour')}
            onMinutesChange={createOnChangeHandler('endMin')}
            value={[quietTimes.endHour, quietTimes.endMin]}
          />
        </Grid>
      )}
      <div style={{ marginBottom: '8px', marginTop: '28px' }}>
        <Tooltip
          tooltip={'Enable quiet hours to activate quiet days'}
          isTooltipEmpty={hasQuietHoursTimePeriod}
          offset={[-340, 6]}
          placement="bottom"
          arrow={false}
        >
          <div>
            <Switch
              isActive={hasQuietDays}
              onChange={handleQuietDaysSwitchChange}
              disabled={!hasQuietHoursTimePeriod}
            >
              <span>Quiet days</span>
            </Switch>
          </div>
        </Tooltip>
        {!hasQuietDays && (
          <body style={{ marginTop: '8px', color: colors.textLight, height: '20px' }}>
            One or several full days during which the user won’t receive any messages.
          </body>
        )}
      </div>
      {hasQuietDays && (
        <Grid
          template="95px 98px 119px 104px 83px 101px 91px"
          style={{ marginTop: '8px', height: '20px' }}
          gap={10}
        >
          {dayValues.map(dayValue => (
            <Checkbox
              key={dayValue}
              checked={quietDaysOfWeek.has(dayValue)}
              onChange={handleQuietDaysChange(dayValue)}
              label={convertNumberToDayOfWeek(dayValue)}
            />
          ))}
        </Grid>
      )}
      {(hasQuietHoursTimePeriod || hasQuietDays) && (
        <HorizontalDivider style={{ marginTop: '28px' }} />
      )}
      {(hasQuietHoursTimePeriod || hasQuietDays) && (
        <div style={{ marginTop: 28, height: 'fit-content' }}>
          <p
            style={{
              fontSize: 14,
              fontWeight: 500,
            }}
          >
            Fallback strategy
          </p>

          <InputWrapper>
            <div style={{ marginBottom: 10, marginTop: 8 }}>
              <Radio
                checked={quietTimes.behavior === 'wait'}
                label="Send at the next available time"
                onChange={handleBehavior('wait')}
              />
            </div>
            <div>
              <Radio
                checked={quietTimes.behavior === 'skip'}
                label="Skip the message step and continue"
                onChange={handleBehavior('skip')}
              />
            </div>
          </InputWrapper>
        </div>
      )}
      {(hasQuietHoursTimePeriod || quietDaysOfWeek.size > 0) && !noMessagesWillBeReceived && (
        <div
          style={{
            backgroundColor: schemes.grayscale[10],
            borderRadius: '8px',
            marginTop: '28px',
            padding: '12px',
            color: colors.textLight,
          }}
        >
          <QuietTimesSummary quietTimes={quietTimes} />
          <br />
          {generateFallbackSummary()}
        </div>
      )}
      {noMessagesWillBeReceived && (
        <div
          style={{
            backgroundColor: colors.opacifyWarning,
            borderRadius: '8px',
            marginTop: '28px',
            padding: '12px',
            color: colors.textWarning,
            fontWeight: 500,
          }}
        >
          The users will not receive any messages.
        </div>
      )}
    </div>
  )
}
