// @flow

import * as React from 'react'

import { generateListOfDays } from 'com.batch.common/utils'

import { type QuietTimesRecord } from 'com.batch/orchestration-journey/models/journey.records'

const formatHourMinute = (hour: number, minute: number): string => {
  return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
}

export const QuietTimesSummary = ({ quietTimes }: { quietTimes: QuietTimesRecord }): React.Node => {
  const formattedQuietHoursStart = React.useMemo(
    () => formatHourMinute(quietTimes.startHour, quietTimes.startMin),
    [quietTimes]
  )
  const formattedQuietHoursEnd = React.useMemo(
    () => formatHourMinute(quietTimes.endHour, quietTimes.endMin),
    [quietTimes]
  )

  if (!quietTimes.quietHoursTimePeriodDisabled && quietTimes.quietDaysOfWeek.size === 0) {
    return (
      <React.Fragment>
        Users will not receive any messages from <b>{formattedQuietHoursStart}</b> to{' '}
        <b>{formattedQuietHoursEnd}</b>.
      </React.Fragment>
    )
  }

  if (quietTimes.quietHoursTimePeriodDisabled && quietTimes.quietDaysOfWeek.size > 0) {
    return (
      <React.Fragment>
        Users will not receive any messages on{' '}
        <b>{generateListOfDays(quietTimes.quietDaysOfWeek)}.</b>
      </React.Fragment>
    )
  }

  if (!quietTimes.quietHoursTimePeriodDisabled && quietTimes.quietDaysOfWeek.size > 0) {
    return (
      <React.Fragment>
        Users will not receive any messages from <b>{formattedQuietHoursStart}</b> to{' '}
        <b>{formattedQuietHoursEnd}</b> nor on{' '}
        <b>{generateListOfDays(quietTimes.quietDaysOfWeek)}.</b>
      </React.Fragment>
    )
  }

  return <div />
}
