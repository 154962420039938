// @flow

import { request } from 'com.batch.common/request'
import { generateUrl } from 'com.batch.common/router/router'

import { fetchPaginatedEntities } from 'com.batch/shared/infra/fetch-paginated-entities'
import { generateProjectKeyBlockRequest } from 'com.batch/shared/infra/generate-block-request'

export type ListSegmentResponse = {
  segments: Array<SegmentResponse>,
  countTotal: number,
  countFiltered: number,
}

export type SegmentResponse = {
  name: string,
  displayName: string,
  campaignCount: number,
  campaignRunningCount: number,
  automationCount: number,
  automationRunningCount: number,
  query: string,
  deletedAt: string,
}

export type GetSegmentResponse = {
  segments: Array<{
    name: string,
    displayName: string,
    query: string,
    deletedAt: string,
  }>,
}

export type CreateSegmentResponse = {}

export type CreateSegmentRequest = {
  name: string,
  displayName: string,
  query: string,
}

export type UpdateSegmentResponse = {}

export type UpdateSegmentRequest = {
  name: string,
  displayName: string,
  query: string,
}

type SortField =
  | 'SORT_FIELD_UNSPECIFIED'
  | 'SORT_FIELD_NAME'
  | 'SORT_FIELD_DISPLAY_NAME'
  | 'SORT_FIELD_UPDATED_AT'
  | 'SORT_FIELD_CREATED_AT'

type SortDirection = 'SORT_DIRECTION_UNSPECIFIED' | 'SORT_DIRECTION_ASC' | 'SORT_DIRECTION_DESC'

type ListSegmentRequest = {
  filter: string,
  sortDirection: SortDirection,
  sortField: SortField,
}

export const segmentsService = {
  fetchSegmentList: async ({
    projectKey,
    page,
    nbPerPage,
    search = '',
    sortDirection,
    sortField,
    total,
    totalMatching,
  }: {
    projectKey: string,
    page: number,
    nbPerPage: number,
    search: string,
    sortDirection: SortDirection,
    sortField: SortField,
    total: ?number,
    totalMatching: ?number,
  }): Promise<ListSegmentResponse> => {
    const fetcher = async ({ page, pageSize }: { page: number, pageSize: number }) => {
      const listSegmentRequest: ListSegmentRequest = {
        filter: search,
        sortDirection,
        sortField,
      }
      const response = await request.post<ListSegmentResponse>(
        generateUrl('api_grpc_segment_service', {
          methodName: 'List',
        }),
        {
          ...generateProjectKeyBlockRequest(projectKey),
          ...listSegmentRequest,
          page,
          pageSize,
        }
      )

      return {
        entities: response.segments,
        total: response.countTotal,
        totalMatching: response.countFiltered,
      }
    }

    const {
      entities,
      total: totalResult,
      totalMatching: totalMatchingResult,
    } = await fetchPaginatedEntities<SegmentResponse>({
      page,
      pageSize: nbPerPage,
      total,
      totalMatching,
      fetcher,
    })

    return {
      segments: entities,
      countTotal: totalResult ?? 0,
      countFiltered: totalMatchingResult ?? 0,
    }
  },
  createSegment: async ({
    segmentRequest,
    projectKey,
  }: {
    segmentRequest: CreateSegmentRequest,
    projectKey: string,
  }): Promise<void> => {
    return request
      .post<void>(generateUrl('api_grpc_segment_service', { methodName: 'Create' }), {
        ...segmentRequest,
        ...generateProjectKeyBlockRequest(projectKey),
      })
      .catch(error => {
        const errors = error.error.errors.map((error: { message: string }) => error.message)
        throw new Error(`Error while creating segment for project ${projectKey}: ${errors}`)
      })
  },
  updateSegment: async ({
    segmentRequest,
    projectKey,
  }: {
    segmentRequest: UpdateSegmentRequest,
    projectKey: string,
  }): Promise<void> => {
    return request
      .post<void>(generateUrl('api_grpc_segment_service', { methodName: 'Update' }), {
        ...segmentRequest,
        ...generateProjectKeyBlockRequest(projectKey),
      })
      .catch(error => {
        throw new Error(`Error while updating segment for project ${projectKey}: ${error.message}`)
      })
  },
  deleteSegment: async ({
    segmentName,
    projectKey,
  }: {
    segmentName: string,
    projectKey: string,
  }): Promise<void> => {
    return request.post<void>(generateUrl('api_grpc_segment_service', { methodName: 'Delete' }), {
      name: segmentName,
      ...generateProjectKeyBlockRequest(projectKey),
    })
  },
  getSegmentsByName: async ({
    projectKey,
    names,
  }: {
    projectKey: string,
    names: Array<string>,
  }): Promise<GetSegmentResponse> => {
    return request
      .post<GetSegmentResponse>(
        generateUrl('api_grpc_segment_service', {
          methodName: 'Get',
        }),
        {
          ...generateProjectKeyBlockRequest(projectKey),
          name: names,
        }
      )
      .then(resp => {
        if (!resp.segments) {
          throw new Error('not found')
        }
        return resp
      })
  },
}
