// @flow
// will pass query build context event to child condition, so the autocomplete knows where to look

import Immutable, { type Set } from 'immutable'
import * as React from 'react'

export const QueryBuilderContext: React.Context<{
  context: 'targeting' | 'event_filter',
  eventId: ?string,
  isSample: boolean,
  errors: Set<string>,
  touched: boolean,
  isProfileMode: boolean,
  queryId: string,
  maxDepthReached: boolean,
}> = React.createContext({
  context: 'targeting',
  errors: Immutable.Set(),
  touched: false,
  isSample: false,
  isProfileMode: false,
  eventId: null,
  queryId: 'main',
  maxDepthReached: false,
})
