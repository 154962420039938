// @flow

import * as React from 'react'
import { useSelector } from 'react-redux'

import {
  useIsCurrentUserAllowedTo,
  useCurrentCompanyHasFeature,
  useCurrentCompanyHasOneFeatureAmongst,
} from 'components/_hooks'
import { CreateCampaignDropdownItem } from 'components/campaign/dropdowns/create-campaign-dropdown-item'
import { Dropdown } from 'components/common/button'
import { Grid } from 'components/common/grid'

import { generateOrchestrationUrl, generateUrl } from 'com.batch.common/router'

import { optionalCurrentProjectSelector } from '../../../redux/project.selector'
import { type AppRecord } from 'com.batch.redux/_records'
import { currentAppCanPush, currentAppSelector } from 'com.batch.redux/app'

type CreateCampaignDropdownProps = {
  isAutomation: boolean,
  schedulingType: string,
  companyId: number,
  projectId: ?string,
  hasThemes: boolean,
}

export const CreateCampaignDropdown = ({
  isAutomation,
  schedulingType,
  companyId,
  projectId,
  hasThemes,
}: CreateCampaignDropdownProps): React.Node => {
  const canPush: boolean = useSelector(currentAppCanPush)
  const app: AppRecord = useSelector(currentAppSelector)
  const maybeProject = useSelector(optionalCurrentProjectSelector)
  const isAllowedToUpdatePush = useIsCurrentUserAllowedTo(['app', 'push:write'])
  const isAllowedToUpdateInApp = useIsCurrentUserAllowedTo(['app', 'in-app:write'])
  const currentCompanyCanUseInApp = useCurrentCompanyHasFeature('inapp-campaigns')
  const currentCompanyCanUseMepRecurringOrTrigger = useCurrentCompanyHasOneFeatureAmongst([
    'cep-show-legacy-recurring',
    'cep-show-legacy-trigger',
  ])
  const currentCompanyCanUseMepCampaign = useCurrentCompanyHasFeature('cep-show-legacy-campaign')
  const currentCompanyCanUseMepCampaignOmni = useCurrentCompanyHasFeature(
    'cep-show-legacy-campaign-omni'
  )

  const hideStandardPushOption = React.useMemo(() => {
    if (!maybeProject) {
      return false
    }
    if (isAutomation && !currentCompanyCanUseMepRecurringOrTrigger) {
      return true
    }
    if (!isAutomation && !currentCompanyCanUseMepCampaign) {
      return true
    }
    return false
  }, [
    currentCompanyCanUseMepCampaign,
    currentCompanyCanUseMepRecurringOrTrigger,
    isAutomation,
    maybeProject,
  ])
  console.log({ isAutomation, currentCompanyCanUseMepCampaign, hideStandardPushOption })

  const userNotAllowedInApp: boolean = React.useMemo(
    () => !isAllowedToUpdateInApp || !currentCompanyCanUseInApp,
    [currentCompanyCanUseInApp, isAllowedToUpdateInApp]
  )
  const userNotAllowedPush: boolean = React.useMemo(
    () => !isAllowedToUpdatePush || !canPush,
    [isAllowedToUpdatePush, canPush]
  )

  const validConfigNeededMessage = `You need a valid ${
    app.platform === 'ios'
      ? 'APNS certificate'
      : app.platform === 'android'
        ? 'FCM and/or HMS certificate'
        : app.platform === 'webpush'
          ? 'Web push API config'
          : 'certificate'
  }`

  return (
    <Dropdown
      label={isAutomation ? 'New automation' : 'New campaign'}
      buttonProps={{
        intent: 'action',
        kind: 'primary',
      }}
      forcedWidth={180}
      placement="bottom-end"
      offset={[0, 5]}
    >
      <React.Fragment>
        {!hideStandardPushOption && (
          <CreateCampaignDropdownItem
            disabled={userNotAllowedPush}
            to={generateOrchestrationUrl({
              page: 'form',
              companyId,
              projectId,
              token: 'new',
              sendType: schedulingType === 'campaigns' ? 'scheduled' : 'trigger',
              channel: { appId: app.id },
            })}
            tooltip={
              canPush ? 'Upgrade your plan to create Push notification' : validConfigNeededMessage
            }
          >
            Push notification
          </CreateCampaignDropdownItem>
        )}
        {isAutomation && (
          <CreateCampaignDropdownItem
            disabled={userNotAllowedInApp || !hasThemes}
            to={generateOrchestrationUrl({
              page: 'form',
              companyId,
              projectId,
              token: 'new',
              sendType: 'in-app',
              channel: { appId: app.id },
            })}
            tooltip={
              userNotAllowedInApp
                ? 'Upgrade your plan to create In-app message'
                : 'You need at least one theme to create an In-app campaign.'
            }
          >
            In-app message
          </CreateCampaignDropdownItem>
        )}

        {projectId !== undefined && !isAutomation && currentCompanyCanUseMepCampaignOmni && (
          <CreateCampaignDropdownItem
            disabled={userNotAllowedPush}
            // deprecated route but still used
            to={generateUrl('project_campaign_new_multiplatform', { companyId, projectId })}
            tooltip={
              canPush ? 'Upgrade your plan to create multiplatform push' : validConfigNeededMessage
            }
          >
            <Grid template="1fr auto">Push multiplatform</Grid>
          </CreateCampaignDropdownItem>
        )}
      </React.Fragment>
    </Dropdown>
  )
}
