// @flow
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

import { type StateToggler, useToggle } from 'components/_hooks'
import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { colors } from 'components/styled/tokens'
import { TemplateWizard } from 'components/template/template-wizard'

import {
  getAreRequiredEmailFieldsFilledSelector,
  getEmailContentForActiveLanguageSelector,
} from 'com.batch/email/store/email-automation.selector'
import {
  multilanguageEnabledSelector,
  previewLanguageSelector,
} from 'com.batch/message/store/message.selector'
import { personalisationAttrSelector } from 'com.batch.redux/attribute.selector'
import { triggerEventSelector } from 'com.batch.redux/campaign.selector'
import { currentCompanySelector } from 'com.batch.redux/company'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

import {
  stripoCodeEditorId,
  stripoPreviewId,
  stripoRedoId,
  stripoSettingsId,
  stripoUndoId,
} from 'com.batch/email/constants/stripo-container-ids'
import { initStripo } from 'com.batch/email/infra/services/stripo'
import { EmailEditorPopin } from 'com.batch/email/ui/components/email-editor-popin'
import { SendTestEmailButton } from 'com.batch/email/ui/components/send-test-email-button'
import {
  saveDragDropHtml,
  saveDragDropOptimizedTemplate,
} from 'com.batch/email/usecases/update-content'
import { updateEmailContent } from 'com.batch/email/usecases/update-email-content'
import { LangPicker } from 'com.batch/message-builder/ui/components/lang-picker/lang-picker'
import { Toolbar, ToolbarDivider } from 'com.batch/shared/ui/component/toolbar/toolbar.styles'
import { useLeaveConfirmation } from 'com.batch/shared/ui/hooks/use-leave-confirmation'

type DragAndDropEditorProps = {
  popinState: StateToggler,
  messageId: string,
  sendTestEmailPopinState: StateToggler,
}

export const DragAndDropEditor = ({
  popinState,
  messageId,
  sendTestEmailPopinState,
}: DragAndDropEditorProps): React.Node => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const getContent = useSelector(getEmailContentForActiveLanguageSelector)
  const content = React.useMemo(() => getContent(messageId), [getContent, messageId])
  const lang = useSelector(previewLanguageSelector)
  const project = useSelector(currentProjectSelector)
  const company = useSelector(currentCompanySelector)
  const contextEventId = useSelector(triggerEventSelector)
  const attributes = useSelector(personalisationAttrSelector)
  const multilanguageEnabled = useSelector(
    multilanguageEnabledSelector({ messageId, channel: 'email' })
  )

  const { confirmBeforeAction } = useLeaveConfirmation({
    isPristine: true,
    runtimeCheck: window.StripoApi?.allDataSaved,
  })

  const isLoadedState = useToggle(false)
  const toggleCodeEditor = useToggle(false)

  const pinIntercomButton = React.useCallback((shouldPin: boolean) => {
    const intercomLauncher = document.querySelector('.intercom-launcher')
    if (shouldPin) {
      if (intercomLauncher) {
        intercomLauncher.style.bottom = '76px'
      }
    } else {
      if (intercomLauncher) intercomLauncher.style.bottom = ''
      if (window?.Intercom) {
        window.Intercom('update', {
          vertical_padding: 76,
        })
      }
    }
  }, [])

  React.useEffect(() => {
    pinIntercomButton(true)

    return () => {
      pinIntercomButton(false)
    }
  }, [pinIntercomButton])

  const toggleCodeEditorCallback = React.useCallback(
    (isCodeEditorVisible: boolean) => {
      if (isCodeEditorVisible) {
        toggleCodeEditor.open()
      } else {
        toggleCodeEditor.close()
      }
    },
    [toggleCodeEditor]
  )

  const templateId = React.useMemo(
    () =>
      content?.htmlEditorConfig?.templateId ? content?.htmlEditorConfig?.templateId : uuidv4(),
    [content?.htmlEditorConfig?.templateId]
  )

  const dynamicContentPopinState = useToggle()
  const htmlBackup = React.useRef(content?.html)
  const appendText = React.useRef((value: string) =>
    console.error('appendText not initialized', { value })
  )

  // Cette fonction est donnée à Stripo pour qu'il puisse nous donner le callback à appeler pour insérer le contenu dynamique et ouvrir la popin
  const dynamicContentCallback = React.useCallback(
    (callback: (value: string) => void) => {
      appendText.current = callback
      dynamicContentPopinState.open()
    },
    [dynamicContentPopinState]
  )

  const save = React.useCallback(() => {
    dispatch(saveDragDropOptimizedTemplate({ lang, messageId })).catch()
  }, [dispatch, lang, messageId])

  const saveAndClose = React.useCallback(() => {
    dispatch(saveDragDropHtml({ lang, templateId, messageId })).then(popinState.close).catch()
  }, [dispatch, lang, popinState.close, templateId, messageId])

  const dismissPopin = React.useCallback(async () => {
    const confirmed = await confirmBeforeAction()
    if (confirmed) {
      dispatch(
        updateEmailContent({
          messageId,
          lang,
          field: 'html',
          value: htmlBackup.current ?? '',
          // Pas besoin de restaurer l'ancien htmlEditorConfig car il n'a pas été touché
          htmlEditorConfig: content?.htmlEditorConfig,
          isInstant: true,
        })
      )
      popinState.close()
    }
  }, [confirmBeforeAction, content?.htmlEditorConfig, dispatch, lang, messageId, popinState])

  const getAreRequiredEmailFieldsFilled = useSelector(getAreRequiredEmailFieldsFilledSelector)
  const areRequiredEmailFieldsFilled = React.useMemo(
    () => getAreRequiredEmailFieldsFilled(messageId),
    [getAreRequiredEmailFieldsFilled, messageId]
  )

  React.useEffect(() => {
    isLoadedState.close()

    initStripo({
      html: content?.htmlEditorConfig?.html ?? undefined,
      css: content?.htmlEditorConfig?.css ?? undefined,
      companyId: company.id,
      projectId: project.projectKey,
      templateId,
      dynamicContentCallback,
      isLoadedCallback: isLoadedState.open,
      toggleCodeEditorCallback,
      viewOnly: theme?.disabledMode,
    })

    return () => {
      window.StripoApi?.stop()
    }

    // C'est voulu que content.html ne soit pas dans les deps pour ne pas recharger stripo lorsqu'un save est effectué pour le sendtest
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, multilanguageEnabled])

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <TemplateWizard
        appendText={appendText.current}
        attributes={attributes}
        close={dynamicContentPopinState.close}
        contextEventId={contextEventId}
        opened={dynamicContentPopinState.value}
      />
      <EmailEditorPopin
        messageId={messageId}
        popinState={popinState}
        sendTestClickAddons={save}
        saveAction={saveAndClose}
        cancelAction={dismissPopin}
        templateId={templateId}
      >
        {multilanguageEnabled && (
          <LangPicker messageId={messageId} channel="email" templateId={templateId} />
        )}
        <div style={{ display: 'flex' }}>
          <div
            id={stripoSettingsId}
            style={{
              flexBasis: 360,
              maxHeight: `calc(100vh - (56px * 2) - ${multilanguageEnabled ? '52px' : '0px'})`,
              borderRight: `1px solid ${colors.stroke}`,
            }}
          ></div>

          {!isLoadedState.value && (
            <div
              style={{
                flexGrow: 1,
                textAlign: 'center',
                paddingTop: 30,
                background: '#F6F6F6',
              }}
            >
              <img src="/medias/img/canvas/email-preview.svg" style={{ width: '600px' }} />
            </div>
          )}

          <div
            style={{
              flexGrow: 1,
              display: isLoadedState.value ? 'block' : 'none',
            }}
          >
            <div
              id={stripoPreviewId}
              style={{
                maxHeight: `calc(100vh - (56px * 2) - ${multilanguageEnabled ? '52px' : '0px'})`,
              }}
            ></div>
            <Toolbar style={{ position: 'fixed', bottom: 34, zIndex: 220, left: '50%' }}>
              <Button id={stripoUndoId} addOn="prefix" type="button">
                <Icon icon="undo" />
                Undo
              </Button>

              <Button id={stripoRedoId} addOn="suffix" type="button">
                Redo
                <Icon icon="redo" />
              </Button>
              <ToolbarDivider />
              <Button isActive={toggleCodeEditor.value} id={stripoCodeEditorId}>
                HTML
              </Button>
              <ToolbarDivider />
              <SendTestEmailButton
                messageId={messageId}
                hasMandatoryInfo={areRequiredEmailFieldsFilled}
                togglePopinSendTest={sendTestEmailPopinState}
                onClickAddons={save}
              ></SendTestEmailButton>
            </Toolbar>
          </div>
        </div>
      </EmailEditorPopin>
    </div>
  )
}
