// @flow
import * as React from 'react'

import { Icon } from 'components/common/svg-icon'

import { pluralize } from 'com.batch.common/utils'

import { platforms as listPlatforms } from 'com.batch/profile/constants/platforms'
import { type ProfilePlatformRecord } from 'com.batch/profile/models/profile.records'
import { ProfilePlatformsCounterContainer } from 'com.batch/profile/ui/components/profile-hero/profile-hero.styles'

type Props = {
  platforms: Array<ProfilePlatformRecord>,
}

export const ProfilePlatformsCounter = ({ platforms }: Props): React.Node => (
  <ProfilePlatformsCounterContainer>
    <span>{pluralize('platform', platforms.length)}</span>
    {platforms.map(p => (
      <Icon key={listPlatforms[p.platform].icon} icon={listPlatforms[p.platform].icon} />
    ))}
  </ProfilePlatformsCounterContainer>
)
