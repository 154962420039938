// @flow

import styled, { type StyledComponent } from 'styled-components'

export const QueryBuilderWrapper: StyledComponent<{ ... }, {}, HTMLDivElement> = styled.div`
  max-height: calc(100vh - 520px);
  overflow-y: auto;

  .styled-empty {
    margin-left: 18px;
  }
`
