// @flow

import Immutable, { type List } from 'immutable'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { useToggle } from 'components/_hooks'
import {
  Box,
  BoxBody,
  BoxFooter,
  BoxHeader,
  BoxSection,
  HeaderBoxActions,
  HeaderBoxTitle,
} from 'components/common/box'
import { Button } from 'components/common/button'
import { Code } from 'components/common/code'
import { confirm } from 'components/common/confirm'
import { Grid } from 'components/common/grid'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'
import { Form, Input, InputWrapper, Select, TabButton, TabButtonItem } from 'components/form'

import { generateProfilesOrSettingsUrl } from 'com.batch.common/router/url-generator-helper'
import { formatIdentifier } from 'com.batch.common/utils'

import { type AppRecord } from 'com.batch.redux/_records'
import { optionalCurrentProjectSelector } from 'com.batch.redux/project.selector'
import { lookForUser } from 'com.batch.redux/stat.api.debug'

type GDPRNewProps = {
  app: AppRecord,
  handleSubmit: (
    idType: 'installation_id' | 'custom_id' | 'advertising_id',
    isErasure: boolean,
    value: string
  ) => Promise<any>,
  ...
}
type IDOptionType = 'advertising_id' | 'custom_id' | 'installation_id'
type IDOption = { label: string, type: IDOptionType }

const placeholderByType = {
  advertising_id: 'i.e. EA7583CD-A667-48BC-B806-42ECB2B48606',
  custom_id: 'i.e. jean-paul@batch.com',
  installation_id: 'i.e. cb4f844c-1e81-11eb-adc1-0242ac120002',
}
const optToString = (o: ?IDOption): string => o?.type ?? ''
export const GDPRNew = ({ app, handleSubmit }: GDPRNewProps): React.Node => {
  const navigate = useNavigate()
  const location = useLocation()
  const project = useSelector(optionalCurrentProjectSelector)

  const erasureState = useToggle()
  const [idType, setIdType] = React.useState('custom_id')
  const [value, setValue] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(false)

  const IDOptions: List<IDOption> = new Immutable.List().push(
    ...[
      { label: 'Custom user ID', type: 'custom_id' },
      { label: 'Installation ID', type: 'installation_id' },
    ],
    ...(app.platform !== 'webpush' ? [{ label: 'Advertising ID', type: 'advertising_id' }] : [])
  )
  const close = React.useCallback(
    () =>
      navigate(
        `${generateProfilesOrSettingsUrl({
          companyId: app.companyId ?? 0,
          appId: app.id,
          activeTab: 'privacy',
          projectId: project?.id,
        })}?${location.search}`
      ),
    [project, app.companyId, app.id, navigate, location.search]
  )
  // ====================== CALLBACK

  const confirmNotFound = React.useCallback(
    () =>
      confirm({
        message: (
          <div>
            <p>
              We did not find any <strong>{formatIdentifier(app.platform, idType)}</strong>{' '}
              <Code>{value}</Code>. If you are sure this data identifier is correct, you can send
              the request anyway.
            </p>
          </div>
        ),
        sensitive: true,
        title: 'There might be an issue with this data identifier',
        confirm: 'Submit anyway',
      }).then(
        () =>
          handleSubmit(idType, erasureState.value, value).then(() => setLoading(false) || close()),
        () => {
          setLoading(false)
        }
      ),
    [app.platform, close, handleSubmit, idType, erasureState.value, value]
  )

  const preSubmit = React.useCallback(() => {
    if (!value) {
      setError(true)
      return
    }
    setError(false)
    setLoading(true)
    lookForUser(
      app.id,
      value,
      idType === 'installation_id' ? 'di' : idType === 'custom_id' ? 'cid' : 'aid'
    ).then(
      r => {
        if (r && r.size > 0) {
          handleSubmit(idType, erasureState.value, value).then(() => setLoading(false) || close())
        } else {
          confirmNotFound()
        }
      },
      () => {
        confirmNotFound()
      }
    )
  }, [value, app.id, idType, handleSubmit, erasureState.value, close, confirmNotFound])
  const onIdOptionChange = React.useCallback(opt => {
    if (opt) setIdType(opt.type)
  }, [])
  const onValueChange = React.useCallback(evt => setValue(evt.target.value), [])
  return (
    <ThemeProvider theme={{ isEmpty: false }}>
      <Popin opened close={close}>
        <Form onSubmit={preSubmit}>
          <Box style={{ maxWidth: 680 }} noBorder>
            <BoxHeader>
              <HeaderBoxTitle title="Request" />
              <HeaderBoxActions>
                <Button kind="inline" intent="neutral" onClick={close} type="button">
                  <Icon icon="close" />
                </Button>
              </HeaderBoxActions>
            </BoxHeader>
            <BoxBody style={{ overflow: 'hidden' }}>
              <BoxSection $padding style={{ backgroundColor: '#FCFCFC' }}>
                <p>
                  Batch has taken measures to comply with the requisites of data protection
                  regulations, which is why this page provides a way to let you handle Data Access
                  and Data Removal requests easily.
                </p>
                <br />
                <p>
                  Requests must be submitted for every Batch app separately at the moment. Requests
                  must provide a valid data subject identifier (eg: customer-level or device-level
                  ID).
                </p>
              </BoxSection>
              <BoxSection $padding>
                <InputWrapper label="Identifier" feedback={error ? 'Please enter a value' : false}>
                  <Grid template="170px 1fr">
                    <Select
                      value={IDOptions.find(o => o.type === idType)}
                      optionToString={optToString}
                      onChange={onIdOptionChange}
                      options={IDOptions}
                      style={{ width: 160 }}
                    />
                    <Input
                      placeholder={placeholderByType[idType]}
                      value={value}
                      invalid={error}
                      onChange={onValueChange}
                    />
                  </Grid>
                </InputWrapper>
                <InputWrapper label="Type of request">
                  <TabButton>
                    <TabButtonItem isActive={!erasureState.value} onClick={erasureState.close}>
                      Data access
                    </TabButtonItem>
                    <TabButtonItem isActive={erasureState.value} onClick={erasureState.open}>
                      Data erasure
                    </TabButtonItem>
                  </TabButton>
                </InputWrapper>
              </BoxSection>
            </BoxBody>
            <BoxFooter>
              <Button
                kind="primary"
                intent={erasureState.value ? 'danger' : 'action'}
                isLoading={loading}
                type="submit"
              >
                Submit data {erasureState.value ? 'erasure' : 'access'} request
              </Button>
            </BoxFooter>
          </Box>
        </Form>
      </Popin>
    </ThemeProvider>
  )
}
