// @flow

import { type List } from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'

import { useToggle, useUserHasPermission } from 'components/_hooks'
import {
  Button,
  ButtonNavLink,
  SplitButtonContainer,
  DropdownMenu,
  useDropdown,
} from 'components/common/button'
import { confirm } from 'components/common/confirm'
import { Wrapper, InappEmptyIcon, GlobalErrorOverlayProps } from 'components/common/empty-states'
import { Grid } from 'components/common/grid'
import { trackEvent } from 'components/common/page-tracker'
import { Icon } from 'components/common/svg-icon'
import { FilterSearch, FilterSelectMulti, FilterCounterOption } from 'components/filter'
import { Title } from 'components/styled/text'

import config from 'com.batch.common/config'
import { generateUrl } from 'com.batch.common/router'

import { OtherThemeModal } from './other-theme-modal'
import { ThemeThumb } from './theme-thumb'
import { ThemeFormatsList, type ThemeFormat } from './theme-utils'
import {
  TitleContainer,
  Separator,
  ThemeListContainer,
  ThemeThumbPlaceholder,
  ThemeFilterEmptyContainer,
} from './theme.styles'

import { type AppRecord } from 'com.batch.redux/_records'
import { currentAppSelector } from 'com.batch.redux/app'
import { ArchiveTheme, FetchThemes, newThemeInit } from 'com.batch.redux/theme'
import { type SampleDataRecord, type ThemeRecord } from 'com.batch.redux/theme.records'
import {
  OtherThemesSelector,
  ThemesFetchedSelector,
  ThemesLoadingSelector,
  ThemesErrorSelector,
  ThemesSelector,
} from 'com.batch.redux/theme.selector'
import { currentUserSelector } from 'com.batch.redux/user.selector'

const optionToString = (opt: ?ThemeFormat) => opt?.label ?? ''

// ====================== ThemeList COMPONENT
export const ThemeList = (): React.Node => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  // ====================== Redux state
  const app: AppRecord = useSelector(currentAppSelector)
  const themesList: List<ThemeRecord> = useSelector(ThemesSelector)
  const otherThemes: List<ThemeRecord> = useSelector(OtherThemesSelector)
  const newTheme: ThemeRecord = useSelector(state => state.theme.entities.get('new'))
  const loading: boolean = useSelector(ThemesLoadingSelector)
  const fetched: boolean = useSelector(ThemesFetchedSelector)
  const error: boolean = useSelector(ThemesErrorSelector)
  const sample: SampleDataRecord = useSelector(state => state.theme.sample)
  const user = useSelector(currentUserSelector)

  // ====================== Component state
  const otherAppModalState = useToggle()
  // toggle dropdown
  const { dropdownProps, triggerProps, closeDropdown } = useDropdown({
    placement: 'bottom',
    offset: [-100, 5],
  })

  const query = React.useMemo(() => new URLSearchParams(location.search), [location.search])
  const searchQuery = query.get('search') ?? ''

  const themeQuery = query.get('theme') ?? ''
  const themeQueryList = themeQuery.split(',')
  const themeQueryValue = React.useMemo(
    () => ThemeFormatsList.filter(elm => themeQueryList.includes(elm.value)),
    [themeQueryList]
  )
  const searchInProgress: boolean = React.useMemo(
    () => searchQuery.length > 0 || themeQuery.length > 0,
    [searchQuery.length, themeQuery.length]
  )

  const init = React.useMemo(
    () => themesList.size === 0 && !fetched && !error && !loading,
    [error, fetched, loading, themesList.size]
  )

  const themeFiltered = React.useMemo(
    () =>
      themesList.filter(
        e =>
          e.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
          (themeQuery.length === 0 || themeQuery.includes(e.payloadVars.kind ?? ''))
      ),
    [themesList, themeQuery, searchQuery]
  )

  const userAllowed = useUserHasPermission(user, ['app', 'themes:write'])

  const templateSize = React.useMemo(
    () =>
      window.innerWidth > 1500
        ? 10
        : window.innerWidth > 1260
          ? 8
          : window.innerWidth > 730
            ? 6
            : 4,
    []
  )
  // ====================== Empty state
  const emptyThemeThumbList = React.useMemo(() => Array(templateSize).fill(''), [templateSize])
  const emptyThemesList = React.useMemo(
    () => (themesList.size === 0 && !loading) || error,
    [error, loading, themesList.size]
  )
  const emptyThemesNoSearch = React.useMemo(
    () => (themesList.size === 0 && !searchInProgress) || error || loading || init,
    [error, init, loading, searchInProgress, themesList.size]
  )

  // ====================== useEffect
  React.useEffect(() => {
    if (!fetched && !error) dispatch(FetchThemes())
  }, [fetched, app, dispatch, error])

  // Reset new theme datas when we have dupplicated an other one before
  React.useEffect(() => {
    if (newTheme.payloadVars.kind !== null) dispatch(newThemeInit())
  }, [newTheme, dispatch])

  // ====================== Callbacks
  const onConfirmThemeDeletion = React.useCallback(
    (theme: ThemeRecord) => () =>
      confirm({
        message: (
          <article>
            <p>This theme will be permanently removed from your registered themes list.</p>
            {theme.nbCampaignsRunning > 0 && (
              <p>
                This theme is currently used in <code>{theme.nbCampaignsRunning}</code> running
                campaigns.
              </p>
            )}
            {theme.nbCampaignsRunning > 0 && (
              <p>
                You can create a new theme for this app, or attach an existing theme.
                <br />
                <br />
                <br />
              </p>
            )}
          </article>
        ),
        title: 'Delete this theme?',
        sensitive: true,
      }).then(
        () => {
          dispatch(ArchiveTheme(theme))
        },
        () => {}
      ),
    [dispatch]
  )
  const onFormatFilterChange = React.useCallback(
    opt => {
      const optList = opt ? opt.map(e => e.value).join() : ''
      navigate({ search: `?search=${searchQuery}&theme=${optList}` })
      trackEvent('THEMES_FILTERED', {
        filter_type: 'format',
        filter_source: 'theme_list',
      })
    },
    [navigate, searchQuery]
  )
  const onQueryChange = React.useCallback(
    query => {
      navigate({
        search: `?search=${encodeURIComponent(query)}&theme=${themeQuery}`,
      })
      trackEvent('THEMES_FILTERED', {
        filter_type: 'search',
        filter_source: 'theme_list',
      })
    },
    [navigate, themeQuery]
  )
  const filterTerm = React.useMemo(
    () => count => (count === 1 ? themeQueryValue.first().label : `${count} formats`),
    [themeQueryValue]
  )
  const formatFilterFormatter = React.useMemo(
    () => opt => {
      return (
        <FilterCounterOption
          label={opt.label}
          counter={themesList.filter(f => f.payloadVars.kind === opt.value).size}
        />
      )
    },
    [themesList]
  )
  const onOpenOtherApps = React.useCallback(() => {
    closeDropdown()
    otherAppModalState.open()
  }, [closeDropdown, otherAppModalState])

  // ====================== Render
  return (
    <React.Fragment>
      <Grid
        template={
          !emptyThemesNoSearch
            ? 'minmax(100px, 1fr) auto auto 9px 100px 33px'
            : 'minmax(100px, 1fr) 150px'
        }
        gap={8}
        style={{ marginBottom: 38 }}
      >
        <TitleContainer>
          <Title>Themes</Title>
          {themesList.size > 0 && !loading && <span>{themeFiltered.size}</span>}
        </TitleContainer>

        {!emptyThemesNoSearch && (
          <React.Fragment>
            <FilterSearch
              identifier={'theme'}
              value={searchQuery}
              onChange={onQueryChange}
              disabled={themesList.size === 0 || loading}
              expandedMaxWidth={240}
            />
            <FilterSelectMulti
              isSearchable={false}
              style={{ width: '100%' }}
              optionToString={optionToString}
              options={ThemeFormatsList}
              term={filterTerm}
              placeholder="Any format"
              value={themeQueryValue}
              onChange={onFormatFilterChange}
              isDisabled={themesList.size === 0 || loading}
              optionFormatter={formatFilterFormatter}
            />

            <Separator />
          </React.Fragment>
        )}
        <SplitButtonContainer>
          <ButtonNavLink
            kind="primary"
            intent="action"
            to={generateUrl('theme_editor', {
              companyId: app.companyId,
              appId: app.id,
              id: 'new',
            })}
            data-testid="btn-new-theme"
          >
            New theme
          </ButtonNavLink>
          <Button kind="primary" intent="action" {...triggerProps}>
            <Icon icon="options" />
          </Button>
          <DropdownMenu {...dropdownProps}>
            <ButtonNavLink
              disabled={!userAllowed}
              to={config.common.urls.appSettingsThemeEditor
                .replace('{companyId}', app.companyId)
                .replace('{appId}', app.id)
                .replace('{id}', 'new')}
            >
              Create new theme
            </ButtonNavLink>
            <Button disabled={otherThemes.size === 0 || !userAllowed} onClick={onOpenOtherApps}>
              Browse themes from other apps
            </Button>
          </DropdownMenu>
        </SplitButtonContainer>
      </Grid>

      <Wrapper
        isEmpty={emptyThemesList}
        isLoading={loading}
        isOverlayShown={!loading && emptyThemesList}
        overlayProps={
          error
            ? GlobalErrorOverlayProps
            : {
                status: 'empty-page',

                title: 'Create your first in-app theme',
                description:
                  'Compose fullscreen, banner, modal or entirely custom In-app messages and engage any user on your app.',
                content: <InappEmptyIcon />,
                links: [
                  {
                    name: 'Managing In-App themes',
                    href: 'https://help.batch.com/en/articles/1871294-how-to-manage-my-in-app-themes-and-use-them-for-my-campaigns',
                  },
                  {
                    name: 'Choose the right format',
                    href: 'https://doc.batch.com/dashboard/settings/app-settings#choosing-a-format',
                  },
                ],
              }
        }
      >
        {themeFiltered.size > 0 && !error ? (
          <ThemeListContainer>
            {themeFiltered.map(theme => (
              <ThemeThumb
                app={app}
                key={theme.id}
                theme={theme}
                archive={onConfirmThemeDeletion(theme)}
                data={sample.data}
                userNotAllowed={!userAllowed}
              />
            ))}
          </ThemeListContainer>
        ) : searchInProgress ? (
          <ThemeFilterEmptyContainer kind="basic">
            <h3>No results</h3>
            <p>There’s nothing matching your criterias, try something else</p>
          </ThemeFilterEmptyContainer>
        ) : (
          <ThemeListContainer>
            {emptyThemeThumbList.map((f, i) => (
              <ThemeThumbPlaceholder key={i} />
            ))}
          </ThemeListContainer>
        )}
      </Wrapper>

      <OtherThemeModal
        app={app}
        themes={otherThemes}
        data={sample.data}
        opened={otherAppModalState.value}
        close={otherAppModalState.close}
      />
    </React.Fragment>
  )
}
