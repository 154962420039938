// @flow
import * as React from 'react'
import { useSelector } from 'react-redux'

import {
  lastVisitInstallByPlatformSelector,
  profileDataStateSelector,
  importedTokensSelector,
} from 'com.batch/profile/store/profile.selector'
import { currentProjectAppsSelector } from 'com.batch.redux/app'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

import { convertPlatformData } from 'com.batch/profile/infra/formats/convert-platform-data'
import { type IdentityRecord } from 'com.batch/profile/models/profile.records'
import { ProfileHeroBody } from 'com.batch/profile/ui/components/profile-hero/profile-hero-body'
import { ProfileHeroFooter } from 'com.batch/profile/ui/components/profile-hero/profile-hero-footer'
import { ProfileHeroHeader } from 'com.batch/profile/ui/components/profile-hero/profile-hero-header'
import { ProfileHeroContainer } from 'com.batch/profile/ui/components/profile-hero/profile-hero.styles'
import { CollapsePanel } from 'com.batch/shared/ui/component/collapse/collapse'

type Props = {
  identity: IdentityRecord,
  isLoading: boolean,
  profileId: string,
  isRefresh: boolean,
  dataLoaded: boolean,
  hasError: boolean,
}

export const ProfileHero = ({
  isLoading,
  identity,
  profileId,
  isRefresh,
  dataLoaded,
  hasError,
}: Props): React.Node => {
  const profileData = useSelector(profileDataStateSelector)
  const installs = useSelector(lastVisitInstallByPlatformSelector)
  const importedTokens = useSelector(importedTokensSelector)
  const apps = useSelector(currentProjectAppsSelector)
  const project = useSelector(currentProjectSelector)

  const [isBodyShown, setIsBodyShown] = React.useState<boolean>(false)
  const [profilePhoneNumber, setProfilePhoneNumber] = React.useState('')

  const platforms = React.useMemo(
    () =>
      convertPlatformData({
        installs,
        importedTokens,
        apps,
        companyId: project.companyId,
      }),
    [installs, importedTokens, apps, project.companyId]
  )

  React.useEffect(() => {
    if (profileData.phoneNumber)
      import(
        /* webpackChunkName: "libphonenumber" */
        /* webpackMode: "lazy" */
        'libphonenumber-js'
      ).then(parsePhoneNumber => {
        setProfilePhoneNumber(parsePhoneNumber(profileData.phoneNumber).formatInternational())
      })
  }, [profileData.phoneNumber])

  const handleOnToggle = React.useCallback((isToggled: boolean) => {
    setIsBodyShown(isToggled)
  }, [])

  React.useEffect(() => {
    if ((dataLoaded && !isRefresh) || hasError) {
      setIsBodyShown(false)
    }
  }, [dataLoaded, isRefresh, hasError])

  return (
    <ProfileHeroContainer>
      <ProfileHeroHeader
        isLoading={isLoading}
        identity={identity}
        email={profileData.email}
        profileId={profileId}
        timezone={profileData.timezone}
        lastVisitDate={profileData.lastVisit.date}
      />
      <CollapsePanel isOpened={isBodyShown}>
        <ProfileHeroBody
          platforms={platforms}
          email={profileData.email}
          phoneNumber={profilePhoneNumber}
          subscription={profileData.subscription}
          isLoading={isLoading}
        />
      </CollapsePanel>
      <ProfileHeroFooter
        isBodyShown={isBodyShown}
        onToggle={handleOnToggle}
        email={profileData.email}
        phoneNumber={profilePhoneNumber}
        platforms={platforms}
      />
    </ProfileHeroContainer>
  )
}
