// @flow

import Immutable, { type Map, type List } from 'immutable'
import * as React from 'react'
import { connect } from 'react-redux'
import request from 'superagent-interface-promise'

import { AppAvatar as Avatar } from 'components/common/avatar'
import { Grid } from 'components/common/grid'
import { Tag } from 'components/common/tag'
import { Select } from 'components/form'
import { LightText } from 'components/styled/text'

import { generateUrl } from 'com.batch.common/router'

import { Ellipsis } from '../console.style'
import { type State } from '../redux/console.records'
import { type AppRecord, type Dispatch } from 'com.batch.redux/_records'
import { normalizeApp } from 'com.batch.redux/app.api'
import { getPlanFromCode } from 'com.batch.redux/billing.api'

type OwnProps = {
  isClearable?: boolean,
  width?: number,
  noProject?: boolean,
  companyId?: number,
  platform?: ProjectPlatforms,
  placeholder?: string,
  appId?: number,
  setApp: (c: ?AppRecord) => void,
}
type StateProps = {
  apps: Map<number, AppRecord>,
}
type DispatchProps = {
  dispatch: Dispatch,
}
type AppPickerProps = { ...OwnProps, ...StateProps, ...DispatchProps }
const appToString = (opt: ?AppRecord) => opt?.id?.toString() ?? ''

const AppPickerRaw = ({
  appId,
  apps,
  width = 300,
  platform,
  noProject = false,
  setApp,
  placeholder = 'Search for an app',
  isClearable = false,
  companyId,
}: AppPickerProps) => {
  const app = React.useMemo(
    () => (appId && apps.has(appId) ? apps.get(appId) : null),
    [appId, apps]
  )
  const fetchApps = React.useCallback(
    (inputValue: string): Promise<List<AppRecord>> => {
      let params: {
        query: string,
        noProject: string,
        companyId?: number,
        platform?: ProjectPlatforms,
        ...
      } = {
        query: inputValue,
        noProject: noProject ? 'true' : 'false',
      }
      if (companyId) params.companyId = companyId
      if (platform) params.platform = platform
      return request.get(generateUrl('console_api_apps', params)).then(({ body }) => {
        const apps = new Immutable.List().push(...body.entities.map(normalizeApp))
        // loadAppsSuccess({ count: parseInt(body.count), entities:  new Immutable.List().push(...apps) })
        return apps
      })
    },
    [companyId, noProject, platform]
  )
  return (
    <Select
      style={{ width }}
      loadOptions={fetchApps}
      value={app}
      onChange={setApp}
      optionMenuHeight={53}
      optionMenuShownCount={7}
      placeholder={placeholder}
      isClearable={isClearable}
      optionToString={appToString}
      isSearchable={true}
      optionFormatter={AppOption}
    />
  )
}

const AppOption = (app: AppRecord) => {
  return (
    <Grid template="40px 1fr 70px 40px">
      <Avatar placeholder={app.name.split(' ')[0]} platform={app.platform} url={app.icon} />
      <Ellipsis>{app.name}</Ellipsis>
      <Tag>{getPlanFromCode(app.status).name}</Tag>
      <LightText>{app.id}</LightText>
    </Grid>
  )
}
const mapStateToProps = (state: State): StateProps => {
  return {
    apps: state.app.entities,
  }
}

export const AppPicker: React.AbstractComponent<OwnProps> = connect<
  AppPickerProps,
  OwnProps,
  StateProps,
  DispatchProps,
  _,
  _,
>(mapStateToProps)(AppPickerRaw)
