// @flow
import setTimeout from 'core-js/features/set-timeout'
import * as React from 'react'
import { useDispatch } from 'react-redux'

import { BoxTitle } from 'components/common/box'
import { Button } from 'components/common/button'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'
import { LinkArrow } from 'components/styled/text'
import { Separator } from 'components/styled/utils'

import { platforms } from 'com.batch/profile/constants/platforms'
import { type ProfilePlatformRecord } from 'com.batch/profile/models/profile.records'
import { ProfileCopyableId } from 'com.batch/profile/ui/components/profile-hero/profile-copyable-id/profile-copyable-id'
import {
  PlatformModalBox,
  PlatformModalBoxHeader,
  PlatformModalBody,
  ButtonReload,
} from 'com.batch/profile/ui/components/profile-hero/profile-platforms/platform-modal.styles'
import { ProfileMockupOptin } from 'com.batch/profile/ui/components/profile-hero/profile-platforms/profile-mockup-optin'
import { refreshPlatform } from 'com.batch/profile/usecases/refresh-platform'

type Props = {
  opened: boolean,
  close: () => void,
  platform: ProfilePlatformRecord,
}
export const PlatformModal = ({ opened, close, platform }: Props): React.Node => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const platformData = React.useMemo(() => platforms[platform.platform], [platform.platform])

  const handleOnClickReload = React.useCallback(() => {
    if (platform.kind === 'native') {
      setIsLoading(true)
      dispatch(refreshPlatform({ installId: platform.installId })).finally(() => {
        const loadingTimeout = setTimeout(() => setIsLoading(false), 500)
        return () => clearTimeout(loadingTimeout)
      })
    }
  }, [dispatch, platform.kind, platform.installId])

  return (
    <Popin opened={opened} close={close}>
      <PlatformModalBox>
        <PlatformModalBoxHeader>
          <BoxTitle>
            <Icon icon={platformData.icon} style={{ marginRight: 8 }} />
            {platform.kind === 'imported'
              ? `Unknow ${platform.platform} device`
              : platform.deviceName}
            {platform.kind === 'imported' && (
              <span>
                <Icon icon="import" />
                Imported token
              </span>
            )}
          </BoxTitle>
          {platform.kind === 'native' && (
            <React.Fragment>
              <ButtonReload onClick={handleOnClickReload} disabled={isLoading}>
                <Icon icon="reload" />
              </ButtonReload>
              <Separator />
            </React.Fragment>
          )}
          <Button onClick={close}>
            <Icon icon="close" />
          </Button>
        </PlatformModalBoxHeader>
        <PlatformModalBody>
          <div>
            <ProfileCopyableId
              label="Installation ID"
              id={platform.installId}
              noIdMessage="Not available"
              isLoading={false}
            />
            <ProfileCopyableId
              label="Push token"
              id={platform.pushToken}
              noIdMessage="No push token"
              isLoading={false}
            />
            {platform.debugUrl && (
              <LinkArrow intent="action" target="_blank" href={platform.debugUrl}>
                Inspect in app debug
              </LinkArrow>
            )}
          </div>
          <ProfileMockupOptin
            kind={platform.kind}
            size="expanded"
            device={platform.deviceName}
            subscriptionStatus={platform.subscriptionStatus}
            notifType={platform.notifType}
            isLoading={isLoading}
          />
        </PlatformModalBody>
      </PlatformModalBox>
    </Popin>
  )
}
