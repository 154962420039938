// @flow

import * as React from 'react'
import { createPortal } from 'react-dom'
import { useSelector } from 'react-redux'

import { useAction } from 'components/_hooks'
import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { LinkDoc } from 'components/styled/text'

import { InfoPanelContainer, InfoPanelContent, InfoPanelHeader } from './info-panel.styles'

import { inlineEditorConfigSelector } from 'com.batch/message-builder/store/inline-editor.selector'

import {
  type MessageBuilderEditableField,
  type MessageBuilderField,
} from 'com.batch/message-builder/models/message-builder-field'
import { FieldInfoPanel } from 'com.batch/message-builder/ui/components/field-info-panel'
import { setInlineEditor } from 'com.batch/message-builder/usecases/manage-inline-editor'

type InfoPanelProps = {
  variant: 'a' | 'b',
  field: MessageBuilderField,
  messageId: string,
  clearEditing?: () => void,
  canUseTemplating: boolean,
  value: string,
  style?: { [string]: string | number, ... },
}

const LabelForField = {
  image: 'Image',
  icon: 'Icon',
  message: 'Body',
  pushBody: 'Body',
  smsMessage: 'Message',
  title: 'Title',
  pushTitle: 'Title',
  pushPicture: 'Image',
  androidIcon: 'Image',
  subject: 'Subject',
  replyTo: 'Reply to',
}
const dynamicContentAllowedFields: MessageBuilderEditableField[] = [
  'title',
  'message',
  'pushTitle',
  'pushBody',
  'smsMessage',
]
export const InfoPanel: React$AbstractComponent<InfoPanelProps, *> = React.forwardRef(
  ({ messageId, field, value, clearEditing, canUseTemplating, style }: InfoPanelProps, ref) => {
    const setInlineEditorBound = useAction(setInlineEditor)
    const inlineEditorConfig = useSelector(inlineEditorConfigSelector)

    const openTemplateWizard = React.useCallback(() => {
      setInlineEditorBound(inlineEditorConfig.set('personalizationModalIsOpen', true))
    }, [inlineEditorConfig, setInlineEditorBound])

    if (!field) return null

    return (
      <InfoPanelContainer ref={ref} style={style}>
        <InfoPanelHeader>
          <h3>{LabelForField[field]}</h3>

          {field === 'smsMessage' && (
            <LinkDoc
              href="https://help.batch.com/en/articles/9208297-how-does-character-encoding-impact-the-size-of-my-sms"
              intent="action"
              target="_blank"
            >
              Help?
            </LinkDoc>
          )}
        </InfoPanelHeader>

        <FieldInfoPanel
          messageId={messageId}
          field={field}
          value={value}
          clearEditing={clearEditing}
        />

        {dynamicContentAllowedFields.includes(field) && (
          <InfoPanelContent>
            <Button
              kind="inline"
              intent="neutral"
              addOn="prefix"
              disabled={!canUseTemplating}
              onClick={openTemplateWizard}
              style={{
                textAlign: 'start',
                width: '100%',
              }}
            >
              <Icon icon="macros" />
              Insert variable
            </Button>
          </InfoPanelContent>
        )}
      </InfoPanelContainer>
    )
  }
)

export const InfoPanelPortaled: React$AbstractComponent<InfoPanelProps, *> = React.forwardRef(
  (props: InfoPanelProps, ref): React.Node => {
    const el = document.getElementById(`js-variant-${props.variant}`)
    if (!el) return null

    return createPortal(<InfoPanel {...props} ref={ref} />, el)
  }
)
